import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const volunteerRouter: RouteConfig[] = [
  {
    path: "volunteer",
    component: AppContent,
    name: "Volunteer",
    redirect: "/volunteer/volunteer-list",
    meta: {
      title: "志愿者管理",
    },
    children: [
      {
        path: "volunteer/list",
        name: "VolunteerList",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/volunteer/volunteer/list.vue"
          ),
        meta: {
          title: "志愿者信息维护",
        },
      },
      {
        path: "volunteer/audit-list",
        name: "VolunteerAuditList",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/volunteer/volunteer/audit-list.vue"
          ),
        meta: {
          title: "志愿者申请审核",
        },
      },
      {
        path: "volunteer/edit/:id",
        name: "volunteerEdit",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/volunteer/volunteer/edit.vue"
          ),
        meta: {
          title: "修改志愿者",
        },
      },
      {
        path: "volunteer/create",
        name: "volunteerCreate",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/volunteer/volunteer/edit.vue"
          ),
        meta: {
          title: "新增志愿者",
        },
      },
      {
        path: "volunteer/audit/detail/:id",
        name: "VolunteerAuditDetail",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/volunteer/volunteer/audit-detail.vue"
          ),
        meta: {
          title: "志愿者申请详情",
        },
      },
      {
        path: "volunteer/:id?",
        name: "VolunteerDetail",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/volunteer/volunteer/detail.vue"
          ),
        meta: {
          title: "志愿者详情",
        },
      },
      {
        path: "orgVolunteerApply",
        name: "orgVolunteerApply",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/admin/orgVolunteerApply/index.vue"
          ),
        meta: {
          title: "志愿者表单自定义",
        },
      },
      {
        path: "volunteerRegister",
        name: "VolunteerRegister",
        component: () =>
          import(
            /* webpackChunkName: "volunteer" */ "@/views/volunteer/volunteer/register.vue"
            ),
        meta: {
          title: "注册用户",
        },
      },
    ],
  },
];

export default volunteerRouter;
