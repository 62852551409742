import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const organizationApplyRouter: RouteConfig[] = [
  {
    path: "organizationApply",
    component: AppContent,
    name: "organizationApply",
    meta: {
      title: "合作方申请管理",
    },
    children: [
      {
        path: "list",
        name: "organizationApplyList",
        component: () => import("@/views/organizationApply/list.vue"),
        meta: {
          title: "合作方申请列表",
        },
      },
      {
        path: "edit/:id",
        name: "organization-apply-edit",
        component: () => import("@/views/organization/edit.vue"),
        meta: {
          title: "修改合作方",
        },
      },
      {
        path: "detail/:id",
        name: "organizationApplyDetail",
        component: () => import("@/views/organizationApply/detail.vue"),
        meta: {
          title: "合作方申请详情",
        },
      },
    ],
  },
];

export default organizationApplyRouter;
