import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const examRouter: RouteConfig[] = [
  {
    path: "examMaintain",
    component: AppContent,
    name: "examMaintain",
    redirect: "",
    meta: {
      title: "题库管理",
    },
    children: [
      {
        path: "questionList",
        name: "questionList",
        component: () => import("@/views/exam/questionList.vue"),
        meta: {
          title: "试题管理",
        },
      },
      {
        path: "question/create",
        name: "questionCreate",
        component: () => import("@/views/exam/edit.vue"),
        meta: {
          title: "新增试题",
        },
      },
      {
        path: "question/edit/:id",
        name: "questionEdit",
        component: () => import("@/views/exam/edit.vue"),
        meta: {
          title: "编辑试题",
        },
      },
    ],
  },
];

export default examRouter;
