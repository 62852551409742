import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const trainManagementRouter: RouteConfig[] = [
  {
    path: "trainManagement",
    component: AppContent,
    name: "trainManagement",
    redirect: "",
    meta: {
      title: "培训管理",
    },
    children: [
      {
        path: "trainList",
        name: "trainList",
        component: () => import("@/views/train/maintain/list.vue"),
        meta: {
          title: "培训列表",
        },
      },
      {
        path: "trainList/detail/:id",
        name: "trainDetail",
        component: () => import("@/views/train/maintain/detail.vue"),
        meta: {
          title: "培训详情",
        },
      },
      {
        path: "trainList/create",
        name: "trainCreate",
        component: () => import("@/views/train/maintain/edit.vue"),
        meta: {
          title: "新增培训",
        },
      },
      {
        path: "trainList/edit/:id",
        name: "trainEdit",
        component: () => import("@/views/train/maintain/edit.vue"),
        meta: {
          title: "编辑培训",
        },
      },
      //培训报名
      {
        path: "trainApplyManage",
        name: "trainApplyManage",
        component: () => import("@/views/train/trainApply/trainList.vue"),
        meta: {
          title: "培训报名管理",
        },
      },
      {
        path: "trainApplySummary/:id",
        name: "trainApplySummary",
        component: () => import("@/views/train/trainApply/summary.vue"),
        meta: {
          title: "培训报名签到明细",
        },
      },
      {
        path: "trainApplyDetail/:id",
        name: "trainApplyDetail",
        component: () => import("@/views/train/trainApply/detail.vue"),
        meta: {
          title: "培训报名详情",
        },
      },
      //培训记录管理
      {
        path: "trainRecordList",
        name: "trainRecordList",
        component: () => import("@/views/train/trainRecord/list.vue"),
        meta: {
          title: "培训记录管理",
        },
      },
      {
        path: "trainRecordList/edit/:id",
        name: "trainRecordEdit",
        component: () => import("@/views/train/trainRecord/edit.vue"),
        meta: {
          title: "培训记录编辑",
        },
      },
      {
        path: "trainRecordList/detail/:id",
        name: "trainRecordDetail",
        component: () => import("@/views/train/trainRecord/detail.vue"),
        meta: {
          title: "培训记录详情",
        },
      },



      {
        path: "trainExam/:id",
        name: "trainExamList",
        component: () => import("@/views/train/maintain/trainExamList.vue"),
        meta: {
          title: "考题管理",
        },
      },
      {
        path: "managementTableList",
        name: "trainManagementTableList",
        component: () => import("@/views/train/trainManagement/list.vue"),
        meta: {
          title: "培训管理总结列表",
        },
      },
      {
        path: "managementTable/create",
        name: "trainManagementTableCreate",
        component: () => import("@/views/train/trainManagement/edit.vue"),
        meta: {
          title: "新增培训总结列表",
        },
      },
      {
        path: "managementTable/edit/:id",
        name: "trainManagementTableEdit",
        component: () => import("@/views/train/trainManagement/edit.vue"),
        meta: {
          title: "编辑培训总结列表",
        },
      },
      {
        path: "managementTable/detail/:id",
        name: "trainManagementTableDetail",
        component: () => import("@/views/train/trainManagement/detail.vue"),
        meta: {
          title: "培训总结表详情",
        },
      },
    ],
  },
];

export default trainManagementRouter;
