import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";


const donateRouter: RouteConfig[] = [
  {
    path: "donate",
    component: AppContent,
    name: "donate",
    redirect: "",
    meta: {
      title: "捐赠管理",
    },
    children: [
      {
        path: "fundIncomeRecord",
        name: "fundIncomeRecord",
        component: () => import("@/views/fund/fundIncomeRecord/list.vue"),
        meta: {
          title: "基金善款捐赠明细",
        },
        children: [
          {
            path: "detail/:id",
            name: "fundIncomeRecordDetail",
            component: () =>
              import("@/views/fund/fundIncomeRecord/detail2.vue"),
            meta: {
              title: "捐赠记录详情"
            },
          },

        ],
      },
      {
        path: "donationCertificate/:id?",
        name: "donationCertificate",
        component: () => import("@/views/fund/fundIncomeRecord/donationCertificate.vue"),
        meta: {
          title: "证书详情",
        },
      },
      {
        path: "fundIncomeRecord/create",
        name: "fundIncomeRecordCreate",
        component: () => import("@/views/fund/fundIncomeRecord/edit.vue"),
        meta: {
          title: "新增基金善款捐赠明细"
        }
      },
      {
        path: "fundIncomeRecord/edit/:id",
        name: "fundIncomeRecordEdit",
        component: () => import("@/views/fund/fundIncomeRecord/edit.vue"),
        meta: {
          title: "修改基金善款捐赠明细"
        }
      },
      // {
      //   path: 'fundIncomeRecord/detail/:id',
      //   name: 'fundIncomeRecordDetail',
      //   component: () => import('@/views/fund/fundIncomeRecord/detail.vue'),
      //   meta: {
      //     title: '捐赠记录详情'
      //   }
      // },
      {
        path: "fundDonationMonthly",
        name: "fundDonationMonthly",
        component: () => import("@/views/fund/fundDonationMonthly/list.vue"),
        meta: {
          title: "定时捐管理"
        },
        children: [
          {
            path: "detail/:id",
            name: "fundDonationMonthlyDetail",
            component: () =>
              import("@/views/fund/fundDonationMonthly/detail.vue"),
            meta: {
              title: "月捐详情"
            }
          }
        ]
      },

      {
        path: "fundDonationOrder",
        name: "fundDonationOrder",
        component: () =>
          import("@/views/fundProject/fundDonationOrder/list.vue"),
        meta: {
          title: "基金会项目捐助订单"
        }
      },
      {
        path: "fundProjectDonationRecord",
        name: "fundProjectDonationRecord",
        component: () =>
          import("@/views/fundProject/fundProjectDonationRecord/list.vue"),
        meta: {
          title: "基金会项目捐助记录"
        }
      },
      {
        path: "fundNonCashDonation",
        name: "fundNonCashDonation",
        component: () =>
          import("@/views/fundProject/fundNonCashDonation/list.vue"),
        meta: {
          title: "实物捐赠管理"
        }
      },
      {
        path: "fundNonCashDonation/create",
        name: "fundNonCashDonationCreate",
        component: () =>
          import("@/views/fundProject/fundNonCashDonation/edit.vue"),
        meta: {
          title: "新增实物捐赠"
        }
      },
      {
        path: "fundNonCashDonation/detail/:id",
        name: "fundNonCashDonationDetail",
        component: () =>
          import("@/views/fundProject/fundNonCashDonation/detail.vue"),
        meta: {
          title: "实物捐赠详情"
        }
      }
    ]
  }
];

export default donateRouter;
