import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const syncDataRouter: RouteConfig[] = [
  {
    path: "/syncData",
    component: AppContent,
    name: "syncData",
    redirect: "",
    meta: {
      title: "数据同步",
    },
    children: [
      {
        path: "largeDonation",
        name: "largeDonation",
        component: () =>
          import(
            /* webpackChunkName: "syncData" */ "@/views/syncData/largeDonation/list.vue"
          ),
        meta: {
          title: "大额捐赠同步",
        },
      },
      // {
      //   path: 'largeDonation-create',
      //   name: 'largeDonation-create',
      //   component: () => import(/* webpackChunkName: "ouUserManager" */ '@/views/syncData/largeDonation/edit.vue'),
      //   meta: {
      //     title: '新建大额捐赠同步'
      //   }
      // },
      // {
      //   path: 'largeDonation-edit/:id',
      //   name: 'largeDonation-edit',
      //   component: () => import(/* webpackChunkName: "ouUserManager" */ '@/views/syncData/largeDonation/edit.vue'),
      //   meta: {
      //     title: '编辑大额捐赠同步'
      //   }
      // },
    ],
  },
];

export default syncDataRouter;
