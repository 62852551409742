import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const activityRouter: RouteConfig[] = [
  {
    path: "activity",
    component: AppContent,
    name: "activity",
    redirect: "/activity/activity-list",
    meta: {
      title: "活动管理",
    },
    children: [
      {
        path: "activity-list",
        name: "ActivityList",
        component: () =>
          import(
            /* webpackChunkName: "Activity" */ "@/views/activity/activity/list.vue"
          ),
        meta: {
          title: "活动维护",
        },
      },
      {
        path: "activity-apply-info-list",
        name: "ActivityApplyInfoList",
        component: () =>
          import(
            /* webpackChunkName: "Activity" */ "@/views/activity/activity/applyInfoList.vue"
          ),
        meta: {
          title: "活动报名管理",
        },
      },
      {
        path: "detail/:id?",
        name: "ActivityDetail",
        component: () =>
          import(
            /* webpackChunkName: "ActivityDetail" */ "@/views/activity/activity-detail.vue"
          ),
        meta: {
          title: "活动详情",
        },
      },
      {
        path: "activity/edit/:id",
        name: "ActivityEdit",
        component: () =>
          import(
            /* webpackChunkName: "ActivityEdit" */ "@/views/activity/activity/edit.vue"
          ),
        meta: {
          title: "活动发布",
        },
      },
      {
        path: "activity/checkIn/:id?",
        name: "ActivityCheckIn",
        component: () =>
          import(
            /* webpackChunkName: "ActivityCheckIn" */ "@/views/activity/activity/checkIn.vue"
          ),
        meta: {
          title: "报名签到",
        },
      },
      {
        path: "activity/checkInRecord/:id?",
        name: "ActivityCheckInRecord",
        component: () =>
          import(
            /* webpackChunkName: "ActivityCheckIn" */ "@/views/activity/activity/checkInRecord.vue"
          ),
        meta: {
          title: "签到记录",
        },
      },

      {
        path: "apply/list/:activityId?",
        name: "ActivityApplyList",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyList" */ "@/views/activity/apply/list.vue"
          ),
        meta: {
          title: "活动订单",
        },
        children: [
          {
            path: "apply/:id?",
            name: "ActivityApplyDetail",
            component: () =>
              import(
                /* webpackChunkName: "ActivityApplyDetail" */ "@/views/activity/apply/detail.vue"
              ),
            meta: {
              title: "报名详情",
            },
          },
        ],
      },
      {
        path: "checkInDetail/:id",
        name: "ActivityCheckInDetail",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyList" */ "@/views/activity/apply/checkInDetail.vue"
            ),
        meta: {
          title: "活动报名记录详情",
        },
      },
      {
        path: "record/list",
        name: "ActivityRecordList",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyList" */ "@/views/activity/record/list.vue"
            ),
        meta: {
          title: "活动记录管理",
        },
      },
      {
        path: "record/detail/:id?",
        name: "ActivityRecordDetail",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyDetail" */ "@/views/activity/record/detail.vue"
            ),
        meta: {
          title: "活动记录详情",
        },
      },

      {
        path: "summary/list",
        name: "ActivityManagementList",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyList" */ "@/views/activity/management/list.vue"
            ),
        meta: {
          title: "活动管理总结表",
        },
      },
      {
        path: "summary/detail/:id",
        name: "ActivityManagementDetail",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyDetail" */ "@/views/activity/management/detail.vue"
            ),
        meta: {
          title: "活动管理总结表详情",
        },
      },
      {
        path: "summary/edit/:id",
        name: "ActivityManagementEdit",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyDetail" */ "@/views/activity/management/edit.vue"
            ),
        meta: {
          title: "活动管理总结表编辑",
        },
      },
      {
        path: "summary/create",
        name: "ActivityManagementCreate",
        component: () =>
          import(
            /* webpackChunkName: "ActivityApplyDetail" */ "@/views/activity/management/edit.vue"
            ),
        meta: {
          title: "活动管理总结表新增",
        },
      },
    ],
  },
];

export default activityRouter;
