import { AttachmentHostType } from "@/api/appService";

export class Attachment {
  static download(id: number): void {
    const baseURL = process.env.VUE_APP_BASE_API;
    window.open(
      `${baseURL}/api/services/app/Attachment/Download?id=${id}`,
      "_blank"
    );
  }
}
