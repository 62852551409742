



















































import { Component, Vue } from "vue-property-decorator";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import Hamburger from "@/components/Hamburger/index.vue";

import { removeOu } from "@/utils/cookies";
import ChangePassword from "@/views/ou/user/changePassword.vue";

@Component({
  name: "Navbar",
  components: {
    Breadcrumb,
    Hamburger,
    ChangePassword,
  },
})
export default class extends Vue {
  get sidebar() {
    return (this as any).$store.getters["app/sidebar"];
  }

  get device() {
    return (this as any).$store.getters["app/device"].toString();
  }

  get avatar() {
    const getters = (this as any).$store.getters;
    const image = getters["user/avatar"] || getters["tenant/squareLogo"];
    return image;
  }

  get userName() {
    // return (this as any).$store.getters['user/fullName'];
    return (this as any).$store.getters["user/name"];
  }

  private toggleSideBar() {
    (this as any).$store.commit("app/TOGGLE_SIDEBAR");
  }

  async logout() {
    // await UserModule.LogOut()
    await removeOu();
    (this as any).$store.commit("user/LOGOUT");
    this.$router.push({ name: "login" });
    // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
  }

  defineImg = require("@/assets/images/default-header.png");

  private async changePassword() {
    (this.$refs.changePasswordForm as any).show = true;
  }
}
