import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const auditRouter: RouteConfig[] = [
  {
    path: "audit",
    name: "audit",
    redirect: "",
    component: AppContent,
    meta: {
      title: "审核流程设置",
    },
    children: [
      {
        path: "flowDefinition/management",
        name: "auditFlowDefinitionManagement",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/audits/auditFlowList.vue"
          ),
        meta: {
          title: "审核流程定义",
        },
      },
      {
        path: "flowDefinition/edit/:id",
        name: "auditFlowDefinitionEdit",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/audits/edit-auditFlow.vue"
          ),
        meta: {
          title: "审核流程编辑",
        },
      },
      {
        path: "flowDefinition/create",
        name: "auditFlowDefinitionCreate",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/audits/edit-auditFlow.vue"
          ),
        meta: {
          title: "审核流程编辑",
        },
      },

      {
        path: "preDefinition",
        name: "preDefinition",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/audits/auditNodePreDefineList.vue"
          ),
        meta: {
          title: "审核节点预定义",
        },
      },

      {
        path: "auditFlowDetail/:hostType/:hostId/:auditFlowId",
        name: "auditFlowDetail",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/audits/auditFlowDetail.vue"
          ),
        meta: {
          title: "审核信息详情",
        },
      },
      {
        path: "auditFlow/edit/:key",
        name: "auditFlowConfiguration",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/auditFlow/auditFlowConfiguration.vue"
          ),
        meta: {
          title: "审核流程配置",
        },
      },
      {
        path: "auditFlow/auditFlowList/:key",
        name: "auditFlowConfigurationList",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/auditFlow/auditFlowList.vue"
          ),
        meta: {
          title: "审核流程制定",
        },
      },
      {
        path: "auditFlow/main",
        name: "auditFlowMain",
        component: () =>
          import(/* webpackChunkName: "audit" */ "@/views/auditFlow/main.vue"),
        meta: {
          title: "审核流程配置",
        },
      },

      {
        path: "auditFlowRecord/:hostType/:hostId/:auditFlowId",
        name: "auditFlowRecord",
        component: () =>
          import(
            /* webpackChunkName: "audit" */ "@/views/audits/auditFlowRecord.vue"
          ),
        meta: {
          title: "审核信息详情",
        },
      },
    ],
  },
];

export default auditRouter;
