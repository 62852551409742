import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const financeNewRouter: RouteConfig[] = [
  {
    path: "financeManage",
    name: "financeManage",
    redirect: "",
    component: AppContent,
    meta: {
      title: "财务管理",
    },
    children: [
      {
        path: "fundAccount",
        name: "fundAccount",
        component: () => import("@/views/fund/fundAccount/list.vue"),
        meta: {
          title: "基金账户查询",
        },
      },
      {
        path: "electronicInvoice",
        name: "electronicInvoice",
        component: () =>
          import(
            /* webpackChunkName: "financeNew" */ "@/views/financeNew/electronicInvoice/list.vue"
          ),
        meta: {
          title: "电子发票管理",
        },
        children: [
          {
            path: "detail/:id",
            name: "electronicInvoiceDetail",
            component: () =>
              import(
                /* webpackChunkName: "financeNew" */ "@/views/financeNew/electronicInvoice/detail.vue"
              ),
            meta: {
              title: "电子发票详情",
            },
          },
        ],
      },

      {
        path: "electronicInvoice/edit/:id",
        name: "electronicInvoiceEdit",
        component: () =>
          import(
            /* webpackChunkName: "financeNew" */ "@/views/financeNew/electronicInvoice/edit.vue"
          ),
        meta: {
          title: "修改电子发票",
        },
      },
      {
        path: "applicationPayment",
        name: "applicationPayment",
        component: () =>
          import("@/views/financeNew/applicationPayment/list.vue"),
        meta: {
          title: "善款拨付发票识别",
        },
        children: [
          {
            path: "detail/:id",
            name: "applicationPaymentDetail",
            component: () =>
              import("@/views/financeNew/applicationPayment/detail.vue"),
            meta: {
              title: "查看善款拨付发票识别",
            },
          },
        ],
      },

      {
        path: "tenantBankAccount",
        name: "tenantBankAccount",
        component: () =>
          import("@/views/financeNew/tenantBankAccount/list.vue"),
        meta: {
          title: "付款账户管理",
        },
      },
      {
        path: "fundAppropriateRecord",
        name: "fundAppropriateRecord",
        component: () => import("@/views/fund/fundAppropriateRecord/list.vue"),
        meta: {
          title: "基金善款拨付记录",
        },
      },
      {
        path: "fundManageFeeRecord",
        name: "fundManageFeeRecord",
        component: () => import("@/views/fund/fundManageFeeRecord/list.vue"),
        meta: {
          title: "基金管理费维护",
        },
        children: [
          {
            path: "detail/:id",
            name: "fundManageFeeRecordDetail",
            component: () =>
              import("@/views/fund/fundManageFeeRecord/detail.vue"),
            meta: {
              title: "基金管理费详情",
            },
          },
        ],
      },
    ],
  },
];

export default financeNewRouter;
