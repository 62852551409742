import {RouteConfig} from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const signUpRouter: RouteConfig[] = [
  {
    path: "signUpManage",
    component: AppContent,
    name: "signUpManage",
    redirect: "/signUpManage",
    meta: {
      title: "报名管理"
    },
    children: [
      {
        path: "signUpInfo",
        name: "signUpInfo",
        component: () => import("@/views/signUpInfo/signUpMaintain/list.vue"),
        meta: {
          title: "报名信息管理"
        },
      },
      {
        path:"signUpInfo/Detail/:id",
        name:"signUpInfoDetail",
        component:()=> import("@/views/signUpInfo/signUpMaintain/detail.vue"),
        meta:{
          title: "报名详情",
        },
      },
    ],
  },
];

export default signUpRouter;
