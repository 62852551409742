import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const notifyRouter: RouteConfig[] = [
  {
    path: "notify",
    component: AppContent,
    name: "cms",
    redirect: "",
    meta: {
      title: "通知模板",
    },
    children: [
      {
        path: "list",
        name: "notify-list",
        component: () => import("@/views/notify/template/list.vue"),
        meta: {
          title: "通知模板列表",
        },
      },
      {
        path: "create",
        name: "notifyCreate",
        component: () => import("@/views/notify/template/edit.vue"),
        meta: {
          title: "创建通知模板",
        },
      },
      {
        path: "edit/:id",
        name: "notifyEdit",
        component: () => import("@/views/notify/template/edit.vue"),
        meta: {
          title: "修改通知模板",
        },
      },
      {
        path: "detail/:id",
        name: "notifyDetail",
        component: () => import("@/views/notify/template/detail.vue"),
        meta: {
          title: "通知模板详情",
        },
      },
    ],
  },
];

export default notifyRouter;
