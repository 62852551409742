import {RouteConfig} from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const cmsRouter = [
  {
    path: "cms",
    component: AppContent,
    name: "cms",
    redirect: "/cms/categories",
    meta: {
      title: "内容管理",
    },
    children: [
      {
        path: "categories",
        name: "categories",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/category/index.vue"
            ),
        meta: {
          title: "栏目管理",
        },
      },
      {
        path: "category-create/:parentId",
        name: "category-create",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/category/edit.vue"
            ),
        meta: {
          title: "新建栏目",
        },
      },
      {
        path: "category-edit/:id",
        name: "category-edit",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/category/edit.vue"
            ),
        meta: {
          title: "编辑栏目",
        },
      },
      {
        path: "content-list/:fundId/:fundProjectId",
        name: "content-list-other",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/content/list.vue"
            ),
        meta: {
          title: "内容管理",
        },
      },
      {
        path: "content-list",
        name: "content-list",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/content/list.vue"
            ),
        meta: {
          title: "内容管理",
        },
      },
      {
        path: "content-create/:fundId/:fundProjectId",
        name: "content-create-other",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/content/edit.vue"
            ),
        meta: {
          title: "新增内容",
        },
      },
      {
        path: "content-create",
        name: "content-create",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/content/edit.vue"
            ),
        meta: {
          title: "新增内容",
        },
      },
      {
        path: "content-edit/:id",
        name: "content-edit",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/content/edit.vue"
            ),
        meta: {
          title: "編輯内容",
        },
      },
      {
        path: "content-detail/:id",
        name: "content-detail",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/content/detail.vue"
            ),
        meta: {
          title: "内容详情",
        },
      },
      {
        path: "wechat-content-list/:fundId/:fundProjectId",
        name: "wechat-content-list-other",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/wechatContent/list.vue"
            ),
        meta: {
          title: "微信文章同步管理",
        },
      },
      {
        path: "wechat-content-list",
        name: "wechat-content-list",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/wechatContent/list.vue"
            ),
        meta: {
          title: "微信文章同步管理",
        },
      },
      {
        path: "trash",
        name: "trash",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/content/trash.vue"
            ),
        meta: {
          title: "内容废纸篓",
        },
      },
      {
        path: "import",
        name: "import",
        component: () =>
          import("@/views/cms/content/import.vue"),
        meta: {
          title: "导入转存（临时)",
        },
      },
      {
        path: "friendLink",
        name: "friendLink",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/friendLink/list.vue"
            ),
        meta: {
          title: "合作伙伴",
        },
      },
      {
        path: "friendLink/create",
        name: "friendLinkCreate",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/friendLink/edit.vue"
            ),
        meta: {
          title: "新建合作伙伴",
        },
      },
      {
        path: "friendLink/edit/:id",
        name: "friendLinkEdit",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/friendLink/edit.vue"
            ),
        meta: {
          title: "编辑合作伙伴",
        },
      },
      {
        path: "album-list",
        name: "album-list",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/albumPhotos/list.vue"
            ),
        meta: {
          title: "相册管理",
        },
      },
      {
        path: "photo-list/:id",
        name: "photo-list",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/albumPhotos/photoList.vue"
            ),
        meta: {
          title: "照片管理",
        },
      },
      {
        path: "anchor",
        name: "anchor",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/anchor/list.vue"
            ),
        meta: {
          title: "锚点管理",
        },
      },
      {
        path: "anchor/create",
        name: "anchorCreate",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/anchor/edit.vue"
            ),
        meta: {
          title: "新建锚点",
        },
      },
      {
        path: "anchor/edit/:id",
        name: "anchorEdit",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/anchor/edit.vue"
            ),
        meta: {
          title: "编辑锚点",
        },
      },
      {
        path: "swiper",
        name: "swiper",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/swiper/list.vue"
            ),
        meta: {
          title: "轮播图管理",
        },
      },
      {
        path: "swiper/create",
        name: "swiperCreate",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/swiper/edit.vue"
            ),
        meta: {
          title: "新建轮播图",
        },
      },
      {
        path: "swiper/edit/:id",
        name: "swiperEdit",
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "@/views/cms/swiper/edit.vue"
            ),
        meta: {
          title: "编辑轮播图",
        },
      },
      {
        path: 'slashScreen',
        name: 'swiperManagement',
        meta: {
          title: '闪屏维护'
        },
        component: () => import(/*webpackChunkName:"cms"*/ '@/views/cms/slashScreen/list.vue'),
        children: [
          {
            name: 'slashScreen-detail',
            path: 'detail/:id',
            meta: {
              title: '闪屏信息详情'
            },
            component: () => import(/*webpackChunkName:"cms"*/ '@/views/cms/slashScreen/detail.vue'),
          }
        ]
      },
      {
        name: 'slashScreen-edit',
        path: 'slashScreen/edit/:id',
        meta: {
          title: '编辑闪屏信息'
        },
        component: () => import(/*webpackChunkName:"cms"*/ '@/views/cms/slashScreen/edit.vue'),
      },
      {
        name: 'slashScreen-create',
        path: 'slashScreen/create',
        meta: {
          title: '新增闪屏信息'
        },
        component: () => import(/*webpackChunkName:"cms"*/ '@/views/cms/slashScreen/edit.vue'),
      },
      {
        path: 'donateDetail',
        name: 'donateDetailManagement',
        meta: {
          title: '捐赠明细'
        },
        component: () => import(/*webpackChunkName:"cms"*/ '@/views/cms/donateDetail/list.vue'),
      },
      {
        path: 'expenditureDetail',
        name: 'expenditureDetailManagement',
        meta: {
          title: '支出明细'
        },
        component: () => import(/*webpackChunkName:"cms"*/ '@/views/cms/expenditureDetail/list.vue'),
      },
      {
        path: 'musicMachineDonateDetail',
        name: 'musicMachineDonateDetailManagement',
        meta: {
          title: '音乐智能机捐赠明细'
        },
        component: () => import(/*webpackChunkName:"cms"*/ '@/views/cms/musicMachineDonateDetail/list.vue'),
      },
    ],
  },
];

export default cmsRouter;
