/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export class IList<T> extends Array<T> {}
export class List<T> extends Array<T> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount: number;
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount!: number;
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static isTenantAvailable(
    params: {
      /** requestBody */
      body?: IsTenantAvailableInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IsTenantAvailableOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/IsTenantAvailable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 微信用户登录
   */
  static weiXinLogin(
    params: {
      /**  */
      weiXinUser?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginPostRespDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/WeiXinLogin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { weiXinUser: params['weiXinUser'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 注册
   */
  static register(
    params: {
      /** requestBody */
      body?: RegisterInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegisterOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 邮箱注册
   */
  static registerWithEmail(
    params: {
      /** requestBody */
      body?: RegisterWithEmailInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegisterOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/RegisterWithEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 登录
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginPostReqDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginPostRespDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkUserMobilePhone(
    params: {
      /**  */
      phone?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AbpInvokeReturn> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/CheckUserMobilePhone';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { phone: params['phone'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取短信验证码
   */
  static getVerificationCode(
    params: {
      /**  */
      phone?: string;
      /**  */
      verificationCode?: VerificationCode;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/GetVerificationCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { phone: params['phone'], verificationCode: params['verificationCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取邮箱验证码
   */
  static getVerificationCodeWithEmail(
    params: {
      /**  */
      emailAddress: string;
      /**  */
      verificationCode?: VerificationCode;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/GetVerificationCodeWithEmail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { emailAddress: params['emailAddress'], verificationCode: params['verificationCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkVerificationCode(
    params: {
      /**  */
      phone?: string;
      /**  */
      code?: string;
      /**  */
      verificationCode?: VerificationCode;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AbpInvokeReturn> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/CheckVerificationCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { phone: params['phone'], code: params['code'], verificationCode: params['verificationCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查手机号码是否存在
   */
  static checkPhoneExist(
    params: {
      /**  */
      phone?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/CheckPhoneExist';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { phone: params['phone'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改账号资料
   */
  static changeInfo(
    params: {
      /** requestBody */
      body?: ChangeInfoPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/ChangeInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActivityService {
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      title?: string;
      /** 状态 */
      status?: ActivityStatus;
      /** 是否为志愿者活动 */
      isVolunteerActivity?: boolean;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 活动开始时间 */
      activityStartTime?: string;
      /** 活动结束时间 */
      activityEndTime?: string;
      /** 活动类型(枚举) */
      typeId?: number;
      /** 活动类型（数据字典） */
      activityTypeId?: number;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        IsVolunteerActivity: params['isVolunteerActivity'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        ActivityStartTime: params['activityStartTime'],
        ActivityEndTime: params['activityEndTime'],
        TypeID: params['typeId'],
        ActivityTypeId: params['activityTypeId'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取活动所在的省份列表
   */
  static getProvinceList(options: IRequestOptions = {}): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetProvinceList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取活动所在的城市列表
   */
  static getCityList(
    params: {
      /** 所在省份 */
      provinceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetCityList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provinceId: params['provinceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取活动所在的区县列表
   */
  static getAreaList(
    params: {
      /** 所在区县 */
      cityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetAreaList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityId: params['cityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportHasBeenAppliedActivity(
    params: {
      /** requestBody */
      body?: ActivityPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/ExportHasBeenAppliedActivity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllHasBeenApplied(
    params: {
      /** 标题 */
      title?: string;
      /** 状态 */
      status?: ActivityStatus;
      /** 是否为志愿者活动 */
      isVolunteerActivity?: boolean;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 活动开始时间 */
      activityStartTime?: string;
      /** 活动结束时间 */
      activityEndTime?: string;
      /** 活动类型(枚举) */
      typeId?: number;
      /** 活动类型（数据字典） */
      activityTypeId?: number;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetAllHasBeenApplied';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        IsVolunteerActivity: params['isVolunteerActivity'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        ActivityStartTime: params['activityStartTime'],
        ActivityEndTime: params['activityEndTime'],
        TypeID: params['typeId'],
        ActivityTypeId: params['activityTypeId'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByPortal(
    params: {
      /** 标题 */
      title?: string;
      /** 状态 */
      status?: ActivityStatus;
      /** 是否为志愿者活动 */
      isVolunteerActivity?: boolean;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 活动开始时间 */
      activityStartTime?: string;
      /** 活动结束时间 */
      activityEndTime?: string;
      /** 活动类型(枚举) */
      typeId?: number;
      /** 活动类型（数据字典） */
      activityTypeId?: number;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        IsVolunteerActivity: params['isVolunteerActivity'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        ActivityStartTime: params['activityStartTime'],
        ActivityEndTime: params['activityEndTime'],
        TypeID: params['typeId'],
        ActivityTypeId: params['activityTypeId'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发布活动
   */
  static publish(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消发布活动
   */
  static cancelPublish(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/CancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取已发布的活动
   */
  static getAllWithPublished(
    params: {
      /** 标题 */
      title?: string;
      /** 状态 */
      status?: ActivityStatus;
      /** 是否为志愿者活动 */
      isVolunteerActivity?: boolean;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 活动开始时间 */
      activityStartTime?: string;
      /** 活动结束时间 */
      activityEndTime?: string;
      /** 活动类型(枚举) */
      typeId?: number;
      /** 活动类型（数据字典） */
      activityTypeId?: number;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetAllWithPublished';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        IsVolunteerActivity: params['isVolunteerActivity'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        ActivityStartTime: params['activityStartTime'],
        ActivityEndTime: params['activityEndTime'],
        TypeID: params['typeId'],
        ActivityTypeId: params['activityTypeId'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取二维码
   */
  static downQrCode(
    params: {
      /**  */
      id?: number;
      /**  */
      qrType?: ActivityQrType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/DownQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], qrType: params['qrType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新阅读数量
   */
  static updateReadCount(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/UpdateReadCount';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 判断活动是否可以报名
   */
  static canSignupActivity(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AbpInvokeReturn> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/CanSignupActivity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ActivityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ActivityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 标题 */
      title?: string;
      /** 状态 */
      status?: ActivityStatus;
      /** 是否为志愿者活动 */
      isVolunteerActivity?: boolean;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 活动开始时间 */
      activityStartTime?: string;
      /** 活动结束时间 */
      activityEndTime?: string;
      /** 活动类型(枚举) */
      typeId?: number;
      /** 活动类型（数据字典） */
      activityTypeId?: number;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        IsVolunteerActivity: params['isVolunteerActivity'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        ActivityStartTime: params['activityStartTime'],
        ActivityEndTime: params['activityEndTime'],
        TypeID: params['typeId'],
        ActivityTypeId: params['activityTypeId'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Activity/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActivityApplyService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ActivityApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取服务记录统计
   */
  static getTotalDetails(
    params: {
      /**  */
      activityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetTotalDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { activityId: params['activityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllPaid(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetAllPaid';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllPriced(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetAllPriced';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取已报名人数(不包含已退回和已取消)
   */
  static getSignUpCount(
    params: {
      /**  */
      activityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetSignUpCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { activityId: params['activityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postToAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/PostToAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出活动名单
   */
  static exportExcel(
    params: {
      /** requestBody */
      body?: ActivityApplyPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/ExportExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出活动名单
   */
  static exportOrderExcel(
    params: {
      /** requestBody */
      body?: ActivityApplyPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/ExportOrderExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 报名签到，有管理权限的可以为别人签到
   */
  static checkIn(
    params: {
      /** requestBody */
      body?: ActivityApplyCheckInPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/CheckIn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消报名记录（不处理订单）
   */
  static cancelActivityApply(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/CancelActivityApply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 未支付取消(用户可以取消自己的报名,管理员可以任意取消)
   */
  static unPaidCancel(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/UnPaidCancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 未支付取消(用户可以取消自己的报名,管理员可以任意取消)
   */
  static unPaidToAudited(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/UnPaidToAudited';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 报名
   */
  static signUp(
    params: {
      /** requestBody */
      body?: ActivityApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/SignUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取活动报名订单
   */
  static getActivityApplyOrder(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetActivityApplyOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取活动报名
   */
  static getActivityApplyByOrderNo(
    params: {
      /**  */
      orderNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetActivityApplyByOrderNo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 处理支付回调
   */
  static processOrder(
    params: {
      /** requestBody */
      body?: PaymentInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/ProcessOrder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByPortal(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户报名活动信息
   */
  static getUserActivityApplyInfo(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserActivityApplyInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetUserActivityApplyInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查订单金额
   */
  static checkOrderPriceTotal(
    params: {
      /**  */
      orderNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AbpBoolDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/CheckOrderPriceTotal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取报名明细
   */
  static getApplies(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetApplies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取签到明细
   */
  static getCheckIn(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyCheckInRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetCheckIn';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 个人中心-我的活动列表
   */
  static getAllByUserCenter(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetAllByUserCenter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ActivityApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      activityId?: number;
      /**  */
      activityTitle?: string;
      /**  */
      applyUserPhone?: string;
      /**  */
      applyUserName?: string;
      /**  */
      isPay?: boolean;
      /**  */
      isPriced?: boolean;
      /**  */
      isOnlyMine?: boolean;
      /** 1:已付款；2:未付款；3:已关闭 */
      specialType?: number;
      /**  */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ActivityId: params['activityId'],
        ActivityTitle: params['activityTitle'],
        ApplyUserPhone: params['applyUserPhone'],
        ApplyUserName: params['applyUserName'],
        IsPay: params['isPay'],
        IsPriced: params['isPriced'],
        IsOnlyMine: params['isOnlyMine'],
        SpecialType: params['specialType'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActivityManagementService {
  /**
   *
   */
  static getAll(
    params: {
      /** 活动标题 */
      title?: string;
      /** 主办方 */
      host?: string;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityManagementDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityManagement/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Host: params['host'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ActivityManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityManagement/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ActivityManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityManagement/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityManagementCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityManagement/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityManagement/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityManagement/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActivityRecordService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 姓名 */
      surName?: string;
      /** 培训主题 */
      activityTitle?: string;
      /** 志愿者编号 */
      volunteerCode?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        ActivityTitle: params['activityTitle'],
        VolunteerCode: params['volunteerCode'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ActivityRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ActivityRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出签到列表到Excel
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: ActivityRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 通过ActivityApplyId获取数据
   */
  static getEditByApplyId(
    params: {
      /**  */
      applyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/GetEditByApplyId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { applyId: params['applyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端提交新增，需要开启审核流程
   */
  static createByPortal(
    params: {
      /** requestBody */
      body?: ActivityRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/CreateByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
 * /// <summary>
前端提交更新，需要重新开启审核流程
</summary><param name="input"></param><returns></returns>
 */
  static updateByPortal(
    params: {
      /** requestBody */
      body?: ActivityRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/UpdateByPortal';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 姓名 */
      surName?: string;
      /** 培训主题 */
      activityTitle?: string;
      /** 志愿者编号 */
      volunteerCode?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        ActivityTitle: params['activityTitle'],
        VolunteerCode: params['volunteerCode'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivityRecord/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActivitySignInRecordService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivitySignInRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivitySignInRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
      /**  */
      sorting?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivitySignInRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivitySignInRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount'],
        Sorting: params['sorting']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ActivitySignInRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivitySignInRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivitySignInRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ActivitySignInRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivitySignInRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivitySignInRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ActivitySignInRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AlbumService {
  /**
   * 获取相册信息
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 创建相册
   */
  static create(
    params: {
      /** requestBody */
      body?: AlbumCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新相册
   */
  static update(
    params: {
      /** requestBody */
      body?: AlbumCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除相册
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有相册
   */
  static getAllAlbums(
    params: {
      /**  */
      name?: string;
      /** 是否显示在官网 */
      isShowPortal?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/GetAllAlbums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        IsShowPortal: params['isShowPortal'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传照片到相册
   */
  static uploadPhoto(options: IRequestOptions = {}): Promise<AlbumPhotoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/UploadPhoto';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除照片
   */
  static deletePhoto(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/DeletePhoto';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取照片列表
   */
  static getAllPhotos(
    params: {
      /** 相册Id */
      albumId?: number;
      /** 照片名称 */
      name?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumPhotoDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/GetAllPhotos';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AlbumId: params['albumId'],
        Name: params['name'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 将照片转到新相册
   */
  static moveToNewAlbum(
    params: {
      /** requestBody */
      body?: AlbumPhotoMoveInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/MoveToNewAlbum';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 将照片设为相册封面
   */
  static setAsCover(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/SetAsCover';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重命名照片
   */
  static renamePhoto(
    params: {
      /** requestBody */
      body?: RenameAlbumPhotoInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumPhotoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/RenamePhoto';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static download(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDownload(
    params: {
      /** 相册Id */
      albumId: number;
      /** 相册图片Id（多个用,隔开） */
      albumPhotoId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/BatchDownload';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { AlbumId: params['albumId'], AlbumPhotoId: params['albumPhotoId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 是否显示在官网
   */
  static showPortal(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/ShowPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取相册下的照片数量
   */
  static getAlbumPhotoCount(
    params: {
      /**  */
      albumIds?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumPhotoCountDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/GetAlbumPhotoCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { albumIds: params['albumIds'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ApplicationPaymentService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ApplicationPaymentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPaymentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/GetAllLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPaymentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 申请人姓名 */
      applicantName?: string;
      /** 标题 */
      title?: string;
      /** 基金名称 */
      fundName?: string;
      /** 基金项目名称 */
      fundProjectName?: string;
      /** 审批状态 */
      isAudited?: boolean;
      /** 验证状态 */
      validationStatus?: ApplicationPaymentValidationStatus;
      /**  */
      endDate?: string;
      /**  */
      isOnlyMine?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPaymentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ApplicantName: params['applicantName'],
        Title: params['title'],
        FundName: params['fundName'],
        FundProjectName: params['fundProjectName'],
        IsAudited: params['isAudited'],
        ValidationStatus: params['validationStatus'],
        EndDate: params['endDate'],
        IsOnlyMine: params['isOnlyMine'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ApplicationPaymentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPaymentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置发票号码
   */
  static setInvoiceNumbers(
    params: {
      /** requestBody */
      body?: ApplicationPaymentInvoiceNumberInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/SetInvoiceNumbers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取发票号码
   */
  static getInvoiceNumbers(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/GetInvoiceNumbers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 增值税发票识别
   */
  static vatInvoice(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/VatInvoice';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查拨款
   */
  static checkFunding(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/CheckFunding';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 确认拨款
   */
  static confirmFunding(
    params: {
      /** requestBody */
      body?: ConfirmFundingInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/ConfirmFunding';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TestNc
   */
  static testNc(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/TestNc';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 供应商
   */
  static testSupplierXml(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/TestSupplierXml';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 付款申请
   */
  static testPaymentApplicationXml(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/TestPaymentApplicationXml';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 申请人姓名 */
      applicantName?: string;
      /** 标题 */
      title?: string;
      /** 基金名称 */
      fundName?: string;
      /** 基金项目名称 */
      fundProjectName?: string;
      /** 审批状态 */
      isAudited?: boolean;
      /** 验证状态 */
      validationStatus?: ApplicationPaymentValidationStatus;
      /**  */
      endDate?: string;
      /**  */
      isOnlyMine?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPaymentDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ApplicantName: params['applicantName'],
        Title: params['title'],
        FundName: params['fundName'],
        FundProjectName: params['fundProjectName'],
        IsAudited: params['isAudited'],
        ValidationStatus: params['validationStatus'],
        EndDate: params['endDate'],
        IsOnlyMine: params['isOnlyMine'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ApplicationPayment/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AreaService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AreaCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AreaCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      cityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityId: params['cityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      areaName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AreaName: params['areaName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AttachmentService {
  /**
   *
   */
  static getAttachments(
    params: {
      /**  */
      hostId?: string;
      /**  */
      hostType?: AttachmentHostType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAttachments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { hostId: params['hostId'], hostType: params['hostType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAttachmentsByIdAndAuditFlowId(
    params: {
      /**  */
      id?: string;
      /**  */
      auditFlowId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAttachmentsByIdAndAuditFlowId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], auditFlowId: params['auditFlowId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAttachmentsByIds(
    params: {
      /**  */
      ids?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAttachmentsByIds';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ids: params['ids'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传到临时目录
   */
  static uploadToTemp(options: IRequestOptions = {}): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadToTemp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传到Oss
   */
  static uploadToOss(options: IRequestOptions = {}): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadToOss';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传附件
   */
  static uploadAttachments(options: IRequestOptions = {}): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadAttachments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取阿里云STS临时授权Token
   */
  static getAliyunOssStsToken(options: IRequestOptions = {}): Promise<AliyunOssStsDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAliyunOssSTSToken';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadAttachment(
    params: {
      /** requestBody */
      body?: AttachmentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadAttachment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载附件
   */
  static download(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditFlowService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      key?: string;
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Key: params['key'],
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSort(
    params: {
      /** requestBody */
      body?: AuditFlowUpdateSortPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/UpdateSort';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static active(
    params: {
      /** requestBody */
      body?: GuidEntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Active';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditFlowDefinitionService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDefinitionListDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowDefinition/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { displayName: params['displayName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDefinitionDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowDefinition/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditFlowProxyService {
  /**
   * CreateAsync
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditProxyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * GetForEdit
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowCreateOrEditProxyDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      key?: string;
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Key: params['key'],
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * UpdateAsync
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditProxyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      scope?: AuditFlowScope;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scope: params['scope'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditNodePreDefineService {
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditNodePreDefineCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditNodePreDefineCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AutoInvoicingService {
  /**
   * 获取最新待开票
   */
  static getInvoiceList(options: IRequestOptions = {}): Promise<InvoiceDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AutoInvoicing/GetInvoiceList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInvoiceListAsCsv(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AutoInvoicing/GetInvoiceListAsCsv';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 开票完成
   */
  static complete(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AutoInvoicing/Complete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BankNameService {
  /**
   *
   */
  static syncBankNamesFromNc(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankName/SyncBankNamesFromNC';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankNameDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankName/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankNameDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankName/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BankNameCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankNameDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankName/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BankNameCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankNameDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankName/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankName/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BankReconciliationService {
  /**
   * 银行对账收入统计
   */
  static getBankReconciliationIncomeReport(
    params: {
      /**  */
      year?: number;
      /** 账号 */
      bankAccount?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DecimalMonthlyItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankReconciliation/GetBankReconciliationIncomeReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Year: params['year'], BankAccount: params['bankAccount'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 银行对账支出统计
   */
  static getBankReconciliationExpenseReport(
    params: {
      /**  */
      year?: number;
      /** 账号 */
      bankAccount?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DecimalMonthlyItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankReconciliation/GetBankReconciliationExpenseReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Year: params['year'], BankAccount: params['bankAccount'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BankTypeService {
  /**
   *
   */
  static syncBankTypesFromNc(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankType/SyncBankTypesFromNC';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankTypeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankType/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankTypeDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankType/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BankTypeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankTypeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankType/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BankTypeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BankTypeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankType/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankType/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BbsGroupService {
  /**
   *
   */
  static getAll(
    params: {
      /** 群组名称 */
      name?: string;
      /** 群组类型 */
      groupTypeId?: number;
      /** 群组状态 */
      status?: GroupStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        GroupTypeId: params['groupTypeId'],
        Status: params['status'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BbsGroupCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BbsGroupCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 启用群组
   */
  static enable(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/Enable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 禁用群组
   */
  static disable(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/Disable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGroupListByRandom(
    params: {
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/GetGroupListByRandom';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroup/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BbsGroupUserService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupUserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BbsGroupUserCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupUserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 志愿者编号 */
      volunteerOrgCode?: string;
      /** 志愿者姓名 */
      name?: string;
      /** 手机号 */
      phoneNumber?: string;
      /** 群组Id */
      bbsGroupId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupUserDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerOrgCode: params['volunteerOrgCode'],
        Name: params['name'],
        PhoneNumber: params['phoneNumber'],
        BbsGroupId: params['bbsGroupId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 启用群组成员
   */
  static enable(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/Enable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 禁用群组成员
   */
  static disable(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/Disable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 退出群组
   */
  static quitGroup(
    params: {
      /**  */
      groupId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/QuitGroup';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { groupId: params['groupId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BbsGroupUserCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsGroupUserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsGroupUser/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BbsPostService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BbsPostCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPost/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 群组名称 */
      groupName?: string;
      /** 主贴标题 */
      title?: string;
      /** 群组类型 */
      groupType?: string;
      /** 发帖人姓名 */
      realName?: string;
      /** 发帖时间 */
      createTime?: string;
      /** 群组Id */
      bbsGroupId?: number;
      /** 主贴类型ID */
      postTypeId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPost/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GroupName: params['groupName'],
        Title: params['title'],
        GroupType: params['groupType'],
        RealName: params['realName'],
        CreateTime: params['createTime'],
        BbsGroupId: params['bbsGroupId'],
        PostTypeId: params['postTypeId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 置顶
   */
  static stickyPost(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPost/StickyPost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 点赞
   */
  static thumbsUp(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPost/ThumbsUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPost/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPost/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BbsPostCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPost/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BbsPostCommentService {
  /**
   *
   */
  static getAll(
    params: {
      /** 发帖人姓名 */
      realName?: string;
      /** 发帖时间 */
      createTime?: string;
      /**  */
      postId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostCommentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPostComment/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        RealName: params['realName'],
        CreateTime: params['createTime'],
        PostId: params['postId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BbsPostCommentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostCommentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPostComment/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取回复内容
   */
  static getAllList(
    params: {
      /**  */
      parentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostCommentDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPostComment/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { parentId: params['parentId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 点赞
   */
  static thumbsUp(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPostComment/ThumbsUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostCommentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPostComment/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BbsPostCommentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsPostCommentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPostComment/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BbsPostComment/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BeneficiaryService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BeneficiaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BeneficiaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkExist(
    params: {
      /** requestBody */
      body?: BeneficiaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/CheckExist';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 个案编号 */
      code?: string;
      /** 姓名 */
      realName?: string;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        RealName: params['realName'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 个案编号 */
      code?: string;
      /** 姓名 */
      realName?: string;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        RealName: params['realName'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BeneficiaryServiceRecordServiceService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      serviceRecordType?: ServiceRecordType;
      /**  */
      beneficiaryName?: string;
      /**  */
      schoolName?: string;
      /**  */
      projectName?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectServiceRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceRecordType: params['serviceRecordType'],
        BeneficiaryName: params['beneficiaryName'],
        SchoolName: params['schoolName'],
        ProjectName: params['projectName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: ProjectServiceRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      serviceRecordType?: ServiceRecordType;
      /**  */
      beneficiaryName?: string;
      /**  */
      schoolName?: string;
      /**  */
      projectName?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectServiceRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceRecordType: params['serviceRecordType'],
        BeneficiaryName: params['beneficiaryName'],
        SchoolName: params['schoolName'],
        ProjectName: params['projectName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecordService/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BmsActivityOrderService {
  /**
   * 创建BMS订单
   */
  static create(
    params: {
      /**  */
      applyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BmsActivityOrder/Create';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { applyId: params['applyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 同步处理支付回调
   */
  static processOrderSync(
    params: {
      /**  */
      signType?: string;
      /**  */
      paymentType?: string;
      /**  */
      buyerAccount?: string;
      /**  */
      tradeStatus?: string;
      /**  */
      tradeNo?: string;
      /**  */
      totalFee?: number;
      /**  */
      orderNo?: string;
      /**  */
      notifyType?: string;
      /**  */
      notifyId?: string;
      /**  */
      notifyTime?: string;
      /**  */
      sign?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BmsActivityOrder/ProcessOrderSync';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sign_Type: params['signType'],
        Payment_Type: params['paymentType'],
        Buyer_Account: params['buyerAccount'],
        Trade_Status: params['tradeStatus'],
        Trade_No: params['tradeNo'],
        Total_Fee: params['totalFee'],
        Order_No: params['orderNo'],
        Notify_Type: params['notifyType'],
        Notify_Id: params['notifyId'],
        Notify_Time: params['notifyTime'],
        Sign: params['sign']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 异步处理支付回调
   */
  static processOrder(
    params: {
      /**  */
      signType?: string;
      /**  */
      paymentType?: string;
      /**  */
      buyerAccount?: string;
      /**  */
      tradeStatus?: string;
      /**  */
      tradeNo?: string;
      /**  */
      totalFee?: number;
      /**  */
      orderNo?: string;
      /**  */
      notifyType?: string;
      /**  */
      notifyId?: string;
      /**  */
      notifyTime?: string;
      /**  */
      sign?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BmsActivityOrder/ProcessOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sign_Type: params['signType'],
        Payment_Type: params['paymentType'],
        Buyer_Account: params['buyerAccount'],
        Trade_Status: params['tradeStatus'],
        Trade_No: params['tradeNo'],
        Total_Fee: params['totalFee'],
        Order_No: params['orderNo'],
        Notify_Type: params['notifyType'],
        Notify_Id: params['notifyId'],
        Notify_Time: params['notifyTime'],
        Sign: params['sign']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BmsFundDonationOrderService {
  /**
   * 创建BMS订单
   */
  static create(
    params: {
      /**  */
      orderId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BmsFundDonationOrder/Create';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderId: params['orderId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 同步处理支付回调
   */
  static processOrderSync(
    params: {
      /**  */
      signType?: string;
      /**  */
      paymentType?: string;
      /**  */
      buyerAccount?: string;
      /**  */
      tradeStatus?: string;
      /**  */
      tradeNo?: string;
      /**  */
      totalFee?: number;
      /**  */
      orderNo?: string;
      /**  */
      notifyType?: string;
      /**  */
      notifyId?: string;
      /**  */
      notifyTime?: string;
      /**  */
      sign?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BmsFundDonationOrder/ProcessOrderSync';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sign_Type: params['signType'],
        Payment_Type: params['paymentType'],
        Buyer_Account: params['buyerAccount'],
        Trade_Status: params['tradeStatus'],
        Trade_No: params['tradeNo'],
        Total_Fee: params['totalFee'],
        Order_No: params['orderNo'],
        Notify_Type: params['notifyType'],
        Notify_Id: params['notifyId'],
        Notify_Time: params['notifyTime'],
        Sign: params['sign']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 异步处理支付回调
   */
  static processOrder(
    params: {
      /**  */
      signType?: string;
      /**  */
      paymentType?: string;
      /**  */
      buyerAccount?: string;
      /**  */
      tradeStatus?: string;
      /**  */
      tradeNo?: string;
      /**  */
      totalFee?: number;
      /**  */
      orderNo?: string;
      /**  */
      notifyType?: string;
      /**  */
      notifyId?: string;
      /**  */
      notifyTime?: string;
      /**  */
      sign?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BmsFundDonationOrder/ProcessOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sign_Type: params['signType'],
        Payment_Type: params['paymentType'],
        Buyer_Account: params['buyerAccount'],
        Trade_Status: params['tradeStatus'],
        Trade_No: params['tradeNo'],
        Total_Fee: params['totalFee'],
        Order_No: params['orderNo'],
        Notify_Type: params['notifyType'],
        Notify_Id: params['notifyId'],
        Notify_Time: params['notifyTime'],
        Sign: params['sign']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CapitalExpenseRecordAppserviceService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取变更历史记录
   */
  static getRecordChangeList(
    params: {
      /**  */
      recordId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetRecordChangeList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { recordId: params['recordId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContractNoInformation(
    params: {
      /**  */
      contractNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractNoInformationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetContractNoInformation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractNo: params['contractNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取内部项目预算项及实际使用金额
   */
  static getInternalBudgetItemAndUseRecord(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalBudgetItemAndUseRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetInternalBudgetItemAndUseRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 保存变更申请
   */
  static changeApply(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/ChangeApply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消变更申请
   */
  static cancelChangeApply(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/CancelChangeApply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectReportList(
    params: {
      /**  */
      projectName?: string;
      /**  */
      year?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      projectType?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetProjectReportList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        Year: params['year'],
        ProjectSeriesId: params['projectSeriesId'],
        ProjectType: params['projectType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectRepprtDetailList(
    params: {
      /**  */
      projectName?: string;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      minAmount?: number;
      /**  */
      maxAmount?: number;
      /**  */
      projectId?: number;
      /**  */
      itemName?: string;
      /**  */
      remark?: string;
      /**  */
      summary?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectDetailReprotDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetProjectRepprtDetailList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        MinAmount: params['minAmount'],
        MaxAmount: params['maxAmount'],
        ProjectId: params['projectId'],
        ItemName: params['itemName'],
        Remark: params['remark'],
        Summary: params['summary'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExpenseRecordDetailByYear(
    params: {
      /**  */
      isInternal?: boolean;
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDetail[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetExpenseRecordDetailByYear';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { isInternal: params['isInternal'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 保存支付时间
   */
  static savePayDate(
    params: {
      /**  */
      id?: number;
      /**  */
      payDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/SavePayDate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], payDate: params['payDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 收款单位 */
      payeeName?: string;
      /** 开户银行 */
      payeeBankAccount?: string;
      /** 付款方式 */
      payType?: PayType;
      /** 合同编号 */
      contractNo?: string;
      /** 开始日期 */
      startDate?: string;
      /** 截止日期 */
      endDate?: string;
      /**  */
      projectName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PayeeName: params['payeeName'],
        PayeeBankAccount: params['payeeBankAccount'],
        PayType: params['payType'],
        ContractNo: params['contractNo'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectName: params['projectName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 收款单位 */
      payeeName?: string;
      /** 开户银行 */
      payeeBankAccount?: string;
      /** 付款方式 */
      payType?: PayType;
      /** 合同编号 */
      contractNo?: string;
      /** 开始日期 */
      startDate?: string;
      /** 截止日期 */
      endDate?: string;
      /**  */
      projectName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PayeeName: params['payeeName'],
        PayeeBankAccount: params['payeeBankAccount'],
        PayType: params['payType'],
        ContractNo: params['contractNo'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectName: params['projectName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CapitalIncomeRecordFromDonationService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromDonationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromDonationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取资金捐赠合计金额
   */
  static getDonationTotalMoney(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      donatorName?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      capitalProperty?: CapitalProperty;
      /**  */
      currencyType?: CurrencyType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetDonationTotalMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        DonatorName: params['donatorName'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        CapitalProperty: params['capitalProperty'],
        CurrencyType: params['currencyType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromDonationPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      donatorName?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      capitalProperty?: CapitalProperty;
      /**  */
      currencyType?: CurrencyType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        DonatorName: params['donatorName'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        CapitalProperty: params['capitalProperty'],
        CurrencyType: params['currencyType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      donatorName?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      capitalProperty?: CapitalProperty;
      /**  */
      currencyType?: CurrencyType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        DonatorName: params['donatorName'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        CapitalProperty: params['capitalProperty'],
        CurrencyType: params['currencyType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CapitalIncomeRecordFromOtherService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromOtherCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromOtherCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取其他收入合计金额
   */
  static getOtherTotalMoney(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      incomeCategory?: IncomeCategory;
      /**  */
      currencyType?: CurrencyType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetOtherTotalMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        IncomeCategory: params['incomeCategory'],
        CurrencyType: params['currencyType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromOtherPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      incomeCategory?: IncomeCategory;
      /**  */
      currencyType?: CurrencyType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        IncomeCategory: params['incomeCategory'],
        CurrencyType: params['currencyType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      incomeCategory?: IncomeCategory;
      /**  */
      currencyType?: CurrencyType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        IncomeCategory: params['incomeCategory'],
        CurrencyType: params['currencyType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CityService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      provinceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provinceId: params['provinceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      cityName?: string;
      /**  */
      shortCode?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CityName: params['cityName'],
        ShortCode: params['shortCode'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsAnchorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsAnchorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsAnchorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /**  */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsCategoryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static showIt(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/ShowIt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hiddenIt(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/HiddenIt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCmsCategoryList(
    params: {
      /**  */
      categoryCode?: string;
      /**  */
      isLeftJoin?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetCmsCategoryList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { categoryCode: params['categoryCode'], isLeftJoin: params['isLeftJoin'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByCode(
    params: {
      /**  */
      categoryCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetByCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { categoryCode: params['categoryCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改栏目排序
   */
  static updateSort(
    params: {
      /** requestBody */
      body?: CmsCategoryUpdateSortDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/UpdateSort';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 只和UseParentIdParameter为true时使用。 */
      parentId?: number;
      /**  */
      parentCode?: string;
      /**  */
      useParentIdParameter?: boolean;
      /**  */
      isHidden?: boolean;
      /**  */
      enableDataPermissionLimit?: boolean;
      /**  */
      needShowContentCategory?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        ParentCode: params['parentCode'],
        UseParentIdParameter: params['useParentIdParameter'],
        IsHidden: params['isHidden'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NeedShowContentCategory: params['needShowContentCategory'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsContentService {
  /**
   * 获取文章详情，包含明细
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publish(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelPublish(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 置顶
   */
  static stickyPost(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/StickyPost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消置顶
   */
  static cancelStickyPost(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CancelStickyPost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static moveToTrash(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/MoveToTrash';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static restoreFromTrash(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/RestoreFromTrash';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsContentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsContentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 点赞
   */
  static praise(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Praise';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchPublishedContent(
    params: {
      /**  */
      title?: string;
      /**  */
      categoryId?: number;
      /**  */
      categoryCode?: string;
      /**  */
      publishYear?: number;
      /**  */
      status?: CmsContentStatus;
      /**  */
      enableDataPermissionLimit?: boolean;
      /** 指定要不包含的文章ID */
      noContainsId?: number;
      /** 排序方式.1=>默认方式,2=>lastest(最新) */
      sorting?: string;
      /** 基金Id */
      fundId?: number;
      /** 基金项目Id */
      fundProjectId?: number;
      /**  */
      hasCategory?: boolean;
      /** 记录创建人员 */
      creatorUserId?: number;
      /** 是否加载子栏目的文章列表 */
      isLoadChildrenCategoryArticles?: boolean;
      /** 前端是否需要非发布状态记录数据 */
      frontendNeedAllStatus?: boolean;
      /** 济南-获取临时转储数据 */
      isGetAllTemp?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/SearchPublishedContent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        CategoryId: params['categoryId'],
        CategoryCode: params['categoryCode'],
        PublishYear: params['publishYear'],
        Status: params['status'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NoContainsId: params['noContainsId'],
        Sorting: params['sorting'],
        FundID: params['fundId'],
        FundProjectID: params['fundProjectId'],
        HasCategory: params['hasCategory'],
        CreatorUserId: params['creatorUserId'],
        IsLoadChildrenCategoryArticles: params['isLoadChildrenCategoryArticles'],
        FrontendNeedAllStatus: params['frontendNeedAllStatus'],
        IsGetAllTemp: params['isGetAllTemp'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前登录用户收藏的文章列表
   */
  static searchMyCollectedPublishedContent(
    params: {
      /**  */
      title?: string;
      /**  */
      categoryId?: number;
      /**  */
      categoryCode?: string;
      /**  */
      publishYear?: number;
      /**  */
      status?: CmsContentStatus;
      /**  */
      enableDataPermissionLimit?: boolean;
      /** 指定要不包含的文章ID */
      noContainsId?: number;
      /** 排序方式.1=>默认方式,2=>lastest(最新) */
      sorting?: string;
      /** 基金Id */
      fundId?: number;
      /** 基金项目Id */
      fundProjectId?: number;
      /**  */
      hasCategory?: boolean;
      /** 记录创建人员 */
      creatorUserId?: number;
      /** 是否加载子栏目的文章列表 */
      isLoadChildrenCategoryArticles?: boolean;
      /** 前端是否需要非发布状态记录数据 */
      frontendNeedAllStatus?: boolean;
      /** 济南-获取临时转储数据 */
      isGetAllTemp?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/SearchMyCollectedPublishedContent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        CategoryId: params['categoryId'],
        CategoryCode: params['categoryCode'],
        PublishYear: params['publishYear'],
        Status: params['status'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NoContainsId: params['noContainsId'],
        Sorting: params['sorting'],
        FundID: params['fundId'],
        FundProjectID: params['fundProjectId'],
        HasCategory: params['hasCategory'],
        CreatorUserId: params['creatorUserId'],
        IsLoadChildrenCategoryArticles: params['isLoadChildrenCategoryArticles'],
        FrontendNeedAllStatus: params['frontendNeedAllStatus'],
        IsGetAllTemp: params['isGetAllTemp'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 收藏文章
   */
  static collectContent(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CollectContent';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消收藏文章
   */
  static cancelCollectContent(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CancelCollectContent';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 当前用户是否已经收藏文章
   */
  static isCollectContent(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/IsCollectContent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      title?: string;
      /**  */
      categoryId?: number;
      /**  */
      categoryCode?: string;
      /**  */
      publishYear?: number;
      /**  */
      status?: CmsContentStatus;
      /**  */
      enableDataPermissionLimit?: boolean;
      /** 指定要不包含的文章ID */
      noContainsId?: number;
      /** 排序方式.1=>默认方式,2=>lastest(最新) */
      sorting?: string;
      /** 基金Id */
      fundId?: number;
      /** 基金项目Id */
      fundProjectId?: number;
      /**  */
      hasCategory?: boolean;
      /** 记录创建人员 */
      creatorUserId?: number;
      /** 是否加载子栏目的文章列表 */
      isLoadChildrenCategoryArticles?: boolean;
      /** 前端是否需要非发布状态记录数据 */
      frontendNeedAllStatus?: boolean;
      /** 济南-获取临时转储数据 */
      isGetAllTemp?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        CategoryId: params['categoryId'],
        CategoryCode: params['categoryCode'],
        PublishYear: params['publishYear'],
        Status: params['status'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NoContainsId: params['noContainsId'],
        Sorting: params['sorting'],
        FundID: params['fundId'],
        FundProjectID: params['fundProjectId'],
        HasCategory: params['hasCategory'],
        CreatorUserId: params['creatorUserId'],
        IsLoadChildrenCategoryArticles: params['isLoadChildrenCategoryArticles'],
        FrontendNeedAllStatus: params['frontendNeedAllStatus'],
        IsGetAllTemp: params['isGetAllTemp'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量更新文章栏目
   */
  static batchChangeCategory(
    params: {
      /**  */
      contentIds?: string;
      /**  */
      categoryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/BatchChangeCategory';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { contentIds: params['contentIds'], categoryId: params['categoryId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static syncWechatNews(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/SyncWechatNews';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createByPortal(
    params: {
      /** requestBody */
      body?: CmsContentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CreateByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateByPortal(
    params: {
      /** requestBody */
      body?: CmsContentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/UpdateByPortal';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteByPortal(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/DeleteByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端提交审核
   */
  static submitAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/SubmitAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      title?: string;
      /**  */
      categoryId?: number;
      /**  */
      categoryCode?: string;
      /**  */
      publishYear?: number;
      /**  */
      status?: CmsContentStatus;
      /**  */
      enableDataPermissionLimit?: boolean;
      /** 指定要不包含的文章ID */
      noContainsId?: number;
      /** 排序方式.1=>默认方式,2=>lastest(最新) */
      sorting?: string;
      /** 基金Id */
      fundId?: number;
      /** 基金项目Id */
      fundProjectId?: number;
      /**  */
      hasCategory?: boolean;
      /** 记录创建人员 */
      creatorUserId?: number;
      /** 是否加载子栏目的文章列表 */
      isLoadChildrenCategoryArticles?: boolean;
      /** 前端是否需要非发布状态记录数据 */
      frontendNeedAllStatus?: boolean;
      /** 济南-获取临时转储数据 */
      isGetAllTemp?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        CategoryId: params['categoryId'],
        CategoryCode: params['categoryCode'],
        PublishYear: params['publishYear'],
        Status: params['status'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NoContainsId: params['noContainsId'],
        Sorting: params['sorting'],
        FundID: params['fundId'],
        FundProjectID: params['fundProjectId'],
        HasCategory: params['hasCategory'],
        CreatorUserId: params['creatorUserId'],
        IsLoadChildrenCategoryArticles: params['isLoadChildrenCategoryArticles'],
        FrontendNeedAllStatus: params['frontendNeedAllStatus'],
        IsGetAllTemp: params['isGetAllTemp'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsFriendLinkService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsFriendLinkCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsFriendLinkCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /**  */
      link?: string;
      /**  */
      displayOrder?: number;
      /** 类型Id（数据字典） */
      typeId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Link: params['link'],
        DisplayOrder: params['displayOrder'],
        TypeId: params['typeId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CollectWaterService {
  /**
   * 同步NC收款流水
   */
  static syncCollectWater(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CollectWater/SyncCollectWater';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CollectWaterDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CollectWater/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 单据编号 */
      billNo?: string;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /** 收支项目 */
      donateProject?: string;
      /** 摘要 */
      summary?: string;
      /** 确认状态 */
      status?: CollectWaterStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CollectWaterDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CollectWater/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BillNo: params['billNo'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        DonateProject: params['donateProject'],
        Summary: params['summary'],
        Status: params['status'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CollectWaterCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CollectWaterDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CollectWater/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CollectWaterCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CollectWaterDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CollectWater/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CollectWater/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CommunityService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Community/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /**  */
      townId?: number;
      /**  */
      contactName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Community/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        ContactName: params['contactName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CommunityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Community/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CommunityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Community/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Community/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CommunityUserService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CommunityUserCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CommunityUser/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CommunityUserUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CommunityUser/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除社区工作人员
   */
  static delete(
    params: {
      /**  */
      communityUserId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CommunityUser/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { communityUserId: params['communityUserId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: CommunityUserResetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CommunityUser/ResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConfigurationService {
  /**
   *
   */
  static changeUiTheme(
    params: {
      /** requestBody */
      body?: ChangeUiThemeInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Configuration/ChangeUiTheme';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContactsService {
  /**
   * 获取捐赠人
   */
  static getDonorAll(
    params: {
      /** 用户名 */
      surname?: string;
      /** 手机号 */
      phone?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonorDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Contacts/GetDonorAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Surname: params['surname'],
        Phone: params['phone'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CustomFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CustomFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static copyExistForm(
    params: {
      /** requestBody */
      body?: CustomFormCopyInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/CopyExistForm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取表单的ID
   */
  static getCustomFormId(
    params: {
      /**  */
      hostType?: CustomFormType;
      /**  */
      hostId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/GetCustomFormId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { HostType: params['hostType'], HostId: params['hostId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据类名获取默认的固定字段表单定义
   */
  static getDefaultFields(
    params: {
      /**  */
      formType?: CustomFormType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormFieldDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/GetDefaultFields';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { formType: params['formType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 拷贝
   */
  static copy(
    params: {
      /** requestBody */
      body?: CustomFormCopyInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Copy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      hostType?: CustomFormType;
      /**  */
      hostId?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        HostType: params['hostType'],
        HostId: params['hostId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CycleDonateConfigService {
  /**
   *
   */
  static getAll(
    params: {
      /** 捐助人姓名 */
      displayName?: string;
      /** 手机号 */
      mobileTelephone?: string;
      /** 定时捐类型 */
      donationType?: DonationType;
      /** 是否开启 */
      isOpen?: boolean;
      /** 捐助人身份 */
      userType?: UserType;
      /** 捐赠类型 */
      donorType?: DonorType;
      /** Id */
      id?: number;
      /** 订单Id */
      contractOrderId?: string;
      /** 用户Id */
      userId?: number;
      /** 捐贈意向类型 */
      donorItemType?: DonorItemType;
      /** 捐赠意向Id */
      donorItemId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CycleDonateConfigDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CycleDonateConfig/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        MobileTelephone: params['mobileTelephone'],
        DonationType: params['donationType'],
        IsOpen: params['isOpen'],
        UserType: params['userType'],
        DonorType: params['donorType'],
        Id: params['id'],
        ContractOrderId: params['contractOrderId'],
        UserId: params['userId'],
        DonorItemType: params['donorItemType'],
        DonorItemId: params['donorItemId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CycleDonateConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CycleDonateConfig/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailByUser(
    params: {
      /** Id */
      id?: number;
      /** 捐贈意向类型 */
      donorItemType?: DonorItemType;
      /** 捐赠意向Id */
      donorItemId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CycleDonateConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CycleDonateConfig/GetDetailByUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'], DonorItemType: params['donorItemType'], DonorItemId: params['donorItemId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailByOrder(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CycleDonateConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CycleDonateConfig/GetDetailByOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeStatus(
    params: {
      /** requestBody */
      body?: CycleDonateChangeStatusInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CycleDonateConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CycleDonateConfig/ChangeStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeCycle(
    params: {
      /** requestBody */
      body?: CycleDonateChangeCycleInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CycleDonateConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CycleDonateConfig/ChangeCycle';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DashbordService {
  /**
   * 获取每月发布普通活动数量、志愿者活动数量
   */
  static getActivityCountMonthly(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActivityReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetActivityCountMonthly';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取发布文章总数、发布活动总数
   */
  static getPublishedCount(options: IRequestOptions = {}): Promise<DashbordPublishedCount> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetPublishedCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取每月总志愿者数量、每月新增志愿者数量
   */
  static getVolunteerCountMonthly(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetVolunteerCountMonthly';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取站点访问记录(参数必填，否则报错)
   */
  static getAccessRecord(
    params: {
      /**  */
      start: string;
      /**  */
      end: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TotalTenantAccessRecordReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetAccessRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { start: params['start'], end: params['end'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 专项基金收入排行
   */
  static getFundIncomeRecordRank(
    params: {
      /**  */
      start: string;
      /**  */
      end?: string;
      /**  */
      fundType?: FundType;
      /**  */
      isLimitTop10?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundRankDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetFundIncomeRecordRank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        start: params['start'],
        end: params['end'],
        fundType: params['fundType'],
        isLimitTop10: params['isLimitTop10']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 专项基金支出排行
   */
  static getFundAppropriateRecordRank(
    params: {
      /**  */
      start: string;
      /**  */
      end?: string;
      /**  */
      fundType?: FundType;
      /**  */
      isLimitTop10?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundRankDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetFundAppropriateRecordRank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        start: params['start'],
        end: params['end'],
        fundType: params['fundType'],
        isLimitTop10: params['isLimitTop10']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 基金账户余额排行
   */
  static getFundBalanceRecordRank(
    params: {
      /**  */
      start: string;
      /**  */
      end?: string;
      /**  */
      fundType?: FundType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundRankDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetFundBalanceRecordRank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { start: params['start'], end: params['end'], fundType: params['fundType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 各项目当月筹款排行
   */
  static getFundProjectIncomeRank(options: IRequestOptions = {}): Promise<FundProjectRankDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetFundProjectIncomeRank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目领域占比表
   */
  static getProjectCategoryChart(options: IRequestOptions = {}): Promise<ProjectCategoryChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetProjectCategoryChart';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 年度筹款额
   */
  static getTotalIncomeByYear(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetTotalIncomeByYear';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 年度新增基金总数
   */
  static getNewFundByYear(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetNewFundByYear';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 年度新增基金项目总数
   */
  static getNewFundProjectByYear(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetNewFundProjectByYear';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 年度新增志愿者总数
   */
  static getNewVolunteerByYear(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetNewVolunteerByYear';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 当月新增志愿者总数
   */
  static getNewVolunteerByCurrentMonth(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetNewVolunteerByCurrentMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 当月志愿者活动签到次数
   */
  static getActivitySignInCountByCurrentMonth(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetActivitySignInCountByCurrentMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 当月志愿者活动报名次数
   */
  static getActivityApplyCountByCurrentMonth(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetActivityApplyCountByCurrentMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 当月新发布志愿者活动数量
   */
  static getNewPublishedActivityCountByCurrentMonth(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/GetNewPublishedActivityCountByCurrentMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 志愿者积分排名
   */
  static getVolunteerPointRank(
    params: {
      /**  */
      level?: number;
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerPointRankDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Dashbord/getVolunteerPointRank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Level: params['level'], Limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DataDictionaryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DataDictionaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DataDictionaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static syncToTenant(
    params: {
      /** requestBody */
      body?: DataDictionarySyncInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/SyncToTenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页查询
   */
  static getAll(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据parentID获取子字典数据
   */
  static getListByParentId(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetListByParentId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Key获取子字典数据
   */
  static getDataDictionaryListByKey(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetDataDictionaryListByKey';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Key获取不分页的字典项
   */
  static getDataDictionaryByKey(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetDataDictionaryByKey';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDataDictionaryByKeyAndValue(
    params: {
      /**  */
      key?: string;
      /**  */
      value?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetDataDictionaryByKeyAndValue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'], value: params['value'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有的一级字典项
   */
  static getAllRoot(options: IRequestOptions = {}): Promise<DataDictionaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetAllRoot';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DataPermissionLimitService {
  /**
   * 获取数据
   */
  static getAll(
    params: {
      /**  */
      hostId?: string;
      /**  */
      hostType?: DataPermissionLimitHostType;
      /**  */
      targetType?: DataPermissionLimitTargetType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataPermissionLimitDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { HostId: params['hostId'], HostType: params['hostType'], TargetType: params['targetType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DataPermissionLimitUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reset(
    params: {
      /** requestBody */
      body?: DataPermissionLimitHostType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Reset';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DepartmentService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DepartmentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DepartmentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DonatedAmountConfigService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DonatedAmountConfigCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonatedAmountConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonatedAmountConfig/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static search(
    params: {
      /** 名称 */
      name?: string;
      /**  */
      sourceType?: DonatedAmountConfigSourceType;
      /**  */
      sourceId?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonatedAmountConfigDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonatedAmountConfig/Search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        SourceType: params['sourceType'],
        SourceId: params['sourceId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DonatedAmountConfigCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonatedAmountConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonatedAmountConfig/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonatedAmountConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonatedAmountConfig/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 名称 */
      name?: string;
      /**  */
      sourceType?: DonatedAmountConfigSourceType;
      /**  */
      sourceId?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonatedAmountConfigDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonatedAmountConfig/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        SourceType: params['sourceType'],
        SourceId: params['sourceId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonatedAmountConfig/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DonateDetailService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DonateDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonateDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DonateDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonateDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入捐赠明细
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByPortal(
    params: {
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 捐赠者类型 */
      donorType?: DonorType;
      /** 支付方式 */
      paymentType?: string;
      /** 关键字 */
      keywords?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonateDetailSimpleDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        DonorType: params['donorType'],
        PaymentType: params['paymentType'],
        Keywords: params['keywords'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTotalMoney(
    params: {
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 捐赠者类型 */
      donorType?: DonorType;
      /** 支付方式 */
      paymentType?: string;
      /** 关键字 */
      keywords?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/GetTotalMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        DonorType: params['donorType'],
        PaymentType: params['paymentType'],
        Keywords: params['keywords'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonateDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 捐赠人 */
      donorName?: string;
      /** 捐赠意向 */
      donationIntent?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DonateDetailDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DonateDetail/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        DonorName: params['donorName'],
        DonationIntent: params['donationIntent'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DownloadService {
  /**
   *
   */
  static attachment(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Download/Attachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDownload(
    params: {
      /**  */
      id?: string;
      /**  */
      zipFileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Download/BatchDownload';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], zipFileName: params['zipFileName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EditionService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EditionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      isPublic?: boolean;
      /**  */
      name?: string;
      /**  */
      displayName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EditionDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { IsPublic: params['isPublic'], Name: params['name'], DisplayName: params['displayName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: EditionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EditionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: EditionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EditionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setAsPublic(
    params: {
      /** requestBody */
      body?: EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/SetAsPublic';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setAsPrivate(
    params: {
      /** requestBody */
      body?: EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/SetAsPrivate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setAsDefault(
    params: {
      /** requestBody */
      body?: EditionSetAsDefaultDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/SetAsDefault';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static grantFeatures(
    params: {
      /** requestBody */
      body?: GrantFeaturesToEditionInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/GrantFeatures';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGrantedFeatures(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GrantFeatureDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Edition/GetGrantedFeatures';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ElectronicInvoiceService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ElectronicInvoiceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectronicInvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createByPaySuccess(
    params: {
      /**  */
      invoiceAmount?: number;
      /** requestBody */
      body?: ElectronicInvoiceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectronicInvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/CreateByPaySuccess';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { invoiceAmount: params['invoiceAmount'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ElectronicInvoiceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectronicInvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectronicInvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSelectFundIncomeRecord(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordMiniDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/GetSelectFundIncomeRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserElectronicInvoice(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserElectronicInvoiceDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/GetUserElectronicInvoice';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取发票数据
   */
  static getInvoiceCount(options: IRequestOptions = {}): Promise<ElectronicInvoiceCountGetDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/GetInvoiceCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置发票数量
   */
  static setInvoiceCount(
    params: {
      /** requestBody */
      body?: ElectronicInvoiceCountPutDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/SetInvoiceCount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 确认
   */
  static confirm(
    params: {
      /** requestBody */
      body?: ElectronicInvoiceConfirmPutDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/Confirm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 冲红
   */
  static creditNote(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/CreditNote';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 捐赠票据抬头 */
      donationNoteTitle?: string;
      /** 电子发票状态 */
      status?: ElectronicInvoiceStatus;
      /** 手机号 */
      mobilePhone?: string;
      /** 邮箱 */
      email?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectronicInvoiceDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DonationNoteTitle: params['donationNoteTitle'],
        Status: params['status'],
        MobilePhone: params['mobilePhone'],
        Email: params['email'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ElectronicInvoice/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EnumService {
  /**
   * 获取枚举选项列表
   */
  static getValues(
    params: {
      /** 枚举名称，如：NotifyTemplateType */
      typeName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetValues';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { typeName: params['typeName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllEnums(
    params: {
      /**  */
      filter?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAllEnums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filter: params['filter'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAppEnums(options: IRequestOptions = {}): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAppEnums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAppEnumNames(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAppEnumNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExamQuestionService {
  /**
   * 新增试题，包括答案
   */
  static createWithChoiceItem(
    params: {
      /** requestBody */
      body?: SaveDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/CreateWithChoiceItem';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新试题，包括答案
   */
  static updateWithChoiceItem(
    params: {
      /** requestBody */
      body?: SaveDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/UpdateWithChoiceItem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExamQuestionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取设置的试题列表
   */
  static getQuestionList(
    params: {
      /**  */
      sourceId?: number;
      /**  */
      sourceType?: ExamSourceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainExamQuestionDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/GetQuestionList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { SourceId: params['sourceId'], SourceType: params['sourceType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全部未选中的考题
   */
  static getUnselectedQuestionsByTrain(
    params: {
      /** 试题名称 */
      question?: string;
      /**  */
      trainId?: number;
      /** 题型 */
      questionType?: QuestionType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
      /**  */
      sourceType?: ExamSourceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExamQuestionDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/GetUnselectedQuestionsByTrain';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Question: params['question'],
        TrainId: params['trainId'],
        QuestionType: params['questionType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount'],
        sourceType: params['sourceType']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全部选中的考题
   */
  static getSelectedQuestionsByTrain(
    params: {
      /** 试题名称 */
      question?: string;
      /**  */
      trainId?: number;
      /** 题型 */
      questionType?: QuestionType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
      /**  */
      sourceType?: ExamSourceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExamQuestionDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/GetSelectedQuestionsByTrain';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Question: params['question'],
        TrainId: params['trainId'],
        QuestionType: params['questionType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount'],
        sourceType: params['sourceType']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量添加试题
   */
  static trainQuestionBatchSave(
    params: {
      /** requestBody */
      body?: TrainQuestionBatchSave;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/TrainQuestionBatchSave';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量删除试题
   */
  static trainQuestionBatchDeleteSave(
    params: {
      /** requestBody */
      body?: TrainQuestionBatchDeleteSave;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/TrainQuestionBatchDeleteSave';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 培训试题添加
   */
  static trainQuestionSave(
    params: {
      /**  */
      questionId?: number;
      /**  */
      trainId?: number;
      /** requestBody */
      body?: ExamSourceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/TrainQuestionSave';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { questionId: params['questionId'], trainId: params['trainId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 培训试题删除
   */
  static trainQuestionDelete(
    params: {
      /**  */
      questionId?: number;
      /**  */
      trainId?: number;
      /** requestBody */
      body?: ExamSourceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/TrainQuestionDelete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { questionId: params['questionId'], trainId: params['trainId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取某次培训中最后一次提交的试卷
   */
  static getLatestTrainPaperId(
    params: {
      /**  */
      trainId?: number;
      /**  */
      userId?: number;
      /**  */
      sourceType?: ExamSourceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExamPaperMiniDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/GetLatestTrainPaperId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { trainId: params['trainId'], userId: params['userId'], sourceType: params['sourceType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取答卷对错题详细分析
   */
  static getQuestionAnalysisList(
    params: {
      /**  */
      paperId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuestionAnalysisDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/GetQuestionAnalysisList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paperId: params['paperId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 交卷
   */
  static submitPaper(
    params: {
      /** requestBody */
      body?: SubmitPaperDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/SubmitPaper';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 试题名称 */
      question?: string;
      /**  */
      trainId?: number;
      /** 题型 */
      questionType?: QuestionType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExamQuestionDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Question: params['question'],
        TrainId: params['trainId'],
        QuestionType: params['questionType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExamQuestionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExamQuestionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExamQuestionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExamQuestionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExamQuestion/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExpenditureDetailService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExpenditureDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpenditureDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExpenditureDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpenditureDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入支出明细
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static statisticsTotalAmount(
    params: {
      /**  */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 关键字 */
      keywords?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/StatisticsTotalAmount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        Keywords: params['keywords'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpenditureDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 关键字 */
      keywords?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpenditureDetailDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExpenditureDetail/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        Keywords: params['keywords'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalDueDiligenceRecordService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalDueDiligenceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 尽调形式 */
      dueDiligenceTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startDateStart?: string;
      /**  */
      startDateEnd?: string;
      /**  */
      endDateStart?: string;
      /**  */
      endDateEnd?: string;
      /**  */
      place?: string;
      /**  */
      members?: string;
      /**  */
      visitObjectJson?: string;
      /**  */
      orgInfo?: string;
      /**  */
      projectInfo?: string;
      /**  */
      omnibusOpinion?: string;
      /**  */
      organizationUnitName?: string;
      /**  */
      projectName?: string;
      /**  */
      organizationUnitId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DueDiligenceTypeId: params['dueDiligenceTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartDateStart: params['startDateStart'],
        StartDateEnd: params['startDateEnd'],
        EndDateStart: params['endDateStart'],
        EndDateEnd: params['endDateEnd'],
        Place: params['place'],
        Members: params['members'],
        VisitObjectJson: params['visitObjectJson'],
        OrgInfo: params['orgInfo'],
        ProjectInfo: params['projectInfo'],
        OmnibusOpinion: params['omnibusOpinion'],
        OrganizationUnitName: params['organizationUnitName'],
        ProjectName: params['projectName'],
        OrganizationUnitId: params['organizationUnitId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalDueDiligenceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 尽调形式 */
      dueDiligenceTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startDateStart?: string;
      /**  */
      startDateEnd?: string;
      /**  */
      endDateStart?: string;
      /**  */
      endDateEnd?: string;
      /**  */
      place?: string;
      /**  */
      members?: string;
      /**  */
      visitObjectJson?: string;
      /**  */
      orgInfo?: string;
      /**  */
      projectInfo?: string;
      /**  */
      omnibusOpinion?: string;
      /**  */
      organizationUnitName?: string;
      /**  */
      projectName?: string;
      /**  */
      organizationUnitId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DueDiligenceTypeId: params['dueDiligenceTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartDateStart: params['startDateStart'],
        StartDateEnd: params['startDateEnd'],
        EndDateStart: params['endDateStart'],
        EndDateEnd: params['endDateEnd'],
        Place: params['place'],
        Members: params['members'],
        VisitObjectJson: params['visitObjectJson'],
        OrgInfo: params['orgInfo'],
        ProjectInfo: params['projectInfo'],
        OmnibusOpinion: params['omnibusOpinion'],
        OrganizationUnitName: params['organizationUnitName'],
        ProjectName: params['projectName'],
        OrganizationUnitId: params['organizationUnitId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalMonthlyPlanService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我的未填写总结的月活动计划列表
   */
  static getMyExternalMonthlyPlanList(
    params: {
      /**  */
      projectId?: number;
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetMyExternalMonthlyPlanList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ProjectId: params['projectId'], Date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的项目id
   */
  static getExternalProjectIdListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetExternalProjectIdListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalMonthlySummarizeService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectService {
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 复制新增
   */
  static copyCreate(
    params: {
      /** requestBody */
      body?: ExternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/CopyCreate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取外部项目计划书详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据ProjectId获取外部项目计划书详情
   */
  static getDetailByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetDetailByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectMonthlySummaizeDetailsByProjectId(
    params: {
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthlySummaizeDetailsDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetProjectMonthlySummaizeDetailsByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新外部项目预算表
   */
  static updateBudgetItem(
    params: {
      /** requestBody */
      body?: UpdateExternalProjectBudgetItemsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/UpdateBudgetItem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取外部项目已立项列表
   */
  static getExternalProjectList(options: IRequestOptions = {}): Promise<ExternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetExternalProjectList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的外部项目列表
   */
  static getExternalProjectListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetExternalProjectListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      orgName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      orgName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectChangeApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectChangeApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectChangeApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 变更时间 */
      changeDate?: string;
      /**  */
      changeTypes?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ChangeDate: params['changeDate'],
        ChangeTypes: params['changeTypes'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 变更时间 */
      changeDate?: string;
      /**  */
      changeTypes?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ChangeDate: params['changeDate'],
        ChangeTypes: params['changeTypes'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectCloseReportService {
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CloseReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CloseReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送评估总结报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectIntentionService {
  /**
   * 获取项目意向书详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectIntentionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectIntentionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectPhaseService {
  /**
   *
   */
  static getProjectPhasesList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectPhaseItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectPhasesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectMonthList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectMonthList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectAttachmentListByPhase(
    params: {
      /** 项目ID */
      projectId?: number;
      /** 节点枚举 */
      stage?: WorkflowOfProjectByExternal;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectAttachmentListByPhase';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        Stage: params['stage'],
        Year: params['year'],
        Month: params['month'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectAttachmentListByMonth(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
      /** 项目ID */
      projectId?: number;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectAttachmentListByMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount'],
        ProjectId: params['projectId'],
        Year: params['year'],
        Month: params['month']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDownloadProjectPhasesAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      stage?: WorkflowOfProjectByExternal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/BatchDownloadProjectPhasesAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], stage: params['stage'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDownloadProjectMonthAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/BatchDownloadProjectMonthAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], year: params['year'], month: params['month'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectProcessMonitoringService {
  /**
   * 获取监测记录详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectProcessMonitoringCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectProcessMonitoringCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送监测记录填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 监测主题 */
      title?: string;
      /** 监测方式 */
      monitoringType?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        Title: params['title'],
        MonitoringType: params['monitoringType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 监测主题 */
      title?: string;
      /** 监测方式 */
      monitoringType?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        Title: params['title'],
        MonitoringType: params['monitoringType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectProgressReportService {
  /**
   * 获取中期报告详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProgressReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProgressReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送中期报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectReportService {
  /**
   * 外部项目宏观展示
   */
  static getMacroscopic(options: IRequestOptions = {}): Promise<LineChartDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetMacroscopic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 外部项目微观展示
   */
  static getMicrocosmics(
    params: {
      /** 项目年份 */
      year?: number;
      /** 项目类型 */
      projectType?: number;
      /** 项目状态 */
      status?: WorkflowOfProjectByExternal;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMicrocosmicDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetMicrocosmics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        ProjectType: params['projectType'],
        Status: params['status'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未完成月总结的项目
   */
  static getNotCompleteMonthlySummaryProjects(
    params: {
      /**  */
      year?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotCompleteMonthlySummaryProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未正常关项的项目
   */
  static getNotClosingNormallyProjects(
    params: {
      /**  */
      year?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotClosingNormallyProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 没有交付中期报告的项目列表
   */
  static getNotCompleteProgressReportProjects(
    params: {
      /**  */
      year?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotCompleteProgressReportProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 没有交付结项自评报告的项目列表
   */
  static getNotCompleteSummaryReportProjects(
    params: {
      /**  */
      year?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotCompleteSummaryReportProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectSummaryReportService {
  /**
   * 获取结项自评报告详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据项目Id获取结项自评报告详情
   */
  static getDetailByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetDetailByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送结项自评报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FeatureService {
  /**
   *
   */
  static getAllDefinitionFeatures(options: IRequestOptions = {}): Promise<FeatureDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feature/GetAllDefinitionFeatures';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FeedbackService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submit(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Submit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 我的在线留言
   */
  static getMy(
    params: {
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 联系方式 */
      contactType?: string;
      /** 反馈类型Id */
      typeCodeId?: number;
      /** 是否显示在官网 */
      isShowPortal?: boolean;
      /** 反馈来源 */
      sourceType?: FeedbackSourceType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/GetMy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Content: params['content'],
        ContactType: params['contactType'],
        TypeCodeId: params['typeCodeId'],
        IsShowPortal: params['isShowPortal'],
        SourceType: params['sourceType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static replySave(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/ReplySave';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 是否显示在官网
   */
  static showPortal(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/ShowPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 联系方式 */
      contactType?: string;
      /** 反馈类型Id */
      typeCodeId?: number;
      /** 是否显示在官网 */
      isShowPortal?: boolean;
      /** 反馈来源 */
      sourceType?: FeedbackSourceType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Content: params['content'],
        ContactType: params['contactType'],
        TypeCodeId: params['typeCodeId'],
        IsShowPortal: params['isShowPortal'],
        SourceType: params['sourceType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FeedbackReplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FeedbackReplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FeedbackReplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 显示记录
   */
  static show(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Show';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 隐藏记录
   */
  static hidden(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Hidden';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 反馈信息Id */
      feedbackId?: number;
      /** 内容 */
      content?: string;
      /** 是否隐藏 */
      isHide?: boolean;
      /** 用户Id */
      userId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FeedbackId: params['feedbackId'],
        Content: params['content'],
        IsHide: params['isHide'],
        UserId: params['userId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FormCacheService {
  /**
   * 设置缓存
   */
  static setCache(
    params: {
      /** requestBody */
      body?: CacheFormPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FormCache/SetCache';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取缓存
   */
  static getCache(
    params: {
      /**  */
      formType?: FormType;
      /**  */
      hostId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FormCache/GetCache';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { formType: params['formType'], hostId: params['hostId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除缓存
   */
  static delCache(
    params: {
      /**  */
      formType?: FormType;
      /**  */
      hostId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FormCache/DelCache';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { formType: params['formType'], hostId: params['hostId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundService {
  /**
   * 开启筹款
   */
  static enableFundRaising(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/EnableFundRaising';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 关闭筹款
   */
  static disableFundRaising(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/DisableFundRaising';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 全部发送基金消息
   */
  static sendAllMessage(
    params: {
      /**  */
      messageId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/SendAllMessage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { messageId: params['messageId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 选择发送基金消息
   */
  static sendMessage(
    params: {
      /** requestBody */
      body?: FundSendMessageInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/SendMessage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取二维码
   */
  static downQrCode(
    params: {
      /**  */
      fundId?: number;
      /**  */
      donateType?: DonateType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/DownQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fundId: params['fundId'], donateType: params['donateType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 基金名称 */
      name?: string;
      /** 基金类型 */
      type?: FundType;
      /**  */
      isAudited?: boolean;
      /** 细分类别 */
      fundCategory?: string;
      /** 标签 */
      tag?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Type: params['type'],
        IsAudited: params['isAudited'],
        FundCategory: params['fundCategory'],
        Tag: params['tag'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getList(
    params: {
      /** 基金名称 */
      name?: string;
      /** 基金类型 */
      type?: FundType;
      /**  */
      isAudited?: boolean;
      /** 细分类别 */
      fundCategory?: string;
      /** 标签 */
      tag?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Type: params['type'],
        IsAudited: params['isAudited'],
        FundCategory: params['fundCategory'],
        Tag: params['tag'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static stickyPost(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/StickyPost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取基金消息
   */
  static getFundMessage(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundMessageDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/GetFundMessage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取基金消息
   */
  static getFundMessageAll(
    params: {
      /** 基金Id */
      fundId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundMessageDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/GetFundMessageAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取基金消息的未读数量
   */
  static getFundMessageUnReadCount(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/GetFundMessageUnReadCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新基金消息的阅读信息
   */
  static updateFundMessageRead(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/UpdateFundMessageRead';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置是否官网显示
   */
  static setOfficialWebsiteDisplay(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Fund/SetOfficialWebsiteDisplay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundAccountService {
  /**
   *
   */
  static getAll(
    params: {
      /** 基金类型 */
      type?: FundType;
      /** 基金Id */
      fundId?: number;
      /**  */
      projectId?: number;
      /** 起始日期 */
      startDate?: string;
      /** 截止日期 */
      endDate?: string;
      /** 是否前端 */
      isFront?: boolean;
      /** 记录类型 */
      operationType?: FundAccountRecordOperationType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAccountDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAccount/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Type: params['type'],
        FundID: params['fundId'],
        ProjectId: params['projectId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        IsFront: params['isFront'],
        OperationType: params['operationType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: FundAccountPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAccount/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static queryIncomeAndAppropriateFundRecord(
    params: {
      /** 基金类型 */
      type?: FundType;
      /** 基金Id */
      fundId?: number;
      /**  */
      projectId?: number;
      /** 起始日期 */
      startDate?: string;
      /** 截止日期 */
      endDate?: string;
      /** 是否前端 */
      isFront?: boolean;
      /** 记录类型 */
      operationType?: FundAccountRecordOperationType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAccountRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAccount/QueryIncomeAndAppropriateFundRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Type: params['type'],
        FundID: params['fundId'],
        ProjectId: params['projectId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        IsFront: params['isFront'],
        OperationType: params['operationType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前用户所在的基金账号
   */
  static getCurrentFundAccount(options: IRequestOptions = {}): Promise<FundAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAccount/GetCurrentFundAccount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundApplyService {
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户最近一次申请的记录
   */
  static getLatestAudit(
    params: {
      /**  */
      fundType?: FundType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/GetLatestAudit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fundType: params['fundType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 基金名称 */
      fundName?: string;
      /**  */
      isAudited?: boolean;
      /** 基金类型 */
      type?: FundType;
      /** 细分类别 */
      fundCategory?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        IsAudited: params['isAudited'],
        Type: params['type'],
        FundCategory: params['fundCategory'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 基金名称 */
      fundName?: string;
      /**  */
      isAudited?: boolean;
      /** 基金类型 */
      type?: FundType;
      /** 细分类别 */
      fundCategory?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        IsAudited: params['isAudited'],
        Type: params['type'],
        FundCategory: params['fundCategory'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundAppropriateApplyService {
  /**
   *
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundAppropriateApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAppropriateApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      fundName?: string;
      /**  */
      projectName?: string;
      /**  */
      fundId?: number;
      /**  */
      isAudited?: boolean;
      /**  */
      projectId?: number;
      /**  */
      title?: string;
      /** 拨付金额 */
      moneyAmount?: number;
      /**  */
      moneyAmountStart?: number;
      /**  */
      moneyAmountEnd?: number;
      /** 拨付理由 */
      appropriateReason?: string;
      /** 收款银行账号 */
      bankAccountCode?: string;
      /** 开户行名称 */
      bankName?: string;
      /** 户名 */
      bankAccountName?: string;
      /** 收款人姓名 */
      receiverName?: string;
      /** 收款人电话 */
      receiverTelephone?: string;
      /** 拨付类型 */
      appropriateType?: FundAppropriateType;
      /** 其他项目名称 */
      otherProjectName?: string;
      /**  */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAppropriateApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        ProjectName: params['projectName'],
        FundId: params['fundId'],
        IsAudited: params['isAudited'],
        ProjectId: params['projectId'],
        Title: params['title'],
        MoneyAmount: params['moneyAmount'],
        MoneyAmountStart: params['moneyAmountStart'],
        MoneyAmountEnd: params['moneyAmountEnd'],
        AppropriateReason: params['appropriateReason'],
        BankAccountCode: params['bankAccountCode'],
        BankName: params['bankName'],
        BankAccountName: params['bankAccountName'],
        ReceiverName: params['receiverName'],
        ReceiverTelephone: params['receiverTelephone'],
        AppropriateType: params['appropriateType'],
        OtherProjectName: params['otherProjectName'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAppropriateApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      fundName?: string;
      /**  */
      projectName?: string;
      /**  */
      fundId?: number;
      /**  */
      isAudited?: boolean;
      /**  */
      projectId?: number;
      /**  */
      title?: string;
      /** 拨付金额 */
      moneyAmount?: number;
      /**  */
      moneyAmountStart?: number;
      /**  */
      moneyAmountEnd?: number;
      /** 拨付理由 */
      appropriateReason?: string;
      /** 收款银行账号 */
      bankAccountCode?: string;
      /** 开户行名称 */
      bankName?: string;
      /** 户名 */
      bankAccountName?: string;
      /** 收款人姓名 */
      receiverName?: string;
      /** 收款人电话 */
      receiverTelephone?: string;
      /** 拨付类型 */
      appropriateType?: FundAppropriateType;
      /** 其他项目名称 */
      otherProjectName?: string;
      /**  */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAppropriateApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        ProjectName: params['projectName'],
        FundId: params['fundId'],
        IsAudited: params['isAudited'],
        ProjectId: params['projectId'],
        Title: params['title'],
        MoneyAmount: params['moneyAmount'],
        MoneyAmountStart: params['moneyAmountStart'],
        MoneyAmountEnd: params['moneyAmountEnd'],
        AppropriateReason: params['appropriateReason'],
        BankAccountCode: params['bankAccountCode'],
        BankName: params['bankName'],
        BankAccountName: params['bankAccountName'],
        ReceiverName: params['receiverName'],
        ReceiverTelephone: params['receiverTelephone'],
        AppropriateType: params['appropriateType'],
        OtherProjectName: params['otherProjectName'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundAppropriateRecordService {
  /**
   * 获取拨付统计
   */
  static statisticsTotalAmountByFundId(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateRecord/StatisticsTotalAmountByFundID';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取拨付统计
   */
  static statisticsTotalAmount(
    params: {
      /**  */
      fundName?: string;
      /**  */
      fundId?: number;
      /**  */
      projectId?: number;
      /**  */
      projectName?: string;
      /**  */
      title?: string;
      /** 拨付金额 */
      moneyAmount?: number;
      /** 拨付金额 */
      moneyAmountStart?: number;
      /** 拨付金额 */
      moneyAmountEnd?: number;
      /** 拨付理由 */
      appropriateReason?: string;
      /** 拨付时间 */
      appropriateTimeStart?: string;
      /**  */
      appropriateTimeEnd?: string;
      /** 收款银行账号 */
      bankAccountCode?: string;
      /** 开户行名称 */
      bankName?: string;
      /** 户名 */
      bankAccountName?: string;
      /** 收款人姓名 */
      receiverName?: string;
      /** 收款人电话 */
      receiverTelephone?: string;
      /** 状态 */
      status?: ValidStatus;
      /** 拨付类型 */
      appropriateType?: FundAppropriateType;
      /** 其他项目名称 */
      otherProjectName?: string;
      /** 申请人 */
      creatorUserName?: string;
      /**  */
      year?: string;
      /**  */
      month?: string;
      /**  */
      day?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateRecord/StatisticsTotalAmount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        FundId: params['fundId'],
        ProjectId: params['projectId'],
        ProjectName: params['projectName'],
        Title: params['title'],
        MoneyAmount: params['moneyAmount'],
        MoneyAmountStart: params['moneyAmountStart'],
        MoneyAmountEnd: params['moneyAmountEnd'],
        AppropriateReason: params['appropriateReason'],
        AppropriateTimeStart: params['appropriateTimeStart'],
        AppropriateTimeEnd: params['appropriateTimeEnd'],
        BankAccountCode: params['bankAccountCode'],
        BankName: params['bankName'],
        BankAccountName: params['bankAccountName'],
        ReceiverName: params['receiverName'],
        ReceiverTelephone: params['receiverTelephone'],
        Status: params['status'],
        AppropriateType: params['appropriateType'],
        OtherProjectName: params['otherProjectName'],
        CreatorUserName: params['creatorUserName'],
        Year: params['year'],
        Month: params['month'],
        Day: params['day'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      fundName?: string;
      /**  */
      fundId?: number;
      /**  */
      projectId?: number;
      /**  */
      projectName?: string;
      /**  */
      title?: string;
      /** 拨付金额 */
      moneyAmount?: number;
      /** 拨付金额 */
      moneyAmountStart?: number;
      /** 拨付金额 */
      moneyAmountEnd?: number;
      /** 拨付理由 */
      appropriateReason?: string;
      /** 拨付时间 */
      appropriateTimeStart?: string;
      /**  */
      appropriateTimeEnd?: string;
      /** 收款银行账号 */
      bankAccountCode?: string;
      /** 开户行名称 */
      bankName?: string;
      /** 户名 */
      bankAccountName?: string;
      /** 收款人姓名 */
      receiverName?: string;
      /** 收款人电话 */
      receiverTelephone?: string;
      /** 状态 */
      status?: ValidStatus;
      /** 拨付类型 */
      appropriateType?: FundAppropriateType;
      /** 其他项目名称 */
      otherProjectName?: string;
      /** 申请人 */
      creatorUserName?: string;
      /**  */
      year?: string;
      /**  */
      month?: string;
      /**  */
      day?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAppropriateRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        FundId: params['fundId'],
        ProjectId: params['projectId'],
        ProjectName: params['projectName'],
        Title: params['title'],
        MoneyAmount: params['moneyAmount'],
        MoneyAmountStart: params['moneyAmountStart'],
        MoneyAmountEnd: params['moneyAmountEnd'],
        AppropriateReason: params['appropriateReason'],
        AppropriateTimeStart: params['appropriateTimeStart'],
        AppropriateTimeEnd: params['appropriateTimeEnd'],
        BankAccountCode: params['bankAccountCode'],
        BankName: params['bankName'],
        BankAccountName: params['bankAccountName'],
        ReceiverName: params['receiverName'],
        ReceiverTelephone: params['receiverTelephone'],
        Status: params['status'],
        AppropriateType: params['appropriateType'],
        OtherProjectName: params['otherProjectName'],
        CreatorUserName: params['creatorUserName'],
        Year: params['year'],
        Month: params['month'],
        Day: params['day'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改拨付时间
   */
  static changeAppropriateTime(
    params: {
      /** requestBody */
      body?: FundAppropriateRecordChangeAppropriateTimeInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateRecord/ChangeAppropriateTime';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundAppropriateRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundAppropriateRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundBankInfoService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundBankInfoCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundBankInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundBankInfo/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundBankInfoCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundBankInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundBankInfo/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundBankInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundBankInfo/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundBankInfoDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundBankInfo/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundBankInfo/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundDonationMonthlyConfigService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDonationMonthlyConfigDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationMonthlyConfig/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 捐赠项目类型 */
      donorItemType?: number;
      /** 捐赠项目编号 */
      donorItemId?: number;
      /** 捐赠协议序列号(签约银行返回) */
      donorSignSn?: string;
      /** 捐赠方类型(个人，团体，企业) */
      donorType?: DonorType;
      /** 固定捐助天 */
      donorDay?: number;
      /** 捐赠金额,单位：分 */
      donationAmount?: number;
      /**  */
      donationAmountStart?: number;
      /**  */
      donationAmountEnd?: number;
      /** 发票Id */
      invoiceId?: number;
      /** 捐赠留言 */
      leaveMessage?: string;
      /** 捐赠人联系号码 */
      mobileTelephone?: string;
      /** 发票附件编号，多个使用英文","隔开 */
      attachments?: string;
      /** 捐赠人名称(匿名使用“爱心人士”) */
      displayName?: string;
      /** 平台与天付宝确认签约订单号，关联发票订单Id */
      spBillNo?: string;
      /** 天付宝确认签约订单号，关联发票订单Id */
      listId?: string;
      /** 月捐状态(1：开启，0：停止月捐) */
      isOpen?: boolean;
      /** 是否已确认 */
      isConfirmed?: boolean;
      /** 推荐人 */
      referrer?: string;
      /** 停止月捐时间 */
      stoppedTimeStart?: string;
      /**  */
      stoppedTimeEnd?: string;
      /** 月捐类型（1天付宝，2微信） */
      configType?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDonationMonthlyConfigDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationMonthlyConfig/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DonorItemType: params['donorItemType'],
        DonorItemId: params['donorItemId'],
        DonorSignSn: params['donorSignSn'],
        DonorType: params['donorType'],
        DonorDay: params['donorDay'],
        DonationAmount: params['donationAmount'],
        DonationAmountStart: params['donationAmountStart'],
        DonationAmountEnd: params['donationAmountEnd'],
        InvoiceId: params['invoiceId'],
        LeaveMessage: params['leaveMessage'],
        MobileTelephone: params['mobileTelephone'],
        Attachments: params['attachments'],
        DisplayName: params['displayName'],
        SpBillNo: params['spBillNo'],
        ListId: params['listId'],
        IsOpen: params['isOpen'],
        IsConfirmed: params['isConfirmed'],
        Referrer: params['referrer'],
        StoppedTimeStart: params['stoppedTimeStart'],
        StoppedTimeEnd: params['stoppedTimeEnd'],
        ConfigType: params['configType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 停止月捐
   */
  static stop(
    params: {
      /** requestBody */
      body?: EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationMonthlyConfig/Stop';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundDonationOrderService {
  /**
   * 创建基金捐助订单
   */
  static create(
    params: {
      /** requestBody */
      body?: FundDonationOrderCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDonationOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationOrder/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 创建自动扣款订单
   */
  static createAutoDeductionOrder(
    params: {
      /** requestBody */
      body?: FundAutoDeductionOrderCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDonationOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationOrder/CreateAutoDeductionOrder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 处理支付回调
   */
  static processOrder(
    params: {
      /** requestBody */
      body?: PaymentInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationOrder/ProcessOrder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDonationOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationOrder/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByOrderId(
    params: {
      /**  */
      orderId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDonationOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationOrder/GetByOrderId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderId: params['orderId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      fundName?: string;
      /**  */
      fundProjectName?: string;
      /** 订单号 */
      orderId?: string;
      /** 捐赠基金Id */
      fundId?: number;
      /** 捐赠项目ID */
      fundProjectId?: number;
      /** 捐赠金额 */
      donationAmount?: number;
      /**  */
      donationAmountStart?: number;
      /**  */
      donationAmountEnd?: number;
      /** 是否为手动新增订单 */
      isManualCreate?: boolean;
      /** 用户Id */
      userId?: number;
      /** 捐赠人手机号码 */
      mobileTelephone?: string;
      /** 捐赠人类型 */
      donorType?: DonorType;
      /** 捐赠人姓名 */
      donorName?: string;
      /** 留言 */
      leaveMessage?: string;
      /** 是否支付成功 */
      isPayed?: boolean;
      /** 支付成功时间 */
      payedTime?: string;
      /**  */
      payedTimeStart?: string;
      /**  */
      payedTimeEnd?: string;
      /** 支付类型 */
      paymentType?: string;
      /** 支付平台是否响应 */
      isResponsed?: boolean;
      /** 支付平台响应时间 */
      responseTime?: string;
      /** 支付平台返回消息 */
      resultMessage?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 支付交易号 */
      tradeNo?: string;
      /** 捐赠者账号 */
      donorAccount?: string;
      /** 捐赠类型 */
      donationType?: IncomeType;
      /** 是否需要发票 */
      needInvoice?: boolean;
      /** 订单交易流水号(天付宝用) */
      orderSerialNo?: string;
      /** 推荐人 */
      referrer?: string;
      /** 月捐日期 */
      monthDonorDay?: number;
      /** 捐赠意向Id */
      donorItemId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundDonationOrderDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundDonationOrder/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        FundProjectName: params['fundProjectName'],
        OrderId: params['orderId'],
        FundID: params['fundId'],
        FundProjectID: params['fundProjectId'],
        DonationAmount: params['donationAmount'],
        DonationAmountStart: params['donationAmountStart'],
        DonationAmountEnd: params['donationAmountEnd'],
        IsManualCreate: params['isManualCreate'],
        UserId: params['userId'],
        MobileTelephone: params['mobileTelephone'],
        DonorType: params['donorType'],
        DonorName: params['donorName'],
        LeaveMessage: params['leaveMessage'],
        IsPayed: params['isPayed'],
        PayedTime: params['payedTime'],
        PayedTimeStart: params['payedTimeStart'],
        PayedTimeEnd: params['payedTimeEnd'],
        PaymentType: params['paymentType'],
        IsResponsed: params['isResponsed'],
        ResponseTime: params['responseTime'],
        ResultMessage: params['resultMessage'],
        DisplayName: params['displayName'],
        TradeNo: params['tradeNo'],
        DonorAccount: params['donorAccount'],
        DonationType: params['donationType'],
        NeedInvoice: params['needInvoice'],
        OrderSerialNo: params['orderSerialNo'],
        Referrer: params['referrer'],
        MonthDonorDay: params['monthDonorDay'],
        DonorItemId: params['donorItemId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundIncomeRecordService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundIncomeRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据订单号获取善款捐赠明细
   */
  static getByOrderId(
    params: {
      /**  */
      orderId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/GetByOrderId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderId: params['orderId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 关键词 */
      keyword?: string;
      /** 捐赠者名称 */
      donorName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 捐赠者类型 */
      donorType?: DonorType;
      /** 捐赠意向 */
      incomeType?: IncomeType;
      /** 基金Id */
      fundId?: number;
      /** 基金项目Id */
      projectId?: number;
      /** 支付方式 */
      paymentType?: string;
      /** 捐助方式 */
      isMonthDonor?: boolean;
      /** 用户Id */
      userId?: number;
      /** 申请人 */
      creatorUserName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        DonorName: params['donorName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        DonorType: params['donorType'],
        IncomeType: params['incomeType'],
        FundId: params['fundId'],
        ProjectId: params['projectId'],
        PaymentType: params['paymentType'],
        IsMonthDonor: params['isMonthDonor'],
        UserId: params['userId'],
        CreatorUserName: params['creatorUserName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundIncomeRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByPortal(
    params: {
      /** 关键词 */
      keyword?: string;
      /** 捐赠者名称 */
      donorName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 捐赠者类型 */
      donorType?: DonorType;
      /** 捐赠意向 */
      incomeType?: IncomeType;
      /** 基金Id */
      fundId?: number;
      /** 基金项目Id */
      projectId?: number;
      /** 支付方式 */
      paymentType?: string;
      /** 捐助方式 */
      isMonthDonor?: boolean;
      /** 用户Id */
      userId?: number;
      /** 申请人 */
      creatorUserName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        DonorName: params['donorName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        DonorType: params['donorType'],
        IncomeType: params['incomeType'],
        FundId: params['fundId'],
        ProjectId: params['projectId'],
        PaymentType: params['paymentType'],
        IsMonthDonor: params['isMonthDonor'],
        UserId: params['userId'],
        CreatorUserName: params['creatorUserName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTotalMoney(
    params: {
      /** 关键词 */
      keyword?: string;
      /** 捐赠者名称 */
      donorName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startMoney?: number;
      /**  */
      endMoney?: number;
      /** 捐赠者类型 */
      donorType?: DonorType;
      /** 捐赠意向 */
      incomeType?: IncomeType;
      /** 基金Id */
      fundId?: number;
      /** 基金项目Id */
      projectId?: number;
      /** 支付方式 */
      paymentType?: string;
      /** 捐助方式 */
      isMonthDonor?: boolean;
      /** 用户Id */
      userId?: number;
      /** 申请人 */
      creatorUserName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordTotalDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/GetTotalMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        DonorName: params['donorName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartMoney: params['startMoney'],
        EndMoney: params['endMoney'],
        DonorType: params['donorType'],
        IncomeType: params['incomeType'],
        FundId: params['fundId'],
        ProjectId: params['projectId'],
        PaymentType: params['paymentType'],
        IsMonthDonor: params['isMonthDonor'],
        UserId: params['userId'],
        CreatorUserName: params['creatorUserName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTotalByProtal(options: IRequestOptions = {}): Promise<FundIncomeRecordTotalByPortalDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/GetTotalByProtal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出捐赠明细
   */
  static exportFundIncomeRecord(
    params: {
      /** requestBody */
      body?: FundIncomeRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/ExportFundIncomeRecord';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出捐赠明细总表
   */
  static exportFundIncomeRecordTotal(
    params: {
      /** requestBody */
      body?: FundIncomeRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/ExportFundIncomeRecordTotal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户捐赠数据
   */
  static getUserDonateInfo(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDonateInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/GetUserDonateInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDonationIntentById(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundIncomeRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/GetDonationIntentById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 是否显示留言
   */
  static showLeaveMessage(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/ShowLeaveMessage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入资金捐赠
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundIncomeRecord/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundManageFeeRecordService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      fundId?: number;
      /**  */
      amount?: number;
      /**  */
      amountStart?: number;
      /**  */
      amountEnd?: number;
      /**  */
      content?: string;
      /**  */
      ration?: number;
      /**  */
      rationStart?: number;
      /**  */
      rationEnd?: number;
      /**  */
      remark?: string;
      /** 基金名称 */
      fundName?: string;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundManageFeeRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundManageFeeRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        Amount: params['amount'],
        AmountStart: params['amountStart'],
        AmountEnd: params['amountEnd'],
        Content: params['content'],
        Ration: params['ration'],
        RationStart: params['rationStart'],
        RationEnd: params['rationEnd'],
        Remark: params['remark'],
        FundName: params['fundName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundManageFeeFeeRecordDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundManageFeeRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 变更管理费
   */
  static change(
    params: {
      /** requestBody */
      body?: FundManageFeeRecordChangeRequestInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundManageFeeRecord/Change';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundModifyApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundModifyApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundModifyApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundModifyApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundModifyApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundModifyApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 基金名称 */
      name?: string;
      /** 基金类型 */
      type?: FundType;
      /**  */
      isAudited?: boolean;
      /** 细分类别 */
      fundCategory?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundModifyApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Type: params['type'],
        IsAudited: params['isAudited'],
        FundCategory: params['fundCategory'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 基金名称 */
      name?: string;
      /** 基金类型 */
      type?: FundType;
      /**  */
      isAudited?: boolean;
      /** 细分类别 */
      fundCategory?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundModifyApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundModifyApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Type: params['type'],
        IsAudited: params['isAudited'],
        FundCategory: params['fundCategory'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundNonCashDonationService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundNonCashDonationRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundNonCashDonationRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /**  */
      company?: string;
      /**  */
      address?: string;
      /**  */
      homeTelephone?: string;
      /** 手机号码 */
      telephone?: string;
      /** 期望联系方式（1手机 2邮箱 3两种皆可） */
      contactType?: number;
      /** 捐赠类型（1物品捐赠，2场地/其他捐赠） */
      donationType?: number;
      /**  */
      isAudited?: boolean;
      /** 关键词 */
      keyword?: string;
      /** 捐赠者类型 */
      donatorType?: DonatorType;
      /**  */
      donatorName?: string;
      /**  */
      createdTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundNonCashDonationRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Company: params['company'],
        Address: params['address'],
        HomeTelephone: params['homeTelephone'],
        Telephone: params['telephone'],
        ContactType: params['contactType'],
        DonationType: params['donationType'],
        IsAudited: params['isAudited'],
        Keyword: params['keyword'],
        DonatorType: params['donatorType'],
        DonatorName: params['donatorName'],
        CreatedTime: params['createdTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取捐赠项物品详细信息列表
   */
  static getAllDonationItemList(
    params: {
      /**  */
      name?: string;
      /**  */
      company?: string;
      /**  */
      address?: string;
      /**  */
      homeTelephone?: string;
      /** 手机号码 */
      telephone?: string;
      /** 期望联系方式（1手机 2邮箱 3两种皆可） */
      contactType?: number;
      /** 捐赠类型（1物品捐赠，2场地/其他捐赠） */
      donationType?: number;
      /**  */
      isAudited?: boolean;
      /** 关键词 */
      keyword?: string;
      /** 捐赠者类型 */
      donatorType?: DonatorType;
      /**  */
      donatorName?: string;
      /**  */
      createdTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundNonCashDonationItemRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/GetAllDonationItemList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Company: params['company'],
        Address: params['address'],
        HomeTelephone: params['homeTelephone'],
        Telephone: params['telephone'],
        ContactType: params['contactType'],
        DonationType: params['donationType'],
        IsAudited: params['isAudited'],
        Keyword: params['keyword'],
        DonatorType: params['donatorType'],
        DonatorName: params['donatorName'],
        CreatedTime: params['createdTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户实物捐赠次数
   */
  static getUserNonCashDonationCount(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserNonCashDonationInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/GetUserNonCashDonationCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundNonCashDonationRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundNonCashDonationRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      name?: string;
      /**  */
      company?: string;
      /**  */
      address?: string;
      /**  */
      homeTelephone?: string;
      /** 手机号码 */
      telephone?: string;
      /** 期望联系方式（1手机 2邮箱 3两种皆可） */
      contactType?: number;
      /** 捐赠类型（1物品捐赠，2场地/其他捐赠） */
      donationType?: number;
      /**  */
      isAudited?: boolean;
      /** 关键词 */
      keyword?: string;
      /** 捐赠者类型 */
      donatorType?: DonatorType;
      /**  */
      donatorName?: string;
      /**  */
      createdTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundNonCashDonationRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Company: params['company'],
        Address: params['address'],
        HomeTelephone: params['homeTelephone'],
        Telephone: params['telephone'],
        ContactType: params['contactType'],
        DonationType: params['donationType'],
        IsAudited: params['isAudited'],
        Keyword: params['keyword'],
        DonatorType: params['donatorType'],
        DonatorName: params['donatorName'],
        CreatedTime: params['createdTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundNonCashDonationRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundNonCashDonation/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByPortal(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/GetByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByPortal(
    params: {
      /** 基金名称 */
      fundName?: string;
      /** 基金项目名称 */
      fundProjectName?: string;
      /** 基金项目状态 */
      status?: FundProjectStatus;
      /** 基金项目类型 */
      projectType?: FundProjectType;
      /**  */
      categoryName?: string;
      /**  */
      isNeedAll?: boolean;
      /**  */
      isBrand?: boolean;
      /** 基金Id */
      fundId?: number;
      /** 是否过滤OrgId */
      isFilterOrgId?: boolean;
      /** 是否开启筹款 */
      isFundRaising?: boolean;
      /** 指定要不包含的项目ID */
      noContainsId?: number;
      /** 基金项目分类Id */
      classificationId?: number;
      /** 基金项目分类名称 */
      classification?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundName: params['fundName'],
        FundProjectName: params['fundProjectName'],
        Status: params['status'],
        ProjectType: params['projectType'],
        CategoryName: params['categoryName'],
        IsNeedAll: params['isNeedAll'],
        IsBrand: params['isBrand'],
        FundId: params['fundId'],
        IsFilterOrgId: params['isFilterOrgId'],
        IsFundRaising: params['isFundRaising'],
        NoContainsId: params['noContainsId'],
        ClassificationId: params['classificationId'],
        Classification: params['classification'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getList(
    params: {
      /** 基金Id */
      fundId?: number;
      /** 基金名称 */
      fundName?: string;
      /** 基金项目名称 */
      name?: string;
      /** 项目范围 */
      categoryName?: string;
      /** 基金项目类型 */
      projectType?: FundProjectType;
      /** 基金项目状态 */
      projectStatus?: FundProjectStatus;
      /** 是否品牌项目 */
      isBrand?: boolean;
      /**  */
      areaIds?: string;
      /** 是否开启筹款 */
      isFundRaising?: boolean;
      /** 指定要不包含的项目ID */
      noContainsId?: number;
      /** 基金项目分类Id */
      classificationId?: number;
      /** 基金项目类型 */
      fundProjectTypes?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        FundName: params['fundName'],
        Name: params['name'],
        CategoryName: params['categoryName'],
        ProjectType: params['projectType'],
        ProjectStatus: params['projectStatus'],
        IsBrand: params['isBrand'],
        AreaIds: params['areaIds'],
        IsFundRaising: params['isFundRaising'],
        NoContainsId: params['noContainsId'],
        ClassificationId: params['classificationId'],
        FundProjectTypes: params['fundProjectTypes'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummarization(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectSummarizationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/GetSummarization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 手动创建订单记录
   */
  static manualCreateDonateOrder(
    params: {
      /** requestBody */
      body?: FundProjectManualCreateDonateOrderInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/ManualCreateDonateOrder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目结项
   */
  static close(
    params: {
      /** requestBody */
      body?: FundProjectCloseInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/Close';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载二维码
   */
  static downloadQrCode(
    params: {
      /**  */
      id?: number;
      /**  */
      donateType?: DonateType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/DownloadQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], donateType: params['donateType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 开启筹款
   */
  static enableFundRaising(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/EnableFundRaising';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 关闭筹款
   */
  static disableFundRaising(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/DisableFundRaising';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static disableFundSpecial(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/DisableFundSpecial';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 关闭公示
   */
  static enableFundSpecial(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/EnableFundSpecial';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 基金Id */
      fundId?: number;
      /** 基金名称 */
      fundName?: string;
      /** 基金项目名称 */
      name?: string;
      /** 项目范围 */
      categoryName?: string;
      /** 基金项目类型 */
      projectType?: FundProjectType;
      /** 基金项目状态 */
      projectStatus?: FundProjectStatus;
      /** 是否品牌项目 */
      isBrand?: boolean;
      /**  */
      areaIds?: string;
      /** 是否开启筹款 */
      isFundRaising?: boolean;
      /** 指定要不包含的项目ID */
      noContainsId?: number;
      /** 基金项目分类Id */
      classificationId?: number;
      /** 基金项目类型 */
      fundProjectTypes?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        FundName: params['fundName'],
        Name: params['name'],
        CategoryName: params['categoryName'],
        ProjectType: params['projectType'],
        ProjectStatus: params['projectStatus'],
        IsBrand: params['isBrand'],
        AreaIds: params['areaIds'],
        IsFundRaising: params['isFundRaising'],
        NoContainsId: params['noContainsId'],
        ClassificationId: params['classificationId'],
        FundProjectTypes: params['fundProjectTypes'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static stickyPost(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/StickyPost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置是否官网显示
   */
  static setOfficialWebsiteDisplay(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProject/SetOfficialWebsiteDisplay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectAccountService {
  /**
   *
   */
  static getAll(
    params: {
      /** 基金项目名称 */
      fundProjectName?: string;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectAccountDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectAccount/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundProjectName: params['fundProjectName'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundProjectApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByPortal(
    params: {
      /**  */
      fundId?: number;
      /**  */
      name?: string;
      /**  */
      projectType?: FundProjectType;
      /**  */
      isAudited?: boolean;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        Name: params['name'],
        ProjectType: params['projectType'],
        IsAudited: params['isAudited'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundProjectApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      fundId?: number;
      /**  */
      name?: string;
      /**  */
      projectType?: FundProjectType;
      /**  */
      isAudited?: boolean;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        Name: params['name'],
        ProjectType: params['projectType'],
        IsAudited: params['isAudited'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      fundId?: number;
      /**  */
      name?: string;
      /**  */
      projectType?: FundProjectType;
      /**  */
      isAudited?: boolean;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        Name: params['name'],
        ProjectType: params['projectType'],
        IsAudited: params['isAudited'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectContentService {
  /**
   * 创建基金项目的文章。请先保存文章，在获取得到文章的ID后，再调用此接口
   */
  static create(
    params: {
      /** requestBody */
      body?: FundProjectCmsContentCreateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectContent/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除基金项目的文章关联，并下架该文章
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectContent/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      projectId?: number;
      /**  */
      projectName?: string;
      /**  */
      title?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectCmsContentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectContent/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        ProjectName: params['projectName'],
        Title: params['title'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectDonationRecordService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      projectId?: number;
      /**  */
      projectName?: string;
      /**  */
      fundId?: number;
      /**  */
      fundName?: string;
      /**  */
      donorType?: number;
      /**  */
      displayName?: string;
      /**  */
      donateTimeStart?: string;
      /**  */
      donateTimeEnd?: string;
      /**  */
      donateMoney?: number;
      /**  */
      donateMoneyStart?: number;
      /**  */
      donateMoneyEnd?: number;
      /**  */
      content?: string;
      /**  */
      leaveMessage?: string;
      /**  */
      recordSource?: number;
      /**  */
      donorMobilePhone?: string;
      /** 捐赠类型（1为线上捐款，2为线下捐款) */
      paymentType?: number;
      /** 捐赠类型（1为捐给项目，2为捐给基金) */
      donationType?: number;
      /** 是否月捐 */
      isMonthDonor?: boolean;
      /** 推荐人 */
      referrer?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectDonationRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectDonationRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        ProjectName: params['projectName'],
        FundId: params['fundId'],
        FundName: params['fundName'],
        DonorType: params['donorType'],
        DisplayName: params['displayName'],
        DonateTimeStart: params['donateTimeStart'],
        DonateTimeEnd: params['donateTimeEnd'],
        DonateMoney: params['donateMoney'],
        DonateMoneyStart: params['donateMoneyStart'],
        DonateMoneyEnd: params['donateMoneyEnd'],
        Content: params['content'],
        LeaveMessage: params['leaveMessage'],
        RecordSource: params['recordSource'],
        DonorMobilePhone: params['donorMobilePhone'],
        PaymentType: params['paymentType'],
        DonationType: params['donationType'],
        IsMonthDonor: params['isMonthDonor'],
        Referrer: params['referrer'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectModifyApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundProjectModifyApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectModifyApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectModifyApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      projectId?: number;
      /**  */
      fundId?: number;
      /**  */
      name?: string;
      /**  */
      isAudited?: boolean;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectModifyApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        FundId: params['fundId'],
        Name: params['name'],
        IsAudited: params['isAudited'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundProjectModifyApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectModifyApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      projectId?: number;
      /**  */
      fundId?: number;
      /**  */
      name?: string;
      /**  */
      isAudited?: boolean;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectModifyApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        FundId: params['fundId'],
        Name: params['name'],
        IsAudited: params['isAudited'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectModifyApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectProgressService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundProjectProgressCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectProgressDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundProjectProgressCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectProgressDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发布
   */
  static publish(
    params: {
      /** requestBody */
      body?: EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消发布
   */
  static cancelPublish(
    params: {
      /** requestBody */
      body?: EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/CancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByPortal(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectProgressDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/GetByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByPortal(
    params: {
      /**  */
      projectId?: number;
      /**  */
      summary?: string;
      /**  */
      title?: string;
      /**  */
      isPublish?: boolean;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectProgressDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        Summary: params['summary'],
        Title: params['title'],
        IsPublish: params['isPublish'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      projectId?: number;
      /**  */
      summary?: string;
      /**  */
      title?: string;
      /**  */
      isPublish?: boolean;
      /**  */
      areaIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectProgressDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        Summary: params['summary'],
        Title: params['title'],
        IsPublish: params['isPublish'],
        AreaIds: params['areaIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectProgressDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectProgress/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundProjectYearSummaryService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectYearSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectYearSummary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 基金会点评
   */
  static appraise(
    params: {
      /** requestBody */
      body?: FundProjectYearSummaryAppraiseInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectYearSummary/Appraise';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundProjectYearSummaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectYearSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectYearSummary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundProjectYearSummaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectYearSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectYearSummary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectYearSummary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      projectId?: number;
      /**  */
      projectName?: string;
      /**  */
      year?: number;
      /**  */
      serviceArea?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundProjectYearSummaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundProjectYearSummary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        ProjectName: params['projectName'],
        Year: params['year'],
        ServiceArea: params['serviceArea'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FundUserService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FundUserCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundUserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundUser/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundUserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundUser/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getList(
    params: {
      /**  */
      fundId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundUserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundUser/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fundId: params['fundId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 基金Id */
      fundId?: number;
      /** 基金名称 */
      fundName?: string;
      /** 姓名 */
      surname?: string;
      /** 账号 */
      userName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundUserDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundUser/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FundId: params['fundId'],
        FundName: params['fundName'],
        Surname: params['surname'],
        UserName: params['userName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFundUserList(options: IRequestOptions = {}): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundUser/GetFundUserList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FundUserCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FundUserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundUser/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FundUser/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HelpAppliesService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: HelpAppliesCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpAppliesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端提交求助需求
   */
  static submitByPortal(
    params: {
      /** requestBody */
      body?: HelpAppliesSubmitDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/SubmitByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editByPortal(
    params: {
      /** requestBody */
      body?: HelpAppliesSubmitDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/EditByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分配机构
   */
  static distributionOrg(
    params: {
      /**  */
      applyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/DistributionOrg';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { applyId: params['applyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改机构并分配
   */
  static distributionAndUpdateOrg(
    params: {
      /** requestBody */
      body?: DistributionAndUpdateOrgInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/DistributionAndUpdateOrg';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceObject(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServicesObjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/GetServiceObject';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpAppliesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端提交审核
   */
  static submitAudit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/SubmitAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: HelpAppliesCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpAppliesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 志愿者Id */
      volunteerName?: string;
      /** 联系人姓名 */
      name?: string;
      /** 联系人手机号 */
      phone?: string;
      /** 审核状态 */
      auditStatus?: AuditStatus;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 是否前端用户查询 */
      isQueryByPortalUser?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpAppliesDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerName: params['volunteerName'],
        Name: params['name'],
        Phone: params['phone'],
        AuditStatus: params['auditStatus'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        IsQueryByPortalUser: params['isQueryByPortalUser'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 志愿者Id */
      volunteerName?: string;
      /** 联系人姓名 */
      name?: string;
      /** 联系人手机号 */
      phone?: string;
      /** 审核状态 */
      auditStatus?: AuditStatus;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 是否前端用户查询 */
      isQueryByPortalUser?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpAppliesDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HelpApplies/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerName: params['volunteerName'],
        Name: params['name'],
        Phone: params['phone'],
        AuditStatus: params['auditStatus'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        IsQueryByPortalUser: params['isQueryByPortalUser'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HonorCertificateService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: HonorCertificateCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HonorCertificateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HonorCertificate/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 颁发证书
   */
  static issueCertificate(
    params: {
      /** requestBody */
      body?: IssueCertificatePostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HonorCertificate/IssueCertificate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HonorCertificateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HonorCertificate/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 证书名称 */
      title?: string;
      /** 证书类型 */
      type?: CertificateType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HonorCertificateDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HonorCertificate/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Type: params['type'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: HonorCertificateCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HonorCertificateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HonorCertificate/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/HonorCertificate/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalMonthlyPlanService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页查询
   */
  static getAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我的月活动计划列表
   */
  static getMyInternalMonthlyPlanList(
    params: {
      /**  */
      projectId?: number;
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetMyInternalMonthlyPlanList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ProjectId: params['projectId'], Date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的项目id
   */
  static getInternalProjectIdListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetInternalProjectIdListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalMonthlySummarizeService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalPersonalYearEndSummaryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalPersonalYearEndSummaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalPersonalYearEndSummaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年 */
      year?: number;
      /**  */
      userName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        UserName: params['userName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 年 */
      year?: number;
      /**  */
      userName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        UserName: params['userName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectService {
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取内部项目已立项列表
   */
  static getInternalProjectList(options: IRequestOptions = {}): Promise<InternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetInternalProjectList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的内部项目列表
   */
  static getInternalProjectListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetInternalProjectListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取内部项目立项书详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据项目Id获取内部项目立项书详情
   */
  static getDetailByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetDetailByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectMonthlySummaizeDetailsByProjectId(
    params: {
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthlySummaizeDetailsDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetProjectMonthlySummaizeDetailsByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectBudgetItemListByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectBudgetItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetProjectBudgetItemListByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新内部项目预算表
   */
  static updateBudgetItem(
    params: {
      /** requestBody */
      body?: UpdateInternalProjectBudgetItemDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/UpdateBudgetItem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 复制内部项目
   */
  static copy(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Copy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectPhaseService {
  /**
   * 获取项目节点列表
   */
  static getProjectPhasesList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectPhasesItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectPhasesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目月份列表
   */
  static getProjectMonthList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectMonthList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页获取项目节点附件列表，全部时Stage传null
   */
  static getProjectAttachmentListByPhase(
    params: {
      /** 项目ID */
      projectId?: number;
      /** 节点枚举 */
      stage?: WorkflowOfProjectByInternal;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectAttachmentListByPhase';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        Stage: params['stage'],
        Year: params['year'],
        Month: params['month'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页获取项目月度附件列表，全部时Year和Month传0
   */
  static getProjectAttachmentListByMonth(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
      /** 项目ID */
      projectId?: number;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectAttachmentListByMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount'],
        ProjectId: params['projectId'],
        Year: params['year'],
        Month: params['month']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目节点附件全部下载
   */
  static batchDownloadProjectPhasesAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      stage?: WorkflowOfProjectByInternal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/BatchDownloadProjectPhasesAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], stage: params['stage'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目月度附件全部下载
   */
  static batchDownloadProjectMonthAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/BatchDownloadProjectMonthAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], year: params['year'], month: params['month'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectReportService {
  /**
   * 内部项目宏观展示
   */
  static getMacroscopic(options: IRequestOptions = {}): Promise<LineChartDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetMacroscopic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 内部项目的微观展示
   */
  static getMicrocosmics(
    params: {
      /** 项目年份 */
      year?: number;
      /** 项目类型 */
      projectType?: number;
      /** 项目状态 */
      status?: WorkflowOfProjectByInternal;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMicrocosmicDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetMicrocosmics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        ProjectType: params['projectType'],
        Status: params['status'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未完成月总结的项目
   */
  static getNotCompleteMonthlySummaryProjects(
    params: {
      /**  */
      year?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetNotCompleteMonthlySummaryProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未正常关项的项目
   */
  static getNotClosingNormallyProjects(
    params: {
      /**  */
      year?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetNotClosingNormallyProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectSummaryReportsService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalProjectSummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalProjectSummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送总结报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 年 */
      year?: number;
      /** 项目名称 */
      displayName?: string;
      /** 填写人 */
      userName?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        DisplayName: params['displayName'],
        UserName: params['userName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年 */
      year?: number;
      /** 项目名称 */
      displayName?: string;
      /** 填写人 */
      userName?: string;
      /**  */
      projectId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        DisplayName: params['displayName'],
        UserName: params['userName'],
        ProjectId: params['projectId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class JobService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: JobCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JobDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Job/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: JobCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JobDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Job/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Job/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 信息标题 */
      title?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JobDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Job/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JobDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Job/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LecturerService {
  /**
   * 导出到Excel
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: LecturerPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Lecturer/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LecturerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Lecturer/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 姓名 */
      name?: string;
      /** 手机号码 */
      mobile?: string;
      /** 性别 */
      sex?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LecturerDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Lecturer/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Mobile: params['mobile'],
        Sex: params['sex'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: LecturerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LecturerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Lecturer/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: LecturerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LecturerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Lecturer/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Lecturer/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MerchantService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: MerchantCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Merchant/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: MerchantCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Merchant/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Merchant/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Merchant/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Merchant/EnableOrDisable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 推送至用友
   */
  static syncSupplierToNc(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Merchant/SyncSupplierToNC';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 单位名称 */
      unitName?: string;
      /** 单位地址 */
      unitAddress?: string;
      /** 负责人姓名 */
      principalName?: string;
      /** 负责人手机号 */
      principalMobilePhone?: string;
      /** 负责人邮箱 */
      principalEmailAddress?: string;
      /** 联系人姓名 */
      contactName?: string;
      /** 联系人手机号 */
      contactMobilePhone?: string;
      /** 联系人邮箱 */
      contactEmailAddress?: string;
      /** 所属基金 */
      fundName?: string;
      /** 是否已推送至用友 */
      isSynced?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Merchant/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        UnitName: params['unitName'],
        UnitAddress: params['unitAddress'],
        PrincipalName: params['principalName'],
        PrincipalMobilePhone: params['principalMobilePhone'],
        PrincipalEmailAddress: params['principalEmailAddress'],
        ContactName: params['contactName'],
        ContactMobilePhone: params['contactMobilePhone'],
        ContactEmailAddress: params['contactEmailAddress'],
        FundName: params['fundName'],
        IsSynced: params['isSynced'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MerchantBankAccountService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: MerchantBankAccountCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantBankAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MerchantBankAccount/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: MerchantBankAccountCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantBankAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MerchantBankAccount/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MerchantBankAccount/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MerchantBankAccount/EnableOrDisable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 推送至用友
   */
  static syncBankAccountToNc(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MerchantBankAccount/SyncBankAccountToNC';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantBankAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MerchantBankAccount/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 客商主表Id */
      merchantId?: number;
      /** 银行账号 */
      bankAccountCode?: string;
      /** 银行名称 */
      bankName?: string;
      /** 户名 */
      bankAccountName?: string;
      /**  */
      isOnlyMine?: boolean;
      /**  */
      isSynced?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MerchantBankAccountDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MerchantBankAccount/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        MerchantId: params['merchantId'],
        BankAccountCode: params['bankAccountCode'],
        BankName: params['bankName'],
        BankAccountName: params['bankAccountName'],
        IsOnlyMine: params['isOnlyMine'],
        IsSynced: params['isSynced'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MessageService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: MessageCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Message/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: MessageCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Message/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Message/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Message/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 消息标题 */
      title?: string;
      /** 起始日期 */
      startDate?: string;
      /** 截止日期 */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Message/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MusicMachineDonateDetailService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: MusicMachineDonateDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MusicMachineDonateDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: MusicMachineDonateDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MusicMachineDonateDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入捐赠明细
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取音乐智能机版本列表
   */
  static getVersionList(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/GetVersionList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MusicMachineDonateDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      year?: number;
      /**  */
      month?: number;
      /**  */
      version?: string;
      /**  */
      provinceName?: string;
      /**  */
      cityName?: string;
      /**  */
      areaName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MusicMachineDonateDetailDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MusicMachineDonateDetail/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        Month: params['month'],
        Version: params['version'],
        ProvinceName: params['provinceName'],
        CityName: params['cityName'],
        AreaName: params['areaName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotifyRecordService {
  /**
   * 获取通知发送记录详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: NotifyRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: NotifyRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 通知标题 */
      title?: string;
      /** 通知类型 */
      notifyType?: NotifyTemplateType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        NotifyType: params['notifyType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotifyTemplateService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 通知模板标题 */
      title?: string;
      /** 通知模板类型 */
      notifyTemplateType?: NotifyTemplateType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        NotifyTemplateType: params['notifyTemplateType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: NotifyTemplateCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: NotifyTemplateCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrderService {
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Order/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Order/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 订单类型 */
      type?: OrderType;
      /** 关联表Id */
      applyId?: number;
      /**  */
      userName?: string;
      /**  */
      title?: string;
      /** 订单状态 */
      status?: OrderStatus;
      /**  */
      onlyMine?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Order/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Type: params['type'],
        ApplyId: params['applyId'],
        UserName: params['userName'],
        Title: params['title'],
        Status: params['status'],
        OnlyMine: params['onlyMine'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: OrderCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Order/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: OrderCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Order/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Order/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrganizationUnitService {
  /**
   * 导出合作方
   */
  static export(
    params: {
      /** requestBody */
      body?: OrganizationUnitPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 申请入驻(首次申请)
   */
  static apply(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Code获取邀约详情
   */
  static getInviteByCode(
    params: {
      /**  */
      inviteCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetInviteByCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { inviteCode: params['inviteCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重新申请
   */
  static reapply(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Reapply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createLoginAccount(
    params: {
      /** requestBody */
      body?: AgileOrganizationUnit;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitLoginAccountResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/CreateLoginAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出列表到Excel
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: OrganizationUnitPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前登录用户的申请信息
   */
  static getCurrentUserByPortal(options: IRequestOptions = {}): Promise<OrganizationUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetCurrentUserByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取审核通过的机构（合作方）
   */
  static getAllApproved(options: IRequestOptions = {}): Promise<OrganizationUnitDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetAllApproved';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      displayName?: string;
      /** 机构的类型(数据字典的ID） */
      organizationType?: number;
      /**  */
      isApply?: boolean;
      /**  */
      status?: OrganizationStatus;
      /**  */
      principalName?: string;
      /**  */
      mobilePhone?: string;
      /**  */
      emailAddress?: string;
      /** 联系人姓名 */
      contactName?: string;
      /** 联系人手机号 */
      contactMobilePhone?: string;
      /** 联系人邮箱 */
      contactEmailAddress?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrganizationType: params['organizationType'],
        IsApply: params['isApply'],
        Status: params['status'],
        PrincipalName: params['principalName'],
        MobilePhone: params['mobilePhone'],
        EmailAddress: params['emailAddress'],
        ContactName: params['contactName'],
        ContactMobilePhone: params['contactMobilePhone'],
        ContactEmailAddress: params['contactEmailAddress'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /** 机构的类型(数据字典的ID） */
      organizationType?: number;
      /**  */
      isApply?: boolean;
      /**  */
      status?: OrganizationStatus;
      /**  */
      principalName?: string;
      /**  */
      mobilePhone?: string;
      /**  */
      emailAddress?: string;
      /** 联系人姓名 */
      contactName?: string;
      /** 联系人手机号 */
      contactMobilePhone?: string;
      /** 联系人邮箱 */
      contactEmailAddress?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrganizationType: params['organizationType'],
        IsApply: params['isApply'],
        Status: params['status'],
        PrincipalName: params['principalName'],
        MobilePhone: params['mobilePhone'],
        EmailAddress: params['emailAddress'],
        ContactName: params['contactName'],
        ContactMobilePhone: params['contactMobilePhone'],
        ContactEmailAddress: params['contactEmailAddress'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PartnerCapitalApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PartnerCapitalApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PartnerCapitalApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 是否内部项目 */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 是否内部项目 */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PartnerInviteService {
  /**
   *
   */
  static testEasyNetQ(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/TestEasyNetQ';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PartnerInviteCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 邀约机构名称 */
      organizationName?: string;
      /** 机构类型 */
      organizationType?: number;
      /**  */
      emailAddress?: string;
      /**  */
      mobile?: string;
      /**  */
      content?: string;
      /**  */
      hasUsed?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationName: params['organizationName'],
        OrganizationType: params['organizationType'],
        EmailAddress: params['emailAddress'],
        Mobile: params['mobile'],
        Content: params['content'],
        HasUsed: params['hasUsed'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PartnerInviteCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PayService {
  /**
   * JS-SDK支付回调地址（在统一下单接口中设置notify_url）
   */
  static payNotifyUrl(
    params: {
      /** requestBody */
      body?: TenPayNotifyXml;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Pay/PayNotifyUrl';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PcHomeService {
  /**
   *
   */
  static getSummary(options: IRequestOptions = {}): Promise<SummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PCHome/GetSummary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PermissionDefinitionService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PermissionDefinitionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PermissionDefinitionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCreatedNames(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetAllCreatedNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有可用的权限
   */
  static getAllCanUsePermissions(options: IRequestOptions = {}): Promise<PermissionDefinitionDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetAllCanUsePermissions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPermissionNameSchemes(
    params: {
      /**  */
      permissionCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionNameSchemeDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetPermissionNameSchemes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { permissionCode: params['permissionCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllPermissionNames(options: IRequestOptions = {}): Promise<StringStringKeyValuePair[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetAllPermissionNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductConsultationsService {
  /**
   * 后台查看详情
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductConsultationsDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductConsultations/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 产品咨询
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductConsultationsCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductConsultationsDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductConsultations/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 姓名 */
      userName?: string;
      /** 电话 */
      mobilePhone?: string;
      /** 机构名称 */
      orgName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductConsultationsDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductConsultations/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        UserName: params['userName'],
        MobilePhone: params['mobilePhone'],
        OrgName: params['orgName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductConsultationsCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductConsultations/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductConsultationsCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductConsultationsDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductConsultations/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductConsultations/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      year?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      projectTypes?: string;
      /**  */
      sorting?: string;
      /**  */
      noContainsId?: number;
      /** �Ƿ����IsPublicShow��ѯ�������ж� */
      isIgnorePublicShow?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        ProjectSeriesId: params['projectSeriesId'],
        ProjectTypes: params['projectTypes'],
        Sorting: params['sorting'],
        NoContainsId: params['noContainsId'],
        IsIgnorePublicShow: params['isIgnorePublicShow'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目基本信息详情
   */
  static getProjectBasicDetail(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBasicDetailUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/GetProjectBasicDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新项目基本信息
   */
  static updateProjectBasicDetail(
    params: {
      /** requestBody */
      body?: ProjectBasicDetailUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/UpdateProjectBasicDetail';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置是否公开显示
   */
  static changePublicShow(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/ChangePublicShow';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 终止项目
   */
  static terminate(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Terminate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目合作机构Id
   */
  static getProjectMappings(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOrganizationUnitMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/GetProjectMappings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新项目合作机构
   */
  static updateMapping(
    params: {
      /** requestBody */
      body?: ProjectOrganizationUnitMappingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/UpdateMapping';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectBeneficiaryMappingService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      beneficiaryName?: string;
      /**  */
      projectId?: number;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BeneficiaryName: params['beneficiaryName'],
        ProjectId: params['projectId'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      beneficiaryName?: string;
      /**  */
      projectId?: number;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BeneficiaryName: params['beneficiaryName'],
        ProjectId: params['projectId'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectBeneficiaryMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectBeneficiaryMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      projectId?: number;
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectInviteService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectInviteCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectInviteCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 年份 */
      year?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        Year: params['year'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectSeriesService {
  /**
   * 根据Sequence升序获取列表
   */
  static getProjectSeriesList(options: IRequestOptions = {}): Promise<ProjectSeries[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/GetProjectSeriesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 系列名称 */
      title?: string;
      /** 是否公开显示 */
      isPublicShow?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        IsPublicShow: params['isPublicShow'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectSeriesCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectSeriesCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProvinceService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProvinceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProvinceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(options: IRequestOptions = {}): Promise<ProvinceDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      provinceName?: string;
      /**  */
      shortName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProvinceName: params['provinceName'],
        ShortName: params['shortName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoleService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: RoleCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoles(
    params: {
      /**  */
      permission?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleListDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetRoles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Permission: params['permission'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: RoleCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分配权限
   */
  static grantPermission(
    params: {
      /** requestBody */
      body?: RoleGrantPermissionInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GrantPermission';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有可分配的权限信息
   */
  static getAllPermissions(options: IRequestOptions = {}): Promise<PermissionDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetAllPermissions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取分配给角色的权限数据权限分配
   */
  static getGrantedDataPermissionSettings(
    params: {
      /**  */
      roleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataPermissionSettingDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetGrantedDataPermissionSettings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { roleId: params['roleId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取已分配的权限信息
   */
  static getGrantedPermissionNames(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetGrantedPermissionNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetRoleForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetRoleForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateUserRole(
    params: {
      /** requestBody */
      body?: UserRoleDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/UpdateUserRole';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SchoolService {
  /**
   * 创建学校
   */
  static create(
    params: {
      /** requestBody */
      body?: SchoolCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除学校
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllContacts(
    params: {
      /**  */
      schoolId?: number;
      /** 联系人姓名 */
      contactName?: string;
      /** 联系人手机号 */
      contactPhone?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolContactDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/GetAllContacts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SchoolId: params['schoolId'],
        ContactName: params['contactName'],
        ContactPhone: params['contactPhone'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContact(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolContactDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/GetContact';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加联系人
   */
  static addContact(
    params: {
      /** requestBody */
      body?: SchoolContactCreateOrUpdateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolContactDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/AddContact';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新联系人
   */
  static updateContact(
    params: {
      /** requestBody */
      body?: SchoolContactCreateOrUpdateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/UpdateContact';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 禁用联系人
   */
  static disableContact(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/DisableContact';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 激活联系人
   */
  static activeContact(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/ActiveContact';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除联系人
   */
  static deleteContract(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/DeleteContract';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前用户可访问到的所有学校
   */
  static getOnlyCanAccessSchools(options: IRequestOptions = {}): Promise<SchoolMiniDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/GetOnlyCanAccessSchools';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载 学校信息 导入模板
   */
  static downloadSchoolTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/DownloadSchoolTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 学校信息 导入
   */
  static importSchool(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/ImportSchool';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 学校信息 导出
   */
  static exportSchool(
    params: {
      /** requestBody */
      body?: SchoolPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/ExportSchool';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载 学校联系人信息 导入模板
   */
  static downloadSchoolContactTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/DownloadSchoolContactTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 学校联系人信息 导入
   */
  static importSchoolContact(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/ImportSchoolContact';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 学校联系人信息 导出
   */
  static exportSchoolContact(
    params: {
      /** requestBody */
      body?: SchoolContactPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/ExportSchoolContact';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      schoolName?: string;
      /**  */
      schoolNo?: string;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SchoolName: params['schoolName'],
        SchoolNo: params['schoolNo'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SchoolCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SchoolDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/School/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ScopeDataSourceService {
  /**
   * 获取数据源内容
   */
  static getItems(
    params: {
      /**  */
      providerName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ScopeDataSource/GetItems';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { providerName: params['providerName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有的数据源提供者
   */
  static getProviders(
    params: {
      /**  */
      permissionCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ScopeDataSource/GetProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { permissionCode: params['permissionCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有的支持数据权限控制的过滤器
   */
  static getAllSupportProviders(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ScopeDataSource/GetAllSupportProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 服务Id */
      serviceId?: number;
      /** 报名用户Id */
      userId?: number;
      /** 志愿者Id */
      volunteerId?: number;
      /** 姓名 */
      surName?: string;
      /** 手机号码 */
      phone?: string;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 服务标题 */
      title?: string;
      /** 服务开始时间 */
      serviceStartTime?: string;
      /** 服务结束时间 */
      serviceEndTime?: string;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceId: params['serviceId'],
        UserId: params['userId'],
        VolunteerId: params['volunteerId'],
        SurName: params['surName'],
        Phone: params['phone'],
        Status: params['status'],
        Title: params['title'],
        ServiceStartTime: params['serviceStartTime'],
        ServiceEndTime: params['serviceEndTime'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 报名
   */
  static signUp(
    params: {
      /** requestBody */
      body?: ServiceApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/SignUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消报名
   */
  static cancelSignUp(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/CancelSignUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 培训签到，有管理权限的可以为别人签到
   */
  static checkIn(
    params: {
      /** requestBody */
      body?: ServiceApplyCheckInPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/CheckIn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取签到明细
   */
  static getServiceApplyCheckInRecord(
    params: {
      /** 服务Id */
      serviceId?: number;
      /** 报名用户Id */
      userId?: number;
      /** 志愿者Id */
      volunteerId?: number;
      /** 姓名 */
      surName?: string;
      /** 手机号码 */
      phone?: string;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 服务标题 */
      title?: string;
      /** 服务开始时间 */
      serviceStartTime?: string;
      /** 服务结束时间 */
      serviceEndTime?: string;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyCheckInRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/GetServiceApplyCheckInRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceId: params['serviceId'],
        UserId: params['userId'],
        VolunteerId: params['volunteerId'],
        SurName: params['surName'],
        Phone: params['phone'],
        Status: params['status'],
        Title: params['title'],
        ServiceStartTime: params['serviceStartTime'],
        ServiceEndTime: params['serviceEndTime'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出报名明细到Excel
   */
  static exportApplyListToExcel(
    params: {
      /** requestBody */
      body?: ServiceApplyPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/ExportApplyListToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出签到明细到Excel
   */
  static exportCheckInListToExcel(
    params: {
      /** requestBody */
      body?: ServiceApplyPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/ExportCheckInListToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 个人中心-我的服务列表
   */
  static getAllByPortal(
    params: {
      /** 服务Id */
      serviceId?: number;
      /** 报名用户Id */
      userId?: number;
      /** 志愿者Id */
      volunteerId?: number;
      /** 姓名 */
      surName?: string;
      /** 手机号码 */
      phone?: string;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 服务标题 */
      title?: string;
      /** 服务开始时间 */
      serviceStartTime?: string;
      /** 服务结束时间 */
      serviceEndTime?: string;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceId: params['serviceId'],
        UserId: params['userId'],
        VolunteerId: params['volunteerId'],
        SurName: params['surName'],
        Phone: params['phone'],
        Status: params['status'],
        Title: params['title'],
        ServiceStartTime: params['serviceStartTime'],
        ServiceEndTime: params['serviceEndTime'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getApplyStatus(
    params: {
      /**  */
      applyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyStatusDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/GetApplyStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { applyId: params['applyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 服务Id */
      serviceId?: number;
      /** 报名用户Id */
      userId?: number;
      /** 志愿者Id */
      volunteerId?: number;
      /** 姓名 */
      surName?: string;
      /** 手机号码 */
      phone?: string;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 服务标题 */
      title?: string;
      /** 服务开始时间 */
      serviceStartTime?: string;
      /** 服务结束时间 */
      serviceEndTime?: string;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceId: params['serviceId'],
        UserId: params['userId'],
        VolunteerId: params['volunteerId'],
        SurName: params['surName'],
        Phone: params['phone'],
        Status: params['status'],
        Title: params['title'],
        ServiceStartTime: params['serviceStartTime'],
        ServiceEndTime: params['serviceEndTime'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceMaintainService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发布服务
   */
  static publish(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消发布服务
   */
  static cancelPublish(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/CancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取二维码
   */
  static downQrCode(
    params: {
      /**  */
      id?: number;
      /**  */
      qrType?: ServiceQrType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/DownQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], qrType: params['qrType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端查询服务列表（志愿地图模块通用）
   */
  static getListByPortal(
    params: {
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 服务标题 */
      title?: string;
      /** 服务对象类型 */
      serviceObjectTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /** 服务类型 */
      typeId?: number;
      /** 状态 */
      status?: ServiceStatus;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 已被报名 */
      hasBeenApplied?: boolean;
      /**  */
      host?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/GetListByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        Title: params['title'],
        ServiceObjectTypeId: params['serviceObjectTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        TypeID: params['typeId'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        HasBeenApplied: params['hasBeenApplied'],
        Host: params['host'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取服务所在的省份列表
   */
  static getProvinceList(options: IRequestOptions = {}): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/GetProvinceList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取服务所在的城市列表
   */
  static getCityList(
    params: {
      /** 所在省份 */
      provinceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/GetCityList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provinceId: params['provinceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取服务所在的区县列表
   */
  static getAreaList(
    params: {
      /** 所在区县 */
      cityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/GetAreaList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityId: params['cityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出服务到Excel
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: ServicePagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /** 服务标题 */
      title?: string;
      /** 服务对象类型 */
      serviceObjectTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /** 服务类型 */
      typeId?: number;
      /** 状态 */
      status?: ServiceStatus;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 已被报名 */
      hasBeenApplied?: boolean;
      /**  */
      host?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceMaintain/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        Title: params['title'],
        ServiceObjectTypeId: params['serviceObjectTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        TypeID: params['typeId'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        HasBeenApplied: params['hasBeenApplied'],
        Host: params['host'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceManagementService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceManagement/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceManagement/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceManagementCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceManagement/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceManagement/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 服务标题 */
      title?: string;
      /** 领队姓名 */
      leaderUSerName?: string;
      /** 主办方 */
      host?: string;
      /** 服务机构Id */
      serviceOrganizationId?: number;
      /** 培训开始时间 */
      serviceStartTime?: string;
      /** 培训结束时间 */
      serviceEndTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceManagementDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceManagement/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        LeaderUSerName: params['leaderUSerName'],
        Host: params['host'],
        ServiceOrganizationId: params['serviceOrganizationId'],
        ServiceStartTime: params['serviceStartTime'],
        ServiceEndTime: params['serviceEndTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceManagement/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceObjectService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceObjectCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServicesObjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 服务机构 */
      serviceOrganization?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServicesObjectDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Phone: params['phone'],
        ServiceOrganization: params['serviceOrganization'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入服务对象
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出服务对象
   */
  static exportExcel(
    params: {
      /** requestBody */
      body?: ServiceObjectPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/ExportExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServicesObjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceObjectUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServicesObjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObject/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceObjectFeedbackService {
  /**
   * 获取详情
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceObjectFeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 服务对象Id */
      serviceObjectId?: number;
      /** 服务Id */
      serviceId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceObjectFeedbackDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceObjectId: params['serviceObjectId'],
        ServiceId: params['serviceId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 新增
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceObjectFeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceObjectFeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取服务满意度二维码
   */
  static downQrCode(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/DownQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据serviceId获取服务对象
   */
  static getServiceObjectsByServiceId(
    params: {
      /**  */
      serviceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceObjectFeedbackDropList[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/GetServiceObjectsByServiceId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { serviceId: params['serviceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据服务Id获取志愿者
   */
  static getVolunteersByServiceId(
    params: {
      /**  */
      serviceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceObjectFeedbackDropList[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/GetVolunteersByServiceId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { serviceId: params['serviceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceObjectFeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceObjectFeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceObjectFeedback/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceOrganizationService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceOrganizationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceOrganizationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceOrganization/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceOrganizationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceOrganizationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceOrganization/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceOrganization/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有机构列表
   */
  static getAllServiceOrganizationList(options: IRequestOptions = {}): Promise<ServiceOrganizationDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceOrganization/GetAllServiceOrganizationList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceOrganizationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceOrganization/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 服务机构名称 */
      name?: string;
      /** 服务机构Id */
      serviceOrganizationTypeId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceOrganizationDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceOrganization/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        ServiceOrganizationTypeId: params['serviceOrganizationTypeId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceRecordService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 审核通过时自动生成的唯一编号 */
      volunteerOrgCode?: string;
      /** 姓名 */
      surname?: string;
      /** 服务标题 */
      title?: string;
      /** 服务类型Id */
      typeId?: number;
      /** 服务机构Id */
      serviceOrganizationId?: number;
      /** 服务对象Id */
      serviceObjectIds?: string;
      /** 服务开始时间 */
      serviceStartTime?: string;
      /** 志愿者 */
      volunteerId?: number;
      /** 服务结束时间 */
      serviceEndTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerOrgCode: params['volunteerOrgCode'],
        Surname: params['surname'],
        Title: params['title'],
        TypeId: params['typeId'],
        ServiceOrganizationId: params['serviceOrganizationId'],
        ServiceObjectIds: params['serviceObjectIds'],
        ServiceStartTime: params['serviceStartTime'],
        VolunteerId: params['volunteerId'],
        ServiceEndTime: params['serviceEndTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出服务记录到Excel
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: ServiceRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 通过ActivityApplyId获取数据
   */
  static getEditByApplyId(
    params: {
      /**  */
      applyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/GetEditByApplyId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { applyId: params['applyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端提交新增，需要开启审核流程
   */
  static createByPortal(
    params: {
      /** requestBody */
      body?: ServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/CreateByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
 * /// <summary>
前端提交更新，需要重新开启审核流程
</summary><param name="input"></param><returns></returns>
 */
  static updateByPortal(
    params: {
      /** requestBody */
      body?: ServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/UpdateByPortal';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 审核通过时自动生成的唯一编号 */
      volunteerOrgCode?: string;
      /** 姓名 */
      surname?: string;
      /** 服务标题 */
      title?: string;
      /** 服务类型Id */
      typeId?: number;
      /** 服务机构Id */
      serviceOrganizationId?: number;
      /** 服务对象Id */
      serviceObjectIds?: string;
      /** 服务开始时间 */
      serviceStartTime?: string;
      /** 志愿者 */
      volunteerId?: number;
      /** 服务结束时间 */
      serviceEndTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerOrgCode: params['volunteerOrgCode'],
        Surname: params['surname'],
        Title: params['title'],
        TypeId: params['typeId'],
        ServiceOrganizationId: params['serviceOrganizationId'],
        ServiceObjectIds: params['serviceObjectIds'],
        ServiceStartTime: params['serviceStartTime'],
        VolunteerId: params['volunteerId'],
        ServiceEndTime: params['serviceEndTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ServiceRecord/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SessionService {
  /**
   * 获取基础信息
   */
  static getBasicInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Session/GetBasicInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserLoginInfo(options: IRequestOptions = {}): Promise<UserLoginInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Session/GetUserLoginInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前登录用户信息
   */
  static getCurrentLoginInformations(options: IRequestOptions = {}): Promise<GetCurrentLoginInformationsOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Session/GetCurrentLoginInformations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingManagerService {
  /**
   * 获取银行信息
   */
  static getBankInfo(options: IRequestOptions = {}): Promise<BankInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SettingManager/GetBankInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getValue(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SettingManager/GetValue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SignupInfoService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SignupInfoCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SignupInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SignupInfo/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCache(
    params: {
      /** requestBody */
      body?: SignupInfoCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SignupInfo/CreateCache';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSimple(
    params: {
      /** requestBody */
      body?: SignupInfoCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SignupInfo/CreateSimple';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 报名列表
   */
  static getAll(
    params: {
      /** 参赛者类型 */
      userType?: string;
      /** 姓名/公司名称 */
      userName?: string;
      /** 参赛昵称 */
      nickName?: string;
      /** 参赛者国籍 */
      nation?: string;
      /** 参赛者联系电话 */
      phone?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SignupInfoDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SignupInfo/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        UserType: params['userType'],
        UserName: params['userName'],
        NickName: params['nickName'],
        Nation: params['nation'],
        Phone: params['phone'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 报名详情
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SignupInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SignupInfo/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出报名记录
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: SignupInfoPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SignupInfo/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SiteMessageService {
  /**
   * 获取未读消息的数量
   */
  static getUnreadCount(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/GetUnreadCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 关键字 */
      keyword?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SiteMessageDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置成已读
   */
  static read(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/Read';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置成已读
   */
  static readById(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/ReadById';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SlashScreenService {
  /**
   * 获取前端可用的闪屏信息
   */
  static getAllForFront(options: IRequestOptions = {}): Promise<SlashScreenDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SlashScreen/GetAllForFront';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SlashScreenDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SlashScreen/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      title?: string;
      /**  */
      isActive?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SlashScreenDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SlashScreen/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        IsActive: params['isActive'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SlashScreenCreateOrUpateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SlashScreenDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SlashScreen/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SlashScreenCreateOrUpateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SlashScreenDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SlashScreen/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SlashScreen/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StarRatingService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StarRatingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      title?: string;
      /** 评定类型Id */
      typeId?: number;
      /** 状态 */
      status?: StarRatingStatus;
      /** 是否开启公示 */
      isPublicity?: boolean;
      /** 创建时间 */
      creationTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        TypeId: params['typeId'],
        Status: params['status'],
        IsPublicity: params['isPublicity'],
        CreationTime: params['creationTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StarRatingPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StarRatingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 开启公示
   */
  static publicity(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/Publicity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 结束公示
   */
  static unPublicity(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/UnPublicity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发布
   */
  static publish(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消发布
   */
  static unPublish(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/UnPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRating/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StarRatingApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StarRatingApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 报名
   */
  static signUp(
    params: {
      /** requestBody */
      body?: StarRatingApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/SignUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 星级评定Id */
      starRatingId?: number;
      /** 姓名 */
      surname?: string;
      /** 手机号 */
      phoneNumber?: string;
      /** 报名状态 */
      status?: StarRatingApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StarRatingId: params['starRatingId'],
        Surname: params['surname'],
        PhoneNumber: params['phoneNumber'],
        Status: params['status'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StarRatingApplyPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StarRatingApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 个人中心-星级评定列表
   */
  static getAllByPortal(
    params: {
      /** 星级评定Id */
      starRatingId?: number;
      /** 姓名 */
      surname?: string;
      /** 手机号 */
      phoneNumber?: string;
      /** 报名状态 */
      status?: StarRatingApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingApply/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StarRatingId: params['starRatingId'],
        Surname: params['surname'],
        PhoneNumber: params['phoneNumber'],
        Status: params['status'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StarRatingManagementService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StarRatingManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingManagement/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingManagement/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 星级评定Id */
      starRatingId?: number;
      /** 星级评定标题 */
      starRatingTitle?: string;
      /** 评定类型Id */
      typeId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingManagementDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingManagement/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StarRatingId: params['starRatingId'],
        StarRatingTitle: params['starRatingTitle'],
        TypeId: params['typeId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StarRatingManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingManagement/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingManagement/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StarRatingRecordService {
  /**
   * 考试完成后调用
   */
  static create(
    params: {
      /** requestBody */
      body?: StarRatingRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByApplyId(
    params: {
      /**  */
      applyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GetByApplyId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { applyId: params['applyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 星级评定Id */
      starRatingId?: number;
      /** 志愿者编号 */
      volunteerOrgCode?: string;
      /** 姓名 */
      surname?: string;
      /** 星级评定标题 */
      starRatingTitle?: string;
      /** 发布时间 */
      creationTime?: string;
      /** 评定类型Id */
      typeId?: number;
      /** 是否只包含 已开启的公示 */
      isIncludePublicity?: boolean;
      /** 状态 */
      status?: StarRatingRecordStatus;
      /** 状态 */
      searchStatus?: StarRatingRecordSearchStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StarRatingId: params['starRatingId'],
        VolunteerOrgCode: params['volunteerOrgCode'],
        Surname: params['surname'],
        StarRatingTitle: params['starRatingTitle'],
        CreationTime: params['creationTime'],
        TypeId: params['typeId'],
        IsIncludePublicity: params['isIncludePublicity'],
        Status: params['status'],
        SearchStatus: params['searchStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StarRatingRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取评估公示
   */
  static getPublicityAll(
    params: {
      /** 星级评定Id */
      starRatingId?: number;
      /** 志愿者编号 */
      volunteerOrgCode?: string;
      /** 姓名 */
      surname?: string;
      /** 星级评定标题 */
      starRatingTitle?: string;
      /** 发布时间 */
      creationTime?: string;
      /** 评定类型Id */
      typeId?: number;
      /** 是否只包含 已开启的公示 */
      isIncludePublicity?: boolean;
      /** 状态 */
      status?: StarRatingRecordStatus;
      /** 状态 */
      searchStatus?: StarRatingRecordSearchStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GetPublicityAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StarRatingId: params['starRatingId'],
        VolunteerOrgCode: params['volunteerOrgCode'],
        Surname: params['surname'],
        StarRatingTitle: params['starRatingTitle'],
        CreationTime: params['creationTime'],
        TypeId: params['typeId'],
        IsIncludePublicity: params['isIncludePublicity'],
        Status: params['status'],
        SearchStatus: params['searchStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取星级名单
   */
  static getStarAll(
    params: {
      /** 星级评定Id */
      starRatingId?: number;
      /** 志愿者编号 */
      volunteerOrgCode?: string;
      /** 姓名 */
      surname?: string;
      /** 星级评定标题 */
      starRatingTitle?: string;
      /** 发布时间 */
      creationTime?: string;
      /** 评定类型Id */
      typeId?: number;
      /** 是否只包含 已开启的公示 */
      isIncludePublicity?: boolean;
      /** 状态 */
      status?: StarRatingRecordStatus;
      /** 状态 */
      searchStatus?: StarRatingRecordSearchStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GetStarAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StarRatingId: params['starRatingId'],
        VolunteerOrgCode: params['volunteerOrgCode'],
        Surname: params['surname'],
        StarRatingTitle: params['starRatingTitle'],
        CreationTime: params['creationTime'],
        TypeId: params['typeId'],
        IsIncludePublicity: params['isIncludePublicity'],
        Status: params['status'],
        SearchStatus: params['searchStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StarRatingRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 成长报告提交
   */
  static growthReportSubmit(
    params: {
      /** requestBody */
      body?: StarRatingRecordSubmitDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GrowthReportSubmit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 成长报告和面试审核
   */
  static growthReportAndInterviewReview(
    params: {
      /** requestBody */
      body?: StarRatingRecordAuditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GrowthReportAndInterviewReview';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置
   */
  static reset(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/Reset';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 星级评定Id */
      starRatingId?: number;
      /** 志愿者编号 */
      volunteerOrgCode?: string;
      /** 姓名 */
      surname?: string;
      /** 星级评定标题 */
      starRatingTitle?: string;
      /** 发布时间 */
      creationTime?: string;
      /** 评定类型Id */
      typeId?: number;
      /** 是否只包含 已开启的公示 */
      isIncludePublicity?: boolean;
      /** 状态 */
      status?: StarRatingRecordStatus;
      /** 状态 */
      searchStatus?: StarRatingRecordSearchStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StarRatingRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StarRatingId: params['starRatingId'],
        VolunteerOrgCode: params['volunteerOrgCode'],
        Surname: params['surname'],
        StarRatingTitle: params['starRatingTitle'],
        CreationTime: params['creationTime'],
        TypeId: params['typeId'],
        IsIncludePublicity: params['isIncludePublicity'],
        Status: params['status'],
        SearchStatus: params['searchStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StarRatingRecord/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudentService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StudentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StudentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量更新年级
   */
  static batchUpdateGrade(
    params: {
      /** requestBody */
      body?: BatchUpdateGradeInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/BatchUpdateGrade';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量使学生年级升级
   */
  static batchGradeUp(
    params: {
      /** requestBody */
      body?: BatchGradeUpInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/BatchGradeUp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量修改学生的状态
   */
  static batchUpdateStatus(
    params: {
      /** requestBody */
      body?: BatchUpdateStudentStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/BatchUpdateStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDelete(
    params: {
      /** requestBody */
      body?: BatchGradeUpInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/BatchDelete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入学生信息
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StudentPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      schoolName?: string;
      /**  */
      schoolId?: number;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      provinceId?: number;
      /**  */
      cityId?: number;
      /**  */
      areaId?: number;
      /**  */
      counterpart?: string;
      /**  */
      studentGrade?: StudentGrade;
      /**  */
      studentStatus?: StudentStatus;
      /**  */
      sex?: Sex;
      /**  */
      bidStatus?: StudentAidBidStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Student/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SchoolName: params['schoolName'],
        SchoolId: params['schoolId'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        Counterpart: params['counterpart'],
        StudentGrade: params['studentGrade'],
        StudentStatus: params['studentStatus'],
        Sex: params['sex'],
        BidStatus: params['bidStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudentAidBidService {
  /**
   * 获取已创建的标的列表
   */
  static getAll(
    params: {
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      schoolName?: string;
      /**  */
      schoolId?: number;
      /** 标的开始日期(完全匹配) */
      bidStartDate?: string;
      /** 学生所在省 */
      provinceId?: number;
      /** 学生所在市 */
      cityId?: number;
      /** 学生所在区/县 */
      areaId?: number;
      /** 标的状态 */
      bidStatus?: StudentAidBidStatus;
      /** 要获取的多个标的状态 */
      bidStatusFilters?: StudentAidBidStatus[];
      /** 是否包含当前标的的已资助金额 */
      isIncludeFundedAmount?: boolean;
      /** 是否包含进度 */
      isIncludeProgress?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BidDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        SchoolName: params['schoolName'],
        SchoolId: params['schoolId'],
        BidStartDate: params['bidStartDate'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        BidStatus: params['bidStatus'],
        BidStatusFilters: params['bidStatusFilters'],
        IsIncludeFundedAmount: params['isIncludeFundedAmount'],
        IsIncludeProgress: params['isIncludeProgress'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取标的信息
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BidDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有可发布标的的学生
   */
  static getCanCreateNewBidStudents(
    params: {
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      schoolId?: number;
      /** 学生所在省 */
      provinceId?: number;
      /** 学生所在市 */
      cityId?: number;
      /** 学生所在区/县 */
      areaId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/GetCanCreateNewBidStudents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        SchoolId: params['schoolId'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 创建标的
   */
  static create(
    params: {
      /** requestBody */
      body?: BidCreateOrUpdateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量创建标的
   */
  static batchCreate(
    params: {
      /** requestBody */
      body?: BatchCreateBidInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/BatchCreate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新标的
   */
  static update(
    params: {
      /** requestBody */
      body?: BidCreateOrUpdateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量发放
   */
  static batchGrant(
    params: {
      /** requestBody */
      body?: BatchGrantInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/BatchGrant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量发布
   */
  static batchPublish(
    params: {
      /** requestBody */
      body?: Int64BatchOperationInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/BatchPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量取消发布
   */
  static batchCancelPublish(
    params: {
      /** requestBody */
      body?: Int64BatchOperationInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/BatchCancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发布
   */
  static publish(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消发布
   */
  static cancelPublish(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/CancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除标的
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 为标的添加捐助方
   */
  static addDonor(
    params: {
      /** requestBody */
      body?: AddDonorToBidInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/AddDonor';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 移除捐助方
   */
  static deleteDonor(
    params: {
      /** 捐助方id */
      donorId?: number;
      /**  */
      bidId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/DeleteDonor';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { donorId: params['donorId'], bidId: params['bidId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户认捐(公开招募/指定捐助方),并创建待支付订单
   */
  static userSubscribe(
    params: {
      /** requestBody */
      body?: UserSubscribeInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentBidOrderShortDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/UserSubscribe';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: BidPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidBid/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudentAidReportService {
  /**
   * 获取捐助记录
   */
  static getAllDonateRecords(
    params: {
      /** 捐助方姓名 */
      donorName?: string;
      /** 捐助人联系人 */
      donorContactName?: string;
      /**  */
      donorContactPhone?: string;
      /** 捐助方类型 */
      donorType?: StudentDonorType;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      paySuccessStartTime?: string;
      /**  */
      paySuccessEndTime?: string;
      /**  */
      transactionId?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonateRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidReport/GetAllDonateRecords';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DonorName: params['donorName'],
        DonorContactName: params['donorContactName'],
        DonorContactPhone: params['donorContactPhone'],
        DonorType: params['donorType'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        PaySuccessStartTime: params['paySuccessStartTime'],
        PaySuccessEndTime: params['paySuccessEndTime'],
        transactionId: params['transactionId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取捐助的总人次和总金额
   */
  static getTotalData(
    params: {
      /** 捐助方姓名 */
      donorName?: string;
      /** 捐助人联系人 */
      donorContactName?: string;
      /**  */
      donorContactPhone?: string;
      /** 捐助方类型 */
      donorType?: StudentDonorType;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      paySuccessStartTime?: string;
      /**  */
      paySuccessEndTime?: string;
      /**  */
      transactionId?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TotalDataDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidReport/GetTotalData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DonorName: params['donorName'],
        DonorContactName: params['donorContactName'],
        DonorContactPhone: params['donorContactPhone'],
        DonorType: params['donorType'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        PaySuccessStartTime: params['paySuccessStartTime'],
        PaySuccessEndTime: params['paySuccessEndTime'],
        transactionId: params['transactionId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取捐助的总金额
   */
  static getTotalAmount(
    params: {
      /** 捐助方姓名 */
      donorName?: string;
      /** 捐助人联系人 */
      donorContactName?: string;
      /**  */
      donorContactPhone?: string;
      /** 捐助方类型 */
      donorType?: StudentDonorType;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      paySuccessStartTime?: string;
      /**  */
      paySuccessEndTime?: string;
      /**  */
      transactionId?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidReport/GetTotalAmount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DonorName: params['donorName'],
        DonorContactName: params['donorContactName'],
        DonorContactPhone: params['donorContactPhone'],
        DonorType: params['donorType'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        PaySuccessStartTime: params['paySuccessStartTime'],
        PaySuccessEndTime: params['paySuccessEndTime'],
        transactionId: params['transactionId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据AidBidId获取所有的发放记录
   */
  static getAllStudentAidGrantRecord(
    params: {
      /** 助学标的Id */
      bidId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentAidGrantRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidReport/GetAllStudentAidGrantRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BidId: params['bidId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StudentDonateRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentAidReport/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudentBidOrderService {
  /**
   * 获取订单列表
   */
  static getAll(
    params: {
      /**  */
      orderStatus?: OrderStatus;
      /** 捐助方名称 */
      donorName?: string;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      schoolId?: number;
      /**  */
      amount?: number;
      /**  */
      successStartTime?: string;
      /**  */
      successEndTime?: string;
      /** 所属标的id */
      bidId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentBidOrderDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrderStatus: params['orderStatus'],
        DonorName: params['donorName'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        SchoolId: params['schoolId'],
        Amount: params['amount'],
        SuccessStartTime: params['successStartTime'],
        SuccessEndTime: params['successEndTime'],
        BidId: params['bidId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取订单信息
   */
  static get(
    params: {
      /** orderId */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentBidOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前用户的订单
   */
  static getAllForUser(
    params: {
      /**  */
      orderStatus?: OrderStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentBidOrderDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/GetAllForUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrderStatus: params['orderStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量取消订单
   */
  static batchCancel(
    params: {
      /** requestBody */
      body?: BatchCancelInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/BatchCancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 手动确认订单付款完成
   */
  static manualConfirmOrderPaySuccess(
    params: {
      /** requestBody */
      body?: ManualConfirmOrderPaySuccessInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/ManualConfirmOrderPaySuccess';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 管理员取消订单
   */
  static cancelOrder(
    params: {
      /** requestBody */
      body?: CancelOrderInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/CancelOrder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户自己取消订单
   */
  static cancelOrderBySelf(
    params: {
      /** requestBody */
      body?: CancelOrderInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/CancelOrderBySelf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StudentBidOrderPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 捐助方-提交线下付款信息
   */
  static submitOfflinePay(
    params: {
      /** requestBody */
      body?: SubmitOfflinePayInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/SubmitOfflinePay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 跳转支付
   */
  static payOrder(
    params: {
      /** requestBody */
      body?: PayOrderInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/PayOrder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 支付成功通知-同步
   */
  static payNotifySync(
    params: {
      /**  */
      signType?: string;
      /**  */
      paymentType?: string;
      /**  */
      buyerAccount?: string;
      /**  */
      tradeStatus?: string;
      /**  */
      tradeNo?: string;
      /**  */
      totalFee?: number;
      /**  */
      orderNo?: string;
      /**  */
      notifyType?: string;
      /**  */
      notifyId?: string;
      /**  */
      notifyTime?: string;
      /**  */
      sign?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/PayNotifySync';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sign_Type: params['signType'],
        Payment_Type: params['paymentType'],
        Buyer_Account: params['buyerAccount'],
        Trade_Status: params['tradeStatus'],
        Trade_No: params['tradeNo'],
        Total_Fee: params['totalFee'],
        Order_No: params['orderNo'],
        Notify_Type: params['notifyType'],
        Notify_Id: params['notifyId'],
        Notify_Time: params['notifyTime'],
        Sign: params['sign']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 异步处理支付回调
   */
  static processOrder(
    params: {
      /**  */
      signType?: string;
      /**  */
      paymentType?: string;
      /**  */
      buyerAccount?: string;
      /**  */
      tradeStatus?: string;
      /**  */
      tradeNo?: string;
      /**  */
      totalFee?: number;
      /**  */
      orderNo?: string;
      /**  */
      notifyType?: string;
      /**  */
      notifyId?: string;
      /**  */
      notifyTime?: string;
      /**  */
      sign?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/ProcessOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sign_Type: params['signType'],
        Payment_Type: params['paymentType'],
        Buyer_Account: params['buyerAccount'],
        Trade_Status: params['tradeStatus'],
        Trade_No: params['tradeNo'],
        Total_Fee: params['totalFee'],
        Order_No: params['orderNo'],
        Notify_Type: params['notifyType'],
        Notify_Id: params['notifyId'],
        Notify_Time: params['notifyTime'],
        Sign: params['sign']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 支付成功通知-异步
   */
  static payNotify(
    params: {
      /**  */
      signType?: string;
      /**  */
      paymentType?: string;
      /**  */
      buyerAccount?: string;
      /**  */
      tradeStatus?: string;
      /**  */
      tradeNo?: string;
      /**  */
      totalFee?: number;
      /**  */
      orderNo?: string;
      /**  */
      notifyType?: string;
      /**  */
      notifyId?: string;
      /**  */
      notifyTime?: string;
      /**  */
      sign?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentBidOrder/PayNotify';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sign_Type: params['signType'],
        Payment_Type: params['paymentType'],
        Buyer_Account: params['buyerAccount'],
        Trade_Status: params['tradeStatus'],
        Trade_No: params['tradeNo'],
        Total_Fee: params['totalFee'],
        Order_No: params['orderNo'],
        Notify_Type: params['notifyType'],
        Notify_Id: params['notifyId'],
        Notify_Time: params['notifyTime'],
        Sign: params['sign']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudentDonorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StudentDonorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create4Portal(
    params: {
      /** requestBody */
      body?: StudentDonorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Create4Portal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StudentDonorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update4Portal(
    params: {
      /** requestBody */
      body?: StudentDonorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Update4Portal';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAllForPortal(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorSimpleDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/GetMyAllForPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 用户自己更新捐助方资料信息
   */
  static updateBySelf(
    params: {
      /** requestBody */
      body?: StudentDonorSelfUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/UpdateBySelf';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我的捐助资料信息
   */
  static getMyProfile(options: IRequestOptions = {}): Promise<StudentDonorSelfDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/GetMyProfile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 启用
   */
  static active(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Active';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 禁用
   */
  static disable(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Disable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDonorAidHistory(
    params: {
      /**  */
      donorId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PairingRecordDetailHistoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/GetDonorAidHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { donorId: params['donorId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StudentDonorPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      isActive?: boolean;
      /**  */
      name?: string;
      /**  */
      mobilePhone?: string;
      /**  */
      emailAddress?: string;
      /**  */
      type?: StudentDonorType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsActive: params['isActive'],
        Name: params['name'],
        MobilePhone: params['mobilePhone'],
        EmailAddress: params['emailAddress'],
        Type: params['type'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取统计数量
   */
  static getMyCount(options: IRequestOptions = {}): Promise<StudentDonorCountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/GetMyCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 关联注册账号
   */
  static associatedRegisterAccount(
    params: {
      /** requestBody */
      body?: StudentDonorRegisterAccountDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/AssociatedRegisterAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 关联注册账号
   */
  static cancelAssociatedRegisterAccount(
    params: {
      /** requestBody */
      body?: StudentDonorRegisterAccountDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/CancelAssociatedRegisterAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentDonorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentDonor/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudentFeedbackRecordService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StudentFeedbackRecordCreateOrUpdateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentFeedbackRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StudentFeedbackRecordCreateOrUpdateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentFeedbackRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量创建反馈记录
   */
  static batchCreate(
    params: {
      /** requestBody */
      body?: StudentFeedbackRecordBatchCreateInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/BatchCreate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: StudentFeedbackRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入学生反馈记录
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentFeedbackRecordCreateOrUpdateInputGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentFeedbackRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      title?: string;
      /**  */
      studentId?: number;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      schoolId?: number;
      /**  */
      creatorUserId?: number;
      /**  */
      year?: number;
      /**  */
      term?: number;
      /**  */
      creationStartTime?: string;
      /**  */
      creationEndTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudentFeedbackRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        StudentId: params['studentId'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        SchoolId: params['schoolId'],
        CreatorUserId: params['creatorUserId'],
        Year: params['year'],
        Term: params['term'],
        CreationStartTime: params['creationStartTime'],
        CreationEndTime: params['creationEndTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentFeedbackRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudentPairingRecordService {
  /**
   * 创建结对记录
   */
  static create(
    params: {
      /** requestBody */
      body?: CreatePairingRecordInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 变更学生
   */
  static changeStudent(
    params: {
      /** requestBody */
      body?: ChangeStudentInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/ChangeStudent';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除结对记录
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取结对记录的学生变更记录
   */
  static getChangeHistories(
    params: {
      /** 结对记录id(二选一) */
      pairingRecordId?: number;
      /** 标的id(二选一) */
      bidId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PairingRecordStudentChangeHistoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/GetChangeHistories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PairingRecordId: params['pairingRecordId'], BidId: params['bidId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前助学结对的当前捐助人与当前学生的结对历史
   */
  static getPairingRecordDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PairingRecordDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/GetPairingRecordDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我结对的信息
   */
  static getMyPairingRecordCount(
    params: {
      /** 学生姓名 */
      studentName?: string;
      /** 状态过滤.使用[所有,已结对,待付款,待认捐]的任意一个值.默认为"已结对" */
      stateFilter?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/GetMyPairingRecordCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StudentName: params['studentName'],
        StateFilter: params['stateFilter'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我结对的信息.
   */
  static getMyPairingRecords(
    params: {
      /** 学生姓名 */
      studentName?: string;
      /** 状态过滤.使用[所有,已结对,待付款,待认捐]的任意一个值.默认为"已结对" */
      stateFilter?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PairingStateDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/GetMyPairingRecords';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StudentName: params['studentName'],
        StateFilter: params['stateFilter'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取已完成结对标的的所有结对记录
   */
  static getAllOfBidCompleted(
    params: {
      /**  */
      studentId?: number;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /**  */
      donorName?: string;
      /**  */
      schoolName?: string;
      /**  */
      schoolId?: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      counterpart?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PairingRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/GetAllOfBidCompleted';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StudentId: params['studentId'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        DonorName: params['donorName'],
        SchoolName: params['schoolName'],
        SchoolId: params['schoolId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        Counterpart: params['counterpart'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出已 完成结对标的的所有结对记录
   */
  static exportOfBidCompleted(
    params: {
      /** requestBody */
      body?: PairingRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/ExportOfBidCompleted';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有已结对/结对中的捐助方=>学生的状态信息
   */
  static getAllPairingStates(
    params: {
      /**  */
      donorName?: string;
      /**  */
      studentName?: string;
      /**  */
      studentNo?: string;
      /** 是否已成功结对 */
      isSuccess?: boolean;
      /**  */
      schoolName?: string;
      /**  */
      schoolId?: number;
      /**  */
      bidStatus?: StudentAidBidStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PairingStateDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/GetAllPairingStates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DonorName: params['donorName'],
        StudentName: params['studentName'],
        StudentNo: params['studentNo'],
        IsSuccess: params['isSuccess'],
        SchoolName: params['schoolName'],
        SchoolId: params['schoolId'],
        BidStatus: params['bidStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出所有已结对/结对中的捐助方=>学生的状态信息
   */
  static exportPairingStates(
    params: {
      /** requestBody */
      body?: PairingStatePagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/ExportPairingStates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入结对记录的模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导入助学结对记录
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情
   */
  static getMyPairingRecordDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PairingStateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/StudentPairingRecord/GetMyPairingRecordDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SwiperService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SwiperCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SwiperCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据锚点编号获取轮播图列表
   */
  static getSwiperListByAnchor(
    params: {
      /**  */
      anchorName?: string;
      /**  */
      isMustImg?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/GetSwiperListByAnchor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { anchorName: params['anchorName'], isMustImg: params['isMustImg'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      cmsAnchorId?: number;
      /**  */
      anchorName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        CmsAnchorId: params['cmsAnchorId'],
        AnchorName: params['anchorName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateTenantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForCurrent(options: IRequestOptions = {}): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GetForCurrent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      contactName?: string;
      /**  */
      contactMobilePhone?: string;
      /**  */
      legalPerson?: string;
      /**  */
      isActive?: boolean;
      /** 版本Id */
      editionId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        ContactName: params['contactName'],
        ContactMobilePhone: params['contactMobilePhone'],
        LegalPerson: params['legalPerson'],
        IsActive: params['isActive'],
        EditionId: params['editionId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取租户当前使用的版本的过期时间
   */
  static getCurrentEditionExpire(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantEditionExpireDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GetCurrentEditionExpire';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 为租户分配版本
   */
  static grantEdition(
    params: {
      /** requestBody */
      body?: TenantGrantEditionInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GrantEdition';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGrantedFeatures(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GrantFeatureDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GetGrantedFeatures';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static grantFeatures(
    params: {
      /** requestBody */
      body?: GrantFeaturesToTenantInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GrantFeatures';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static switchActiveTenant(
    params: {
      /** requestBody */
      body?: EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/SwitchActiveTenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateTenantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantAccessRecordService {
  /**
   *
   */
  static updateAccessRecord(
    params: {
      /** requestBody */
      body?: TenantAccessRecordCreateDto[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantAccessRecord/UpdateAccessRecord';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantAccountRecordService {
  /**
   *
   */
  static getAll(
    params: {
      /** 租户账户记录类型 */
      recordType?: TenantAccountRecordType;
      /** 项目/基金名称 */
      projectName?: string;
      /** 捐赠者名称 */
      donorName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantAccountRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantAccountRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        RecordType: params['recordType'],
        ProjectName: params['projectName'],
        DonorName: params['donorName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTenantAccountByPortal(options: IRequestOptions = {}): Promise<TenantAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantAccountRecord/GetTenantAccountByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantApplyService {
  /**
   * 创建
   */
  static create(
    params: {
      /** requestBody */
      body?: TenantApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询详情
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询列表
   */
  static getAll(
    params: {
      /** 机构全名 */
      name?: string;
      /** 成立日期 */
      incorporationDate?: string;
      /** 联系人姓名 */
      contactName?: string;
      /** 审核状态 */
      status?: TenantApplyAuditStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        IncorporationDate: params['incorporationDate'],
        ContactName: params['contactName'],
        Status: params['status'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改
   */
  static update(
    params: {
      /** requestBody */
      body?: TenantApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核通过
   */
  static pass(
    params: {
      /** requestBody */
      body?: TenantApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantApply/Pass';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核拒绝
   */
  static reject(
    params: {
      /** requestBody */
      body?: TenantApplyRejectPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantApply/Reject';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantBankAccountService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TenantBankAccountCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantBankAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantBankAccount/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TenantBankAccountCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantBankAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantBankAccount/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantBankAccount/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getList(
    params: {
      /** 银行账号 */
      bankAccountCode?: string;
      /** 银行名称 */
      bankName?: string;
      /** 户名 */
      bankAccountName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantBankAccountDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantBankAccount/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BankAccountCode: params['bankAccountCode'],
        BankName: params['bankName'],
        BankAccountName: params['bankAccountName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantBankAccountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantBankAccount/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 银行账号 */
      bankAccountCode?: string;
      /** 银行名称 */
      bankName?: string;
      /** 户名 */
      bankAccountName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantBankAccountDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantBankAccount/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BankAccountCode: params['bankAccountCode'],
        BankName: params['bankName'],
        BankAccountName: params['bankAccountName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantDomainService {
  /**
   * 创建
   */
  static create(
    params: {
      /** requestBody */
      body?: TenantDomainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDomainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantDomain/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询详情
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDomainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantDomain/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询列表
   */
  static getList(
    params: {
      /** 域名 */
      domain?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDomainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantDomain/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Domain: params['domain'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改
   */
  static update(
    params: {
      /** requestBody */
      body?: TenantDomainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDomainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantDomain/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantDomain/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 域名 */
      domain?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDomainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TenantDomain/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Domain: params['domain'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TestEntityService {
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TestMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static executeSql(
    params: {
      /** requestBody */
      body?: ExecuteSqlInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/ExecuteSql';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TestMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TokenAuthService {
  /**
   *
   */
  static authenticate(
    params: {
      /** requestBody */
      body?: AuthenticateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/Authenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static thirdPlatformAuthenticate(
    params: {
      /** requestBody */
      body?: ThirdPlatformAuthenticateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/ThirdPlatformAuthenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static thirdPlatformBindingAccount(
    params: {
      /** requestBody */
      body?: ThirdPlatformBindingModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/ThirdPlatformBindingAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refreshToken(options: IRequestOptions = {}): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/RefreshToken';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static weiXinLoginCallback(
    params: {
      /**  */
      weiXinUser?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/WeiXinLoginCallback';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { weiXinUser: params['weiXinUser'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getWeiXinLoginUrl(
    params: {
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/GetWeiXinLoginUrl';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static weiXinLogin(
    params: {
      /**  */
      weiXinUser?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/WeiXinLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weiXinUser: params['weiXinUser'], returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static weChatAppletAuthenticate(
    params: {
      /** requestBody */
      body?: RegisterInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/WeChatAppletAuthenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TownService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TownCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TownCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      areaId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { areaId: params['areaId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      townName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TownName: params['townName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TrainService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TrainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TrainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publish(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelPublish(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/CancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端查询培训列表
   */
  static getListByPortal(
    params: {
      /** 培训主题 */
      title?: string;
      /** 培训类型 */
      trainTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /** 状态 */
      status?: TrainStatus;
      /** 培训方式 */
      method?: TrainMethod;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetListByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        TrainTypeId: params['trainTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        Status: params['status'],
        Method: params['method'],
        QueryStatus: params['queryStatus'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取培训已经签到的人数
   */
  static getCheckInCount(
    params: {
      /**  */
      trainId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetCheckInCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { trainId: params['trainId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取二维码
   */
  static downQrCode(
    params: {
      /**  */
      id?: number;
      /**  */
      qrType?: TrainQrType;
      /**  */
      title?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/DownQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], qrType: params['qrType'], title: params['title'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取培训所在的省份列表
   */
  static getProvinceList(options: IRequestOptions = {}): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetProvinceList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取培训所在的城市列表
   */
  static getCityList(
    params: {
      /** 所在省份 */
      provinceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetCityList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provinceId: params['provinceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取培训所在的区县列表
   */
  static getAreaList(
    params: {
      /** 所在区县 */
      cityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetAreaList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityId: params['cityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新阅读数量
   */
  static updateReadCount(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/UpdateReadCount';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 培训主题 */
      title?: string;
      /** 培训类型 */
      trainTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /** 状态 */
      status?: TrainStatus;
      /** 培训方式 */
      method?: TrainMethod;
      /** 查询状态 */
      queryStatus?: ActivityQueryStatus;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        TrainTypeId: params['trainTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        Status: params['status'],
        Method: params['method'],
        QueryStatus: params['queryStatus'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TrainApplyService {
  /**
   * 可用于前端服务报名
   */
  static create(
    params: {
      /** requestBody */
      body?: TrainApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getApplyStatus(
    params: {
      /**  */
      trainId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainApplyStatusDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetApplyStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { trainId: params['trainId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TrainApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 姓名 */
      surName?: string;
      /**  */
      phone?: string;
      /**  */
      trainId?: number;
      /**  */
      trainApplyId?: number;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        Phone: params['phone'],
        TrainId: params['trainId'],
        TrainApplyId: params['trainApplyId'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 培训签到，有管理权限的可以为别人签到
   */
  static checkIn(
    params: {
      /** requestBody */
      body?: TrainApplyCheckInPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/CheckIn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 培训签到后填写培训感悟
   */
  static updateCheckIn(
    params: {
      /** requestBody */
      body?: TrainCheckInRecordUdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/UpdateCheckIn';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 签到详情
   */
  static getTrainCheckInRecordDetail(
    params: {
      /**  */
      checkInRecordId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainCheckInRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetTrainCheckInRecordDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { checkInRecordId: params['checkInRecordId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取签到明细
   */
  static getTrainCheckInRecord(
    params: {
      /** 姓名 */
      surName?: string;
      /**  */
      phone?: string;
      /**  */
      trainId?: number;
      /**  */
      trainApplyId?: number;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainCheckInRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetTrainCheckInRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        Phone: params['phone'],
        TrainId: params['trainId'],
        TrainApplyId: params['trainApplyId'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据培训报名记录Id获取培训签到记录列表
   */
  static getTrainCheckInRecordByApplyId(
    params: {
      /** 姓名 */
      surName?: string;
      /**  */
      phone?: string;
      /**  */
      trainId?: number;
      /**  */
      trainApplyId?: number;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainCheckInRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetTrainCheckInRecordByApplyId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        Phone: params['phone'],
        TrainId: params['trainId'],
        TrainApplyId: params['trainApplyId'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出报名列表到Excel
   */
  static exportApplyListToExcel(
    params: {
      /** requestBody */
      body?: TrainApplyPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/ExportApplyListToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出签到列表到Excel
   */
  static exportCheckInListToExcel(
    params: {
      /** requestBody */
      body?: TrainApplyPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/ExportCheckInListToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 个人中心我的培训列表
   */
  static getAllByPortal(
    params: {
      /** 姓名 */
      surName?: string;
      /**  */
      phone?: string;
      /**  */
      trainId?: number;
      /**  */
      trainApplyId?: number;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetAllByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        Phone: params['phone'],
        TrainId: params['trainId'],
        TrainApplyId: params['trainApplyId'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消报名
   */
  static cancelApply(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/CancelApply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 姓名 */
      surName?: string;
      /**  */
      phone?: string;
      /**  */
      trainId?: number;
      /**  */
      trainApplyId?: number;
      /** 报名状态 */
      status?: ActivityApplyStatus;
      /** 查询状态 */
      queryStatus?: QueryApplyStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        Phone: params['phone'],
        TrainId: params['trainId'],
        TrainApplyId: params['trainApplyId'],
        Status: params['status'],
        QueryStatus: params['queryStatus'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TrainManagementService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TrainManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainManagement/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainManagement/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainManagement/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TrainManagementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainManagementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainManagement/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainManagementCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainManagement/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 培训名称 */
      title?: string;
      /** 主办方 */
      host?: string;
      /** 讲师 */
      lecturer?: string;
      /** 培训类型 */
      trainTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainManagementDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainManagement/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Host: params['host'],
        Lecturer: params['lecturer'],
        TrainTypeId: params['trainTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TrainRecordService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 姓名 */
      surName?: string;
      /** 培训主题 */
      trainTitle?: string;
      /** 培训类型 */
      trainTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 志愿者 */
      volunteerId?: number;
      /** 结束时间 */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        TrainTitle: params['trainTitle'],
        TrainTypeId: params['trainTypeId'],
        StartDate: params['startDate'],
        VolunteerId: params['volunteerId'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TrainRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TrainRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出签到列表到Excel
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: TrainRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 通过TrainApplyId获取数据
   */
  static getEditByApplyId(
    params: {
      /**  */
      applyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/GetEditByApplyId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { applyId: params['applyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端提交新增，需要开启审核流程
   */
  static createByPortal(
    params: {
      /** requestBody */
      body?: TrainRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/CreateByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端提交更新，需要重新开启审核流程
   */
  static updateByPortal(
    params: {
      /** requestBody */
      body?: TrainRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/UpdateByPortal';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 姓名 */
      surName?: string;
      /** 培训主题 */
      trainTitle?: string;
      /** 培训类型 */
      trainTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 志愿者 */
      volunteerId?: number;
      /** 结束时间 */
      endDate?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SurName: params['surName'],
        TrainTitle: params['trainTitle'],
        TrainTypeId: params['trainTypeId'],
        StartDate: params['startDate'],
        VolunteerId: params['volunteerId'],
        EndDate: params['endDate'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UEditorHandlerService {
  /**
   *
   */
  static uEditorUpload(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UEditorHandler/UEditorUpload';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uEditorUpload1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UEditorHandler/UEditorUpload';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uEditorUpload2(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UEditorHandler/UEditorUpload';

      const configs: IRequestConfig = getConfigs('options', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateUserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      isActive?: boolean;
      /**  */
      organizationUnitId?: number;
      /**  */
      departmentId?: number;
      /**  */
      departments?: string;
      /** 用户查询模式: foundation | organizationUnit */
      mode?: string;
      /** 角色Id */
      roleId?: number;
      /** 昵称 */
      name?: string;
      /** 姓名 */
      surname?: string;
      /** 手机号 */
      phoneNumber?: string;
      /** 是否显示注册用户 */
      isOnlyMembership?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        IsActive: params['isActive'],
        OrganizationUnitId: params['organizationUnitId'],
        DepartmentId: params['departmentId'],
        Departments: params['departments'],
        Mode: params['mode'],
        RoleId: params['roleId'],
        Name: params['name'],
        Surname: params['surname'],
        PhoneNumber: params['phoneNumber'],
        IsOnlyMembership: params['isOnlyMembership'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByMobilePhone(
    params: {
      /**  */
      mobilePhone?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetByMobilePhone';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mobilePhone: params['mobilePhone'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有可分配的角色
   */
  static getRoles(options: IRequestOptions = {}): Promise<RoleDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetRoles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 为用户分配角色
   */
  static grantRoles(
    params: {
      /** requestBody */
      body?: GrantRoleInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GrantRoles';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户拥有的角色
   */
  static getUserRoles(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetUserRoles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeLanguage(
    params: {
      /** requestBody */
      body?: ChangeUserLanguageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangeLanguage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前租户下的所有用户
   */
  static getAllUsers(options: IRequestOptions = {}): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetAllUsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取机构的所有用户
   */
  static getOrganizationUnitUsers(
    params: {
      /**  */
      keyword?: string;
      /**  */
      isActive?: boolean;
      /**  */
      organizationUnitId?: number;
      /**  */
      departmentId?: number;
      /**  */
      departments?: string;
      /** 用户查询模式: foundation | organizationUnit */
      mode?: string;
      /** 角色Id */
      roleId?: number;
      /** 昵称 */
      name?: string;
      /** 姓名 */
      surname?: string;
      /** 手机号 */
      phoneNumber?: string;
      /** 是否显示注册用户 */
      isOnlyMembership?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserOrganizationUnitDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetOrganizationUnitUsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        IsActive: params['isActive'],
        OrganizationUnitId: params['organizationUnitId'],
        DepartmentId: params['departmentId'],
        Departments: params['departments'],
        Mode: params['mode'],
        RoleId: params['roleId'],
        Name: params['name'],
        Surname: params['surname'],
        PhoneNumber: params['phoneNumber'],
        IsOnlyMembership: params['isOnlyMembership'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据角色编号获取用户
   */
  static getUserListByRoleName(
    params: {
      /**  */
      roleName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetUserListByRoleName';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { roleName: params['roleName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户个人信息
   */
  static getUserProfileForEdit(options: IRequestOptions = {}): Promise<UserProfileUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetUserProfileForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateUserProfile(
    params: {
      /** requestBody */
      body?: UserProfileUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserProfileUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/UpdateUserProfile';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新用户头像
   */
  static updateUserHeadImageUrl(
    params: {
      /**  */
      url?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/UpdateUserHeadImageUrl';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { url: params['url'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码
   */
  static resetPassword4Portal(
    params: {
      /** requestBody */
      body?: ResetPassword4PortalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ResetPassword4Portal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword4PortalWithEmail(
    params: {
      /** requestBody */
      body?: ResetPassword4PortalWithEmailDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ResetPassword4PortalWithEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我的积分
   */
  static getMyPoint(
    params: {
      /** 积分类型 */
      pointType?: PointType;
      /** 开始时间 */
      beginTime?: string;
      /** 结束时间 */
      endTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserPointRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetMyPoint';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PointType: params['pointType'],
        BeginTime: params['beginTime'],
        EndTime: params['endTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出注册用户
   */
  static exportToExcel(
    params: {
      /** requestBody */
      body?: PagedUserResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ExportToExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserCenterService {
  /**
   * 获取信息
   */
  static getInfo(options: IRequestOptions = {}): Promise<UserCenterDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserCenter/GetInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我的小组
   */
  static getMyGroup(options: IRequestOptions = {}): Promise<BbsGroupMiniDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserCenter/GetMyGroup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取小组动态
   */
  static getBbsGroupDynamic(options: IRequestOptions = {}): Promise<Post[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserCenter/GetBbsGroupDynamic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserTaskService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: UserTaskCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserTaskDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserTaskDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 任务管理
   */
  static getAll(
    params: {
      /** 任务名称 */
      title?: string;
      /** 任务状态 */
      status?: TaskStatus;
      /** 紧急程度Id */
      importantLevelId?: number;
      /** 开始时间 */
      startTime?: string;
      /** 截止时间 */
      endTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserTaskDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        ImportantLevelId: params['importantLevelId'],
        StartTime: params['startTime'],
        EndTime: params['endTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UserTaskCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserTaskDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 我的任务
   */
  static getMyTask(
    params: {
      /** 任务名称 */
      title?: string;
      /** 任务状态 */
      status?: TaskStatus;
      /** 紧急程度Id */
      importantLevelId?: number;
      /** 开始时间 */
      startTime?: string;
      /** 截止时间 */
      endTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserTaskDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/GetMyTask';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        ImportantLevelId: params['importantLevelId'],
        StartTime: params['startTime'],
        EndTime: params['endTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 认领任务
   */
  static claim(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/Claim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static submit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/Submit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 任务名称 */
      title?: string;
      /** 任务状态 */
      status?: TaskStatus;
      /** 紧急程度Id */
      importantLevelId?: number;
      /** 开始时间 */
      startTime?: string;
      /** 截止时间 */
      endTime?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserTaskDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        ImportantLevelId: params['importantLevelId'],
        StartTime: params['startTime'],
        EndTime: params['endTime'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UserTask/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VerificationCodeService {
  /**
   * 验证
   */
  static check(
    params: {
      /**  */
      phone?: string;
      /**  */
      code?: string;
      /**  */
      verificationCode?: VerificationCode;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VerificationCode/Check';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { phone: params['phone'], code: params['code'], verificationCode: params['verificationCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VolunteerService {
  /**
   *
   */
  static audit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前用户的手机号
   */
  static getCurrentUserPhoneNumber(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetCurrentUserPhoneNumber';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 志愿者姓名 */
      volunteerName?: string;
      /** 志愿者手机号 */
      volunteerPhone?: string;
      /**  */
      volunteerId?: number;
      /** 志愿者编号 */
      volunteerCode?: string;
      /**  */
      includeUserExtend?: boolean;
      /** 状态 */
      status?: VolunteerStatus;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerName: params['volunteerName'],
        VolunteerPhone: params['volunteerPhone'],
        VolunteerId: params['volunteerId'],
        VolunteerCode: params['volunteerCode'],
        IncludeUserExtend: params['includeUserExtend'],
        Status: params['status'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VolunteerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VolunteerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出活动名单
   */
  static exportExcel(
    params: {
      /** requestBody */
      body?: VolunteerPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/ExportExcel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchAudit(
    params: {
      /** requestBody */
      body?: BatchAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/BatchAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static postToAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/PostToAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static postTorReAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/PostTorReAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 禁用
   */
  static ban(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Ban';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消禁用
   */
  static cancelBan(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/CancelBan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createByPortal(
    params: {
      /** requestBody */
      body?: VolunteerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/CreateByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByPortal(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateByPortal(
    params: {
      /** requestBody */
      body?: VolunteerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/UpdateByPortal';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCurrentUserByPortal(options: IRequestOptions = {}): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetCurrentUserByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getApprovedVolunteerList(options: IRequestOptions = {}): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetApprovedVolunteerList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 活跃志愿者列表
   */
  static getActiveVolunteerList(
    params: {
      /** 取值个数 */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetActiveVolunteerList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 最新志愿者列表
   */
  static getNewestVolunteerList(
    params: {
      /** 取值个数 */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetNewestVolunteerList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 前端查询志愿者信息
   */
  static queryVolunteerDetailByPortal(
    params: {
      /** requestBody */
      body?: VolunteerQueryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerQueryResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/QueryVolunteerDetailByPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户审核日志
   */
  static getUserAuditLog(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAuditLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetUserAuditLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actualAudit(
    params: {
      /** requestBody */
      body?: UserAuditInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/ActualAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /** 志愿者姓名 */
      volunteerName?: string;
      /** 志愿者手机号 */
      volunteerPhone?: string;
      /**  */
      volunteerId?: number;
      /** 志愿者编号 */
      volunteerCode?: string;
      /**  */
      includeUserExtend?: boolean;
      /** 状态 */
      status?: VolunteerStatus;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerName: params['volunteerName'],
        VolunteerPhone: params['volunteerPhone'],
        VolunteerId: params['volunteerId'],
        VolunteerCode: params['volunteerCode'],
        IncludeUserExtend: params['includeUserExtend'],
        Status: params['status'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VolunteerDashbordService {
  /**
   * 获取平台数据
   */
  static getPlatformData(
    params: {
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDashbordCountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerDashbord/GetPlatformData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取会员单位统计数据
   */
  static getOrgStatisticsData(
    params: {
      /**  */
      orgId?: number;
      /**  */
      beginTime?: string;
      /**  */
      endTime?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDashbordCountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerDashbord/GetOrgStatisticsData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orgId: params['orgId'], beginTime: params['beginTime'], endTime: params['endTime'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取志愿者服务时长排行
   */
  static getTotalHoursRanking(
    params: {
      /**  */
      orgId?: number;
      /**  */
      beginTime?: string;
      /**  */
      endTime?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerTotalHoursRankingDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerDashbord/GetTotalHoursRanking';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orgId: params['orgId'], beginTime: params['beginTime'], endTime: params['endTime'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取志愿者公益积分排行
   */
  static getPointRanking(
    params: {
      /**  */
      orgId?: number;
      /**  */
      beginTime?: string;
      /**  */
      endTime?: string;
      /**  */
      pointType?: PointType;
      /**  */
      detailTypeId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerPointRankingDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerDashbord/GetPointRanking';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        orgId: params['orgId'],
        beginTime: params['beginTime'],
        endTime: params['endTime'],
        pointType: params['pointType'],
        detailTypeId: params['detailTypeId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VolunteerServiceHistoryService {
  /**
   * 获取服务记录统计
   */
  static getTotalDetails(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/GetTotalDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VolunteerServiceHistoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerServiceHistoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户志愿者信息
   */
  static getUserVolunteerInfo(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserVolunteerInfo> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/GetUserVolunteerInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerServiceHistoryCreateOrUpdateDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerServiceHistoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 志愿者姓名 */
      volunteerName?: string;
      /** 志愿者手机号 */
      volunteerPhone?: string;
      /**  */
      volunteerId?: number;
      /** 志愿者编号 */
      volunteerCode?: string;
      /**  */
      includeUserExtend?: boolean;
      /** 状态 */
      status?: VolunteerStatus;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerServiceHistoryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        VolunteerName: params['volunteerName'],
        VolunteerPhone: params['volunteerPhone'],
        VolunteerId: params['volunteerId'],
        VolunteerCode: params['volunteerCode'],
        IncludeUserExtend: params['includeUserExtend'],
        Status: params['status'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VolunteerServiceHistoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerServiceHistoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VolunteerServiceHistory/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WechatService {
  /**
   *
   */
  static getWechatLoginUrl(
    params: {
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/openAuth/wechat/GetWechatLoginUrl';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authCallback(
    params: {
      /**  */
      weiXinUser?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/openAuth/wechat/AuthCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { weiXinUser: params['weiXinUser'], returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccessTokenAndOpenId(
    params: {
      /**  */
      returnUrl?: string;
      /**  */
      custom?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/openAuth/wechat/GetAccessTokenAndOpenId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { return_url: params['returnUrl'], custom: params['custom'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static receiveMessage(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Wechat/ReceiveMessage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static receiveMessage1(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Wechat/ReceiveMessage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTempQrCode(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Wechat/GetTempQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface IsTenantAvailableInput {
  /**  */
  tenancyName?: string;
}

export interface IsTenantAvailableOutput {
  /**  */
  state?: TenantAvailabilityState;

  /**  */
  tenantId?: number;
}

export interface LoginPostRespDto {
  /**  */
  errorInfo?: string;

  /**  */
  accessToken?: string;

  /**  */
  identityToken?: string;

  /**  */
  expiresIn?: number;

  /** 租户Id */
  tenantId?: number;

  /** 机构Id */
  organizationUnitId?: number;

  /** 用户Id */
  userId?: number;

  /** 企业微信Id */
  workWeiXinId?: string;
}

export interface RegisterInput {
  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  userName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  captchaResponse?: string;

  /**  */
  code?: string;

  /**  */
  ticket?: string;

  /**  */
  wechatNumber?: string;

  /**  */
  openId?: string;

  /**  */
  miniOpenId?: string;

  /**  */
  unionId?: string;

  /**  */
  encryptedData?: string;

  /**  */
  iv?: string;

  /**  */
  session_key?: string;

  /**  */
  scene?: string;

  /**  */
  password?: string;
}

export interface RegisterOutput {
  /**  */
  userId?: number;

  /**  */
  canLogin?: boolean;
}

export interface RegisterWithEmailInput {
  /** 邮箱 */
  emailAddress?: string;

  /** 验证码 */
  code?: string;

  /**  */
  password?: string;
}

export interface LoginPostReqDto {
  /** 用户名 */
  username?: string;

  /** 密码 */
  password?: string;

  /** 是否记住我 */
  rememberMe?: boolean;

  /** 租户 */
  tenancyName?: string;
}

export interface AbpInvokeReturn {
  /**  */
  hasError?: boolean;

  /**  */
  errorCode?: string;

  /**  */
  errorMessage?: string;

  /**  */
  data?: object;
}

export interface ChangeInfoPostDto {
  /** 新手机号 */
  newMobilePhone?: string;

  /** 短信验证码 */
  code?: string;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /** 昵称 */
  name?: string;

  /** 性别 */
  sex?: string;

  /** 省Id */
  provinceId?: number;

  /** 市Id */
  cityId?: number;

  /** 区Id */
  areaId?: number;

  /** 住址 */
  address?: string;
}

export interface DataDictionaryDto {
  /** 租户。 */
  tenantId?: number;

  /** 键名 */
  key?: string;

  /** 显示的名称 */
  displayName?: string;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  parentId?: number;

  /**  */
  id?: number;
}

export interface AttachmentDto {
  /**  */
  fileName?: string;

  /** 文件的扩展名 */
  extension?: string;

  /** 是否为临时文件 */
  isTemp?: boolean;

  /**  */
  url?: string;

  /** 附件的宿主ID */
  hostId?: string;

  /**  */
  hostType?: AttachmentHostType;

  /** 文件的长度大小(单位：字节） */
  contentLength?: number;

  /** 文件的大小(自动计算的属性) */
  fileSize?: string;

  /**  */
  id?: number;
}

export interface OrganizationUnitShortDto {
  /**  */
  displayName?: string;

  /**  */
  id?: number;
}

export interface UserDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isActive?: boolean;

  /**  */
  fullName?: string;

  /**  */
  lastLoginTime?: Date;

  /**  */
  creationTime?: Date;

  /**  */
  departmentId?: number;

  /**  */
  departmentName?: string;

  /**  */
  roleNames?: string[];

  /**  */
  roleNamesText?: string[];

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  userType?: UserType;

  /**  */
  fundId?: number;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /** 公众号openId */
  openId?: string;

  /** 当前积分 */
  point?: number;

  /**  */
  id?: number;
}

export interface ActivityDto {
  /**  */
  title?: string;

  /**  */
  content?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  place?: string;

  /**  */
  placeCoordinate?: string;

  /**  */
  summary?: string;

  /**  */
  aimAmount?: number;

  /**  */
  price?: number;

  /**  */
  signUpCount?: number;

  /**  */
  activityStartTime?: Date;

  /**  */
  activityEndTime?: Date;

  /**  */
  applyEndTime?: Date;

  /**  */
  status?: ActivityStatus;

  /**  */
  titleImagePath?: string;

  /**  */
  host?: string;

  /**  */
  coOrganizer?: string;

  /**  */
  typeID?: ActivityTypeEnum;

  /**  */
  activityTypeId?: number;

  /**  */
  activityTypeDictionary?: DataDictionaryDto;

  /**  */
  readCount?: number;

  /**  */
  thumbsUpCount?: number;

  /**  */
  isVolunteerActivity?: boolean;

  /**  */
  totalMinutes?: number;

  /** 活动时长(小时) */
  totalHours?: number;

  /**  */
  point?: number;

  /**  */
  attachments?: AttachmentDto[];

  /** 最小志愿者星级 */
  minLevel?: number;

  /**  */
  minLevelText?: string;

  /** 最少志愿者积分 */
  minPoint?: number;

  /** 是否需要人工审核 */
  needApprove?: boolean;

  /** 领队 */
  leaderUserId?: number;

  /**  */
  leader?: UserDto;

  /**  */
  checkInCount?: number;

  /**  */
  cancelCount?: number;

  /** 省 */
  provinceName?: string;

  /** 市 */
  cityName?: string;

  /** 区 */
  areaName?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 微信分享二维码链接 */
  codeUrl?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ActivityDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ActivityDto[];
}

export interface SelectListGroup {
  /**  */
  disabled?: boolean;

  /**  */
  name?: string;
}

export interface SelectListItem {
  /**  */
  disabled?: boolean;

  /**  */
  group?: SelectListGroup;

  /**  */
  selected?: boolean;

  /**  */
  text?: string;

  /**  */
  value?: string;
}

export interface ActivityPagedResultRequestDto {
  /** 标题 */
  title?: string;

  /**  */
  status?: ActivityStatus;

  /** 是否为志愿者活动 */
  isVolunteerActivity?: boolean;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /** 活动开始时间 */
  activityStartTime?: Date;

  /** 活动结束时间 */
  activityEndTime?: Date;

  /** 活动类型(枚举) */
  typeID?: number;

  /** 活动类型（数据字典） */
  activityTypeId?: number;

  /**  */
  queryStatus?: ActivityQueryStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface Int64EntityDto {
  /**  */
  id?: number;
}

export interface ActivityCreateOrUpdateDto {
  /**  */
  status?: ActivityApplyStatus;

  /**  */
  title?: string;

  /**  */
  content?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  place?: string;

  /**  */
  placeCoordinate?: string;

  /**  */
  summary?: string;

  /**  */
  aimAmount?: number;

  /**  */
  price?: number;

  /**  */
  signUpCount?: number;

  /**  */
  activityStartTime?: Date;

  /**  */
  activityEndTime?: Date;

  /**  */
  applyTimeType?: number;

  /**  */
  applyEndTime?: Date;

  /**  */
  titleImagePath?: string;

  /**  */
  host?: string;

  /**  */
  coOrganizer?: string;

  /**  */
  typeID?: number;

  /**  */
  activityTypeId?: number;

  /**  */
  isVolunteerActivity?: boolean;

  /**  */
  point?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /** 最少志愿者积分 */
  minPoint?: number;

  /** 是否需要人工审核 */
  needApprove?: boolean;

  /** 领队 */
  leaderUserId?: number;

  /** 活动时长(小时) */
  totalHours?: number;

  /** 限制报名人数 */
  isLimitAim?: boolean;

  /** 活动是否收费 */
  isNotFree?: boolean;

  /** 是否发布 */
  isPublish?: boolean;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface ActivityCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: ActivityCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface UserAuditInput {
  /**  */
  hostId?: string;

  /**  */
  desc?: string;

  /**  */
  extensionData?: object;

  /**  */
  status?: AuditUserLogStatus;

  /**  */
  saveAttachments?: number[];
}

export interface NodeDto {
  /** Id */
  id?: string;

  /** 描述 */
  desc?: string;

  /** 角色名称 */
  roleName?: string;

  /** 角色Id */
  roleId?: number;

  /** 用户名称 */
  userName?: string;

  /** 用户Id */
  userId?: number;

  /** 序号 */
  index?: number;

  /** 排序 */
  sort?: number;

  /**  */
  auditMethod?: AuditMethod;
}

export interface UserMiniDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  id?: number;
}

export interface LogDto {
  /** 审核流程节点Id */
  auditNodeId?: string;

  /**  */
  status?: AuditUserLogStatus;

  /** 描述 */
  desc?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /** 创建时间 */
  creationTime?: Date;

  /** 审核状态 */
  auditStatus?: number;

  /** 重新提交审核时判断用于排除非当前流程 */
  expired?: boolean;

  /** 版本号 */
  version?: number;

  /** 序号 */
  index?: number;

  /**  */
  id?: number;
}

export interface UserAuditLogDto {
  /** 开始时间 */
  startTime?: Date;

  /** 结束时间 */
  endTime?: Date;

  /**  */
  status?: AuditFlowRecordStatus;

  /** 序号 */
  indexList?: number[];

  /** 审核流程节点 */
  auditNodeList?: NodeDto[];

  /** 用户审核日志 */
  logList?: LogDto[];

  /**  */
  id?: number;
}

export interface ActivityDtoListResultDto {
  /**  */
  items?: ActivityDto[];
}

export interface AuditNodeInfo {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditMethod?: AuditMethod;
}

export interface UserLog {
  /**  */
  auditNodeId?: string;

  /**  */
  status?: AuditUserLogStatus;

  /**  */
  desc?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  auditStatus?: number;

  /**  */
  expired?: boolean;

  /**  */
  index?: number;

  /**  */
  version?: number;

  /**  */
  auditNode?: AuditNodeInfo;
}

export interface AuditUserLogVersionDto {
  /**  */
  index?: number;

  /**  */
  version?: number;
}

export interface AuditUserLogDto {
  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  status?: AuditFlowRecordStatus;

  /**  */
  userLogList?: UserLog[];

  /**  */
  versionList?: AuditUserLogVersionDto[];

  /**  */
  id?: number;
}

export interface AuditNodeDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  tenantId?: number;

  /**  */
  iCanAudit?: boolean;

  /**  */
  auditMethod?: AuditMethod;

  /**  */
  sort?: number;

  /**  */
  id?: string;
}

export interface AuditFlowDto {
  /**  */
  title?: string;

  /**  */
  desc?: string;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  scope?: number;

  /**  */
  type?: AuditFlowType;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  key?: string;

  /**  */
  isLatest?: boolean;

  /**  */
  ruleConfig?: string;

  /**  */
  sort?: number;

  /**  */
  auditNodes?: AuditNodeDto[];

  /**  */
  id?: string;
}

export interface UserToken {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  loginProvider?: string;

  /**  */
  name?: string;

  /**  */
  value?: string;

  /**  */
  expireDate?: Date;

  /**  */
  id?: number;
}

export interface UserLogin {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  loginProvider?: string;

  /**  */
  providerKey?: string;

  /**  */
  id?: number;
}

export interface UserRole {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  roleId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface UserClaim {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  claimType?: string;

  /**  */
  claimValue?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface UserPermissionSetting {
  /**  */
  userId?: number;

  /**  */
  tenantId?: number;

  /**  */
  name?: string;

  /**  */
  isGranted?: boolean;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Setting {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  name?: string;

  /**  */
  value?: string;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface User {
  /**  */
  departmentId?: number;

  /**  */
  normalizedUserName?: string;

  /**  */
  normalizedEmailAddress?: string;

  /**  */
  concurrencyStamp?: string;

  /**  */
  tokens?: UserToken[];

  /**  */
  deleterUser?: User;

  /**  */
  creatorUser?: User;

  /**  */
  lastModifierUser?: User;

  /**  */
  authenticationSource?: string;

  /**  */
  userName?: string;

  /**  */
  tenantId?: number;

  /**  */
  emailAddress?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  fullName?: string;

  /**  */
  password?: string;

  /**  */
  emailConfirmationCode?: string;

  /**  */
  passwordResetCode?: string;

  /**  */
  lockoutEndDateUtc?: Date;

  /**  */
  accessFailedCount?: number;

  /**  */
  isLockoutEnabled?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  isPhoneNumberConfirmed?: boolean;

  /**  */
  securityStamp?: string;

  /**  */
  isTwoFactorEnabled?: boolean;

  /**  */
  logins?: UserLogin[];

  /**  */
  roles?: UserRole[];

  /**  */
  claims?: UserClaim[];

  /**  */
  permissions?: UserPermissionSetting[];

  /**  */
  settings?: Setting[];

  /**  */
  isEmailConfirmed?: boolean;

  /**  */
  isActive?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Fund {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  creatorUser?: User;

  /**  */
  name?: string;

  /**  */
  titleImage?: string;

  /**  */
  founderName?: string;

  /**  */
  mobileTelephone?: string;

  /**  */
  fixTelephone?: string;

  /**  */
  email?: string;

  /**  */
  qq?: string;

  /**  */
  foundDate?: Date;

  /**  */
  purpose?: string;

  /**  */
  managementFeePercentage?: number;

  /**  */
  intialCaptial?: number;

  /**  */
  capitalSource?: string;

  /**  */
  focusArea?: string;

  /**  */
  summary?: string;

  /**  */
  description?: string;

  /**  */
  viceChairman?: string;

  /**  */
  chairman?: string;

  /**  */
  member?: string;

  /**  */
  contacts?: string;

  /**  */
  type?: FundType;

  /**  */
  status?: ValidStatus;

  /**  */
  honorType?: string;

  /**  */
  isPublished?: boolean;

  /**  */
  raisedAmountTotal?: number;

  /**  */
  donatedPersonTotal?: number;

  /**  */
  expenseAmountTotal?: number;

  /**  */
  directorID?: number;

  /**  */
  director?: User;

  /**  */
  instructionsImage?: string;

  /**  */
  plaqueImage?: string;

  /**  */
  canAnonymous?: boolean;

  /**  */
  hasProducts?: boolean;

  /**  */
  isFundRaising?: boolean;

  /**  */
  fundCategory?: string;

  /**  */
  isTop?: boolean;

  /**  */
  topTime?: Date;

  /**  */
  isOfficialWebsiteDisplay?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProvinceDto {
  /**  */
  provinceName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface CityDto {
  /**  */
  cityName?: string;

  /**  */
  provinceId?: number;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface AreaDto {
  /**  */
  cityId?: number;

  /**  */
  areaName?: string;

  /**  */
  id?: number;
}

export interface UserExtendDto {
  /**  */
  tenantId?: number;

  /**  */
  userType?: UserType;

  /**  */
  userId?: number;

  /**  */
  user?: User;

  /**  */
  fundId?: number;

  /**  */
  fund?: Fund;

  /** 性别 */
  sex?: string;

  /** 职务（数据字典且有对应枚举，所以value为1,2,3,4...） */
  duty?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 所在企业 */
  company?: string;

  /** 企业职务 */
  companyDuty?: string;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 住址 */
  address?: string;

  /** 自我介绍 */
  description?: string;

  /** 公众号openId */
  openId?: string;

  /** 小程序openId */
  miniOpenId?: string;

  /** 公众号UnionId */
  unionId?: string;

  /** 生日 */
  birthday?: Date;

  /** 部门Id */
  departmentId?: number;

  /** 用户积分 */
  point?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface VolunteerDto {
  /** 用户Id */
  userId?: number;

  /**  */
  surName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  mobile?: string;

  /**  */
  idNo?: string;

  /**  */
  sex?: string;

  /**  */
  birthday?: Date;

  /**  */
  user?: UserDto;

  /**  */
  status?: VolunteerStatus;

  /** 审核通过时自动生成的唯一编号 */
  volunteerOrgCode?: string;

  /** 志愿者等级
默认为0，未来根据服务时长自动增加 */
  level?: number;

  /**  */
  levelName?: string;

  /** 参加时间 */
  joinTime?: Date;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  isAudited?: boolean;

  /**  */
  totalMinutes?: number;

  /** 服务时长
Gongyila.Standard.CustomDtoMapper */
  totalHours?: number;

  /**  */
  userExtend?: UserExtendDto;

  /**  */
  rejectReason?: string;

  /** 服务次数 */
  serviceCount?: number;

  /** 培训次数 */
  trainCount?: number;

  /** 活动次数 */
  activityCount?: number;

  /** 推荐人Id */
  recommenderUserId?: number;

  /**  */
  recommenderUser?: UserMiniDto;

  /**  */
  id?: number;
}

export interface UserExtendMiniDto {
  /**  */
  user?: UserMiniDto;

  /** 企业职务 */
  companyDuty?: string;

  /** 职务 */
  duty?: string;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /**  */
  address?: string;

  /** 用户积分 */
  point?: number;

  /**  */
  sex?: string;
}

export interface ActivityApplyDto {
  /**  */
  id?: number;

  /** 报名姓名 */
  surname?: string;

  /** 报名手机 */
  phoneNumber?: string;

  /**  */
  organizationUnitId?: number;

  /** 对应的活动Id */
  activityId?: number;

  /**  */
  activity?: ActivityDto;

  /** 报名用户ID
存在导入或 */
  userId?: number;

  /**  */
  applyUser?: UserDto;

  /** 审核通过时更新 */
  approvedTime?: Date;

  /** 用户在前端或管理员在后台取消时更新 */
  cancelTime?: Date;

  /** 审核不通过时更新 */
  sendbackTime?: Date;

  /** 活动报名被退回原因 */
  applyReturnReason?: string;

  /**  */
  status?: ActivityApplyStatus;

  /** 报名时间 */
  applyTime?: Date;

  /** 签到时间 */
  checkInTime?: Date;

  /** 签到次数 */
  checkInTimes?: number;

  /** 总价 */
  totalPrice?: number;

  /** 已支付 */
  totalPaid?: number;

  /** 订单号 */
  billNo?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  isAudited?: boolean;

  /**  */
  auditFlow?: AuditFlowDto;

  /**  */
  currentAuditNodes?: AuditNodeDto[];

  /** 订单号 */
  orderId?: number;

  /** 志愿者编号 */
  volunteerOrgCode?: string;

  /**  */
  volunteer?: VolunteerDto;

  /** 志愿者星级 */
  level?: number;

  /**  */
  minLevelText?: string;

  /**  */
  userExtend?: UserExtendMiniDto;

  /** 性别 */
  sex?: string;

  /** 居住地 */
  place?: string;

  /** 活动次数 */
  days?: number;

  /** 是否允许签到 */
  isAllowCheckIn?: boolean;

  /** 活动时长 */
  totalMinutes?: number;

  /** 是否购买保险 */
  needInsurance?: boolean;

  /** 反馈退回原因 */
  recordReturnReason?: string;

  /**  */
  returnAttachments?: AttachmentDto[];
}

export interface ActivityApplyCreateOrUpdateDto {
  /** 报名姓名 */
  surname?: string;

  /** 报名手机 */
  phoneNumber?: string;

  /**  */
  status?: ApplyStatus;

  /**  */
  organizationUnitId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 对应的活动Id */
  activityId?: number;

  /**  */
  activity?: ActivityDto;

  /** 报名用户ID
存在导入或 */
  userId?: number;

  /**  */
  applyUser?: UserDto;

  /** 审核通过时更新 */
  approvedTime?: Date;

  /** 用户在前端或管理员在后台取消时更新 */
  cancelTime?: Date;

  /** 审核不通过时更新 */
  sendbackTime?: Date;

  /** 活动报名被退回原因 */
  applyReturnReason?: string;

  /** 报名时间 */
  applyTime?: Date;

  /** 总价 */
  totalPrice?: number;

  /**  */
  clientType?: ClientType;

  /**  */
  orderSourceType?: OrderSourceType;

  /** 是否购买保险 */
  needInsurance?: boolean;

  /**  */
  id?: number;
}

export interface ActivityApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ActivityApplyDto[];
}

export interface ExcelRowImportError {
  /**  */
  errorMessage?: string;

  /**  */
  details?: string[];
}

export interface ExcelRowImportResult {
  /**  */
  model?: object;

  /**  */
  success?: boolean;

  /**  */
  error?: ExcelRowImportError;

  /**  */
  rowNumber?: number;

  /**  */
  resultType?: ImportResultType;
}

export interface ImportResult {
  /**  */
  excelFileName?: string;

  /**  */
  importResults?: ExcelRowImportResult[];
}

export interface ActivityApplyPagedResultRequestDto {
  /**  */
  activityId?: number;

  /**  */
  activityTitle?: string;

  /**  */
  applyUserPhone?: string;

  /**  */
  applyUserName?: string;

  /**  */
  isPay?: boolean;

  /**  */
  isPriced?: boolean;

  /**  */
  isOnlyMine?: boolean;

  /** 1:已付款；2:未付款；3:已关闭 */
  specialType?: number;

  /**  */
  status?: ActivityApplyStatus;

  /**  */
  queryStatus?: QueryApplyStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ActivityApplyCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: ActivityApplyCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface ActivityApplyCheckInPostDto {
  /** 签到时间 */
  checkInTime?: Date;

  /**  */
  id?: number;
}

export interface OrderDto {
  /** 订单号 */
  orderNo?: string;

  /** 关联表Id */
  applyId?: number;

  /** 标题 */
  title?: string;

  /** 图片 */
  titleImagePath?: string;

  /**  */
  type?: OrderType;

  /** 订单金额 */
  priceTotal?: number;

  /** 付款金额 */
  payedMoney?: number;

  /** 是否在线支付 */
  isOnlinePayed?: boolean;

  /** 备注 */
  remark?: string;

  /**  */
  status?: OrderStatus;

  /** 支付时间 */
  payedTime?: Date;

  /** 交易号 */
  tradeNo?: string;

  /** 是否回调 */
  isResponse?: boolean;

  /** 回调时间 */
  responseTime?: Date;

  /** 回调结果 */
  resultMessage?: string;

  /** 取消时间 */
  cancelTime?: Date;

  /** 取消原因 */
  cancelReason?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  id?: number;
}

export interface PaymentInputDto {
  /**  */
  tenantId?: number;

  /**  */
  sign_Type?: string;

  /**  */
  payment_Type?: string;

  /**  */
  buyer_Account?: string;

  /**  */
  trade_Status?: string;

  /**  */
  trade_No?: string;

  /**  */
  total_Fee?: number;

  /**  */
  order_No?: string;

  /**  */
  notify_Type?: string;

  /**  */
  notify_Id?: string;

  /**  */
  notify_Time?: string;

  /**  */
  sign?: string;

  /**  */
  sortDict?: object;
}

export interface UserActivityApplyInfoDto {
  /** 报名成功次数 */
  signUpCount?: number;
}

export interface AbpBoolDto {
  /** 结果 */
  result?: boolean;
}

export interface ActivityApplyCheckInRecordDto {
  /** 活动Id */
  activityId?: number;

  /** 活动报名Id */
  activityApplyId?: number;

  /** 用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserDto;

  /** 签到时间 */
  checkInTime?: Date;

  /** 志愿者编号 */
  volunteerOrgCode?: string;

  /** 志愿者星级 */
  level?: number;

  /** 性别 */
  sex?: string;
}

export interface ActivityApplyCheckInRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ActivityApplyCheckInRecordDto[];
}

export interface ActivityApplyDtoListResultDto {
  /**  */
  items?: ActivityApplyDto[];
}

export interface AreaSelectDto {
  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  townId?: number;
}

export interface TownDto {
  /**  */
  areaId?: number;

  /**  */
  townName?: string;

  /**  */
  id?: number;
}

export interface OrganizationUnitDto {
  /** 机构logo */
  logoImageUrl?: string;

  /** 机构名称 */
  displayName?: string;

  /** 机构类型,数据字典Id */
  organizationType?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 地点 */
  place?: string;

  /** 负责人姓名 */
  principalName?: string;

  /** 主要负责人的手机号 */
  mobilePhone?: string;

  /** 主要负责人的邮箱 */
  emailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 合作方银行账号名称 */
  bankAccountName?: string;

  /** 开户行信息 */
  bankName?: string;

  /** 开户账号 */
  bankAccount?: string;

  /** 收件人 */
  receiverName?: string;

  /** 收件地址 */
  receiverAddress?: string;

  /** 收件电话 */
  receiverMobile?: string;

  /** 法人登记证书复印件 */
  certificateImagePath?: string;

  /** 拒绝原因 */
  rejectReason?: string;

  /**  */
  extensionData?: string;

  /**  */
  registerTime?: Date;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ActivityManagementDto {
  /**  */
  organizationUnit?: OrganizationUnitDto;

  /** 所属活动 */
  activityId?: number;

  /**  */
  activity?: ActivityDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface ActivityManagementDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ActivityManagementDto[];
}

export interface ActivityManagementCreateOrUpdateDto {
  /** 活动标题 */
  activityId?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface ActivityRecordDto {
  /**  */
  id?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  activityId?: number;

  /**  */
  activity?: ActivityDto;

  /**  */
  activityApplyId?: number;

  /** 报名用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserMiniDto;

  /** 报名志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 活动感悟 */
  feelings?: string;

  /** 创建时间 */
  creationTime?: Date;

  /** 本次活动签到次数 */
  checkInCount?: number;

  /**  */
  minLevel?: number;

  /**  */
  minLevelText?: string;
}

export interface ActivityRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ActivityRecordDto[];
}

export interface ActivityRecordCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  activityId?: number;

  /**  */
  activityApplyId?: number;

  /** 报名用户Id */
  applyUserId?: number;

  /** 报名志愿者Id */
  volunteerId?: number;

  /** 活动感悟 */
  feelings?: string;

  /**  */
  returnReason?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface ActivityRecordPagedResultRequestDto {
  /** 姓名 */
  surName?: string;

  /** 培训主题 */
  activityTitle?: string;

  /** 志愿者编号 */
  volunteerCode?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ActivityRecordDtoListResultDto {
  /**  */
  items?: ActivityRecordDto[];
}

export interface ActivitySignInRecordDto {
  /**  */
  id?: number;
}

export interface ActivitySignInRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ActivitySignInRecordDto[];
}

export interface ActivitySignInRecordCreateOrUpdateDto {
  /**  */
  id?: number;
}

export interface AlbumDto {
  /** 相册名称 */
  name?: string;

  /** 相册描述 */
  summary?: string;

  /** 封面地址 */
  cover?: string;

  /**  */
  tenantId?: number;

  /**  */
  isShowPortal?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface AlbumCreateUpdateDto {
  /** 相册名称 */
  name?: string;

  /** 相册描述 */
  summary?: string;

  /** 封面地址 */
  cover?: string;

  /**  */
  id?: number;
}

export interface AlbumDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AlbumDto[];
}

export interface AlbumPhotoDto {
  /**  */
  id?: number;

  /** 照片名称 */
  name?: string;

  /** 照片大小(byte) */
  photoSize?: number;

  /**  */
  size?: string;

  /** 照片路径 */
  photoPath?: string;
}

export interface AlbumPhotoDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AlbumPhotoDto[];
}

export interface AlbumPhotoMoveInput {
  /** 目标相册 */
  albumId?: number;

  /**  */
  id?: number;
}

export interface RenameAlbumPhotoInput {
  /** 照片名称 */
  name?: string;

  /**  */
  id?: number;
}

export interface Int32EntityDto {
  /**  */
  id?: number;
}

export interface AlbumPhotoCountDto {
  /** 照片数量 */
  photoCount?: number;

  /**  */
  id?: number;
}

export interface ApplicationPaymentCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  savedAttachments?: number[];

  /** 客商主表Id */
  merchantId?: number;

  /** 客商银行账号表Id */
  merchantBankAccountId?: number;

  /** 标题 */
  title?: string;

  /**  */
  approvalCategory?: ApprovalCategory;

  /** 项目类别 */
  projectCategoryId?: number;

  /** 项目领域 */
  projectDomainId?: number;

  /** 活动分类 */
  activityClassId?: number;

  /** 基金Id */
  fundId?: number;

  /** 基金项目Id */
  fundProjectId?: number;

  /** 请款理由 */
  payReason?: string;

  /**  */
  payCurrencyType?: CurrencyType;

  /** 支付金额 */
  payMoney?: number;

  /** 备注 */
  remark?: string;

  /** 其他项目名称 */
  otherProjectName?: string;

  /** 拟办意见 */
  appropriateReason?: string;

  /**  */
  attachmentStr?: string;

  /**  */
  id?: number;
}

export interface TenantBankAccountDto {
  /**  */
  creatorUser?: UserMiniDto;

  /** 银行账号 */
  bankAccountCode?: string;

  /** 银行名称 */
  bankName?: string;

  /** 户名 */
  bankAccountName?: string;

  /**  */
  id?: number;
}

export interface MerchantDto {
  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 单位名称 */
  unitName?: string;

  /** 单位地址 */
  unitAddress?: string;

  /** 负责人姓名 */
  principalName?: string;

  /** 负责人手机号 */
  principalMobilePhone?: string;

  /** 负责人邮箱 */
  principalEmailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /**  */
  fundId?: number;

  /** 是否推送至NC系统 */
  isSynced?: boolean;

  /** 是否已启用 */
  isEnabled?: boolean;

  /**  */
  id?: number;
}

export interface MerchantBankAccountDto {
  /**  */
  creatorUser?: UserMiniDto;

  /** 客商主表Id */
  merchantId?: number;

  /**  */
  merchant?: MerchantDto;

  /** 银行类型 */
  bankType?: string;

  /**  */
  bankTypePK?: string;

  /**  */
  bankNamePK?: string;

  /** 是否推送至NC系统 */
  isSynced?: boolean;

  /** 是否已启用 */
  isEnabled?: boolean;

  /** 收款人姓名 */
  payeeName?: string;

  /** 收款人电话 */
  payeePhone?: string;

  /** 户名 */
  bankAccountName?: string;

  /** 银行账号 */
  bankAccountCode?: string;

  /** 开户银行名称 */
  bankName?: string;

  /**  */
  id?: number;
}

export interface FundMiniDto {
  /**  */
  name?: string;

  /**  */
  id?: number;
}

export interface FundProjectMiniDto {
  /** 项目标题 */
  name?: string;

  /**  */
  projectType?: FundProjectType;

  /**  */
  id?: number;
}

export interface ApplicationPaymentDto {
  /** 审核流程Id */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  auditFlow?: AuditFlowDto;

  /**  */
  creatorUser?: UserMiniDto;

  /** 基金银行账号表Id */
  tenantBankAccountId?: number;

  /**  */
  tenantBankAccount?: TenantBankAccountDto;

  /** 客商主表Id */
  merchantId?: number;

  /**  */
  merchant?: MerchantDto;

  /** 客商银行账号表Id */
  merchantBankAccountId?: number;

  /**  */
  merchantBankAccount?: MerchantBankAccountDto;

  /** 申请人Id */
  applicantId?: number;

  /**  */
  applicant?: UserMiniDto;

  /** 资料提交人Id */
  operatorId?: number;

  /**  */
  operator?: UserMiniDto;

  /** 基金Id */
  fundId?: number;

  /**  */
  fund?: FundMiniDto;

  /** 基金项目Id */
  fundProjectId?: number;

  /**  */
  fundProject?: FundProjectMiniDto;

  /** 审批编号 */
  approvalNumber?: string;

  /** 提交时间 */
  submitTime?: Date;

  /** 申请人部门Id */
  applicantDeptId?: number;

  /** 标题 */
  title?: string;

  /**  */
  approvalCategory?: ApprovalCategory;

  /** 审批类别 */
  approvalCategoryName?: string;

  /** 项目类别 */
  projectCategoryId?: number;

  /**  */
  projectCategory?: DataDictionaryDto;

  /** 项目领域 */
  projectDomainId?: number;

  /**  */
  projectDomain?: DataDictionaryDto;

  /** 活动分类 */
  activityClassId?: number;

  /**  */
  activityClass?: DataDictionaryDto;

  /** 请款理由 */
  payReason?: string;

  /**  */
  payCurrencyType?: CurrencyType;

  /** 支付金额 */
  payMoney?: number;

  /** 备注 */
  remark?: string;

  /** 发票号码 */
  invoiceNumber?: string;

  /** 发票是否匹配 */
  isInvoiceMatched?: boolean;

  /** 第三方Id */
  thirdPartyId?: string;

  /** 第三方状态 */
  thirdPartyStatus?: string;

  /** 发票数量 */
  invoiceCount?: number;

  /** 已验证的发票数量 */
  verifiedInvoiceCount?: number;

  /**  */
  auditFlowScope?: AuditFlowScope;

  /** 其他项目名称 */
  otherProjectName?: string;

  /** 原因 */
  reason?: string;

  /**  */
  validationStatus?: ApplicationPaymentValidationStatus;

  /** 拟办意见 */
  appropriateReason?: string;

  /** 部门名称 */
  deptName?: string;

  /**  */
  auditStatusStr?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ApplicationPaymentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ApplicationPaymentDto[];
}

export interface ApplicationPaymentInvoiceNumberInput {
  /** 用款申请Id */
  id?: number;

  /** 发票号码 */
  invoiceNumbers?: string[];
}

export interface ConfirmFundingInput {
  /**  */
  id?: number;

  /**  */
  tenantBankAccountId?: number;
}

export interface ApplicationPaymentDtoListResultDto {
  /**  */
  items?: ApplicationPaymentDto[];
}

export interface AreaCreateOrUpdateDto {
  /**  */
  cityId?: number;

  /**  */
  areaName?: string;

  /**  */
  id?: number;
}

export interface AreaDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AreaDto[];
}

export interface UploadResult {
  /**  */
  hostType?: AttachmentHostType;

  /**  */
  attachmentId?: number;

  /**  */
  fileName?: string;

  /**  */
  url?: string;
}

export interface Credentials {
  /**  */
  accessKeyId?: string;

  /**  */
  accessKeySecret?: string;

  /**  */
  expiration?: string;

  /**  */
  securityToken?: string;
}

export interface AssumedRoleUser {
  /**  */
  arn?: string;

  /**  */
  assumedRoleId?: string;
}

export interface AliyunOssStsDto {
  /**  */
  requestId?: string;

  /**  */
  region?: string;

  /**  */
  bucket?: string;

  /**  */
  endpoint?: string;

  /**  */
  fileUrl?: string;

  /**  */
  imageUrl?: string;

  /**  */
  credentials?: Credentials;

  /**  */
  assumedRoleUser?: AssumedRoleUser;
}

export interface AttachmentCreateOrUpdateDto {
  /**  */
  fileName?: string;

  /**  */
  url?: string;

  /** 附件的宿主ID */
  hostId?: string;

  /**  */
  hostType?: AttachmentHostType;

  /** 文件的长度大小(单位：字节） */
  contentLength?: number;

  /** 文件的大小(自动计算的属性) */
  fileSize?: string;

  /**  */
  contentType?: string;

  /**  */
  id?: number;
}

export interface AuditNodeCreateOrEditDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  tenantId?: number;

  /**  */
  auditMethod?: AuditMethod;

  /**  */
  id?: string;
}

export interface AuditFlowCreateOrEditDto {
  /**  */
  title?: string;

  /**  */
  desc?: string;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  type?: AuditFlowType;

  /**  */
  scope?: number;

  /**  */
  key?: string;

  /**  */
  ruleConfig?: string;

  /**  */
  auditNodes?: AuditNodeCreateOrEditDto[];

  /**  */
  id?: string;
}

export interface AuditFlowCreateOrEditDtoGetForEditOutput {
  /**  */
  data?: AuditFlowCreateOrEditDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface AuditFlowDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AuditFlowDto[];
}

export interface AuditFlowUpdateSortPostDto {
  /**  */
  ids?: string[];
}

export interface GuidEntityDto {
  /**  */
  id?: string;
}

export interface ValueSourceInfo {
  /**  */
  source?: string;

  /**  */
  bindValue?: string;

  /**  */
  bindText?: string;
}

export interface AuditFlowSupportRuleDto {
  /**  */
  key?: string;

  /**  */
  displayName?: string;

  /**  */
  valueType?: AuditFlowConditionValueType;

  /**  */
  valueSource?: ValueSourceInfo;
}

export interface AuditFlowDefinitionDetailDto {
  /**  */
  groupName?: string;

  /**  */
  key?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  isRequired?: boolean;

  /**  */
  requireFeature?: string;

  /**  */
  isSupportRules?: boolean;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  auditFlowId?: string;

  /**  */
  ruleConfig?: object;

  /**  */
  supportRules?: AuditFlowSupportRuleDto[];
}

export interface AuditFlowDefinitionListDto {
  /**  */
  groupName?: string;

  /**  */
  details?: AuditFlowDefinitionDetailDto[];
}

export interface AuditFlowCreateOrEditProxyDto {
  /** 标题 */
  title?: string;

  /** 描述 */
  desc?: string;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  type?: AuditFlowType;

  /**  */
  scope?: AuditFlowScope;

  /** Key */
  key?: string;

  /** 条件规则配置 */
  ruleConfig?: string;

  /** 节点 */
  auditNodes?: AuditNodeCreateOrEditDto[];

  /**  */
  id?: string;
}

export interface AuditFlowCreateOrEditProxyDtoGetForEditOutput {
  /**  */
  data?: AuditFlowCreateOrEditProxyDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface AuditNodePreDefineCreateOrEditDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  id?: string;
}

export interface AuditNodePreDefineCreateOrEditDtoGetForEditOutput {
  /**  */
  data?: AuditNodePreDefineCreateOrEditDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface RoleMiniDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  id?: number;
}

export interface AuditNodePreDefineDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  userMini?: UserMiniDto;

  /**  */
  role?: RoleMiniDto;

  /**  */
  id?: string;
}

export interface AuditNodePreDefineDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AuditNodePreDefineDto[];
}

export interface InvoiceDto {
  /** 交款人 */
  donorName?: string;

  /** 交款人类型 */
  donorType?: string;

  /** 收款方式 */
  donateType?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 备注 */
  remark?: string;

  /** 项目名称 */
  projectName?: string;

  /** 数量 */
  number?: number;

  /** 金额 */
  money?: number;

  /** 创建时间 */
  creationTime?: string;

  /** 创建人Id */
  creatorUserId?: number;

  /** 创建人 */
  creatorUserName?: string;

  /**  */
  id?: number;
}

export interface BankNameDto {
  /**  */
  name?: string;

  /**  */
  bankNameCode?: string;

  /**  */
  bankNamePK?: string;

  /**  */
  id?: number;
}

export interface BankNameDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BankNameDto[];
}

export interface BankNameCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  bankNameCode?: string;

  /**  */
  bankNamePK?: string;

  /**  */
  id?: number;
}

export interface DecimalMonthlyItem {
  /**  */
  date?: string;

  /**  */
  value?: number;
}

export interface BankTypeDto {
  /**  */
  name?: string;

  /**  */
  bankTypeCode?: string;

  /**  */
  bankTypePK?: string;

  /**  */
  id?: number;
}

export interface BankTypeDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BankTypeDto[];
}

export interface BankTypeCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  bankTypeCode?: string;

  /**  */
  bankTypePK?: string;

  /**  */
  id?: number;
}

export interface BbsGroupDto {
  /** 租户Id */
  tenantId?: number;

  /** 群组名称 */
  name?: string;

  /** 群组类型ID */
  groupTypeId?: number;

  /**  */
  groupType?: DataDictionaryDto;

  /** 群组简介 */
  summary?: string;

  /**  */
  status?: GroupStatus;

  /** 帖子数量 */
  postCount?: number;

  /** 人员数量 */
  userCount?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 封面图 */
  titleImagePath?: string;

  /**  */
  creatorUser?: UserDto;

  /** 判断当前登录用户是否加入小组，未登录时默认为false */
  isJoined?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface BbsGroupDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BbsGroupDto[];
}

export interface BbsGroupCreateOrUpdateDto {
  /** 群组名称 */
  name?: string;

  /** 群组类型Id */
  groupTypeId?: number;

  /** 群组简介 */
  summary?: string;

  /**  */
  status?: GroupStatus;

  /** 帖子数量 */
  postCount?: number;

  /** 人员数量 */
  userCount?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 封面图 */
  titleImagePath?: string;

  /**  */
  id?: number;
}

export interface BbsGroupCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: BbsGroupCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface BbsGroupUserDto {
  /** 群组Id */
  groupId?: number;

  /**  */
  bbsGroup?: BbsGroupDto;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /**  */
  status?: GroupStatus;

  /**  */
  creatorUser?: UserDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface BbsGroupUserCreateOrUpdateDto {
  /** 群组Id */
  groupId?: number;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  status?: GroupStatus;

  /**  */
  id?: number;
}

export interface BbsGroupUserDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BbsGroupUserDto[];
}

export interface DataDictionary {
  /**  */
  tenantId?: number;

  /**  */
  key?: string;

  /**  */
  displayName?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface BbsPostCreateOrUpdateDto {
  /** 标题 */
  title?: string;

  /** 主贴类型ID */
  postTypeId?: number;

  /**  */
  postType?: DataDictionary;

  /** 内容 */
  content?: string;

  /** 群组ID */
  groupId?: number;

  /**  */
  group?: BbsGroupDto;

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface BbsPostDto {
  /** 评论人 */
  creatorUserId?: number;

  /**  */
  creatorUser?: UserDto;

  /** 标题 */
  title?: string;

  /** 内容 */
  content?: string;

  /** 主贴类型ID */
  postTypeId?: number;

  /**  */
  postType?: DataDictionaryDto;

  /** 群组ID */
  groupId?: number;

  /**  */
  group?: BbsGroupDto;

  /** 是否置顶 */
  isTop?: boolean;

  /** 置顶时间 */
  topTime?: Date;

  /** 点赞数量 */
  thumbsUpCount?: number;

  /** 最新回复时间 */
  lastCommentTime?: Date;

  /**  */
  creationTime?: Date;

  /** 附件列表，仅对分页返回结果有效 */
  fileList?: AttachmentDto[];

  /** 评论数量 */
  commentCount?: number;

  /** 志愿者等级 */
  level?: number;

  /**  */
  id?: number;
}

export interface BbsPostDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BbsPostDto[];
}

export interface BbsPostCommentDto {
  /** 评论人 */
  creatorUserId?: number;

  /**  */
  creatorUser?: UserDto;

  /** 主贴ID */
  postId?: number;

  /**  */
  post?: BbsPostDto;

  /** 评论Id */
  parentId?: number;

  /**  */
  parent?: BbsPostCommentDto;

  /** 评论回复内容 */
  content?: string;

  /** 回复数量 */
  replyCount?: number;

  /** 点赞数量 */
  thumbsUpCount?: number;

  /**  */
  creationTime?: Date;

  /**  */
  replyList?: BbsPostCommentDto[];

  /** 不用传值，前端使用 */
  replyContent?: string;

  /**  */
  id?: number;
}

export interface BbsPostCommentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BbsPostCommentDto[];
}

export interface BbsPostCommentCreateOrUpdateDto {
  /** 主贴ID */
  postId?: number;

  /**  */
  post?: BbsPostDto;

  /** 评论Id */
  parentId?: number;

  /**  */
  parent?: BbsPostCommentDto;

  /** 评论回复内容 */
  content?: string;

  /** 回复数量 */
  replyCount?: number;

  /**  */
  id?: number;
}

export interface FamilyMembersInfoDto {
  /** 姓名 */
  name?: string;

  /** 称谓 */
  appellation?: string;

  /** 出生年月 */
  birthDate?: Date;

  /** 健康情况 */
  health?: string;

  /** 职业 */
  occupation?: string;

  /** 年收入 */
  annualIncome?: number;

  /**  */
  id?: string;
}

export interface BeneficiaryDto {
  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  id?: number;
}

export interface BeneficiaryCreateOrUpdateDto {
  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  id?: number;
}

export interface BeneficiaryDtoListResultDto {
  /**  */
  items?: BeneficiaryDto[];
}

export interface BeneficiaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BeneficiaryDto[];
}

export interface ProjectDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  displayName?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  titleImagePath?: string;

  /**  */
  workflowStep?: WorkflowOfProjectByExternal;

  /**  */
  id?: number;
}

export interface ProjectServiceItemDto {
  /**  */
  sequence?: number;

  /**  */
  serviceTime?: Date;

  /**  */
  serviceMethod?: string;

  /**  */
  serviceContent?: string;

  /**  */
  target?: string;

  /**  */
  completeStatus?: ProjectServiceRecordCompleteStatus;

  /**  */
  remark?: string;

  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  serviceMethods?: string[];

  /**  */
  id?: number;
}

export interface ProjectServiceRecordDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  serviceRecordType?: ServiceRecordType;

  /**  */
  beneficiaryId?: number;

  /**  */
  beneficiary?: BeneficiaryDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  schoolId?: number;

  /**  */
  school?: OrganizationUnitDto;

  /**  */
  extensionData?: string;

  /**  */
  projectBeneficiaryMappingId?: number;

  /**  */
  simpleDetail?: ProjectServiceItemDto;

  /**  */
  serviceItems?: ProjectServiceItemDto[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface ProjectServiceRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectServiceRecordDto[];
}

export interface ProjectServiceItemCreateOrUpdateDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  tenantId?: number;

  /**  */
  serviceTime?: Date;

  /**  */
  serviceMethod?: string;

  /**  */
  serviceMethods?: string[];

  /**  */
  serviceContent?: string;

  /**  */
  target?: string;

  /**  */
  completeStatus?: ProjectServiceRecordCompleteStatus;

  /**  */
  remark?: string;

  /**  */
  savedAttachments?: number[];
}

export interface ProjectServiceRecordCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /**  */
  serviceRecordType?: ServiceRecordType;

  /**  */
  beneficiaryId?: number;

  /**  */
  schoolId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  extensionData?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  projectBeneficiaryMappingId?: number;

  /**  */
  serviceItems?: ProjectServiceItemCreateOrUpdateDto[];

  /**  */
  extensionObject?: object;

  /**  */
  formId?: number;

  /**  */
  id?: number;
}

export interface ProjectServiceRecordPagedResultRequestDto {
  /**  */
  serviceRecordType?: ServiceRecordType;

  /**  */
  beneficiaryName?: string;

  /**  */
  schoolName?: string;

  /**  */
  projectName?: string;

  /**  */
  projectId?: number;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ProjectServiceRecordDtoListResultDto {
  /**  */
  items?: ProjectServiceRecordDto[];
}

export interface CapitalExpenseRecordDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 项目类型 */
  projectType?: string;

  /** 项目类型名称 */
  projectTypeName?: string;

  /** 项目Id（-1为待分摊） */
  projectId?: number;

  /** 内部项目预算子项目内容 */
  internalProjectBudgetItemId?: string;

  /** 三级科目 */
  itemName?: string;

  /** 摘要 */
  summary?: string;

  /** 本次支付金额（元） */
  money?: number;

  /** 备注 */
  remark?: string;

  /** 是否超出预算(该项目该三级科目已支付金额加本次支付金额是否超出预算金额) */
  isOver?: boolean;

  /**  */
  id?: string;
}

export interface CapitalExpenseRecordCreateOrUpdateDto {
  /** 收款单位 */
  payeeName?: string;

  /** 开户银行 */
  payeeBankName?: string;

  /** 开户账号 */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /** 付款账号（数据字典，多选一） */
  payBankAccount?: string;

  /** 合同编号 */
  contractNo?: string;

  /** 合同总金额（元） */
  contractTotalMoney?: number;

  /** 累计已支付金额（元） */
  paidTotalMoney?: number;

  /** 本次支付合计金额（元） */
  totalMoney?: number;

  /** 剩余待分摊总金额（元） */
  nonApportionTotalMoney?: number;

  /**  */
  details?: CapitalExpenseRecordDetailCreateOrUpdateDto[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProjectFullInfoDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  displayName?: string;

  /**  */
  year?: number;

  /**  */
  description?: string;

  /**  */
  projectTypes?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  projectSeriesId?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  isInternal?: boolean;

  /**  */
  progressReportDate?: Date;

  /**  */
  summaryReportDate?: Date;

  /**  */
  workflowStep?: number;

  /**  */
  projectResult?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /**  */
  id?: number;
}

export interface CapitalExpenseRecordDetailDto {
  /**  */
  sequence?: number;

  /**  */
  capitalExpenseRecordId?: number;

  /** 项目类型 */
  projectType?: string;

  /** 项目类型名称 */
  projectTypeName?: string;

  /** 项目Id（-1为待分摊） */
  projectId?: number;

  /**  */
  project?: ProjectFullInfoDto;

  /** 内部项目预算子项目内容 */
  internalProjectBudgetItemId?: string;

  /** 三级科目 */
  itemName?: string;

  /** 摘要 */
  summary?: string;

  /** 本次支付金额（元） */
  money?: number;

  /** 备注 */
  remark?: string;

  /** 是否超出预算(该项目该三级科目已支付金额加本次支付金额是否超出预算金额) */
  isOver?: boolean;

  /**  */
  totalAmount?: number;

  /**  */
  id?: string;
}

export interface CapitalExpenseRecordDto {
  /** 收款单位 */
  payeeName?: string;

  /** 开户银行 */
  payeeBankName?: string;

  /** 开户账号 */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /** 付款账号（数据字典，多选一） */
  payBankAccount?: string;

  /** 合同编号 */
  contractNo?: string;

  /** 合同总金额（元） */
  contractTotalMoney?: number;

  /** 累计已支付金额（元） */
  paidTotalMoney?: number;

  /** 本次支付合计金额（元） */
  totalMoney?: number;

  /** 剩余待分摊总金额（元） */
  nonApportionTotalMoney?: number;

  /**  */
  details?: CapitalExpenseRecordDetailDto[];

  /**  */
  payDate?: Date;

  /**  */
  simpleDetail?: CapitalExpenseRecordDetailDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  version?: number;

  /**  */
  preVersionId?: number;

  /**  */
  changeState?: ChangeState;

  /**  */
  id?: number;
}

export interface ContractNoInformationDto {
  /**  */
  contractTotalMoney?: number;

  /**  */
  paidTotalMoney?: number;
}

export interface InternalBudgetItemAndUseRecordDto {
  /**  */
  projectId?: number;

  /** 序列(显示顺序） */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /**  */
  actualMoney?: number;

  /**  */
  id?: string;
}

export interface CapitalExpenseRecordPagedResultRequestDto {
  /** 收款单位 */
  payeeName?: string;

  /** 开户银行 */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /** 合同编号 */
  contractNo?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 截止日期 */
  endDate?: Date;

  /**  */
  projectName?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ProjectReportDto {
  /**  */
  totalAmount?: number;

  /**  */
  projectName?: string;

  /**  */
  year?: number;

  /**  */
  projectSeries?: string;

  /**  */
  projectType?: string;

  /**  */
  projectTypeName?: string;

  /**  */
  projectId?: number;
}

export interface ProjectReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectReportDto[];
}

export interface ProjectDetailReprotDto {
  /**  */
  money?: number;

  /**  */
  itemName?: string;

  /**  */
  remark?: string;

  /**  */
  summary?: string;

  /**  */
  payeeName?: string;

  /**  */
  payeeBankName?: string;

  /**  */
  payeeBankAccount?: string;

  /**  */
  contractNo?: string;

  /**  */
  payDate?: Date;

  /**  */
  totalAmount?: number;

  /**  */
  projectName?: string;

  /**  */
  year?: number;

  /**  */
  projectSeries?: string;

  /**  */
  projectType?: string;

  /**  */
  projectTypeName?: string;

  /**  */
  projectId?: number;
}

export interface ProjectDetailReprotDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectDetailReprotDto[];
}

export interface CapitalExpenseRecord {
  /**  */
  tenantId?: number;

  /**  */
  payeeName?: string;

  /**  */
  payeeBankName?: string;

  /**  */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /**  */
  payBankAccount?: string;

  /**  */
  contractNo?: string;

  /**  */
  contractTotalMoney?: number;

  /**  */
  paidTotalMoney?: number;

  /**  */
  payDate?: Date;

  /**  */
  totalMoney?: number;

  /**  */
  nonApportionTotalMoney?: number;

  /**  */
  details?: CapitalExpenseRecordDetail[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  enableRestartAudit?: boolean;

  /**  */
  creatorUser?: User;

  /**  */
  version?: number;

  /**  */
  preVersionId?: number;

  /**  */
  changeState?: ChangeState;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProjectSeries {
  /**  */
  tenantId?: number;

  /**  */
  title?: string;

  /**  */
  titleImagePath?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  sequence?: number;

  /**  */
  isDefault?: boolean;

  /**  */
  ignored?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Province {
  /**  */
  provinceName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface City {
  /**  */
  cityName?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: Province;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface Area {
  /**  */
  cityId?: number;

  /**  */
  city?: City;

  /**  */
  areaName?: string;

  /**  */
  id?: number;
}

export interface Town {
  /**  */
  areaId?: number;

  /**  */
  area?: Area;

  /**  */
  townName?: string;

  /**  */
  id?: number;
}

export interface OrganizationUnit {
  /**  */
  tenantId?: number;

  /**  */
  parent?: OrganizationUnit;

  /**  */
  parentId?: number;

  /**  */
  code?: string;

  /**  */
  displayName?: string;

  /**  */
  children?: OrganizationUnit[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface AgileOrganizationUnit {
  /**  */
  organizationType?: number;

  /**  */
  logoImageUrl?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: Province;

  /**  */
  cityId?: number;

  /**  */
  city?: City;

  /**  */
  areaId?: number;

  /**  */
  area?: Area;

  /**  */
  townId?: number;

  /**  */
  town?: Town;

  /**  */
  place?: string;

  /**  */
  principalName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /**  */
  contactName?: string;

  /**  */
  contactMobilePhone?: string;

  /**  */
  contactEmailAddress?: string;

  /**  */
  creditCode?: string;

  /**  */
  bankAccountName?: string;

  /**  */
  bankName?: string;

  /**  */
  bankAccount?: string;

  /**  */
  receiverName?: string;

  /**  */
  receiverAddress?: string;

  /**  */
  receiverMobile?: string;

  /**  */
  certificateImagePath?: string;

  /**  */
  extensionData?: string;

  /**  */
  registerTime?: Date;

  /**  */
  extensionObject?: object;

  /**  */
  creatorUser?: User;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  enableRestartAudit?: boolean;

  /**  */
  partnerInviteId?: number;

  /**  */
  rejectReason?: string;

  /**  */
  tenantId?: number;

  /**  */
  parent?: OrganizationUnit;

  /**  */
  parentId?: number;

  /**  */
  code?: string;

  /**  */
  displayName?: string;

  /**  */
  children?: OrganizationUnit[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Project {
  /**  */
  displayName?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  year?: number;

  /**  */
  description?: string;

  /**  */
  projectTypes?: string;

  /**  */
  titleImagePath?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  projectSeriesId?: number;

  /**  */
  projectSeries?: ProjectSeries;

  /**  */
  isInternal?: boolean;

  /**  */
  workflowStep?: number;

  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: AgileOrganizationUnit;

  /**  */
  progressReportDate?: Date;

  /**  */
  summaryReportDate?: Date;

  /**  */
  ignored?: boolean;

  /**  */
  projectResult?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CapitalExpenseRecordDetail {
  /**  */
  capitalExpenseRecordId?: number;

  /**  */
  capitalExpenseRecord?: CapitalExpenseRecord;

  /**  */
  sequence?: number;

  /**  */
  projectType?: string;

  /**  */
  projectTypeName?: string;

  /**  */
  projectId?: number;

  /**  */
  project?: Project;

  /**  */
  internalProjectBudgetItemId?: string;

  /**  */
  itemName?: string;

  /**  */
  summary?: string;

  /**  */
  money?: number;

  /**  */
  remark?: string;

  /**  */
  isOver?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: string;
}

export interface CapitalExpenseRecordDtoListResultDto {
  /**  */
  items?: CapitalExpenseRecordDto[];
}

export interface CapitalExpenseRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CapitalExpenseRecordDto[];
}

export interface CapitalIncomeRecordFromDonationDetailCreateOrUpdateDto {
  /**  */
  id?: string;

  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /** 项目Id */
  projectId?: number;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 备注 */
  remark?: string;
}

export interface CapitalIncomeRecordFromDonationCreateOrUpdateDto {
  /** 捐赠时间 */
  incomeDate?: Date;

  /** 捐赠方名称 */
  donatorName?: string;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  details?: CapitalIncomeRecordFromDonationDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromDonationDetailDto {
  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /** 项目Id */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 备注 */
  remark?: string;

  /**  */
  id?: string;
}

export interface CapitalIncomeRecordFromDonationDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 捐赠时间 */
  incomeDate?: Date;

  /** 捐赠方名称 */
  donatorName?: string;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  simpleDetail?: CapitalIncomeRecordFromDonationDetailDto;

  /**  */
  details?: CapitalIncomeRecordFromDonationDetailDto[];

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromDonationPagedResultRequestDto {
  /**  */
  incomeDate?: Date;

  /**  */
  incomeDateStart?: Date;

  /**  */
  incomeDateEnd?: Date;

  /**  */
  donatorName?: string;

  /**  */
  incomeType?: PayType;

  /**  */
  bankAccount?: string;

  /**  */
  totalIncomeMoney?: number;

  /**  */
  totalIncomeMoneyStart?: number;

  /**  */
  totalIncomeMoneyEnd?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /**  */
  currencyType?: CurrencyType;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface CapitalIncomeRecordFromDonationDtoListResultDto {
  /**  */
  items?: CapitalIncomeRecordFromDonationDto[];
}

export interface CapitalIncomeRecordFromDonationDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CapitalIncomeRecordFromDonationDto[];
}

export interface CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto {
  /**  */
  id?: string;

  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /**  */
  incomeCategory?: IncomeCategory;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 摘要 */
  remark?: string;
}

export interface CapitalIncomeRecordFromOtherCreateOrUpdateDto {
  /** 收入时间 */
  incomeDate?: Date;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  details?: CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromOtherDetailDto {
  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /**  */
  incomeCategory?: IncomeCategory;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 摘要 */
  remark?: string;

  /**  */
  id?: string;
}

export interface CapitalIncomeRecordFromOtherDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 收入时间 */
  incomeDate?: Date;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  simpleDetail?: CapitalIncomeRecordFromOtherDetailDto;

  /**  */
  details?: CapitalIncomeRecordFromOtherDetailDto[];

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromOtherPagedResultRequestDto {
  /**  */
  incomeDate?: Date;

  /**  */
  incomeDateStart?: Date;

  /**  */
  incomeDateEnd?: Date;

  /**  */
  incomeType?: PayType;

  /**  */
  bankAccount?: string;

  /**  */
  totalIncomeMoney?: number;

  /**  */
  totalIncomeMoneyStart?: number;

  /**  */
  totalIncomeMoneyEnd?: number;

  /**  */
  incomeCategory?: IncomeCategory;

  /**  */
  currencyType?: CurrencyType;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface CapitalIncomeRecordFromOtherDtoListResultDto {
  /**  */
  items?: CapitalIncomeRecordFromOtherDto[];
}

export interface CapitalIncomeRecordFromOtherDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CapitalIncomeRecordFromOtherDto[];
}

export interface CityCreateOrUpdateDto {
  /**  */
  cityName?: string;

  /**  */
  provinceId?: number;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface CityDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CityDto[];
}

export interface CmsAnchorCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /** 锚点内容的建议宽度 */
  width?: number;

  /** 锚点内容的建议高度 */
  height?: number;

  /**  */
  id?: number;
}

export interface CmsAnchorDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /** 锚点内容的建议宽度 */
  width?: number;

  /** 锚点内容的建议高度 */
  height?: number;

  /**  */
  id?: number;
}

export interface CmsAnchorDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsAnchorDto[];
}

export interface CmsCategoryCreateOrUpdateDto {
  /**  */
  categoryCode?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  mobileTitleImagePath?: string;

  /**  */
  type?: CmsCategoryType;

  /**  */
  redirectUrl?: string;

  /**  */
  redirectUrlForWeixinMiniProgram?: string;

  /** 破格图片 */
  unconventionalImagePath?: string;

  /** 副标题 */
  contentAlias?: string;

  /** 栏目简介 */
  shortDesc?: string;

  /**  */
  id?: number;
}

export interface CmsCategoryDto {
  /**  */
  categoryCode?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  mobileTitleImagePath?: string;

  /**  */
  type?: CmsCategoryType;

  /**  */
  redirectUrl?: string;

  /**  */
  redirectUrlForWeixinMiniProgram?: string;

  /** 破格图片 */
  unconventionalImagePath?: string;

  /** 副标题 */
  contentAlias?: string;

  /**  */
  shortDesc?: string;

  /**  */
  isHidden?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CmsCategoryUpdateSortDto {
  /** Id */
  ids?: number[];
}

export interface CmsCategoryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsCategoryDto[];
}

export interface CmsContentDetailDto {
  /** 文章内容 */
  description?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  status?: CmsContentStatus;

  /**  */
  title?: string;

  /**  */
  isTop?: boolean;

  /** 当被设置置顶后，呈现的显示顺序 */
  topDisplayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /**  */
  cmsCategory?: CmsCategoryDto;

  /**  */
  cmsCategoryId?: number;

  /**  */
  author?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  source?: string;

  /**  */
  summary?: string;

  /** 阅读数量 */
  readCount?: number;

  /** 点赞数量 */
  praisedCount?: number;

  /**  */
  customlinks?: string;

  /**  */
  attachments?: AttachmentDto[];

  /** 拒绝理由 */
  rejectReason?: string;

  /**  */
  displayName?: string;

  /** 视频信息 */
  videoPath?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CmsContentCreateOrUpdateDto {
  /**  */
  title?: string;

  /**  */
  summary?: string;

  /**  */
  titleImageUrl?: string;

  /**  */
  cmsCategoryId?: number;

  /**  */
  author?: string;

  /** 发布时间 */
  publishTime?: string;

  /** 是否直接发布 */
  isPublish?: boolean;

  /** 内容来源 */
  source?: string;

  /**  */
  description?: string;

  /**  */
  savedAttachments?: number[];

  /** 阅读数量 */
  readCount?: number;

  /** 点赞数量 */
  praisedCount?: number;

  /** 基金Id */
  fundID?: number;

  /** 基金项目Id */
  fundProjectID?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 是否志愿风采栏目下的内容 */
  isVolunteerStyle?: boolean;

  /** 视频信息 */
  videoPath?: string;

  /**  */
  id?: number;
}

export interface CmsContentDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  status?: CmsContentStatus;

  /**  */
  title?: string;

  /**  */
  isTop?: boolean;

  /** 当被设置置顶后，呈现的显示顺序 */
  topDisplayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /**  */
  cmsCategory?: CmsCategoryDto;

  /**  */
  cmsCategoryId?: number;

  /**  */
  author?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  source?: string;

  /**  */
  summary?: string;

  /** 阅读数量 */
  readCount?: number;

  /** 点赞数量 */
  praisedCount?: number;

  /**  */
  customlinks?: string;

  /**  */
  attachments?: AttachmentDto[];

  /** 拒绝理由 */
  rejectReason?: string;

  /**  */
  displayName?: string;

  /** 视频信息 */
  videoPath?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CmsContentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsContentDto[];
}

export interface CmsContentDtoListResultDto {
  /**  */
  items?: CmsContentDto[];
}

export interface CmsFriendLinkCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  link?: string;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  logo?: string;

  /** 类型Id（数据字典） */
  typeId?: number;

  /**  */
  id?: number;
}

export interface CmsFriendLinkDto {
  /**  */
  name?: string;

  /**  */
  link?: string;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  logo?: string;

  /** 类型Id（数据字典） */
  typeId?: number;

  /**  */
  type?: DataDictionaryDto;

  /**  */
  id?: number;
}

export interface CmsFriendLinkDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsFriendLinkDto[];
}

export interface CollectWaterDto {
  /** 单据编号 */
  billNo?: string;

  /** 单据日期 */
  billDate?: Date;

  /** 生效状态 */
  validStatus?: string;

  /** 交易对象类型 */
  tradeObjectType?: string;

  /** 收款交易类型 */
  collectionTradeType?: string;

  /** 付款银行账号 */
  paymentBankAccount?: string;

  /** 收支项目 */
  donateProject?: string;

  /** 结算方式 */
  paymentType?: string;

  /** 摘要 */
  summary?: string;

  /** 组织本币 */
  organizeLocalMoney?: number;

  /** 客户 */
  customer?: string;

  /** 收款原币金额 */
  originalMoney?: number;

  /** 币种 */
  currency?: string;

  /** 单据状态 */
  billStatus?: string;

  /** 收款银行账户 */
  receivingBankAccount?: string;

  /** 组织本币汇率 */
  organizeLocalExchangeRate?: number;

  /**  */
  status?: CollectWaterStatus;

  /** 关联捐赠记录Id */
  fundIncomeRecordId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface CollectWaterDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CollectWaterDto[];
}

export interface CollectWaterCreateOrUpdateDto {
  /** 单据编号 */
  billNo?: string;

  /** 单据日期 */
  billDate?: Date;

  /** 生效状态 */
  validStatus?: string;

  /** 交易对象类型 */
  tradeObjectType?: string;

  /** 收款交易类型 */
  collectionTradeType?: string;

  /** 付款银行账号 */
  paymentBankAccount?: string;

  /** 收支项目 */
  donateProject?: string;

  /** 结算方式 */
  paymentType?: string;

  /** 摘要 */
  summary?: string;

  /** 组织本币 */
  organizeLocalMoney?: number;

  /** 客户 */
  customer?: string;

  /** 收款原币金额 */
  originalMoney?: number;

  /** 币种 */
  currency?: string;

  /** 单据状态 */
  billStatus?: string;

  /** 收款银行账户 */
  receivingBankAccount?: string;

  /** 组织本币汇率 */
  organizeLocalExchangeRate?: number;

  /**  */
  id?: number;
}

export interface CommunityDto {
  /**  */
  displayName?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  townId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  city?: CityDto;

  /**  */
  area?: AreaDto;

  /**  */
  town?: TownDto;

  /** 社区简介 */
  summary?: string;

  /** 社区介绍 */
  description?: string;

  /** 社区居委会地址 */
  neighborhoodCommitteeAddress?: string;

  /** 社区联系人 */
  contactName?: string;

  /** 社区联系人电话 */
  contactPhone?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CommunityDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CommunityDto[];
}

export interface CommunityCreateOrUpdateDto {
  /**  */
  displayName?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  townId?: number;

  /** 社区简介 */
  summary?: string;

  /** 社区介绍 */
  description?: string;

  /** 社区居委会地址 */
  neighborhoodCommitteeAddress?: string;

  /** 社区联系人 */
  contactName?: string;

  /** 社区联系人电话 */
  contactPhone?: string;

  /**  */
  id?: number;
}

export interface CommunityUserCreateDto {
  /** 所属社区。如果当前登录用户是社区工作人员，则不需要填写 */
  communityId?: number;

  /** 密码 */
  password?: string;

  /** 手机号，也作为登录账号使用 */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /** 姓名 */
  realName?: string;
}

export interface CommunityUserUpdateDto {
  /**  */
  id?: number;

  /**  */
  mobilePhone?: string;

  /**  */
  realName?: string;

  /**  */
  emailAddress?: string;
}

export interface CommunityUserResetPasswordDto {
  /**  */
  id?: number;

  /**  */
  newPassword?: string;
}

export interface ChangeUiThemeInput {
  /**  */
  theme?: string;
}

export interface DonorDto {
  /** 用户Id */
  userId?: number;

  /** 用户名 */
  surname?: string;

  /** 手机号 */
  phoneNumber?: string;

  /** 邮箱 */
  emailAddress?: string;

  /** 性别 */
  sex?: string;

  /** 生日 */
  birthday?: Date;

  /** 年龄 */
  age?: number;

  /** 注册时间 */
  creationTime?: Date;

  /** 累计捐赠次数 */
  firCount?: number;

  /** 累计捐赠金额 */
  firMoneyAmountSum?: number;
}

export interface DonorDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DonorDto[];
}

export interface CustomFormLayoutDetailCreateOrUpdateDto {
  /** 显示顺序 */
  sequence?: number;

  /**  */
  parentId?: string;

  /**  */
  elementName?: string;

  /**  */
  elementContent?: string;

  /**  */
  textAlign?: string;

  /**  */
  elementAttributes?: object;

  /**  */
  id?: string;
}

export interface CustomFieldDataSourceCreateOrUpdateDto {
  /** 绑定的要显示的名称 */
  bindDisplayName?: string;

  /** 绑定的值 */
  bindValue?: string;

  /**  */
  dataSourceType?: CustomFormDataSourceType;

  /**  */
  fieldId?: string;

  /**  */
  dataSource?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldChooseValueCreateOrUpdateDto {
  /**  */
  displayName?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldCreateOrUpdateDto {
  /** 是否为动态字段 */
  isDynamic?: boolean;

  /**  */
  fieldName?: string;

  /**  */
  fieldDisplayName?: string;

  /**  */
  placeholder?: string;

  /**  */
  elementType?: string;

  /** 是否必填项 */
  required?: boolean;

  /**  */
  fieldValidators?: string[];

  /** 所在的布局 */
  layoutId?: string;

  /**  */
  dataSource?: CustomFieldDataSourceCreateOrUpdateDto;

  /** 可用来选择的自定义的列表值(仅当DataSource是CustomValues时，才有值) */
  chooseValues?: CustomFormFieldChooseValueCreateOrUpdateDto[];

  /**  */
  id?: string;
}

export interface CustomFormCreateOrUpdateDto {
  /**  */
  hostType?: CustomFormType;

  /** 宿主ID，可为空。当为空时，表示针对某类通用的。 */
  hostId?: string;

  /**  */
  title?: string;

  /**  */
  layout?: CustomFormLayoutDetailCreateOrUpdateDto[];

  /**  */
  fields?: CustomFormFieldCreateOrUpdateDto[];

  /**  */
  id?: number;
}

export interface CustomFormLayoutDetailDto {
  /**  */
  customFormId?: number;

  /** 显示顺序 */
  sequence?: number;

  /**  */
  parentId?: string;

  /**  */
  elementName?: string;

  /**  */
  elementContent?: string;

  /**  */
  textAlign?: string;

  /**  */
  elementAttributes?: object;

  /**  */
  id?: string;
}

export interface CustomFieldDataSourceDto {
  /**  */
  fieldId?: string;

  /** 绑定的要显示的名称 */
  bindDisplayName?: string;

  /** 绑定的值 */
  bindValue?: string;

  /**  */
  dataSourceType?: CustomFormDataSourceType;

  /**  */
  dataSource?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldChooseValueDto {
  /**  */
  displayName?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldDto {
  /**  */
  customFormId?: number;

  /** 是否为动态字段 */
  isDynamic?: boolean;

  /**  */
  fieldName?: string;

  /**  */
  fieldDisplayName?: string;

  /**  */
  placeholder?: string;

  /**  */
  elementType?: string;

  /** 是否必填项 */
  required?: boolean;

  /**  */
  fieldValidators?: string[];

  /** 所在的布局 */
  layoutId?: string;

  /**  */
  dataSource?: CustomFieldDataSourceDto;

  /** 可用来选择的自定义的列表值(仅当DataSource是CustomValues时，才有值) */
  chooseValues?: CustomFormFieldChooseValueDto[];

  /**  */
  id?: string;
}

export interface CustomFormDto {
  /**  */
  hostType?: CustomFormType;

  /** 宿主ID，可为空。当为空时，表示针对某类通用的。 */
  hostId?: string;

  /**  */
  title?: string;

  /**  */
  layout?: CustomFormLayoutDetailDto[];

  /**  */
  fields?: CustomFormFieldDto[];

  /**  */
  id?: number;
}

export interface CustomFormCopyInput {
  /** 宿主ID，可为空。当为空时，表示针对某类通用的。 */
  hostId?: string;

  /**  */
  id?: number;
}

export interface CustomFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CustomFormDto[];
}

export interface InvoiceInformationDto {
  /** 订单ID */
  orderID?: string;

  /** 付款成功后创建的记录ID或手动新增的收入记录ID */
  recordID?: number;

  /** 抬头 */
  title?: string;

  /** 收件人姓名 */
  name?: string;

  /** 收件人电话 */
  telephone?: string;

  /** 收件人地址 */
  address?: string;

  /**  */
  status?: ValidStatus;

  /** 税号 */
  taxNum?: string;

  /** 发票内容 */
  detailInfo?: string;

  /** 发票金额 */
  taxAmount?: number;

  /**  */
  type?: ElectronicInvoiceType;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 邮箱 */
  email?: string;

  /**  */
  id?: number;
}

export interface CycleDonateConfigDto {
  /**  */
  tenantId?: number;

  /**  */
  user?: UserMiniDto;

  /**  */
  invoice?: InvoiceInformationDto;

  /** 用户Id */
  userId?: number;

  /**  */
  donationType?: DonationType;

  /**  */
  donationCycle?: DonationCycle;

  /** 捐赠时间 */
  donationTime?: Date;

  /** 固定捐助天 */
  donorDay?: number;

  /** 捐赠人 */
  displayName?: string;

  /** 推荐人 */
  referrer?: string;

  /**  */
  donorType?: DonorType;

  /** 联系电话 */
  mobileTelephone?: string;

  /** 捐赠金额 */
  donationAmount?: number;

  /** 捐赠留言 */
  leaveMessage?: string;

  /** 订单Id */
  contractOrderId?: string;

  /** 发票Id */
  invoiceId?: number;

  /**  */
  donorItemType?: DonorItemType;

  /** 捐赠意向Id */
  donorItemId?: number;

  /** 捐赠协议序列号(签约银行返回) */
  donorSignSn?: string;

  /** 平台与天付宝确认签约订单号，关联发票订单Id */
  spBillNo?: string;

  /** 国采订单号 */
  listId?: string;

  /** 是否开启 */
  isOpen?: boolean;

  /** 是否已确认 */
  isConfirmed?: boolean;

  /** 是否为临时 */
  isTemp?: boolean;

  /**  */
  userType?: UserType;

  /** 累计次数 */
  cumulativeTimes?: number;

  /** 累计捐款金额 */
  accumulatedDonationAmount?: number;

  /** 捐赠类型 */
  donationTypeName?: string;

  /** 捐赠周期 */
  donationCycleName?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CycleDonateConfigDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CycleDonateConfigDto[];
}

export interface CycleDonateChangeStatusInput {
  /** Id */
  id?: number;

  /**  */
  donorItemType?: DonorItemType;

  /** 捐赠意向Id */
  donorItemId?: number;
}

export interface CycleDonateChangeCycleInput {
  /** Id */
  id?: number;

  /**  */
  donationCycle?: DonationCycle;

  /** 捐赠时间 */
  donationTime?: Date;

  /** 固定捐助天 */
  donorDay?: number;

  /** 捐赠金额 */
  donationAmount?: number;
}

export interface ActivityReportDto {
  /** 月份 */
  months?: number[];

  /** 普通活动 */
  generalActivity?: number[];

  /** 志愿者活动 */
  volunteerActivity?: number[];
}

export interface DashbordPublishedCount {
  /** 发布文章总数 */
  articlePublishedCount?: number;

  /** 发布活动总数 */
  activityPublishedCount?: number;
}

export interface VolunteerReportDto {
  /** 月份 */
  months?: number[];

  /** 总的数量 */
  total?: number[];

  /** 新增的数量 */
  new?: number[];
}

export interface AccessRecordReportDto {
  /**  */
  dateArray?: string[];

  /**  */
  countArray?: number[];
}

export interface TotalTenantAccessRecordReportDto {
  /**  */
  pcData?: AccessRecordReportDto;

  /**  */
  mobileData?: AccessRecordReportDto;

  /**  */
  wechatData?: AccessRecordReportDto;
}

export interface FundRankDto {
  /**  */
  fundName?: string;

  /**  */
  moneyTotal?: number;

  /**  */
  fundID?: number;
}

export interface FundProjectRankDto {
  /**  */
  projectName?: string;

  /**  */
  moneyTotal?: number;

  /**  */
  projectID?: number;
}

export interface ProjectCategoryChartDto {
  /**  */
  name?: string;

  /**  */
  value?: number;
}

export interface VolunteerPointRankDto {
  /**  */
  surname?: string;

  /**  */
  point?: number;

  /**  */
  code?: string;

  /**  */
  headImage?: string;

  /**  */
  level?: number;
}

export interface DataDictionaryCreateOrUpdateDto {
  /** 键名 */
  key?: string;

  /** 显示的名称 */
  displayName?: string;

  /**  */
  parentId?: number;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  id?: number;
}

export interface DataDictionarySyncInput {
  /**  */
  id?: number;

  /**  */
  syncMode?: DataDictionarySyncMode;
}

export interface DataDictionaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DataDictionaryDto[];
}

export interface DataPermissionLimitDto {
  /** 宿主ID，可以是具体的id,也可以是`all`这个关键字 */
  hostId?: string;

  /**  */
  hostType?: DataPermissionLimitHostType;

  /**  */
  targetType?: DataPermissionLimitTargetType;

  /** 被授权的对象 */
  targetId?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: string;
}

export interface DataPermissionLimitUpdateDto {
  /** 宿主ID，可以是具体的id,也可以是`all`这个关键字 */
  hostId?: string[];

  /**  */
  targetType?: DataPermissionLimitTargetType;

  /**  */
  hostType?: DataPermissionLimitHostType;

  /**  */
  targetId?: string[];

  /**  */
  id?: string;
}

export interface DepartmentCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  id?: number;
}

export interface DepartmentDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface DepartmentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DepartmentDto[];
}

export interface DonatedAmountConfigCreateOrUpdateDto {
  /** 名称 */
  name?: string;

  /** 金额 */
  amount?: number;

  /** 图片地址 */
  imageUrl?: string;

  /**  */
  sourceType?: DonatedAmountConfigSourceType;

  /**  */
  sourceId?: string;

  /**  */
  id?: number;
}

export interface DonatedAmountConfigDto {
  /**  */
  tenantId?: number;

  /** 名称 */
  name?: string;

  /** 金额 */
  amount?: number;

  /** 图片地址 */
  imageUrl?: string;

  /**  */
  sourceType?: DonatedAmountConfigSourceType;

  /** 来源Id */
  sourceId?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface DonatedAmountConfigDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DonatedAmountConfigDto[];
}

export interface DonateDetailCreateOrUpdateDto {
  /** 捐赠时间 */
  donationTime?: string;

  /** 捐赠人 */
  donorName?: string;

  /** 金额 */
  money?: number;

  /** 捐赠意向 */
  donationIntent?: string;

  /**  */
  id?: number;
}

export interface DonateDetailDto {
  /** 捐赠时间 */
  donationTime?: string;

  /** 捐赠人 */
  donorName?: string;

  /** 金额 */
  money?: number;

  /** 物资名称 */
  materialName?: string;

  /** 捐赠意向 */
  donationIntent?: string;

  /**  */
  id?: number;
}

export interface DonateDetailSimpleDto {
  /** 捐赠意向 */
  donationIntent?: string;

  /** 捐赠时间 */
  donationTime?: string;

  /** 金额 */
  money?: number;

  /** 捐赠人 */
  donorName?: string;

  /** 物资名称 */
  materialName?: string;

  /** 支付方式 */
  paymentType?: string;

  /** 爱心留言 */
  leaveMessage?: string;
}

export interface DonateDetailSimpleDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DonateDetailSimpleDto[];
}

export interface DonateDetailDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DonateDetailDto[];
}

export interface EditionDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  color?: string;

  /**  */
  remark?: string;

  /**  */
  isDefault?: boolean;

  /**  */
  isPublic?: boolean;

  /**  */
  priceOfYear?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface EditionCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  color?: string;

  /**  */
  remark?: string;

  /**  */
  description?: string;

  /**  */
  priceOfYear?: number;

  /**  */
  id?: number;
}

export interface EntityDto {
  /**  */
  id?: number;
}

export interface EditionSetAsDefaultDto {
  /**  */
  replaceDefault?: boolean;

  /**  */
  id?: number;
}

export interface GrantFeatureDto {
  /**  */
  featureName?: string;

  /**  */
  value?: string;
}

export interface GrantFeaturesToEditionInput {
  /**  */
  editionId?: number;

  /**  */
  features?: GrantFeatureDto[];
}

export interface ElectronicInvoiceCreateOrUpdateDto {
  /**  */
  type?: ElectronicInvoiceType;

  /** 捐赠票据抬头 */
  donationNoteTitle?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 手机号 */
  mobilePhone?: string;

  /** 邮箱 */
  email?: string;

  /** 善款捐赠明细Id */
  fundIncomeRecordIds?: number[];

  /**  */
  userId?: number;

  /**  */
  id?: number;
}

export interface ElectronicInvoiceDto {
  /**  */
  creatorUser?: UserMiniDto;

  /** 冲红发票Id */
  creditNoteInvoiceId?: number;

  /**  */
  creditNoteInvoice?: ElectronicInvoiceDto;

  /**  */
  type?: ElectronicInvoiceType;

  /** 捐赠票据抬头 */
  donationNoteTitle?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 手机号 */
  mobilePhone?: string;

  /** 邮箱 */
  email?: string;

  /** 开票金额 */
  invoiceAmount?: number;

  /**  */
  status?: ElectronicInvoiceStatus;

  /** 图片地址 */
  imageUrl?: string;

  /** 是否冲红发票 */
  isCreditNoteInvoice?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundIncomeRecordMiniDto {
  /** Id */
  id?: number;

  /** 捐赠意向 */
  donationIntent?: string;

  /** 捐赠金额 */
  moneyAmount?: number;

  /** 捐赠时间 */
  incomeTime?: Date;
}

export interface FundIncomeRecordMiniDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundIncomeRecordMiniDto[];
}

export interface UserElectronicInvoiceDto {
  /**  */
  status?: ElectronicInvoiceStatus;

  /** 图片地址 */
  imageUrl?: string;

  /**  */
  fundIncomeRecords?: FundIncomeRecordMiniDto[];

  /**  */
  id?: number;
}

export interface UserElectronicInvoiceDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserElectronicInvoiceDto[];
}

export interface ElectronicInvoiceCountGetDto {
  /** 发票总张数 */
  totalNumber?: number;

  /** 已开发票数量 */
  invoicedQuantity?: number;

  /** 可开发票数量 */
  canInvoiceQuantity?: number;
}

export interface ElectronicInvoiceCountPutDto {
  /** 新增发票张数 */
  totalNumber?: number;
}

export interface ElectronicInvoiceConfirmPutDto {
  /** 电子发票Id */
  ids?: number[];
}

export interface ElectronicInvoiceDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ElectronicInvoiceDto[];
}

export interface ExamQuestionChoiceItemDto {
  /**  */
  questionId?: number;

  /** 选项值 */
  option?: string;

  /** 选项编号 */
  optionSort?: string;

  /**  */
  isChecked?: boolean;

  /** 附件地址 */
  url?: string;
}

export interface ExamQuestionDto {
  /** 问题描述 */
  question?: string;

  /** 答案 */
  answer?: string;

  /** 分值 */
  point?: number;

  /**  */
  questionType?: QuestionType;

  /**  */
  creationTime?: Date;

  /**  */
  choiceItems?: ExamQuestionChoiceItemDto[];

  /**  */
  id?: number;
}

export interface SaveDto {
  /**  */
  question?: ExamQuestionDto;

  /**  */
  choiceItems?: ExamQuestionChoiceItemDto[];

  /**  */
  id?: number;
}

export interface TrainExamQuestionDto {
  /**  */
  sourceType?: ExamSourceType;

  /**  */
  questionId?: number;

  /** 前端提交的答案（只对前端使用） */
  submitAnswer?: string;

  /** 前端提交的答案（只对前端使用,多选题） */
  submitAnswers?: string[];

  /** 来源Id */
  sourceId?: number;

  /**  */
  examQuestion?: ExamQuestionDto;
}

export interface ExamQuestionDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExamQuestionDto[];
}

export interface TrainQuestionBatchSave {
  /**  */
  questionIds?: string;

  /**  */
  trainId?: number;

  /**  */
  sourceType?: ExamSourceType;
}

export interface TrainQuestionBatchDeleteSave {
  /**  */
  questionIds?: string;

  /**  */
  trainId?: number;

  /**  */
  sourceType?: ExamSourceType;
}

export interface ExamPaperMiniDto {
  /** 试题总分 */
  pointTotal?: number;

  /** 考题数量 */
  questionCount?: number;

  /** 用户得分 */
  score?: number;

  /** 所属培训 */
  trainId?: number;

  /** 完成时间 */
  finishTime?: Date;

  /**  */
  status?: ExamPaperStatus;

  /** 用户Id */
  userId?: number;

  /**  */
  id?: number;
}

export interface ExamQuestionMiniDto {
  /** 问题描述 */
  question?: string;

  /** 答案 */
  answer?: string;

  /** 分值 */
  point?: number;

  /**  */
  questionType?: QuestionType;

  /**  */
  creationTime?: Date;

  /**  */
  choiceItems?: ExamQuestionChoiceItemDto[];

  /**  */
  id?: number;
}

export interface QuestionAnalysisDto {
  /** 答卷Id */
  examPaperId?: number;

  /**  */
  examPaper?: ExamPaperMiniDto;

  /** 问题Id */
  questionId?: number;

  /**  */
  examQuestion?: ExamQuestionMiniDto;

  /** 回答答案 */
  answerValue?: string;

  /** 回答时间 */
  answerTime?: Date;

  /** 是否正确 */
  isRight?: boolean;

  /** 正确答案 */
  rightAnswer?: string;

  /** 题目分值 */
  point?: number;

  /**  */
  id?: number;
}

export interface ItemSaveDto {
  /**  */
  questionID?: number;

  /**  */
  answerValue?: string;
}

export interface SubmitPaperDto {
  /**  */
  examSourceType?: ExamSourceType;

  /**  */
  sourceId?: number;

  /**  */
  answerList?: ItemSaveDto[];
}

export interface ExamQuestionCreateOrUpdateDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 问题描述 */
  question?: string;

  /** 答案 */
  answer?: string;

  /** 分值 */
  point?: number;

  /**  */
  questionType?: QuestionType;

  /**  */
  id?: number;
}

export interface ExpenditureDetailCreateOrUpdateDto {
  /** 支出时间 */
  appropriateTime?: string;

  /** 支出原因 */
  reason?: string;

  /** 支出金额 */
  money?: number;

  /**  */
  id?: number;
}

export interface ExpenditureDetailDto {
  /** 支出时间 */
  appropriateTime?: string;

  /** 支出原因 */
  reason?: string;

  /** 支出金额 */
  money?: number;

  /**  */
  id?: number;
}

export interface ExpenditureDetailDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExpenditureDetailDto[];
}

export interface ExternalDueDiligenceRecordCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  organizationUnitId?: number;

  /**  */
  projectId?: number;

  /**  */
  dueDiligenceTypeId?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  place?: string;

  /**  */
  members?: string;

  /**  */
  visitObjectJson?: string;

  /**  */
  orgInfo?: string;

  /**  */
  projectInfo?: string;

  /**  */
  omnibusOpinion?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  cancelRestartAudit?: boolean;

  /**  */
  id?: number;
}

export interface ExternalDueDiligenceRecordDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  projectId?: number;

  /**  */
  dueDiligenceTypeId?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  place?: string;

  /**  */
  members?: string;

  /**  */
  visitObjectJson?: string;

  /**  */
  orgInfo?: string;

  /**  */
  projectInfo?: string;

  /**  */
  omnibusOpinion?: string;

  /**  */
  dueDiligenceType?: DataDictionaryDto;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  cancelRestartAudit?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ExternalDueDiligenceRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalDueDiligenceRecordDto[];
}

export interface ExternalDueDiligenceRecordDtoListResultDto {
  /**  */
  items?: ExternalDueDiligenceRecordDto[];
}

export interface ExternalMonthlyPlanDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 内容概述 */
  summary?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 详细地址 */
  place?: string;

  /** 预计人数 */
  peopleCount?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanCreateOrUpdateDto {
  /** 日期 */
  date?: Date;

  /**  */
  projectId?: number;

  /**  */
  details?: ExternalMonthlyPlanDetailCreateOrUpdateDto[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanDetailDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 内容概述 */
  summary?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 详细地址 */
  place?: string;

  /** 预计人数 */
  peopleCount?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanDto {
  /** 日期 */
  date?: Date;

  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  details?: ExternalMonthlyPlanDetailDto[];

  /**  */
  project?: ProjectDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanDtoListResultDto {
  /**  */
  items?: ExternalMonthlyPlanDto[];
}

export interface ExternalMonthlyPlanDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalMonthlyPlanDto[];
}

export interface ExternalMonthlySummarizeDetailDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /** 内容概述 */
  summary?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 地点 */
  place?: string;

  /** 实到人数 */
  peopleCount?: number;

  /** 原因\/问题 */
  reason?: string;

  /** 对应策略 */
  tactics?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  externalMonthlyPlanDetailId?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeDto {
  /** 日期 */
  date?: Date;

  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  details?: ExternalMonthlySummarizeDetailDto[];

  /** 面临的问题 */
  problem?: string;

  /** 所需资源 */
  needsResource?: string;

  /**  */
  project?: ProjectDto;

  /** 对应月计划Id */
  externalMonthlyPlanId?: number;

  /**  */
  externalMonthlyPlan?: ExternalMonthlyPlanDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /** 内容概述 */
  summary?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  townId?: number;

  /** 地点 */
  place?: string;

  /** 实到人数 */
  peopleCount?: number;

  /** 原因\/问题 */
  reason?: string;

  /** 对应策略 */
  tactics?: string;

  /**  */
  projectId?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  externalMonthlyPlanDetailId?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 日期 */
  date?: Date;

  /**  */
  details?: ExternalMonthlySummarizeDetailCreateOrUpdateDto[];

  /**  */
  externalMonthlyPlanId?: number;

  /** 面临的问题 */
  problem?: string;

  /** 所需资源 */
  needsResource?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeDtoListResultDto {
  /**  */
  items?: ExternalMonthlySummarizeDto[];
}

export interface ExternalMonthlySummarizeDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalMonthlySummarizeDto[];
}

export interface ProjectSpecificGoalDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /** 项目成效（具体目标） */
  effect?: string;

  /** 具体指标（具体目标描述） */
  norm?: string;

  /** 验证条件 */
  validateCondition?: string;

  /**  */
  id?: string;
}

export interface ExternalProjectSpecificPlanDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /**  */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 具体内容 */
  summary?: string;

  /** 开始年月 */
  startDate?: Date;

  /** 结束年月 */
  endDate?: Date;

  /** 活动频次 */
  activityCount?: number;

  /** 参与人数 */
  peopleCount?: number;

  /** 预计产出 */
  output?: string;

  /**  */
  id?: string;
}

export interface ProjectRiskPlanDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /** 潜在风险 */
  risk?: string;

  /** 应对策略 */
  copingStrategy?: string;

  /**  */
  id?: string;
}

export interface ProjectMemberItemDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /** 姓名 */
  name?: string;

  /** 性别 */
  sex?: string;

  /** 年龄 */
  age?: number;

  /** 学历 */
  educationTypeId?: number;

  /**  */
  educationType?: DataDictionaryDto;

  /** 所学专业 */
  major?: string;

  /** 职业资质 */
  qualifications?: string;

  /** 团队分工 */
  projectRole?: string;

  /** 投入的时间精力比（%） */
  inputPercent?: number;

  /**  */
  id?: string;
}

export interface ExternalProjectBudgetItemDto {
  /**  */
  projectId?: number;

  /** 序列(显示顺序） */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /**  */
  id?: string;
}

export interface ExternalProjectCreateOrUpdateDto {
  /** 项目名称 */
  displayName?: string;

  /** 项目概述 */
  summary?: string;

  /** 项目关注的问题描述，以及对问题原因的分析 */
  problemDescription?: string;

  /** 项目实施地域的情况及服务对象的总体情况 */
  areaDescription?: string;

  /** 针对该问题，其他机构或政府已经采取的服务、政策及经验 */
  otherExperience?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 直接受益对象 */
  benefitObjectOfDirect?: string;

  /** 间接受益对象 */
  benefitObjectOfIndirect?: string;

  /** 总目标 */
  objectiveOfMain?: string;

  /** 项目成效及成功指标 */
  effects?: ProjectSpecificGoalDto[];

  /** 具体计划 */
  specificPlans?: ExternalProjectSpecificPlanDto[];

  /** 项目风险预案 */
  riskPlans?: ProjectRiskPlanDto[];

  /** 项目团队信息 */
  projectMemberItems?: ProjectMemberItemDto[];

  /** 项目负责人简介 */
  projectMangerInfo?: string;

  /** 项目预算明细 */
  budgetItems?: ExternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 实施地点 */
  place?: string;

  /** 总预算 */
  totalMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectDetailDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  projectTypes?: string;

  /** 项目名称 */
  displayName?: string;

  /** 项目概述 */
  summary?: string;

  /** 项目关注的问题描述，以及对问题原因的分析 */
  problemDescription?: string;

  /** 项目实施地域的情况及服务对象的总体情况 */
  areaDescription?: string;

  /** 针对该问题，其他机构或政府已经采取的服务、政策及经验 */
  otherExperience?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 直接受益对象 */
  benefitObjectOfDirect?: string;

  /** 间接受益对象 */
  benefitObjectOfIndirect?: string;

  /** 总目标 */
  objectiveOfMain?: string;

  /** 项目成效及成功指标 */
  effects?: ProjectSpecificGoalDto[];

  /** 具体计划 */
  specificPlans?: ExternalProjectSpecificPlanDto[];

  /** 项目风险预案 */
  riskPlans?: ProjectRiskPlanDto[];

  /** 项目团队信息 */
  projectMemberItems?: ProjectMemberItemDto[];

  /** 项目负责人简介 */
  projectMangerInfo?: string;

  /** 项目预算明细 */
  budgetItems?: ExternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProjectMonthlySummaizeDetailsDto {
  /** 活动照片 */
  attachment?: AttachmentDto[];

  /** 活动标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 执行概述 */
  summary?: string;
}

export interface ProjectMonthlySummaizeDetailsDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectMonthlySummaizeDetailsDto[];
}

export interface UpdateExternalProjectBudgetItemsDto {
  /** 项目预算明细 */
  budgetItems?: ExternalProjectBudgetItemDto[];

  /**  */
  id?: number;
}

export interface ExternalProjectDtoListResultDto {
  /**  */
  items?: ExternalProjectDto[];
}

export interface ExternalProjectDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectDto[];
}

export interface ExternalProjectChangeApplyCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /**  */
  sourceExternalProjectId?: number;

  /**  */
  targetExternalProjectId?: number;

  /** 变更类别,多选，数据字典(经费变更\/项目延期\/内容变更\/目标人群变更\/其他变) */
  changeTypes?: string;

  /**  */
  savedChangeTypes?: string[];

  /** 变更时间 */
  changeDate?: Date;

  /** 变更原因 */
  changeReason?: string;

  /** 变更内容 */
  changeContent?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectChangeApplyDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  sourceExternalProjectId?: number;

  /**  */
  sourceExternalProject?: ExternalProjectDto;

  /**  */
  targetExternalProjectId?: number;

  /**  */
  targetExternalProject?: ExternalProjectDto;

  /** 变更类别,多选，数据字典(经费变更\/项目延期\/内容变更\/目标人群变更\/其他变) */
  changeTypes?: string;

  /**  */
  savedChangeTypes?: string[];

  /** 变更时间 */
  changeDate?: Date;

  /** 变更原因 */
  changeReason?: string;

  /** 变更内容 */
  changeContent?: string;

  /**  */
  organizationUnit?: OrganizationUnit;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectChangeApplyDtoListResultDto {
  /**  */
  items?: ExternalProjectChangeApplyDto[];
}

export interface ExternalProjectChangeApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectChangeApplyDto[];
}

export interface ExternalProjectActualBudgetItemDto {
  /**  */
  projectId?: number;

  /**  */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /** 计划预算明细项Id */
  budgetItemId?: number;

  /** 实际开支 */
  actualBudget?: number;

  /** 差额 */
  balance?: number;

  /** 实际占预算比例 */
  actualPercent?: number;

  /** 差异原因说明 */
  reason?: string;

  /**  */
  id?: string;
}

export interface CloseReportDetailDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  externalProjectActualBudgetItems?: ExternalProjectActualBudgetItemDto[];

  /**  */
  projectId?: number;

  /** 项目经费 */
  totalBudget?: number;

  /** 项目负责人 */
  projectManager?: string;

  /** 评估日期 */
  assessmentDate?: Date;

  /** 评估地点 */
  place?: string;

  /** 主要负责人员 */
  projectTeams?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 服务对象 */
  serviceObjects?: string;

  /** 服务区域 */
  serviceArea?: string;

  /** 评估形式 */
  assessmentType?: string;

  /** 1.	项目提出的问题，以及对问题真实性的验证 */
  problemsCheck?: string;

  /** 2.	回顾项目目标和执行计划 */
  implementReview?: string;

  /** 3.	初期该领域\/人群的状况，以及目前的状况 */
  situationCheck?: string;

  /** 4.	资助行为如何回应了差距\/问题 */
  subsidize?: string;

  /** 5.	被资助方、受益人和其他参与方的访谈情况 */
  interviewCheck?: string;

  /** 1.	总结的成功经验和模式 */
  experience?: string;

  /** 2.	出现的问题及解决策略 */
  problemsSolution?: string;

  /** 3.	成功\/典型案例 */
  successfulCase?: string;

  /** 1.	项目资金使用基本情况 */
  fundsUsage?: string;

  /** 2.	问题及建议 */
  financeProblems?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CloseReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 项目经费 */
  totalBudget?: number;

  /** 项目负责人 */
  projectManager?: string;

  /** 评估日期 */
  assessmentDate?: Date;

  /** 评估地点 */
  place?: string;

  /** 主要负责人员 */
  projectTeams?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 服务对象 */
  serviceObjects?: string;

  /** 服务区域 */
  serviceArea?: string;

  /** 评估形式 */
  assessmentType?: string;

  /** 1.	项目提出的问题，以及对问题真实性的验证 */
  problemsCheck?: string;

  /** 2.	回顾项目目标和执行计划 */
  implementReview?: string;

  /** 3.	初期该领域\/人群的状况，以及目前的状况 */
  situationCheck?: string;

  /** 4.	资助行为如何回应了差距\/问题 */
  subsidize?: string;

  /** 5.	被资助方、受益人和其他参与方的访谈情况 */
  interviewCheck?: string;

  /** 1.	总结的成功经验和模式 */
  experience?: string;

  /** 2.	出现的问题及解决策略 */
  problemsSolution?: string;

  /** 3.	成功\/典型案例 */
  successfulCase?: string;

  /** 1.	项目资金使用基本情况 */
  fundsUsage?: string;

  /** 2.	问题及建议 */
  financeProblems?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CloseReportDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目经费 */
  totalBudget?: number;

  /** 项目负责人 */
  projectManager?: string;

  /** 评估日期 */
  assessmentDate?: Date;

  /** 评估地点 */
  place?: string;

  /** 主要负责人员 */
  projectTeams?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 服务对象 */
  serviceObjects?: string;

  /** 服务区域 */
  serviceArea?: string;

  /** 评估形式 */
  assessmentType?: string;

  /** 1.	项目提出的问题，以及对问题真实性的验证 */
  problemsCheck?: string;

  /** 2.	回顾项目目标和执行计划 */
  implementReview?: string;

  /** 3.	初期该领域\/人群的状况，以及目前的状况 */
  situationCheck?: string;

  /** 4.	资助行为如何回应了差距\/问题 */
  subsidize?: string;

  /** 5.	被资助方、受益人和其他参与方的访谈情况 */
  interviewCheck?: string;

  /** 1.	总结的成功经验和模式 */
  experience?: string;

  /** 2.	出现的问题及解决策略 */
  problemsSolution?: string;

  /** 3.	成功\/典型案例 */
  successfulCase?: string;

  /** 1.	项目资金使用基本情况 */
  fundsUsage?: string;

  /** 2.	问题及建议 */
  financeProblems?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CloseReportDtoListResultDto {
  /**  */
  items?: CloseReportDto[];
}

export interface CloseReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CloseReportDto[];
}

export interface ExternalProjectIntentionCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /**  */
  displayName?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 拟落地的区域 */
  place?: string;

  /** 受益人群 */
  benefitObject?: string;

  /** 拟申请项目概述 */
  summary?: string;

  /** 拟解决的问题与拟达到的目标 */
  solvedProblem?: string;

  /** 拟采取的策略\/开展的活动 */
  activityPlan?: string;

  /** 项目初步预算 */
  budgetPlan?: string;

  /** 执行机构过往经验 */
  executeExperience?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectIntentionDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 拟落地的区域 */
  place?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectIntentionDtoListResultDto {
  /**  */
  items?: ExternalProjectIntentionDto[];
}

export interface ExternalProjectIntentionDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectIntentionDto[];
}

export interface ExternalProjectPhaseItemDto {
  /**  */
  stage?: WorkflowOfProjectByExternal;

  /** 节点名称 */
  name?: string;

  /**  */
  status?: ProjectPhaseStatus;

  /** 跳转链接 */
  redirectUrl?: string[];
}

export interface ProjectMonthItemDto {
  /** 年份 */
  year?: number;

  /** 月份 */
  month?: number;

  /**  */
  status?: ProjectPhaseStatus;
}

export interface AttachmentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AttachmentDto[];
}

export interface ExternalProjectProcessMonitoringCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /** 仅展示 */
  projectName?: string;

  /** 地点 */
  place?: string;

  /** 监测方式 */
  monitoringType?: string;

  /** 监测主题 */
  title?: string;

  /** 监测过程 */
  monitoringProcess?: string;

  /** 观察发现 */
  observe?: string;

  /** 给对方的反馈 */
  feedback?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectProcessMonitoringDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 地点 */
  place?: string;

  /** 监测方式 */
  monitoringType?: string;

  /** 监测主题 */
  title?: string;

  /**  */
  monitoringProcess?: string;

  /**  */
  observe?: string;

  /**  */
  feedback?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectProcessMonitoringDtoListResultDto {
  /**  */
  items?: ExternalProjectProcessMonitoringDto[];
}

export interface ExternalProjectProcessMonitoringDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectProcessMonitoringDto[];
}

export interface ProgressReportDetailDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 项目开展情况 */
  implementStatus?: string;

  /** 项目实施效果 */
  implementEffect?: string;

  /** 项目宣传情况 */
  publicityStatus?: string;

  /** 项目实施对组织自身的影响 */
  selfInfluence?: string;

  /** 项目创新性及可推广性 */
  innovation?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 下半年计划 */
  nextPlan?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProgressReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 项目开展情况 */
  implementStatus?: string;

  /** 项目实施效果 */
  implementEffect?: string;

  /** 项目宣传情况 */
  publicityStatus?: string;

  /** 项目实施对组织自身的影响 */
  selfInfluence?: string;

  /** 项目创新性及可推广性 */
  innovation?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 下半年计划 */
  nextPlan?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProgressReportDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 提交报告日期 */
  reportDate?: Date;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProgressReportDtoListResultDto {
  /**  */
  items?: ProgressReportDto[];
}

export interface ProgressReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProgressReportDto[];
}

export interface LineChartDto {
  /** 年份 */
  years?: number[];

  /** 项目数量 */
  projectCounts?: number[];

  /** 预算金额 */
  totalBudgets?: number[];

  /** 实际支出 */
  actualExpenses?: number[];

  /** 受益人次 */
  actualBeneficiaryCounts?: number[];

  /** 活动场次 */
  activityCounts?: number[];

  /** 覆盖区域数量 */
  townCounts?: number[];
}

export interface ExternalMicrocosmicDto {
  /** 项目名称 */
  projectName?: string;

  /** 开始时间 */
  startDate?: string;

  /** 结束时间 */
  endDate?: string;

  /**  */
  status?: WorkflowOfProjectByExternal;

  /** 活动场次 */
  activityCount?: number;

  /** 受益人次 */
  actualBeneficiaryCount?: number;

  /** 覆盖区域数量 */
  townCount?: number;

  /** 预算金额 */
  budget?: number;

  /** 实际支出 */
  actualExpenses?: number;
}

export interface ExternalMicrocosmicDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalMicrocosmicDto[];
}

export interface ProjectOfNotCompleteDto {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  projectName?: string;

  /** 姓名 */
  name?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  email?: string;

  /**  */
  projectId?: number;

  /** 内部或外部的对应ID */
  hostId?: number;

  /**  */
  user?: User;
}

export interface ProjectOfNotCompleteDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectOfNotCompleteDto[];
}

export interface SummaryReportDetailDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 机构内部的决策机制 */
  decisionMechanism?: string;

  /** 合作机构Json */
  partnersJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 活动开展情况 */
  implementStatus?: string;

  /** 项目活动完成情况对照表 */
  implementStatusJson?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 服务对象反馈 */
  serviceObjectsFeedback?: string;

  /** 相关方反馈 */
  partnersFeedback?: string;

  /** 社会影响 */
  socialImpact?: string;

  /** 项目成果\/产出 */
  output?: string;

  /** 对执行机构的作用 */
  effectForSelf?: string;

  /** 对领域\/行业的作用 */
  effectForIndustry?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 整个项目执行的反思 */
  executeIntrospection?: string;

  /** 对基金会的建议和反馈 */
  feedbackForFoundation?: string;

  /**  */
  externalProjectActualBudgetItems?: ExternalProjectActualBudgetItemDto[];

  /** 实际开支 */
  actualBudget?: number;

  /** 差额 */
  balance?: number;

  /** 实际占预算比例 */
  actualPercent?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface SummaryReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 机构内部的决策机制 */
  decisionMechanism?: string;

  /** 合作机构Json */
  partnersJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 活动开展情况 */
  implementStatus?: string;

  /** 项目活动完成情况对照表 */
  implementStatusJson?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 服务对象反馈 */
  serviceObjectsFeedback?: string;

  /** 相关方反馈 */
  partnersFeedback?: string;

  /** 社会影响 */
  socialImpact?: string;

  /** 项目成果\/产出 */
  output?: string;

  /** 对执行机构的作用 */
  effectForSelf?: string;

  /** 对领域\/行业的作用 */
  effectForIndustry?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 整个项目执行的反思 */
  executeIntrospection?: string;

  /** 对基金会的建议和反馈 */
  feedbackForFoundation?: string;

  /**  */
  externalProjectActualBudgetItems?: ExternalProjectActualBudgetItemDto[];

  /** 实际开支 */
  actualBudget?: number;

  /** 差额 */
  balance?: number;

  /** 实际占预算比例 */
  actualPercent?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface SummaryReportDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 提交报告日期 */
  reportDate?: Date;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface SummaryReportDtoListResultDto {
  /**  */
  items?: SummaryReportDto[];
}

export interface SummaryReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SummaryReportDto[];
}

export interface IValueValidator {
  /**  */
  name?: string;

  /**  */
  attributes?: object;
}

export interface IInputType {
  /**  */
  name?: string;

  /**  */
  attributes?: object;

  /**  */
  validator?: IValueValidator;
}

export interface FeatureDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  inputType?: IInputType;

  /**  */
  defaultValue?: string;

  /**  */
  scope?: FeatureScopes;

  /**  */
  children?: FeatureDto[];
}

export interface FeedbackCreateOrUpdateDto {
  /** 标题 */
  title?: string;

  /** 内容 */
  content?: string;

  /** 联系方式 */
  contactType?: string;

  /** 反馈类型Id */
  typeCodeId?: number;

  /**  */
  sourceType?: FeedbackSourceType;

  /**  */
  id?: number;
}

export interface FeedbackReplyDto {
  /**  */
  creatorUser?: UserMiniDto;

  /** 反馈信息Id */
  feedbackId?: number;

  /** 回复内容 */
  content?: string;

  /** 是否隐藏 */
  isHide?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FeedbackDto {
  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  typeCode?: DataDictionaryDto;

  /** 反馈类型Id */
  typeCodeId?: number;

  /** 标题 */
  title?: string;

  /** 内容 */
  content?: string;

  /** 联系方式 */
  contactType?: string;

  /**  */
  sourceType?: FeedbackSourceType;

  /** 用户Id */
  accountId?: number;

  /** 回复人员Id */
  replyUserId?: number;

  /** 回复内容 */
  replyContent?: string;

  /** 机构用户Id */
  orgAccountId?: number;

  /**  */
  feedbackTime?: Date;

  /** 回复时间 */
  replyTime?: Date;

  /** 是否显示在官网 */
  isShowPortal?: boolean;

  /** 反馈信息回复 */
  feedbackReplies?: FeedbackReplyDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FeedbackDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FeedbackDto[];
}

export interface FeedbackReplyCreateOrUpdateDto {
  /** 反馈信息Id */
  feedbackId?: number;

  /** 回复内容 */
  content?: string;

  /**  */
  id?: number;
}

export interface FeedbackReplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FeedbackReplyDto[];
}

export interface CacheFormPostDto {
  /**  */
  formType?: FormType;

  /** 数据 */
  data?: string;

  /** HostId */
  hostId?: string;
}

export interface FundSendMessageInput {
  /**  */
  messageId?: number;

  /**  */
  fundIds?: number[];
}

export interface FundCreateOrUpdateDto {
  /**  */
  savedAttachments?: number[];

  /**  */
  name?: string;

  /** 封面图片 */
  titleImage?: string;

  /** 创立人姓名 */
  founderName?: string;

  /**  */
  mobileTelephone?: string;

  /**  */
  fixTelephone?: string;

  /** 邮箱 */
  email?: string;

  /**  */
  qq?: string;

  /** 成立日期 */
  foundDate?: Date;

  /** 宗旨 */
  purpose?: string;

  /** 资金使用范围 */
  usageRange?: string;

  /** 管理费比例 */
  managementFeePercentage?: number;

  /** 起设金额 */
  intialCaptial?: number;

  /** 资金来源 */
  capitalSource?: string;

  /** 关注领域 */
  focusArea?: string;

  /**  */
  summary?: string;

  /**  */
  description?: string;

  /**  */
  viceChairman?: string;

  /**  */
  chairman?: string;

  /**  */
  member?: string;

  /** 联系人 */
  contacts?: string;

  /**  */
  type?: FundType;

  /**  */
  status?: ValidStatus;

  /** 类别（数据字典里获得，个冠的类别有关爱之星，荣誉理事，功勋理事） */
  honorType?: string;

  /** 是否在网站上显示 */
  isPublished?: boolean;

  /** 已经筹集捐款金额累计 */
  raisedAmountTotal?: number;

  /** 该基金累计捐款人数 */
  donatedPersonTotal?: number;

  /** 支出金额统计 */
  expenseAmountTotal?: number;

  /** 管委会主任ID */
  directorID?: number;

  /** 批示文件图片 */
  instructionsImage?: string;

  /** 牌匾图片 */
  plaqueImage?: string;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 是否拥有自己的产品 */
  hasProducts?: boolean;

  /** 是否需要募捐 */
  isFundRaising?: boolean;

  /** 细分类别 */
  fundCategory?: string;

  /**  */
  id?: number;
}

export interface FundDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  name?: string;

  /** 封面图片 */
  titleImage?: string;

  /** 创立人姓名 */
  founderName?: string;

  /**  */
  mobileTelephone?: string;

  /**  */
  fixTelephone?: string;

  /** 邮箱 */
  email?: string;

  /**  */
  qq?: string;

  /** 成立日期 */
  foundDate?: Date;

  /** 宗旨 */
  purpose?: string;

  /**  */
  usageRange?: string;

  /** 管理费比例 */
  managementFeePercentage?: number;

  /** 起设金额 */
  intialCaptial?: number;

  /** 资金来源 */
  capitalSource?: string;

  /**  */
  focusArea?: string;

  /**  */
  summary?: string;

  /**  */
  description?: string;

  /**  */
  viceChairman?: string;

  /**  */
  chairman?: string;

  /**  */
  member?: string;

  /** 联系人 */
  contacts?: string;

  /**  */
  type?: FundType;

  /**  */
  status?: ValidStatus;

  /** 类别（数据字典里获得，个冠的类别有关爱之星，荣誉理事，功勋理事） */
  honorType?: string;

  /** 是否在网站上显示 */
  isPublished?: boolean;

  /** 已经筹集捐款金额累计 */
  raisedAmountTotal?: number;

  /** 该基金累计捐款人数 */
  donatedPersonTotal?: number;

  /** 支出金额统计 */
  expenseAmountTotal?: number;

  /** 管委会主任ID */
  directorID?: number;

  /** 批示文件图片 */
  instructionsImage?: string;

  /** 牌匾图片 */
  plaqueImage?: string;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 是否拥有自己的产品 */
  hasProducts?: boolean;

  /** 是否需要募捐 */
  isFundRaising?: boolean;

  /** 细分类别 */
  fundCategory?: string;

  /** 累计收入基金账户金额 */
  accumlativeMoney?: number;

  /** 当前基金账户金额 */
  availableMoney?: number;

  /** 基金成员 */
  users?: UserExtendMiniDto[];

  /** 是否置顶 */
  isTop?: boolean;

  /** 置顶时间 */
  topTime?: Date;

  /** 是否官网显示 */
  isOfficialWebsiteDisplay?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundDto[];
}

export interface MessageDto {
  /**  */
  organizationUnitId?: number;

  /** 消息标题 */
  title?: string;

  /** 消息内容 */
  content?: string;

  /**  */
  messageType?: MessageType;

  /**  */
  status?: ValidStatus;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundMessageDto {
  /**  */
  message?: MessageDto;

  /** 基金Id */
  fundID?: number;

  /** 消息Id */
  messageID?: number;

  /** 否是已经阅读 */
  isReaded?: boolean;

  /** 阅读时间 */
  readTime?: Date;

  /**  */
  attachments?: AttachmentDto[];

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface FundMessageDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundMessageDto[];
}

export interface FundAccountDto {
  /** 基金Id */
  fundID?: number;

  /** 基金名称 */
  fundName?: string;

  /** 当前基金账户金额 */
  availableMoney?: number;

  /** 累计收入基金账户金额 */
  accumlativeMoney?: number;

  /** 累计支出金额 */
  appropriatedMoney?: number;

  /** 管理费 */
  manageFee?: number;
}

export interface FundAccountDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundAccountDto[];
}

export interface FundAccountPagedResultRequestDto {
  /**  */
  type?: FundType;

  /** 基金Id */
  fundID?: number;

  /**  */
  projectId?: number;

  /** 起始日期 */
  startDate?: string;

  /** 截止日期 */
  endDate?: string;

  /** 是否前端 */
  isFront?: boolean;

  /**  */
  operationType?: FundAccountRecordOperationType;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface FundAccountRecordDto {
  /** 操作金额 */
  moneyAmount?: number;

  /** 操作时间 */
  operationTime?: string;

  /** 内容 */
  content?: string;

  /** 操作类型 */
  operationType?: number;
}

export interface FundAccountRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundAccountRecordDto[];
}

export interface FundApplyCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  savedAttachments?: number[];

  /** 基金名称 */
  fundName?: string;

  /** 申请人姓名 */
  applicantName?: string;

  /** 联系电话 */
  applicantMobileTelephone?: string;

  /** 固定电话 */
  applicantFixTelephone?: string;

  /**  */
  applicantEmail?: string;

  /**  */
  applicantQQ?: string;

  /** 启动资金金额 */
  intialCaptial?: number;

  /** 简介 */
  summary?: string;

  /**  */
  type?: FundType;

  /**  */
  status?: ApplyStatus;

  /**  */
  applyTime?: Date;

  /** 管理费比例 */
  managementFeePercentage?: number;

  /** 细分类别 */
  fundCategory?: string;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /**  */
  attachmentStr?: string;

  /** 资金使用范围 */
  usageRange?: string;

  /**  */
  id?: number;
}

export interface FundApplyDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 基金名称 */
  fundName?: string;

  /** 申请人姓名 */
  applicantName?: string;

  /** 联系电话 */
  applicantMobileTelephone?: string;

  /** 固定电话 */
  applicantFixTelephone?: string;

  /**  */
  applicantEmail?: string;

  /**  */
  applicantQQ?: string;

  /** 启动资金金额 */
  intialCaptial?: number;

  /** 简介 */
  summary?: string;

  /**  */
  type?: FundType;

  /**  */
  status?: ApplyStatus;

  /**  */
  applyTime?: Date;

  /** 管理费比例 */
  managementFeePercentage?: number;

  /** 细分类别 */
  fundCategory?: string;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 资金使用范围 */
  usageRange?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundApplyDtoListResultDto {
  /**  */
  items?: FundApplyDto[];
}

export interface FundApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundApplyDto[];
}

export interface FundAppropriateApplyCreateOrUpdateDto {
  /**  */
  fundId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  projectId?: number;

  /** 拨付标题 */
  title?: string;

  /** 拨付金额 */
  moneyAmount?: number;

  /** 拨付理由 */
  appropriateReason?: string;

  /** 收款银行账号 */
  bankAccountCode?: string;

  /** 开户行名称 */
  bankName?: string;

  /** 户名 */
  bankAccountName?: string;

  /** 收款人姓名 */
  receiverName?: string;

  /** 收款人电话 */
  receiverTelephone?: string;

  /**  */
  appropriateType?: FundAppropriateType;

  /**  */
  savedAttachments?: number[];

  /** 其他项目名称 */
  otherProjectName?: string;

  /**  */
  attachmentStr?: string;

  /**  */
  id?: number;
}

export interface FundAppropriateApplyDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  fundId?: number;

  /**  */
  fund?: FundMiniDto;

  /**  */
  projectId?: number;

  /**  */
  fundProject?: FundProjectMiniDto;

  /** 拨付标题 */
  title?: string;

  /** 拨付金额 */
  moneyAmount?: number;

  /** 拨付理由 */
  appropriateReason?: string;

  /** 收款银行账号 */
  bankAccountCode?: string;

  /** 开户行名称 */
  bankName?: string;

  /** 户名 */
  bankAccountName?: string;

  /** 收款人姓名 */
  receiverName?: string;

  /** 收款人电话 */
  receiverTelephone?: string;

  /**  */
  appropriateType?: FundAppropriateType;

  /** 其他项目名称 */
  otherProjectName?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundAppropriateApplyDtoListResultDto {
  /**  */
  items?: FundAppropriateApplyDto[];
}

export interface FundAppropriateApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundAppropriateApplyDto[];
}

export interface FundAppropriateRecordDto {
  /**  */
  fundID?: number;

  /**  */
  fund?: FundMiniDto;

  /**  */
  projectID?: number;

  /**  */
  fundProject?: FundProjectMiniDto;

  /** 最终审核通过人 */
  operatorID?: number;

  /**  */
  operator?: UserMiniDto;

  /** 用款申请Id */
  applicationPaymentId?: number;

  /**  */
  applicationPayment?: ApplicationPaymentDto;

  /** 拨付标题 */
  title?: string;

  /** 拨付理由 */
  appropriateReason?: string;

  /** 拨付金额 */
  moneyAmount?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /** 拨付时间 */
  appropriateTime?: Date;

  /**  */
  status?: ValidStatus;

  /** 收款银行账号 */
  bankAccountCode?: string;

  /** 开户行名称 */
  bankName?: string;

  /** 户名 */
  bankAccountName?: string;

  /** 收款人姓名 */
  receiverName?: string;

  /** 收款人电话 */
  receiverTelephone?: string;

  /**  */
  appropriateType?: FundAppropriateType;

  /** 其他项目名称 */
  otherProjectName?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundAppropriateRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundAppropriateRecordDto[];
}

export interface FundAppropriateRecordChangeAppropriateTimeInput {
  /**  */
  newTime?: Date;

  /**  */
  id?: number;
}

export interface FundBankInfoCreateOrUpdateDto {
  /** 收款人姓名 */
  name?: string;

  /** 收款人电话 */
  telephone?: string;

  /** 户名 */
  accountName?: string;

  /** 银行账号 */
  accountCode?: string;

  /** 开户银行名称 */
  bankName?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface FundBankInfoDto {
  /**  */
  fundID?: number;

  /**  */
  fund?: FundDto;

  /** 收款人姓名 */
  name?: string;

  /** 收款人电话 */
  telephone?: string;

  /** 户名 */
  accountName?: string;

  /** 银行账号 */
  accountCode?: string;

  /** 开户银行名称 */
  bankName?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface FundBankInfoDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundBankInfoDto[];
}

export interface FundDonationMonthlyConfigDto {
  /** 会员Id */
  userId?: number;

  /**  */
  user?: UserMiniDto;

  /** 捐赠项目类型 */
  donorItemType?: number;

  /** 捐赠项目编号 */
  donorItemId?: number;

  /** 捐赠协议序列号(签约银行返回) */
  donorSignSn?: string;

  /**  */
  donorType?: DonorType;

  /** 固定捐助天 */
  donorDay?: number;

  /** 捐赠金额,单位：分 */
  donationAmount?: number;

  /** 发票Id */
  invoiceId?: number;

  /** 捐赠留言 */
  leaveMessage?: string;

  /** 捐赠人联系号码 */
  mobileTelephone?: string;

  /** 发票附件编号，多个使用英文","隔开 */
  attachments?: string;

  /** 捐赠人名称(匿名使用“爱心人士”) */
  displayName?: string;

  /** 平台与天付宝确认签约订单号，关联发票订单Id */
  spBillNo?: string;

  /** 天付宝确认签约订单号，关联发票订单Id */
  listId?: string;

  /** 月捐状态(1：开启，0：停止月捐) */
  isOpen?: boolean;

  /** 是否已确认 */
  isConfirmed?: boolean;

  /** 推荐人 */
  referrer?: string;

  /** 停止月捐时间 */
  stoppedTime?: Date;

  /** 月捐类型（1天付宝，2微信） */
  configType?: number;

  /** 捐赠项目名称 */
  donorItemProjectName?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundDonationMonthlyConfigDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundDonationMonthlyConfigDto[];
}

export interface FundDonationOrderCreateDto {
  /**  */
  orderID?: string;

  /** 基金ID */
  fundID?: number;

  /** 项目ID */
  projectID?: number;

  /** 捐款数额 */
  donationAmount?: number;

  /** 会员ID */
  memberID?: number;

  /** 手机号码 */
  mobileTelephone?: string;

  /** 捐助者姓名 */
  donorName?: string;

  /** 单位全称 */
  unitName?: string;

  /** 留言 */
  leaveMessage?: string;

  /** 发票抬头 */
  invoiceHeader?: string;

  /** 订单生产时间 */
  createdTime?: string;

  /** 是否支付成功 */
  isPayed?: boolean;

  /** 支付成功时间 */
  payedTime?: string;

  /** 支付类型ID */
  paymentType?: number;

  /** 支付平台是否响应 */
  isResponsed?: boolean;

  /** 支付平台响应时间 */
  responseTime?: string;

  /** 支付平台返回消息 */
  resultMessage?: string;

  /** 显示的名称 */
  displayName?: string;

  /** 支付交易号 */
  tadeNO?: string;

  /** 捐赠者账户 */
  donatorAccount?: string;

  /** 失效时间 */
  invalidTime?: string;

  /** 推荐人 */
  referrer?: string;

  /**  */
  name?: string;

  /**  */
  needInvoice?: boolean;

  /**  */
  isAnonymous?: boolean;

  /**  */
  donorType?: DonorType;

  /**  */
  inVoiceTitle?: string;

  /**  */
  inVoiceName?: string;

  /**  */
  inVoiceTelephone?: string;

  /**  */
  inVoiceAddress?: string;

  /**  */
  attachmentStr?: string;

  /**  */
  incomeType?: IncomeType;

  /** 月捐日期 */
  monthDonorDay?: number;

  /**  */
  donationType?: DonationType;

  /**  */
  donationCycle?: DonationCycle;

  /** 捐赠时间 */
  donationTime?: Date;

  /**  */
  donorItemType?: DonorItemType;

  /** 捐赠意向Id */
  donorItemId?: number;

  /**  */
  type?: ElectronicInvoiceType;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 邮箱 */
  email?: string;

  /**  */
  orderSourceType?: OrderSourceType;
}

export interface FundDonationOrderDto {
  /**  */
  organizationUnitId?: number;

  /** 订单号 */
  orderID?: string;

  /** 捐赠基金Id */
  fundID?: number;

  /**  */
  fund?: FundMiniDto;

  /** 捐赠项目ID */
  fundProjectID?: number;

  /**  */
  fundProject?: FundProjectMiniDto;

  /** 捐赠金额 */
  donationAmount?: number;

  /** 是否为手动新增订单 */
  isManualCreate?: boolean;

  /** 用户Id */
  userId?: number;

  /** 捐赠人手机号码 */
  mobileTelephone?: string;

  /**  */
  donorType?: DonorType;

  /** 捐赠人姓名 */
  donorName?: string;

  /** 单位全称 */
  unitName?: string;

  /** 留言 */
  leaveMessage?: string;

  /** 是否支付成功 */
  isPayed?: boolean;

  /** 支付成功时间 */
  payedTime?: Date;

  /** 支付类型 */
  paymentType?: string;

  /** 支付平台是否响应 */
  isResponsed?: boolean;

  /** 支付平台响应时间 */
  responseTime?: Date;

  /** 支付平台返回消息 */
  resultMessage?: string;

  /** 显示的名称 */
  displayName?: string;

  /** 支付交易号 */
  tradeNo?: string;

  /** 捐赠者账号 */
  donorAccount?: string;

  /** 失效时间 */
  invalidTime?: Date;

  /**  */
  donationType?: IncomeType;

  /** 是否需要发票 */
  needInvoice?: boolean;

  /** 订单交易流水号(天付宝用) */
  orderSerialNo?: string;

  /** 推荐人 */
  referrer?: string;

  /** 月捐日期 */
  monthDonorDay?: number;

  /** 捐赠意向Id */
  donorItemId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundAutoDeductionOrderCreateDto {
  /**  */
  fromBmsOrderId?: string;

  /**  */
  newBmsOrderId?: string;

  /**  */
  newBmsOrderPrice?: number;
}

export interface FundDonationOrderDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundDonationOrderDto[];
}

export interface FundIncomeRecordCreateOrUpdateDto {
  /**  */
  savedAttachments?: number[];

  /** 捐赠基金Id */
  fundID?: number;

  /** 捐赠项目Id */
  fundProjectID?: number;

  /** 捐赠产品Id */
  fundProductID?: number;

  /** 捐赠金额 */
  moneyAmount?: number;

  /** 捐赠描述 */
  content?: string;

  /** 捐赠时间 */
  incomeTime?: Date;

  /** 捐赠人姓名 */
  donorName?: string;

  /**  */
  donorType?: DonorType;

  /** 捐赠人电话 */
  donorTelephone?: string;

  /** 捐赠人邮箱 */
  donorEmail?: string;

  /** 捐赠人地址 */
  donorAddress?: string;

  /** 邮政编码 */
  donorPostCode?: string;

  /** 是否需要发票 */
  isNeedInVoice?: boolean;

  /**  */
  incomeType?: IncomeType;

  /** 付款类型(数据字典) */
  paymentType?: string;

  /** 是否线上支付 */
  isOnLinePayment?: boolean;

  /**  */
  currencyType?: CurrencyType;

  /**  */
  status?: ValidStatus;

  /** 留言 */
  leaveMessage?: string;

  /** 已打印次数 */
  printCount?: number;

  /** 是否来自境外 */
  isAbroad?: boolean;

  /** 是否月捐 */
  isMonthDonor?: boolean;

  /** 推荐人 */
  referrer?: string;

  /** 订单号 */
  orderID?: string;

  /** 发票Id */
  invoiceId?: number;

  /** 发票抬头 */
  invoiceTitle?: string;

  /** 发票收件人姓名 */
  invoiceName?: string;

  /** 发票收件人电话 */
  invoiceTelephone?: string;

  /** 发票收件人地址 */
  invoiceAddress?: string;

  /**  */
  type?: ElectronicInvoiceType;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 邮箱 */
  email?: string;

  /**  */
  id?: number;
}

export interface FundIncomeRecordDto {
  /**  */
  organizationUnitId?: number;

  /** 捐赠基金Id */
  fundID?: number;

  /**  */
  fund?: FundMiniDto;

  /** 捐赠项目Id */
  fundProjectID?: number;

  /**  */
  fundProject?: FundProjectMiniDto;

  /** 捐赠产品Id */
  fundProductID?: number;

  /** 捐赠金额 */
  moneyAmount?: number;

  /** 捐赠描述 */
  content?: string;

  /** 捐赠时间 */
  incomeTime?: Date;

  /** 捐赠人姓名 */
  donorName?: string;

  /** 单位全称 */
  unitName?: string;

  /**  */
  donorType?: DonorType;

  /** 捐赠人电话 */
  donorTelephone?: string;

  /** 捐赠人邮箱 */
  donorEmail?: string;

  /** 捐赠人地址 */
  donorAddress?: string;

  /** 邮政编码 */
  donorPostCode?: string;

  /** 是否需要发票 */
  isNeedInVoice?: boolean;

  /**  */
  incomeType?: IncomeType;

  /** 付款类型(数据字典) */
  paymentType?: string;

  /** 是否线上支付 */
  isOnLinePayment?: boolean;

  /**  */
  currencyType?: CurrencyType;

  /**  */
  status?: ValidStatus;

  /** 留言 */
  leaveMessage?: string;

  /** 已打印次数 */
  printCount?: number;

  /** 是否来自境外 */
  isAbroad?: boolean;

  /** 是否月捐 */
  isMonthDonor?: boolean;

  /** 推荐人 */
  referrer?: string;

  /** 订单号 */
  orderID?: string;

  /** 捐赠意向 */
  donationIntent?: string;

  /**  */
  invoiceInformation?: InvoiceInformationDto;

  /** 创建时间 */
  creationTime?: Date;

  /** 基金名称 */
  fundName?: string;

  /** 基金项目名称 */
  projectName?: string;

  /** 标题图片 */
  titleImagePath?: string;

  /**  */
  donateYear?: string;

  /**  */
  donateMonthAndDay?: string;

  /** 是否显示留言 */
  isShowLeaveMessage?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundIncomeRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundIncomeRecordDto[];
}

export interface FundIncomeRecordTotalDto {
  /**  */
  orgTotal?: number;

  /**  */
  fundProjectTotal?: number;

  /**  */
  fundTotal?: number;

  /**  */
  aidStudentTotal?: number;

  /**  */
  totalMoney?: number;
}

export interface FundIncomeRecordTotalByPortalDto {
  /** 捐赠总额 */
  totalMoney?: number;

  /** 捐赠基金数 */
  donateFundCount?: number;

  /** 捐赠项目数 */
  donateProjectCount?: number;
}

export interface FundIncomeRecordPagedResultRequestDto {
  /** 关键词 */
  keyword?: string;

  /** 捐赠者名称 */
  donorName?: string;

  /** 开始时间 */
  startDate?: string;

  /** 结束时间 */
  endDate?: string;

  /**  */
  startMoney?: number;

  /**  */
  endMoney?: number;

  /**  */
  donorType?: DonorType;

  /**  */
  incomeType?: IncomeType;

  /** 基金Id */
  fundId?: number;

  /** 基金项目Id */
  projectId?: number;

  /** 支付方式 */
  paymentType?: string;

  /** 捐助方式 */
  isMonthDonor?: boolean;

  /** 用户Id */
  userId?: number;

  /** 申请人 */
  creatorUserName?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface UserDonateInfoDto {
  /** 资金捐赠次数 */
  donateCount?: number;

  /** 资金捐赠总金额 */
  donateMoney?: number;
}

export interface FundManageFeeRecordDto {
  /**  */
  organizationUnitId?: number;

  /** 捐赠基金Id */
  fundId?: number;

  /**  */
  fund?: FundMiniDto;

  /** 管理费金额 */
  moneyAmount?: number;

  /** 捐赠描述 */
  content?: string;

  /** 管理费比例 */
  ration?: number;

  /** 源记录ID（由哪条原始数据产生） */
  sourceId?: number;

  /** 备注 */
  remark?: string;

  /** 捐赠记录ID */
  incomeRecordId?: number;

  /** 记录是否发生过变更 */
  hasChanged?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundManageFeeRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundManageFeeRecordDto[];
}

export interface FundManageFeeRecordChangeHistoryDto {
  /**  */
  recordId?: number;

  /** 变更前金额 */
  beforeAmount?: number;

  /** 变更后金额 */
  afterAmount?: number;

  /**  */
  type?: ManagerFeeChangeType;

  /**  */
  summary?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  creationTimeText?: string;

  /**  */
  creatorUserId?: number;
}

export interface FundManageFeeFeeRecordDetailDto {
  /**  */
  changeHistories?: FundManageFeeRecordChangeHistoryDto[];

  /**  */
  organizationUnitId?: number;

  /** 捐赠基金Id */
  fundId?: number;

  /**  */
  fund?: FundMiniDto;

  /** 管理费金额 */
  moneyAmount?: number;

  /** 捐赠描述 */
  content?: string;

  /** 管理费比例 */
  ration?: number;

  /** 源记录ID（由哪条原始数据产生） */
  sourceId?: number;

  /** 备注 */
  remark?: string;

  /** 捐赠记录ID */
  incomeRecordId?: number;

  /** 记录是否发生过变更 */
  hasChanged?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundManageFeeRecordChangeRequestInput {
  /** 变更原因说明 */
  sumamry?: string;

  /** 目标金额 */
  amount?: number;

  /**  */
  id?: number;
}

export interface FundModifyApplyCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  name?: string;

  /** 封面图片 */
  titleImage?: string;

  /** 创立人姓名 */
  founderName?: string;

  /**  */
  mobileTelephone?: string;

  /**  */
  fixTelephone?: string;

  /** 邮箱 */
  email?: string;

  /**  */
  qq?: string;

  /** 成立日期 */
  foundDate?: Date;

  /** 宗旨 */
  purpose?: string;

  /** 资金使用范围 */
  usageRange?: string;

  /** 管理费比例 */
  managementFeePercentage?: number;

  /** 起设金额 */
  intialCaptial?: number;

  /** 资金来源 */
  capitalSource?: string;

  /** 关注领域 */
  focusArea?: string;

  /**  */
  summary?: string;

  /**  */
  description?: string;

  /**  */
  viceChairman?: string;

  /**  */
  chairman?: string;

  /**  */
  member?: string;

  /** 联系人 */
  contacts?: string;

  /**  */
  type?: FundType;

  /**  */
  status?: ValidStatus;

  /** 类别（数据字典里获得，个冠的类别有关爱之星，荣誉理事，功勋理事） */
  honorType?: string;

  /** 是否在网站上显示 */
  isPublished?: boolean;

  /** 已经筹集捐款金额累计 */
  raisedAmountTotal?: number;

  /** 该基金累计捐款人数 */
  donatedPersonTotal?: number;

  /** 支出金额统计 */
  expenseAmountTotal?: number;

  /** 管委会主任ID */
  directorID?: number;

  /** 批示文件图片 */
  instructionsImage?: string;

  /** 牌匾图片 */
  plaqueImage?: string;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 是否拥有自己的产品 */
  hasProducts?: boolean;

  /** 是否需要募捐 */
  isFundRaising?: boolean;

  /** 基金Id */
  fundID?: number;

  /** 细分类别 */
  fundCategory?: string;

  /**  */
  id?: number;
}

export interface FundModifyApplyDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  name?: string;

  /** 封面图片 */
  titleImage?: string;

  /** 创立人姓名 */
  founderName?: string;

  /**  */
  mobileTelephone?: string;

  /**  */
  fixTelephone?: string;

  /** 邮箱 */
  email?: string;

  /**  */
  qq?: string;

  /** 成立日期 */
  foundDate?: Date;

  /** 宗旨 */
  purpose?: string;

  /**  */
  usageRange?: string;

  /** 管理费比例 */
  managementFeePercentage?: number;

  /** 起设金额 */
  intialCaptial?: number;

  /** 资金来源 */
  capitalSource?: string;

  /**  */
  focusArea?: string;

  /**  */
  summary?: string;

  /**  */
  description?: string;

  /**  */
  viceChairman?: string;

  /**  */
  chairman?: string;

  /**  */
  member?: string;

  /** 联系人 */
  contacts?: string;

  /**  */
  type?: FundType;

  /**  */
  status?: ValidStatus;

  /** 类别（数据字典里获得，个冠的类别有关爱之星，荣誉理事，功勋理事） */
  honorType?: string;

  /** 是否在网站上显示 */
  isPublished?: boolean;

  /** 已经筹集捐款金额累计 */
  raisedAmountTotal?: number;

  /** 该基金累计捐款人数 */
  donatedPersonTotal?: number;

  /** 支出金额统计 */
  expenseAmountTotal?: number;

  /** 管委会主任ID */
  directorID?: number;

  /** 批示文件图片 */
  instructionsImage?: string;

  /** 牌匾图片 */
  plaqueImage?: string;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 是否拥有自己的产品 */
  hasProducts?: boolean;

  /** 是否需要募捐 */
  isFundRaising?: boolean;

  /**  */
  applyStatus?: ApplyStatus;

  /** 基金Id */
  fundID?: number;

  /** 细分类别 */
  fundCategory?: string;

  /** 拒绝原因 */
  rejectReason?: string;

  /** 基金成员 */
  users?: UserExtendMiniDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundModifyApplyDtoListResultDto {
  /**  */
  items?: FundModifyApplyDto[];
}

export interface FundModifyApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundModifyApplyDto[];
}

export interface FundNonCashDonationRecordCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 姓名 */
  name?: string;

  /** 公司 */
  company?: string;

  /** 地址 */
  address?: string;

  /** 邮箱 */
  email?: string;

  /** 家庭电话 */
  homeTelephone?: string;

  /** 手机号码 */
  telephone?: string;

  /** 期望联系方式（1手机 2邮箱 3两种皆可） */
  contactType?: number;

  /** 捐赠类型（1物品，2场地\/其他） */
  donationType?: number;

  /**  */
  donatorType?: DonatorType;

  /**  */
  nonCashDonationItemJson?: string;

  /** 捐赠物品 */
  itemNames?: string;

  /** 合计价格 */
  totalMoney?: number;

  /** 身份证号码 */
  idNo?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /**  */
  id?: number;
}

export interface FundNonCashDonationRecordDto {
  /**  */
  tenantId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 姓名 */
  name?: string;

  /** 公司 */
  company?: string;

  /** 地址 */
  address?: string;

  /** 邮箱 */
  email?: string;

  /** 家庭电话 */
  homeTelephone?: string;

  /** 手机号码 */
  telephone?: string;

  /** 期望联系方式（1手机 2邮箱 3两种皆可） */
  contactType?: number;

  /** 捐赠类型（1物品捐赠，2场地\/其他捐赠） */
  donationType?: number;

  /**  */
  donatorType?: DonatorType;

  /** 捐赠人ID */
  donorId?: number;

  /**  */
  nonCashDonationItemJson?: string;

  /**  */
  status?: ApplyStatus;

  /** 拒绝原因 */
  rejectReason?: string;

  /** 捐赠物品 */
  itemNames?: string;

  /** 合计价格 */
  totalMoney?: number;

  /** 身份证号码 */
  idNo?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundNonCashDonationRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundNonCashDonationRecordDto[];
}

export interface FundNonCashDonationItemRecordDto {
  /**  */
  content?: string;

  /**  */
  count?: string;

  /**  */
  unit?: string;

  /**  */
  money?: string;

  /**  */
  remark?: string;

  /**  */
  createdTime?: Date;

  /**  */
  status?: ApplyStatus;
}

export interface FundNonCashDonationItemRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundNonCashDonationItemRecordDto[];
}

export interface UserNonCashDonationInfoDto {
  /** 实物捐赠审核通过次数 */
  nonCashDonationCount?: number;
}

export interface FundNonCashDonationRecordDtoListResultDto {
  /**  */
  items?: FundNonCashDonationRecordDto[];
}

export interface FundProjectCreateOrUpdateDto {
  /**  */
  savedAttachments?: number[];

  /** 项目标题 */
  name?: string;

  /** 所属基金ID */
  fundID?: number;

  /**  */
  projectType?: FundProjectType;

  /** 项目分类 */
  classificationId?: number;

  /** 标题图片 */
  titleImagePath?: string;

  /** 项目预算 */
  budget?: number;

  /** 项目预算说明 */
  budgetDescription?: string;

  /** 项目执行计划 */
  executionPlan?: string;

  /** 项目周期（月） */
  period?: number;

  /** 服务地区 */
  serviceArea?: string;

  /** 服务领域 */
  serviceField?: string;

  /** 项目目标 */
  projectGoal?: string;

  /** 项目简介 */
  summary?: string;

  /** 项目详细描述 */
  description?: string;

  /** 联系人姓名 */
  contactsName?: string;

  /** 联系人手机号码 */
  contactsMobileTelephone?: string;

  /** 联系人地址 */
  contactsAddress?: string;

  /** 联系人邮箱 */
  contactsEmail?: string;

  /** 开始时间 */
  startTime?: Date;

  /** 结束时间 */
  endTime?: Date;

  /** 项目编号（唯一） */
  code?: string;

  /** 是否需要筹款 */
  isFundRaising?: boolean;

  /** 项目范围 */
  categoryName?: string;

  /** 是否品牌项目 */
  isBrand?: boolean;

  /** 目标筹款金额 */
  targetAmountTotal?: number;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /**  */
  areaIdList?: number[];

  /** 项目成果 */
  projectAchievements?: string;

  /** 项目备案编号 */
  projectRecordNo?: string;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface DataDictionaryShortDto {
  /** 键名 */
  key?: string;

  /** 显示的名称 */
  displayName?: string;

  /**  */
  id?: number;
}

export interface FundProjectDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 项目标题 */
  name?: string;

  /** 所属基金ID */
  fundID?: number;

  /**  */
  fund?: FundMiniDto;

  /**  */
  projectType?: FundProjectType;

  /**  */
  projectTypeName?: string;

  /** 标题图片 */
  titleImagePath?: string;

  /** 项目预算 */
  budget?: number;

  /** 项目预算说明 */
  budgetDescription?: string;

  /** 项目执行计划 */
  executionPlan?: string;

  /** 项目周期（月） */
  period?: number;

  /** 服务地区 */
  serviceArea?: string;

  /** 服务领域 */
  serviceField?: string;

  /** 项目目标 */
  projectGoal?: string;

  /** 项目简介 */
  summary?: string;

  /** 项目详细描述 */
  description?: string;

  /** 联系人姓名 */
  contactsName?: string;

  /** 联系人手机号码 */
  contactsMobileTelephone?: string;

  /** 联系人地址 */
  contactsAddress?: string;

  /** 联系人邮箱 */
  contactsEmail?: string;

  /** 已经筹集捐款金额累计 */
  raisedAmountTotal?: number;

  /** 该项目累计捐款人数 */
  donatedPersonTotal?: number;

  /** 支出金额统计 */
  expenseAmountTotal?: number;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  status?: FundProjectStatus;

  /** 是否已结项 */
  isEnd?: boolean;

  /** 项目编号（唯一） */
  code?: string;

  /** 是否专题项目 */
  isSpecial?: boolean;

  /** 是否需要筹款 */
  isFundRaising?: boolean;

  /** 项目范围 */
  categoryName?: string;

  /**  */
  categoryNameText?: string;

  /** 是否品牌项目 */
  isBrand?: boolean;

  /** 目标筹款金额 */
  targetAmountTotal?: number;

  /** 结项报告 */
  finalReport?: string;

  /** 项目可用余额 */
  availableMoney?: number;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 基金项目领域 */
  areaNames?: string[];

  /** 项目成果 */
  projectAchievements?: string;

  /** 项目备案编号 */
  projectRecordNo?: string;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /** 最后的审核通过时间 */
  lastApprovedTime?: Date;

  /** 是否过期 */
  isExpire?: boolean;

  /** 是否置顶 */
  isTop?: boolean;

  /** 置顶时间 */
  topTime?: Date;

  /** 项目分类Id */
  classificationId?: number;

  /**  */
  classification?: DataDictionaryShortDto;

  /** 是否官网显示 */
  isOfficialWebsiteDisplay?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundProjectDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectDto[];
}

export interface FundProjectSummarizationDto {
  /**  */
  organizationUnitId?: number;

  /** 结项标题 */
  title?: string;

  /** 简介 */
  summary?: string;

  /** 内容 */
  content?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundProjectManualCreateDonateOrderInput {
  /**  */
  fundProjectId?: number;

  /** 捐赠金额 */
  donationAmount?: number;

  /**  */
  userId?: number;

  /** 付款时间 */
  payedTime?: Date;

  /** 付款方式 */
  paymentType?: string;

  /** 手机号码 */
  mobileTelephone?: string;

  /** 捐助者姓名 */
  donorName?: string;

  /** 留言 */
  leaveMessage?: string;

  /** 显示的名称 */
  displayName?: string;
}

export interface FundProjectCloseInput {
  /**  */
  projectId?: number;

  /**  */
  title?: string;

  /**  */
  summary?: string;

  /**  */
  content?: string;
}

export interface FundProjectAccountDto {
  /** 基金项目Id */
  fundProjectId?: number;

  /** 基金项目名称 */
  fundProjectName?: string;

  /** 当前基金账户金额 */
  availableMoney?: number;

  /** 累计收入基金账户金额 */
  accumlativeMoney?: number;

  /** 累计支出金额 */
  appropriatedMoney?: number;

  /** 管理费 */
  manageFee?: number;

  /** 基金项目领域 */
  areaNames?: string;
}

export interface FundProjectAccountDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectAccountDto[];
}

export interface FundProjectApplyCreateOrUpdateDto {
  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 项目标题 */
  name?: string;

  /** 所属基金ID */
  fundID?: number;

  /**  */
  projectType?: FundProjectType;

  /** 项目分类 */
  classificationId?: number;

  /** 标题图片 */
  titleImagePath?: string;

  /** 项目预算 */
  budget?: number;

  /** 项目预算说明 */
  budgetDescription?: string;

  /** 项目执行计划 */
  executionPlan?: string;

  /** 项目周期（月） */
  period?: number;

  /** 服务地区 */
  serviceArea?: string;

  /** 服务领域 */
  serviceField?: string;

  /** 项目目标 */
  projectGoal?: string;

  /** 项目简介 */
  summary?: string;

  /** 项目详细描述 */
  description?: string;

  /** 联系人姓名 */
  contactsName?: string;

  /** 联系人手机号码 */
  contactsMobileTelephone?: string;

  /** 联系人地址 */
  contactsAddress?: string;

  /** 联系人邮箱 */
  contactsEmail?: string;

  /** 开始时间 */
  startTime?: Date;

  /** 结束时间 */
  endTime?: Date;

  /** 项目编号（唯一） */
  code?: string;

  /** 是否需要筹款 */
  isFundRaising?: boolean;

  /** 项目范围 */
  categoryName?: string;

  /** 是否品牌项目 */
  isBrand?: boolean;

  /** 目标筹款金额 */
  targetAmountTotal?: number;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /**  */
  areaIdList?: number[];

  /** 项目备案编号 */
  projectRecordNo?: string;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface FundProjectApplyDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 项目标题 */
  name?: string;

  /** 所属基金ID */
  fundID?: number;

  /**  */
  fund?: FundMiniDto;

  /**  */
  projectType?: FundProjectType;

  /**  */
  projectTypeName?: string;

  /** 标题图片 */
  titleImagePath?: string;

  /** 项目预算 */
  budget?: number;

  /** 项目预算说明 */
  budgetDescription?: string;

  /** 项目执行计划 */
  executionPlan?: string;

  /** 项目周期（月） */
  period?: number;

  /** 服务地区 */
  serviceArea?: string;

  /** 服务领域 */
  serviceField?: string;

  /** 项目目标 */
  projectGoal?: string;

  /** 项目简介 */
  summary?: string;

  /** 项目详细描述 */
  description?: string;

  /** 联系人姓名 */
  contactsName?: string;

  /** 联系人手机号码 */
  contactsMobileTelephone?: string;

  /** 联系人地址 */
  contactsAddress?: string;

  /** 联系人邮箱 */
  contactsEmail?: string;

  /** 已经筹集捐款金额累计 */
  raisedAmountTotal?: number;

  /** 该项目累计捐款人数 */
  donatedPersonTotal?: number;

  /** 支出金额统计 */
  expenseAmountTotal?: number;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  status?: FundProjectStatus;

  /** 项目编号（唯一） */
  code?: string;

  /** 是否专题项目 */
  isSpecial?: boolean;

  /** 是否需要筹款 */
  isFundRaising?: boolean;

  /** 项目范围 */
  categoryName?: string;

  /**  */
  categoryNameText?: string;

  /** 是否品牌项目 */
  isBrand?: boolean;

  /** 目标筹款金额 */
  targetAmountTotal?: number;

  /** 结项报告 */
  finalReport?: string;

  /** 项目可用余额 */
  availableMoney?: number;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 项目备案编号 */
  projectRecordNo?: string;

  /**  */
  creationTime?: Date;

  /** 基金项目领域 */
  areaNames?: string[];

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /** 最后的提交时间 */
  lastSubmitTime?: Date;

  /** 项目分类Id */
  classificationId?: number;

  /**  */
  classification?: DataDictionaryShortDto;

  /**  */
  id?: number;
}

export interface FundProjectApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectApplyDto[];
}

export interface FundProjectApplyDtoListResultDto {
  /**  */
  items?: FundProjectApplyDto[];
}

export interface FundProjectCmsContentCreateInput {
  /**  */
  projectId?: number;

  /**  */
  cmsContentId?: number;
}

export interface CmsContentMiniDto {
  /**  */
  status?: CmsContentStatus;

  /**  */
  title?: string;

  /**  */
  summary?: string;

  /**  */
  author?: string;

  /** 发布时间 */
  publishTime?: Date;

  /** 内容来源 */
  source?: string;

  /**  */
  id?: number;
}

export interface FundProjectCmsContentDto {
  /**  */
  projectId?: number;

  /**  */
  project?: FundProjectMiniDto;

  /**  */
  cmsContentId?: number;

  /**  */
  content?: CmsContentMiniDto;

  /**  */
  id?: number;
}

export interface FundProjectCmsContentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectCmsContentDto[];
}

export interface FundProjectDonationRecordDto {
  /**  */
  projectId?: number;

  /**  */
  project?: FundProjectMiniDto;

  /** 项目所属基金 */
  fundId?: number;

  /**  */
  fund?: FundMiniDto;

  /**  */
  fundProductId?: number;

  /** 捐助者类型（1为个人，2为单位） */
  donorType?: number;

  /** 捐款人姓名 */
  displayName?: string;

  /** 捐款时间 */
  donateTime?: Date;

  /** 捐款金额 */
  donateMoney?: number;

  /** 捐助内容 */
  content?: string;

  /** 捐助者留言 */
  leaveMessage?: string;

  /** 记录来源:0,手动录入，1：用户捐款 */
  recordSource?: number;

  /** 捐助者手机号 */
  donorMobilePhone?: string;

  /** 线上捐款订单号 */
  orderNO?: string;

  /** 捐赠类型（1为线上捐款，2为线下捐款) */
  paymentType?: number;

  /** 捐赠人，未登录状态捐赠时可为空 */
  userId?: number;

  /** 捐赠类型（1为捐给项目，2为捐给基金) */
  donationType?: number;

  /** 是否月捐 */
  isMonthDonor?: boolean;

  /** 推荐人 */
  referrer?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundProjectDonationRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectDonationRecordDto[];
}

export interface FundProjectModifyApplyCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  projectId?: number;

  /** 项目标题 */
  name?: string;

  /** 所属基金ID */
  fundID?: number;

  /**  */
  projectType?: FundProjectType;

  /** 项目分类 */
  classificationId?: number;

  /** 标题图片 */
  titleImagePath?: string;

  /** 项目预算 */
  budget?: number;

  /** 项目预算说明 */
  budgetDescription?: string;

  /** 项目执行计划 */
  executionPlan?: string;

  /** 项目周期（月） */
  period?: number;

  /** 服务地区 */
  serviceArea?: string;

  /** 服务领域 */
  serviceField?: string;

  /** 项目目标 */
  projectGoal?: string;

  /** 项目简介 */
  summary?: string;

  /** 项目详细描述 */
  description?: string;

  /** 联系人姓名 */
  contactsName?: string;

  /** 联系人手机号码 */
  contactsMobileTelephone?: string;

  /** 联系人地址 */
  contactsAddress?: string;

  /** 联系人邮箱 */
  contactsEmail?: string;

  /** 开始时间 */
  startTime?: Date;

  /** 结束时间 */
  endTime?: Date;

  /** 项目编号（唯一） */
  code?: string;

  /** 是否需要筹款 */
  isFundRaising?: boolean;

  /** 项目范围 */
  categoryName?: string;

  /** 是否品牌项目 */
  isBrand?: boolean;

  /** 目标筹款金额 */
  targetAmountTotal?: number;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /**  */
  areaIdList?: number[];

  /** 项目成果 */
  projectAchievements?: string;

  /** 项目备案编号 */
  projectRecordNo?: string;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface FundProjectModifyApplyDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 项目标题 */
  name?: string;

  /**  */
  projectId?: number;

  /**  */
  project?: FundProjectDto;

  /** 所属基金ID */
  fundID?: number;

  /**  */
  fund?: FundMiniDto;

  /**  */
  projectType?: FundProjectType;

  /**  */
  projectTypeName?: string;

  /** 标题图片 */
  titleImagePath?: string;

  /** 项目预算 */
  budget?: number;

  /** 项目预算说明 */
  budgetDescription?: string;

  /** 项目执行计划 */
  executionPlan?: string;

  /** 项目周期（月） */
  period?: number;

  /** 服务地区 */
  serviceArea?: string;

  /** 服务领域 */
  serviceField?: string;

  /** 项目目标 */
  projectGoal?: string;

  /** 项目简介 */
  summary?: string;

  /** 项目详细描述 */
  description?: string;

  /** 联系人姓名 */
  contactsName?: string;

  /** 联系人手机号码 */
  contactsMobileTelephone?: string;

  /** 联系人地址 */
  contactsAddress?: string;

  /** 联系人邮箱 */
  contactsEmail?: string;

  /** 已经筹集捐款金额累计 */
  raisedAmountTotal?: number;

  /** 该项目累计捐款人数 */
  donatedPersonTotal?: number;

  /** 支出金额统计 */
  expenseAmountTotal?: number;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  status?: FundProjectStatus;

  /** 项目编号（唯一） */
  code?: string;

  /** 是否专题项目 */
  isSpecial?: boolean;

  /** 是否需要筹款 */
  isFundRaising?: boolean;

  /** 项目范围 */
  categoryName?: string;

  /**  */
  categoryNameText?: string;

  /** 是否品牌项目 */
  isBrand?: boolean;

  /** 目标筹款金额 */
  targetAmountTotal?: number;

  /** 结项报告 */
  finalReport?: string;

  /** 项目可用余额 */
  availableMoney?: number;

  /** 是否可以匿名捐赠 */
  canAnonymous?: boolean;

  /** 基金项目领域 */
  areaNames?: string[];

  /** 项目成果 */
  projectAchievements?: string;

  /** 项目备案编号 */
  projectRecordNo?: string;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /** 最后的提交时间 */
  lastSubmitTime?: Date;

  /** 项目分类Id */
  classificationId?: number;

  /**  */
  classification?: DataDictionaryShortDto;

  /**  */
  id?: number;
}

export interface FundProjectModifyApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectModifyApplyDto[];
}

export interface FundProjectModifyApplyDtoListResultDto {
  /**  */
  items?: FundProjectModifyApplyDto[];
}

export interface FundProjectProgressCreateOrUpdateDto {
  /**  */
  organizationUnitId?: number;

  /** 进展标题 */
  title?: string;

  /** 所属项目Id */
  projectId?: number;

  /** 简述 */
  summary?: string;

  /**  */
  remark?: string;

  /** 活动进展图片，以","分隔 */
  images?: string;

  /**  */
  publishedTime?: Date;

  /**  */
  status?: ValidStatus;

  /**  */
  id?: number;
}

export interface FundProjectProgressDto {
  /**  */
  organizationUnitId?: number;

  /** 进展标题 */
  title?: string;

  /** 所属项目Id */
  projectId?: number;

  /**  */
  fundProject?: FundProjectMiniDto;

  /** 简述 */
  summary?: string;

  /**  */
  status?: ValidStatus;

  /**  */
  publishedTime?: Date;

  /**  */
  publishUserId?: number;

  /**  */
  publishUserName?: string;

  /**  */
  remark?: string;

  /** 活动进展图片，以","分隔 */
  images?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /** 基金项目领域 */
  areaNames?: string[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundProjectProgressDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectProgressDto[];
}

export interface FundProjectMixtureMiniDto {
  /**  */
  fund?: FundMiniDto;

  /** 项目标题 */
  name?: string;

  /**  */
  projectType?: FundProjectType;

  /**  */
  id?: number;
}

export interface FundProjectYearSummaryDto {
  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  fundProjectId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  fundProject?: FundProjectMixtureMiniDto;

  /** 服务区域 */
  serviceArea?: string;

  /** 受益人数 */
  benefitNumber?: string;

  /** 活动效果 */
  activityEffect?: string;

  /**  */
  summarization?: string;

  /** 年份 */
  year?: number;

  /** 基金会点评 */
  appraise?: string;

  /** 点评时间 */
  appraisedTime?: Date;

  /** 点评人 */
  appraiseUserId?: number;

  /**  */
  appraiseUser?: UserMiniDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundProjectYearSummaryAppraiseInput {
  /**  */
  appraise?: string;

  /**  */
  id?: number;
}

export interface FundProjectYearSummaryCreateOrUpdateDto {
  /**  */
  fundProjectId?: number;

  /**  */
  organizationUnitId?: number;

  /** 服务区域 */
  serviceArea?: string;

  /** 受益人数 */
  benefitNumber?: string;

  /** 活动效果 */
  activityEffect?: string;

  /**  */
  summarization?: string;

  /** 年份 */
  year?: number;

  /**  */
  id?: number;
}

export interface FundProjectYearSummaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundProjectYearSummaryDto[];
}

export interface FundUserCreateOrUpdateDto {
  /** 账号 */
  userName?: string;

  /** 密码 */
  password?: string;

  /** 昵称 */
  name?: string;

  /** 邮箱 */
  emailAddress?: string;

  /** 姓名 */
  surname?: string;

  /** 手机号码 */
  phoneNumber?: string;

  /** 基金Id */
  fundId?: number;

  /** 性别 */
  sex?: string;

  /** 职务（数据字典且有对应枚举，所以value为1,2,3,4...） */
  duty?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 所在企业 */
  company?: string;

  /** 企业职务 */
  companyDuty?: string;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /**  */
  id?: number;
}

export interface FundUserDto {
  /** 用户Id */
  userId?: number;

  /** 账号 */
  userName?: string;

  /** 昵称 */
  name?: string;

  /** 邮箱 */
  emailAddress?: string;

  /** 姓名 */
  surname?: string;

  /** 手机号码 */
  phoneNumber?: string;

  /** 基金Id */
  fundId?: number;

  /** 基金名称 */
  fundName?: string;

  /** 性别 */
  sex?: string;

  /** 职务（数据字典且有对应枚举，所以value为1,2,3,4...） */
  duty?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 所在企业 */
  company?: string;

  /** 企业职务 */
  companyDuty?: string;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FundUserDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FundUserDto[];
}

export interface HelpAppliesCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  title?: string;

  /** 省Id */
  provinceId?: number;

  /** 市Id */
  cityId?: number;

  /** 区Id */
  areaId?: number;

  /** 地址 */
  address?: string;

  /** 联系人姓名 */
  name?: string;

  /** 联系人手机号 */
  phone?: string;

  /** 内容 */
  content?: string;

  /**  */
  attachmentStr?: string;

  /**  */
  id?: number;
}

export interface HelpAppliesDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  tobeOrganizationUnit?: OrganizationUnitDto;

  /** 待分配机构，当易和书院分配后，该字段分配到OrgId中 */
  tobeOrganizationUnitId?: number;

  /**  */
  organizationUnitId?: number;

  /** 求助主题 */
  title?: string;

  /** 内容 */
  content?: string;

  /** 联系人姓名 */
  name?: string;

  /** 性别 */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 联系人手机号 */
  phone?: string;

  /** 身体状况 */
  physicalStateId?: number;

  /**  */
  physicalState?: DataDictionary;

  /** 服务对象类型 */
  serviceObjectTypeId?: number;

  /**  */
  serviceObjectType?: DataDictionary;

  /** 其他身体状况 */
  physicalStateOther?: string;

  /** 住址 */
  address?: string;

  /** 拒绝理由 */
  rejectReason?: string;

  /** 关联服务数量 */
  serviceCount?: number;

  /** 求助人本人申请 */
  isForMe?: boolean;

  /** 机构名称 */
  orgName?: string;

  /** 机构联系人 */
  orgPrincipalName?: string;

  /** 机构编码 */
  orgCode?: string;

  /** 机构联系电话 */
  orgPrincipalMobilePhone?: string;

  /** 机构规模 */
  orgUserCount?: string;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface HelpAppliesSubmitDto {
  /** 求助主题 */
  title?: string;

  /** 内容 */
  content?: string;

  /** 求助人 */
  name?: string;

  /** 性别 */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 手机号码 */
  phone?: string;

  /** 身体状况 */
  physicalStateId?: number;

  /** 服务对象类型 */
  serviceObjectTypeId?: number;

  /** 其他身体状况 */
  physicalStateOther?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /** 住址 */
  address?: string;

  /**  */
  savedAttachments?: number[];

  /** 求助人本人申请 */
  isForMe?: boolean;

  /** 机构名称 */
  orgName?: string;

  /** 机构联系人 */
  orgPrincipalName?: string;

  /** 机构联系电话 */
  orgPrincipalMobilePhone?: string;

  /** 机构编码 */
  orgCode?: string;

  /** 机构规模 */
  orgUserCount?: string;

  /** 志愿者Id */
  volunteerId?: number;

  /** 待分配机构，当易和书院分配后，该字段分配到OrgId中 */
  tobeOrganizationUnitId?: number;

  /**  */
  id?: number;
}

export interface DistributionAndUpdateOrgInputDto {
  /** 待分配机构 */
  tobeOrganizationUnitId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ServiceOrganizationDto {
  /** 服务机构名称 */
  name?: string;

  /** 服务机构类型Id */
  serviceOrganizationTypeId?: number;

  /**  */
  serviceOrganizationType?: DataDictionaryDto;

  /** 负责人 */
  contactName?: string;

  /** 负责人姓名 */
  contactPhone?: string;

  /** 省份Id */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /** 城市Id */
  cityId?: number;

  /**  */
  city?: CityDto;

  /** 区域Id */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 详细信息 */
  address?: string;

  /** 基本情况 */
  basicInfo?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ServicesObjectDto {
  /** 会员单位 */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 服务机构 */
  serviceOrganizationId?: number;

  /**  */
  serviceOrganization?: ServiceOrganizationDto;

  /** 姓名 */
  name?: string;

  /** 生日 */
  birthday?: Date;

  /** 手机号 */
  phone?: string;

  /** 性别 */
  sex?: string;

  /** 省市区 */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 详细地址 */
  address?: string;

  /** 基本情况 */
  remark?: string;

  /** 服务对象类型Id */
  serviceObjectTypeId?: number;

  /**  */
  serviceObjectType?: DataDictionaryDto;

  /** 身体情况(数据字典) */
  physicalStateId?: number;

  /**  */
  physicalState?: DataDictionaryDto;

  /** 其他身体情况 */
  physicalStateOther?: string;

  /**  */
  serviceTitle?: string;

  /**  */
  serviceContent?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface HelpAppliesDtoListResultDto {
  /**  */
  items?: HelpAppliesDto[];
}

export interface HelpAppliesDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: HelpAppliesDto[];
}

export interface HonorCertificateCreateOrUpdateDto {
  /** 证书名称 */
  title?: string;

  /** 证书描述 */
  description?: string;

  /** 图标 */
  imagePath?: string;

  /**  */
  type?: CertificateType;

  /**  */
  id?: number;
}

export interface HonorCertificateDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /** 证书名称 */
  title?: string;

  /** 证书描述 */
  description?: string;

  /** 图标 */
  imagePath?: string;

  /**  */
  type?: CertificateType;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface IssueCertificatePostDto {
  /**  */
  certificateType?: CertificateType;

  /** 用户Id */
  userIds?: number[];
}

export interface HonorCertificateDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: HonorCertificateDto[];
}

export interface InternalMonthlyPlanDetailCreateOrUpdateDto {
  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 内容概述 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /**  */
  savedPartnerOrgIds?: number[];

  /** 所需支持 */
  needSupport?: string;

  /** 备注 */
  remark?: string;

  /**  */
  projectId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  enableRestartAudit?: boolean;
}

export interface InternalMonthlyPlanCreateOrUpdateDto {
  /** 日期 */
  date?: Date;

  /**  */
  auditFlowId?: string;

  /**  */
  projectId?: number;

  /**  */
  details?: InternalMonthlyPlanDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlyPlanDetailDto {
  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 内容概述 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /**  */
  savedPartnerOrgIds?: number[];

  /** 所需支持 */
  needSupport?: string;

  /** 备注 */
  remark?: string;

  /**  */
  projectId?: number;

  /**  */
  internalMonthlyPlanId?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlyPlanDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 日期 */
  date?: Date;

  /** 年 */
  year?: number;

  /** 对应的月份 */
  month?: number;

  /**  */
  details?: InternalMonthlyPlanDetailDto[];

  /**  */
  project?: ProjectDto;

  /**  */
  id?: number;
}

export interface InternalMonthlyPlanDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalMonthlyPlanDto[];
}

export interface InternalMonthlyPlanDtoListResultDto {
  /**  */
  items?: InternalMonthlyPlanDto[];
}

export interface InternalMonthlySummarizeDetailCreateOrUpdateDto {
  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 原计划 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /**  */
  savedPartnerOrgIds?: number[];

  /** 执行概述 */
  executeSummary?: string;

  /** 受益人次 */
  benefitObjectCount?: number;

  /** 备注 */
  remark?: string;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /**  */
  savedAttachments?: number[];

  /**  */
  projectId?: number;

  /**  */
  internalMonthlyPlanDetailId?: number;
}

export interface InternalMonthlySummarizeCreateOrUpdateDto {
  /** 日期 */
  date?: Date;

  /**  */
  auditFlowId?: string;

  /** 对应月计划Id */
  internalMonthlyPlanId?: number;

  /**  */
  projectId?: number;

  /**  */
  details?: InternalMonthlySummarizeDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlySummarizeDetailDto {
  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 原计划 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /** 执行概述 */
  executeSummary?: string;

  /** 受益人次 */
  benefitObjectCount?: number;

  /** 备注 */
  remark?: string;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /**  */
  internalMonthlySummarizeId?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  savedPartnerOrgIds?: number[];

  /**  */
  projectId?: number;

  /**  */
  internalMonthlyPlanDetailId?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlySummarizeDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 日期 */
  date?: Date;

  /**  */
  year?: number;

  /**  */
  month?: number;

  /** 对应月计划Id */
  internalMonthlyPlanId?: number;

  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  details?: InternalMonthlySummarizeDetailDto[];

  /**  */
  id?: number;
}

export interface InternalMonthlySummarizeDtoListResultDto {
  /**  */
  items?: InternalMonthlySummarizeDto[];
}

export interface InternalMonthlySummarizeDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalMonthlySummarizeDto[];
}

export interface InternalPersonalYearEndSummaryCreateOrUpdateDto {
  /** 年 */
  year?: number;

  /**  */
  role?: string;

  /**  */
  userName?: string;

  /**  */
  summary?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalPersonalYearEndSummaryDto {
  /** 年 */
  year?: number;

  /**  */
  role?: string;

  /**  */
  userName?: string;

  /**  */
  summary?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalPersonalYearEndSummaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalPersonalYearEndSummaryDto[];
}

export interface InternalPersonalYearEndSummaryDtoListResultDto {
  /**  */
  items?: InternalPersonalYearEndSummaryDto[];
}

export interface InternalProjectDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 项目概述 */
  summary?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 总预算 */
  totalMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface InternalProjectDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalProjectDto[];
}

export interface InternalProjectBudgetItemDto {
  /**  */
  projectId?: number;

  /** 序列(显示顺序） */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /**  */
  id?: string;
}

export interface InternalProjectCreateOrUpdateDto {
  /** 项目名称 */
  displayName?: string;

  /** 项目类型，如：,1,11,111, */
  projectTypes?: string;

  /** 项目概述 */
  summary?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 项目预算明细 */
  budgetItems?: InternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface InternalProjectDetailDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 项目名称 */
  displayName?: string;

  /** 项目类型，如：,1,11,111, */
  projectTypes?: string;

  /** 项目概述 */
  summary?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 项目预算明细 */
  budgetItems?: InternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface UpdateInternalProjectBudgetItemDto {
  /** 项目预算明细 */
  budgetItems?: InternalProjectBudgetItemDto[];

  /**  */
  id?: number;
}

export interface InternalProjectDtoListResultDto {
  /**  */
  items?: InternalProjectDto[];
}

export interface InternalProjectPhasesItemDto {
  /**  */
  stage?: WorkflowOfProjectByInternal;

  /** 节点名称 */
  name?: string;

  /**  */
  status?: ProjectPhaseStatus;

  /** 跳转链接 */
  redirectUrl?: string[];
}

export interface InternalMicrocosmicDto {
  /**  */
  projectName?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  status?: WorkflowOfProjectByInternal;

  /** 活动场次 */
  activityCount?: number;

  /** 受益人次 */
  actualBeneficiaryCount?: number;

  /** 受益人数 */
  beneficiaryCount?: number;

  /** 覆盖区域数量 */
  townCount?: number;

  /** 各类型合作方数量 */
  organizationUnitCount?: object;

  /** 预算金额 */
  budget?: number;

  /** 实际支出 */
  actualExpenses?: number;

  /** 服务次数 */
  serviceCount?: number;
}

export interface InternalMicrocosmicDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalMicrocosmicDto[];
}

export interface InternalProjectSummaryReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 年度 */
  year?: number;

  /** 填写人 */
  userName?: string;

  /** 项目总结内容 */
  summary?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalProjectSummaryReportDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 年度 */
  year?: number;

  /** 填写人 */
  userName?: string;

  /** 项目总结内容 */
  summary?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalProjectSummaryReportDtoListResultDto {
  /**  */
  items?: InternalProjectSummaryReportDto[];
}

export interface InternalProjectSummaryReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalProjectSummaryReportDto[];
}

export interface JobCreateOrUpdateDto {
  /** 信息标题 */
  title?: string;

  /** 地址 */
  address?: string;

  /** 招聘人数 */
  recruitNumber?: number;

  /**  */
  description?: string;

  /** 薪资 */
  salary?: string;

  /** 工作经验 */
  experience?: string;

  /** 学历条件 */
  education?: string;

  /**  */
  id?: number;
}

export interface JobDto {
  /**  */
  creatorUser?: UserMiniDto;

  /** 信息标题 */
  title?: string;

  /** 封面图片 */
  titleImagePath?: string;

  /** 工作城市 */
  city?: string;

  /** 地址 */
  address?: string;

  /** 联系方式 */
  contact?: string;

  /** 薪资 */
  salary?: string;

  /** 招聘人数 */
  recruitNumber?: number;

  /**  */
  description?: string;

  /** 学历条件 */
  education?: string;

  /**  */
  status?: ValidStatus;

  /** 发布时间 */
  publishedTime?: Date;

  /** 工作经验 */
  experience?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface JobDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: JobDto[];
}

export interface LecturerPagedResultRequestDto {
  /** 姓名 */
  name?: string;

  /** 手机号码 */
  mobile?: string;

  /** 性别 */
  sex?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface LecturerDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitDto;

  /** 头像 */
  headImageUrl?: string;

  /** 姓名 */
  name?: string;

  /** 手机号码 */
  mobile?: string;

  /** 性别 */
  sex?: string;

  /** 生日 */
  birthday?: Date;

  /** 省份Id */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /** 城市Id */
  cityId?: number;

  /**  */
  city?: CityDto;

  /** 区域Id */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 详细地址 */
  address?: string;

  /** 简介 */
  summary?: string;

  /** 培训次数 */
  trainCount?: number;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface LecturerDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: LecturerDto[];
}

export interface LecturerCreateOrUpdateDto {
  /**  */
  organizationUnitId?: number;

  /** 头像 */
  headImageUrl?: string;

  /** 姓名 */
  name?: string;

  /** 手机号码 */
  mobile?: string;

  /** 性别 */
  sex?: string;

  /** 生日 */
  birthday?: Date;

  /** 省份Id */
  provinceId?: number;

  /** 城市Id */
  cityId?: number;

  /** 区域Id */
  areaId?: number;

  /** 详细地址 */
  address?: string;

  /** 简介 */
  summary?: string;

  /** 培训次数 */
  trainCount?: number;

  /**  */
  id?: number;
}

export interface MerchantCreateOrUpdateDto {
  /** 基金Id */
  fundId?: number;

  /** 单位名称 */
  unitName?: string;

  /** 单位地址 */
  unitAddress?: string;

  /** 负责人姓名 */
  principalName?: string;

  /** 负责人手机号 */
  principalMobilePhone?: string;

  /** 负责人邮箱 */
  principalEmailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /**  */
  id?: number;
}

export interface MerchantDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: MerchantDto[];
}

export interface MerchantBankAccountCreateOrUpdateDto {
  /** 客商主表Id */
  merchantId?: number;

  /**  */
  bankTypePK?: string;

  /** 银行名称 */
  bankNamePK?: string;

  /** 收款人姓名 */
  payeeName?: string;

  /** 收款人电话 */
  payeePhone?: string;

  /** 户名 */
  bankAccountName?: string;

  /** 银行账号 */
  bankAccountCode?: string;

  /** 开户银行名称 */
  bankName?: string;

  /**  */
  id?: number;
}

export interface MerchantBankAccountDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: MerchantBankAccountDto[];
}

export interface MessageCreateOrUpdateDto {
  /**  */
  savedAttachments?: number[];

  /** 消息标题 */
  title?: string;

  /** 消息内容 */
  content?: string;

  /**  */
  messageType?: MessageType;

  /**  */
  status?: ValidStatus;

  /**  */
  id?: number;
}

export interface MessageDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: MessageDto[];
}

export interface MusicMachineDonateDetailCreateOrUpdateDto {
  /** 序号 */
  index?: number;

  /** 年份 */
  year?: number;

  /** 月份 */
  month?: number;

  /** 投放时间 */
  donationTime?: string;

  /** 智能机版本 */
  version?: string;

  /** 省份 */
  provinceId?: number;

  /** 城市 */
  cityId?: number;

  /** 区县 */
  areaId?: number;

  /** 乡镇 */
  townId?: number;

  /** 学校 */
  address?: string;

  /** 数量 */
  count?: string;

  /** 智能机价格(元) */
  money?: number;

  /**  */
  id?: number;
}

export interface MusicMachineDonateDetailDto {
  /** 序号 */
  index?: number;

  /** 年份 */
  year?: number;

  /** 月份 */
  month?: number;

  /** 投放时间 */
  donationTime?: string;

  /** 智能机版本 */
  version?: string;

  /** 省份 */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /** 城市 */
  cityId?: number;

  /**  */
  city?: CityDto;

  /** 区县 */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 乡镇 */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 学校 */
  address?: string;

  /** 数量 */
  count?: string;

  /** 智能机价格(元) */
  money?: number;

  /**  */
  id?: number;
}

export interface MusicMachineDonateDetailDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: MusicMachineDonateDetailDto[];
}

export interface NotifyTemplateDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 评估计划模板名称 */
  title?: string;

  /** 评估计划模板内容（富文本） */
  content?: string;

  /**  */
  notifyTemplateType?: NotifyTemplateType;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface NotifyRecordDetailDto {
  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  project?: ProjectDto;

  /**  */
  notifyTemplate?: NotifyTemplateDto;

  /** 项目Id */
  hostId?: number;

  /**  */
  notifyType?: NotifyTemplateType;

  /** 通知模板Id */
  notifyTemplateId?: number;

  /** 通知标题 */
  title?: string;

  /** 通知内容（富文本） */
  content?: string;

  /**  */
  id?: number;
}

export interface NotifyRecordCreateOrUpdateDto {
  /** 项目Id */
  hostId?: number;

  /**  */
  notifyType?: NotifyTemplateType;

  /** 通知模板Id */
  notifyTemplateId?: number;

  /** 通知标题 */
  title?: string;

  /** 通知内容（富文本） */
  content?: string;

  /**  */
  id?: number;
}

export interface NotifyRecordDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目Id */
  hostId?: number;

  /**  */
  notifyType?: NotifyTemplateType;

  /** 通知模板Id */
  notifyTemplateId?: number;

  /** 通知标题 */
  title?: string;

  /** 通知内容（富文本） */
  content?: string;

  /** 发送时间 */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface NotifyRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: NotifyRecordDto[];
}

export interface NotifyTemplateDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: NotifyTemplateDto[];
}

export interface NotifyTemplateCreateOrUpdateDto {
  /** 评估计划模板名称 */
  title?: string;

  /** 评估计划模板内容（富文本） */
  content?: string;

  /**  */
  notifyTemplateType?: NotifyTemplateType;

  /**  */
  id?: number;
}

export interface OrderCreateOrUpdateDto {
  /** 订单号 */
  orderNo?: string;

  /** 关联表Id */
  applyId?: number;

  /** 标题 */
  title?: string;

  /** 图片 */
  titleImagePath?: string;

  /**  */
  type?: OrderType;

  /** 订单金额 */
  priceTotal?: number;

  /** 付款金额 */
  payedMoney?: number;

  /** 是否在线支付 */
  isOnlinePayed?: boolean;

  /** 备注 */
  remark?: string;

  /**  */
  status?: OrderStatus;

  /** 支付时间 */
  payedTime?: Date;

  /** 交易号 */
  tradeNo?: string;

  /** 是否回调 */
  isResponse?: boolean;

  /** 回调时间 */
  responseTime?: Date;

  /** 回调结果 */
  resultMessage?: string;

  /** 取消时间 */
  cancelTime?: Date;

  /** 取消原因 */
  cancelReason?: string;

  /**  */
  id?: number;
}

export interface OrderCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: OrderCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface OrderDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: OrderDto[];
}

export interface OrganizationUnitPagedResultRequestDto {
  /**  */
  displayName?: string;

  /** 机构的类型(数据字典的ID） */
  organizationType?: number;

  /**  */
  isApply?: boolean;

  /**  */
  status?: OrganizationStatus;

  /**  */
  principalName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface OrganizationUnitCreateOrUpdateDto {
  /**  */
  inviteCode?: string;

  /**  */
  logoImageUrl?: string;

  /**  */
  displayName?: string;

  /** 机构类型,数据字典Id */
  organizationType?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 地点 */
  place?: string;

  /** 负责人姓名 */
  principalName?: string;

  /** 主要负责人的手机号 */
  mobilePhone?: string;

  /** 主要负责人的邮箱 */
  emailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 合作方银行账号名称 */
  bankAccountName?: string;

  /** 开户行信息 */
  bankName?: string;

  /** 开户账号 */
  bankAccount?: string;

  /** 收件人 */
  receiverName?: string;

  /** 收件地址 */
  receiverAddress?: string;

  /** 收件电话 */
  receiverMobile?: string;

  /** 法人登记证书复印件 */
  certificateImagePath?: string;

  /**  */
  extensionData?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  extensionObject?: object;

  /**  */
  formId?: number;

  /**  */
  id?: number;
}

export interface PartnerInviteDto {
  /** 被邀请的机构名称 */
  organizationName?: string;

  /**  */
  organizationType?: number;

  /**  */
  emailAddress?: string;

  /**  */
  mobile?: string;

  /** 邀约的说明内容 */
  content?: string;

  /**  */
  hasUsed?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface OrganizationUnitLoginAccountResult {
  /**  */
  userId?: number;

  /**  */
  userName?: string;

  /**  */
  password?: string;
}

export interface OrganizationUnitDtoListResultDto {
  /**  */
  items?: OrganizationUnitDto[];
}

export interface OrganizationUnitDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: OrganizationUnitDto[];
}

export interface PartnerCapitalApplyDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 资金用途 */
  purpose?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算（元） */
  budgetQuotation?: number;

  /** 配比资金（元） */
  otherFund?: number;

  /** 配比资金来源 */
  otherFundSource?: string;

  /**  */
  id?: string;
}

export interface PartnerCapitalApplyCreateOrUpdateDto {
  /** 项目Id */
  projectId?: number;

  /** 机构联系人 */
  contactName?: string;

  /** 申请理由 */
  applyReason?: string;

  /** 资金预算明细 */
  partnerCapitalApplyDetails?: PartnerCapitalApplyDetailCreateOrUpdateDto[];

  /** 合计申请资金（元） */
  totalApplyMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface PartnerCapitalApplyDto {
  /** 项目Id */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 机构联系人 */
  contactName?: string;

  /** 申请理由 */
  applyReason?: string;

  /** 资金预算明细 */
  partnerCapitalApplyDetails?: PartnerCapitalApplyDetailCreateOrUpdateDto[];

  /** 合计申请资金（元） */
  totalApplyMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface PartnerCapitalApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PartnerCapitalApplyDto[];
}

export interface PartnerCapitalApplyDtoListResultDto {
  /**  */
  items?: PartnerCapitalApplyDto[];
}

export interface PartnerInviteCreateOrUpdateDto {
  /** 被邀请的机构名称 */
  organizationName?: string;

  /** 机构类型,数据字典Id */
  organizationType?: number;

  /**  */
  emailAddress?: string;

  /**  */
  mobile?: string;

  /** 邀约的说明内容 */
  content?: string;

  /**  */
  id?: number;
}

export interface PartnerInviteDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PartnerInviteDto[];
}

export interface TenPayNotifyXml {
  /**  */
  appid?: string;

  /**  */
  mch_id?: string;

  /**  */
  device_info?: string;

  /**  */
  nonce_str?: string;

  /**  */
  sign?: string;

  /**  */
  result_code?: string;

  /**  */
  err_code?: string;

  /**  */
  err_code_des?: string;

  /** 交易类型 */
  trade_type?: string;

  /** 付款银行 ,银行类型，采用字符串类型的银行标识，银行类型见银行列表 */
  bank_type?: string;

  /** is_subscribe 用户是否关注公众账号，Y-关注，N-未关注 */
  is_subscribe?: string;

  /**  */
  openid?: string;

  /**  */
  total_fee?: string;

  /**  */
  settlement_total_fee?: number;

  /**  */
  fee_type?: string;

  /**  */
  cash_fee?: string;

  /**  */
  cash_fee_type?: string;

  /** 微信支付订单号 */
  transaction_id?: string;

  /** 商户订单号 */
  out_trade_no?: string;

  /** 支付完成时间 */
  time_end?: string;

  /**  */
  return_code?: string;

  /**  */
  return_msg?: string;
}

export interface SummaryDto {
  /**  */
  totalBeneficiaries?: number;

  /**  */
  totalProjects?: number;

  /**  */
  totalAmount?: number;
}

export interface PermissionNameSchemeCreateOrUpdateDto {
  /**  */
  displayName?: string;

  /**  */
  tenantId?: number;
}

export interface PermissionDefinitionCreateOrUpdateDto {
  /** 权限的键 */
  name?: string;

  /** 显示的名称 */
  displayName?: string;

  /**  */
  displayOrder?: number;

  /** 依赖的Feature */
  requireFeature?: string;

  /**  */
  url?: string;

  /** 是否不在菜单中显示 */
  isHiddenForMenu?: boolean;

  /**  */
  icon?: string;

  /**  */
  parentId?: number;

  /**  */
  permissionDefinitionScopes?: PermissionDefinitionScope[];

  /** 权限适用端 */
  multiTenancySides?: string;

  /** 开启数据权限 */
  enableDataPermission?: boolean;

  /** 开启按创建人过滤，其中包括“仅自己、本部门、本部门及下属部门” */
  enableDataPermissionForCreator?: boolean;

  /** 开启数据范围过滤 */
  enableDataPermissionSpecifiedLimit?: boolean;

  /** 指定数据范围的数据源。 当开启数据范围过滤时，填写此值。 */
  dataPermissionSpecifiedDataSourceProvider?: string;

  /**  */
  nameSchemes?: PermissionNameSchemeCreateOrUpdateDto[];

  /**  */
  id?: number;
}

export interface PermissionDefinitionDto {
  /** 权限的键 */
  name?: string;

  /** 显示的名称 */
  displayName?: string;

  /** 依赖的Feature */
  requireFeature?: string;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  url?: string;

  /** 是否不在菜单中显示 */
  isHiddenForMenu?: boolean;

  /**  */
  icon?: string;

  /**  */
  parentId?: number;

  /**  */
  permissionDefinitionScopes?: PermissionDefinitionScope[];

  /**  */
  multiTenancySides?: string;

  /** 开启数据权限 */
  enableDataPermission?: boolean;

  /** 开启按创建人过滤，其中包括“仅自己、本部门、本部门及下属部门” */
  enableDataPermissionForCreator?: boolean;

  /** 开启数据范围过滤 */
  enableDataPermissionSpecifiedLimit?: boolean;

  /** 指定数据范围的数据源。 当开启数据范围过滤时，填写此值。 */
  dataPermissionSpecifiedDataSourceProvider?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface PermissionNameSchemeDto {
  /**  */
  tenantName?: string;

  /**  */
  tenantId?: number;

  /**  */
  permissionName?: string;

  /**  */
  displayName?: string;
}

export interface StringStringKeyValuePair {
  /**  */
  key?: string;

  /**  */
  value?: string;
}

export interface PermissionDefinitionDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PermissionDefinitionDto[];
}

export interface ProductConsultationsDto {
  /** 姓名 */
  userName?: string;

  /** 电话 */
  mobilePhone?: string;

  /** 机构名称 */
  orgName?: string;

  /** 详细信息 */
  content?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductConsultationsCreateOrUpdateDto {
  /** 姓名 */
  userName?: string;

  /** 电话 */
  mobilePhone?: string;

  /** 机构名称 */
  orgName?: string;

  /** 详细信息 */
  content?: string;

  /**  */
  id?: number;
}

export interface ProductConsultationsDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductConsultationsDto[];
}

export interface ProductConsultationsCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: ProductConsultationsCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface ProjectFullInfoDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectFullInfoDto[];
}

export interface ProjectBasicDetailUpdateDto {
  /** 项目图片 */
  titleImagePath?: string;

  /** 项目成果 */
  projectResult?: string;

  /** 项目类型，这个不用传，传数组即可 */
  projectTypes?: string;

  /** 中期报告提交时间 */
  progressReportDate?: Date;

  /** 末期报告提交时间 */
  summaryReportDate?: Date;

  /** 项目人员Id */
  projectManagerIds?: number[];

  /**  */
  projectTypesArrary?: string[];

  /**  */
  id?: number;
}

export interface ProjectOrganizationUnitMappingDto {
  /**  */
  projectId?: number;

  /** 合作机构Id */
  organizationUnitIds?: number[];
}

export interface ProjectCreateOrUpdateDto {
  /**  */
  id?: number;
}

export interface ProjectBeneficiaryMappingDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  beneficiaryId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  id?: number;
}

export interface ProjectBeneficiaryMappingDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectBeneficiaryMappingDto[];
}

export interface ProjectBeneficiaryMappingDtoListResultDto {
  /**  */
  items?: ProjectBeneficiaryMappingDto[];
}

export interface ProjectBeneficiaryMappingCreateOrUpdateDto {
  /** 受益人ID */
  beneficiaryId?: number;

  /**  */
  projectId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  extensionData?: string;

  /**  */
  formId?: number;

  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  savedAttachments?: number[];

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  id?: number;
}

export interface ProjectInviteCreateDto {
  /** 项目名称 */
  displayName?: string;

  /** 是否内部项目 */
  isInternal?: boolean;

  /** 所属系列Id，数据字典,外部项目固定为行业支持 */
  projectSeriesId?: number;

  /** 所属年度 */
  year?: number;

  /** 邀约合作机构Id */
  organizationUnitId?: number[];

  /** 项目负责人Id */
  projectManagerIds?: number[];

  /**  */
  id?: number;
}

export interface ProjectSeriesShortDto {
  /** 系列名称 */
  title?: string;

  /**  */
  id?: number;
}

export interface ProjectInviteDto {
  /** 项目名称 */
  displayName?: string;

  /**  */
  project?: ProjectDto;

  /** 是否内部项目 */
  isInternal?: boolean;

  /**  */
  projectSeries?: ProjectSeriesShortDto;

  /** 所属年度 */
  year?: number;

  /** 邀约合作机构Id */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectManagerList?: UserDto[];

  /**  */
  id?: number;
}

export interface ProjectInviteDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectInviteDto[];
}

export interface ProjectSeriesDto {
  /** 系列名称 */
  title?: string;

  /** 图片 */
  titleImagePath?: string;

  /** 是否公开显示 */
  isPublicShow?: boolean;

  /** 序号 */
  sequence?: number;

  /** 是否默认，默认时不可删除 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface ProjectSeriesDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectSeriesDto[];
}

export interface ProjectSeriesCreateOrUpdateDto {
  /** 系列名称 */
  title?: string;

  /** 图片 */
  titleImagePath?: string;

  /** 是否公开显示 */
  isPublicShow?: boolean;

  /** 序号 */
  sequence?: number;

  /**  */
  id?: number;
}

export interface ProvinceCreateOrUpdateDto {
  /**  */
  provinceName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface ProvinceDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProvinceDto[];
}

export interface RoleCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /**  */
  roleScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface RoleDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /**  */
  grantedPermissions?: string[];

  /**  */
  isStatic?: boolean;

  /**  */
  isDefault?: boolean;

  /**  */
  roleScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface RoleListDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  isStatic?: boolean;

  /**  */
  isDefault?: boolean;

  /**  */
  creationTime?: Date;

  /**  */
  roleScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface RoleListDtoListResultDto {
  /**  */
  items?: RoleListDto[];
}

export interface DataPermissionSettingDto {
  /**  */
  permissionCode?: string;

  /**  */
  scope?: DataPermissionScopeType;

  /**  */
  details?: string[];
}

export interface RoleGrantPermissionInput {
  /**  */
  roleId?: number;

  /**  */
  grantedPermissions?: string[];

  /**  */
  dataPermissionSettings?: DataPermissionSettingDto[];
}

export interface PermissionDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  id?: number;
}

export interface PermissionDtoListResultDto {
  /**  */
  items?: PermissionDto[];
}

export interface CreateRoleDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /**  */
  grantedPermissions?: string[];

  /**  */
  id?: number;

  /**  */
  isDefault?: boolean;

  /**  */
  isStatic?: boolean;

  /**  */
  roleScopes?: PermissionDefinitionScope[];
}

export interface GetRoleForEditOutput {
  /**  */
  role?: CreateRoleDto;

  /**  */
  permissions?: PermissionDefinitionDto[];

  /**  */
  grantedPermissions?: PermissionDefinitionDto[];
}

export interface UserRoleDto {
  /**  */
  userIds?: number[];

  /**  */
  roleNames?: string[];
}

export interface RoleDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: RoleDto[];
}

export interface SchoolCreateOrUpdateDto {
  /**  */
  schoolName?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  address?: string;

  /**  */
  remark?: string;

  /**  */
  id?: number;
}

export interface SchoolDto {
  /**  */
  schoolName?: string;

  /**  */
  schoolNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  area?: AreaDto;

  /**  */
  city?: CityDto;

  /**  */
  address?: string;

  /**  */
  remark?: string;

  /**  */
  id?: number;
}

export interface SchoolContactDto {
  /** 所属学校 */
  schoolId?: number;

  /**  */
  sex?: string;

  /** 联系人QQ */
  qq?: string;

  /** 职务 */
  duty?: string;

  /** 联系人邮箱 */
  emailAddress?: string;

  /** 联系人手机号 */
  mobilePhone?: string;

  /** 联系人姓名 */
  contactName?: string;

  /**  */
  isActive?: boolean;

  /**  */
  userExtend?: UserExtendDto;

  /**  */
  id?: number;
}

export interface SchoolContactDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SchoolContactDto[];
}

export interface SchoolContactCreateOrUpdateInput {
  /** 联系人姓名 */
  contactName?: string;

  /** 所在学校 */
  schoolId?: number;

  /** 性别 */
  sex?: string;

  /** 职务 */
  duty?: string;

  /**  */
  emailAddress?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  qq?: string;

  /**  */
  id?: number;
}

export interface SchoolMiniDto {
  /**  */
  schoolName?: string;

  /**  */
  schoolNo?: string;

  /**  */
  id?: number;
}

export interface SchoolPagedResultRequestDto {
  /**  */
  schoolName?: string;

  /**  */
  schoolNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface SchoolContactPagedResultRequestDto {
  /**  */
  schoolId?: number;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactPhone?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface SchoolCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: SchoolCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface SchoolDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SchoolDto[];
}

export interface DisplayItem {
  /**  */
  id?: string;

  /**  */
  displayName?: string;
}

export interface ServiceApplyCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /** 服务Id */
  serviceId?: number;

  /**  */
  id?: number;
}

export interface ServiceDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  serviceOrganizationId?: number;

  /**  */
  serviceOrganization?: ServiceOrganizationDto;

  /** 封面图 */
  titleImagePath?: string;

  /** 标题 */
  title?: string;

  /** 服务对象类型Id */
  serviceObjectTypeId?: number;

  /**  */
  serviceObjectType?: DataDictionaryDto;

  /** 服务对象Id */
  serviceObjectIds?: string;

  /** 服务对象姓名 */
  serviceObjectNames?: string;

  /** 服务详情 */
  content?: string;

  /** 服务开始时间 */
  serviceStartTime?: Date;

  /** 服务结束时间 */
  serviceEndTime?: Date;

  /** 服务时长（分钟） */
  totalMinutes?: number;

  /** 奖励积分 */
  point?: number;

  /** 主办方 */
  host?: string;

  /** 经纬度 */
  placeCoordinate?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 详细地址 */
  address?: string;

  /** 浏览量 */
  readCount?: number;

  /** 已报名成功数量 */
  signUpCount?: number;

  /** 点赞数量 */
  thumbsUpCount?: number;

  /** 报名截止时间 */
  applyEndTime?: Date;

  /** 最小志愿者星级 */
  minLevel?: number;

  /**  */
  minLevelText?: string;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 最大报名人数 */
  aimAmount?: number;

  /** 是否需要人工审核 */
  needApprove?: boolean;

  /**  */
  status?: ServiceStatus;

  /** 服务类型Id */
  typeId?: number;

  /**  */
  type?: DataDictionaryDto;

  /** 领队Id */
  leaderId?: number;

  /**  */
  leaderUser?: UserDto;

  /** 发布时间 */
  publishedTime?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ServiceApplyDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 服务Id */
  serviceId?: number;

  /**  */
  service?: ServiceDto;

  /** 报名用户Id */
  userId?: number;

  /**  */
  user?: UserMiniDto;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 报名时间 */
  applyTime?: Date;

  /** 审核通过时间 */
  approvedTime?: Date;

  /** 取消时间 */
  cancelTime?: Date;

  /** 退回时间 */
  sendbackTime?: Date;

  /** 取消原因 */
  applyCancelReason?: string;

  /** 退回原因 */
  applyReturnReason?: string;

  /**  */
  status?: ActivityApplyStatus;

  /** 金额 */
  totalPrice?: number;

  /** 实际付款金额 */
  totalPaid?: number;

  /** 签到次数 */
  checkInTimes?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /**  */
  minLevelText?: string;

  /** 服务对象姓名 */
  serviceObjectNames?: string;

  /**  */
  userExtend?: UserExtendMiniDto;

  /**  */
  isAudited?: boolean;

  /** 反馈退回原因 */
  recordReturnReason?: string;

  /** 审核退回附件 */
  returnAttachments?: AttachmentDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ServiceApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceApplyDto[];
}

export interface ServiceApplyCheckInPostDto {
  /** 签到时间 */
  checkInTime?: Date;

  /**  */
  id?: number;
}

export interface ServiceApplyCheckInRecordDto {
  /** 服务ID */
  serviceId?: number;

  /**  */
  service?: ServiceDto;

  /** 服务报名Id */
  serviceApplyId?: number;

  /**  */
  serviceApply?: ServiceApplyDto;

  /** 报名用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserMiniDto;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 签到时间 */
  checkInTime?: Date;

  /**  */
  userExtend?: UserExtendMiniDto;

  /**  */
  id?: number;
}

export interface ServiceApplyCheckInRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceApplyCheckInRecordDto[];
}

export interface ServiceApplyPagedResultRequestDto {
  /** 服务Id */
  serviceId?: number;

  /** 报名用户Id */
  userId?: number;

  /** 志愿者Id */
  volunteerId?: number;

  /** 姓名 */
  surName?: string;

  /** 手机号码 */
  phone?: string;

  /**  */
  status?: ActivityApplyStatus;

  /** 服务标题 */
  title?: string;

  /** 服务开始时间 */
  serviceStartTime?: Date;

  /** 服务结束时间 */
  serviceEndTime?: Date;

  /**  */
  queryStatus?: QueryApplyStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ServiceApplyStatusDto {
  /**  */
  status?: ActivityApplyStatus;
}

export interface ServiceApplyDtoListResultDto {
  /**  */
  items?: ServiceApplyDto[];
}

export interface ServiceCreateOrUpdateDto {
  /**  */
  serviceOrganizationId?: number;

  /** 封面图 */
  titleImagePath?: string;

  /** 标题 */
  title?: string;

  /** 服务对象类型Id */
  serviceObjectTypeId?: number;

  /** 服务对象Id */
  serviceObjectIds?: string;

  /** 服务详情 */
  content?: string;

  /** 服务开始时间 */
  serviceStartTime?: Date;

  /** 服务结束时间 */
  serviceEndTime?: Date;

  /** 服务时长（分钟） */
  totalMinutes?: number;

  /** 奖励积分 */
  point?: number;

  /** 主办方 */
  host?: string;

  /** 经纬度 */
  placeCoordinate?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /** 详细地址 */
  address?: string;

  /** 报名截止时间 */
  applyEndTime?: Date;

  /** 最小志愿者星级 */
  minLevel?: number;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 最大报名人数 */
  aimAmount?: number;

  /** 是否需要人工审核 */
  needApprove?: boolean;

  /**  */
  status?: ServiceStatus;

  /** 服务类型Id */
  typeId?: number;

  /** 领队Id */
  leaderId?: number;

  /** 关联服务需求Id */
  helpApplyId?: number;

  /**  */
  id?: number;
}

export interface ServiceDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceDto[];
}

export interface ServicePagedResultRequestDto {
  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /** 服务标题 */
  title?: string;

  /** 服务对象类型 */
  serviceObjectTypeId?: number;

  /** 开始时间 */
  startDate?: Date;

  /** 结束时间 */
  endDate?: Date;

  /** 服务类型 */
  typeID?: number;

  /**  */
  status?: ServiceStatus;

  /**  */
  queryStatus?: ActivityQueryStatus;

  /** 已被报名 */
  hasBeenApplied?: boolean;

  /**  */
  host?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ServiceManagementCreateOrUpdateDto {
  /** 所属服务 */
  serviceId?: number;

  /** 服务机构Id */
  serviceOrganizationId?: number;

  /** 志愿者人数 */
  volunteerCount?: number;

  /** 服务对象人数 */
  serviceObjectCount?: number;

  /** 服务机构基本情况 */
  basicInfo?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface ServiceManagementDto {
  /** 所属服务 */
  serviceId?: number;

  /** 服务机构Id */
  serviceOrganizationId?: number;

  /** 志愿者人数 */
  volunteerCount?: number;

  /** 服务对象人数 */
  serviceObjectCount?: number;

  /** 服务机构基本情况 */
  basicInfo?: string;

  /** 自定义表单Json */
  extensionData?: string;

  /**  */
  service?: ServiceDto;

  /**  */
  serviceOrganization?: ServiceOrganizationDto;

  /**  */
  organizationUnit?: OrganizationUnitDto;

  /**  */
  savedAttachments?: number[];

  /**  */
  formId?: number;

  /**  */
  extensionObject?: object;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ServiceManagementDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceManagementDto[];
}

export interface ServiceObjectCreateDto {
  /** 会员单位 */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 服务机构 */
  serviceOrganizationId?: number;

  /**  */
  serviceOrganization?: ServiceOrganizationDto;

  /** 姓名 */
  name?: string;

  /** 生日 */
  birthday?: Date;

  /** 手机号 */
  phone?: string;

  /** 性别 */
  sex?: string;

  /** 省Id */
  provinceId?: number;

  /** 市Id */
  cityId?: number;

  /** 区Id */
  areaId?: number;

  /** 详细地址 */
  address?: string;

  /** 基本情况 */
  remark?: string;

  /** 身体情况(数据字典) */
  physicalStateId?: number;

  /** 其他身体情况 */
  physicalStateOther?: string;

  /** 服务对象类型Id */
  serviceObjectTypeId?: number;
}

export interface ServicesObjectDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServicesObjectDto[];
}

export interface ServiceObjectPagedResultRequestDto {
  /** 姓名 */
  name?: string;

  /** 手机号 */
  phone?: string;

  /** 服务机构 */
  serviceOrganization?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ServiceObjectUpdateDto {
  /** 会员单位 */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 服务机构 */
  serviceOrganizationId?: number;

  /**  */
  serviceOrganization?: ServiceOrganizationDto;

  /** 姓名 */
  name?: string;

  /** 生日 */
  birthday?: Date;

  /** 手机号 */
  phone?: string;

  /** 性别 */
  sex?: string;

  /** 省Id */
  provinceId?: number;

  /** 市Id */
  cityId?: number;

  /** 区Id */
  areaId?: number;

  /** 详细地址 */
  address?: string;

  /** 基本情况 */
  remark?: string;

  /** 身体情况(数据字典) */
  physicalStateId?: number;

  /** 其他身体情况 */
  physicalStateOther?: string;

  /** 服务对象类型Id */
  serviceObjectTypeId?: number;

  /**  */
  id?: number;
}

export interface ServiceObjectFeedbackDto {
  /** 服务对象Id */
  serviceId?: number;

  /**  */
  service?: ServiceDto;

  /** 服务对象Id */
  serviceObjectId?: number;

  /**  */
  serviceObject?: ServicesObjectDto;

  /** 志愿者Ids */
  volunteerIds?: string;

  /** 志愿者姓名 */
  volunteerNames?: string;

  /** 自定义表单Json */
  extensionData?: string;

  /**  */
  formId?: number;

  /**  */
  extensionObject?: object;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ServiceObjectFeedbackDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceObjectFeedbackDto[];
}

export interface ServiceObjectFeedbackCreateOrUpdateDto {
  /** 服务Id */
  serviceId?: number;

  /** 服务对象Id */
  serviceObjectId?: number;

  /** 志愿者Ids */
  volunteerIds?: string;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface ServiceObjectFeedbackDropList {
  /**  */
  id?: number;

  /**  */
  displayName?: string;
}

export interface ServiceOrganizationCreateOrUpdateDto {
  /** 服务机构名称 */
  name?: string;

  /** 服务机构类型Id */
  serviceOrganizationTypeId?: number;

  /** 负责人 */
  contactName?: string;

  /** 负责人姓名 */
  contactPhone?: string;

  /** 省份Id */
  provinceId?: number;

  /** 城市Id */
  cityId?: number;

  /** 区域Id */
  areaId?: number;

  /** 详细信息 */
  address?: string;

  /** 基本情况 */
  basicInfo?: string;

  /**  */
  id?: number;
}

export interface ServiceOrganizationDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceOrganizationDto[];
}

export interface ServiceRecordCreateOrUpdateDto {
  /** 服务Id */
  serviceId?: number;

  /** 服务报名ID */
  serviceApplyId?: number;

  /** 报名用户Id */
  applyUserId?: number;

  /** 志愿者Id */
  volunteerId?: number;

  /** 服务对象Id */
  serviceObjectIds?: string;

  /** 培训感悟 */
  feelings?: string;

  /**  */
  returnReason?: string;

  /** 实际服务时长（小时） */
  actualHour?: number;

  /** 实际服务时长（分钟） */
  actualMinutes?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface ServiceRecordDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 服务Id */
  serviceId?: number;

  /**  */
  service?: ServiceDto;

  /** 服务报名ID */
  serviceApplyId?: number;

  /**  */
  serviceApply?: ServiceApplyDto;

  /** 报名用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserMiniDto;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 服务对象Id */
  serviceObjectIds?: string;

  /** 培训感悟 */
  feelings?: string;

  /**  */
  returnReason?: string;

  /** 实际服务时长（分钟） */
  actualMinutes?: number;

  /** 服务次数 */
  serviceCount?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /**  */
  minLevelText?: string;

  /** 服务对象姓名 */
  serviceObjectNames?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ServiceRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceRecordDto[];
}

export interface ServiceRecordPagedResultRequestDto {
  /** 审核通过时自动生成的唯一编号 */
  volunteerOrgCode?: string;

  /** 姓名 */
  surname?: string;

  /** 服务标题 */
  title?: string;

  /** 服务类型Id */
  typeId?: number;

  /** 服务机构Id */
  serviceOrganizationId?: number;

  /** 服务对象Id */
  serviceObjectIds?: string;

  /** 服务开始时间 */
  serviceStartTime?: Date;

  /** 志愿者 */
  volunteerId?: number;

  /** 服务结束时间 */
  serviceEndTime?: Date;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface ServiceRecordDtoListResultDto {
  /**  */
  items?: ServiceRecordDto[];
}

export interface UserLoginInfoDto {
  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  userName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  headImage?: string;

  /**  */
  userType?: UserType;

  /**  */
  id?: number;
}

export interface ApplicationInfoDto {
  /**  */
  version?: string;

  /**  */
  releaseDate?: Date;

  /**  */
  features?: object;
}

export interface TenantLoginInfoDto {
  /**  */
  tenancyName?: string;

  /**  */
  name?: string;

  /**  */
  id?: number;
}

export interface GetCurrentLoginInformationsOutput {
  /**  */
  application?: ApplicationInfoDto;

  /**  */
  user?: UserLoginInfoDto;

  /**  */
  tenant?: TenantLoginInfoDto;

  /**  */
  organizationUnit?: OrganizationUnitDto;

  /**  */
  permissions?: string[];
}

export interface BankInfoDto {
  /** 银行名称 */
  name?: string;

  /** 银行卡号 */
  cardNo?: string;

  /** 银行地址 */
  address?: string;
}

export interface SignupInfoCreateOrUpdateDto {
  /** 参赛者类型 */
  userType?: string;

  /** 姓名\/公司名称 */
  userName?: string;

  /** 身份证\/统一信用代码 */
  identifyNumber?: string;

  /** 参赛昵称 */
  nickName?: string;

  /** 参赛者国籍 */
  nation?: string;

  /** 参赛者出生年月日 */
  birthday?: Date;

  /** 参赛者职业 */
  job?: string;

  /** 参赛者联系地 */
  area?: string;

  /** 参赛者联系电话 */
  phone?: string;

  /** 参赛者账号类型 */
  accountType?: string;

  /** QQ\/微信 */
  account?: string;

  /** 参赛者电子邮箱 */
  email?: string;

  /** 参赛者作品集链接或个人网站 */
  link?: string;

  /** 是否了解手办 */
  know?: boolean;

  /** 参赛者从业时长 */
  time?: string;

  /** 现所属公司或工作室 */
  company?: string;

  /** 是否有以下类别商品化只做经验 */
  handMadeExperiences?: string[];

  /** 是否参与过商品批量生产过程的经验 */
  production?: boolean;

  /** 是否有参与 */
  contest?: boolean;

  /** 其他参赛参展经历 */
  other?: string;

  /** 擅长的做法 */
  goodAtPracticegs?: string;

  /** 是否能独立完成 */
  only?: boolean;

  /** 附件路径 */
  attachments?: string;

  /**  */
  id?: number;
}

export interface SignupInfoDto {
  /** 参赛者类型 */
  userType?: string;

  /** 姓名\/公司名称 */
  userName?: string;

  /** 身份证\/统一信用代码 */
  identifyNumber?: string;

  /** 参赛昵称 */
  nickName?: string;

  /** 参赛者国籍 */
  nation?: string;

  /** 参赛者出生年月日 */
  birthday?: Date;

  /** 参赛者职业 */
  job?: string;

  /** 参赛者联系地 */
  area?: string;

  /** 参赛者联系电话 */
  phone?: string;

  /** 参赛者账号类型 */
  accountType?: string;

  /** QQ\/微信 */
  account?: string;

  /** 参赛者电子邮箱 */
  email?: string;

  /** 参赛者作品集链接或个人网站 */
  link?: string;

  /** 是否了解手办 */
  know?: boolean;

  /** 参赛者从业时长 */
  time?: string;

  /** 现所属公司或工作室 */
  company?: string;

  /** 是否有以下类别商品化只做经验 */
  handMadeExperiences?: string[];

  /** 是否参与过商品批量生产过程的经验 */
  production?: boolean;

  /** 是否有参与 */
  contest?: boolean;

  /** 其他参赛参展经历 */
  other?: string;

  /** 擅长的做法 */
  goodAtPracticegs?: string;

  /** 是否能独立完成 */
  only?: boolean;

  /** 附件路径 */
  attachments?: string;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface SignupInfoDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SignupInfoDto[];
}

export interface SignupInfoPagedResultRequestDto {
  /** 参赛者类型 */
  userType?: string;

  /** 姓名\/公司名称 */
  userName?: string;

  /** 参赛昵称 */
  nickName?: string;

  /** 参赛者国籍 */
  nation?: string;

  /** 参赛者联系电话 */
  phone?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface SiteMessageDto {
  /**  */
  title?: string;

  /**  */
  content?: string;

  /**  */
  hasReaded?: boolean;

  /**  */
  creationTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  url?: string;

  /**  */
  id?: string;
}

export interface SiteMessageDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SiteMessageDto[];
}

export interface SlashScreenDto {
  /**  */
  displayOrder?: number;

  /**  */
  title?: string;

  /**  */
  subTitle?: string;

  /** 图片链接 */
  imageUrl?: string;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  isActive?: boolean;

  /**  */
  url?: string;

  /**  */
  tenantId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface SlashScreenDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SlashScreenDto[];
}

export interface SlashScreenCreateOrUpateDto {
  /**  */
  displayOrder?: number;

  /**  */
  title?: string;

  /**  */
  subTitle?: string;

  /** 图片链接 */
  imageUrl?: string;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  isActive?: boolean;

  /**  */
  url?: string;

  /**  */
  id?: number;
}

export interface StarRatingCreateOrUpdateDto {
  /**  */
  savedAttachments?: number[];

  /** 封面图 */
  titleImagePath?: string;

  /** 标题 */
  title?: string;

  /** 详情 */
  content?: string;

  /** 评定类型Id */
  typeId?: number;

  /** 报名截止时间 */
  applyEndTime?: Date;

  /** 笔试截止时间 */
  writtenEndTime?: Date;

  /** 成长报告提交截止时间 */
  reportSubmitEndTime?: Date;

  /** 面试时间 */
  interviewTime?: Date;

  /** 主办方 */
  host?: string;

  /** 负责人Id */
  managerId?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 最小服务时长(小时) */
  minServiceHours?: number;

  /** 最小培训次数，0为不限 */
  trainCount?: number;

  /** 最小服务次数，0为不限 */
  serviceCount?: number;

  /** 最小活动次数，0为不限 */
  activityCount?: number;

  /**  */
  status?: StarRatingStatus;

  /** 培训课程ID */
  trainIds?: string;

  /**  */
  id?: number;
}

export interface TrainChapterDto {
  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /** 培训ID */
  trainId?: number;

  /** 标题 */
  name?: string;

  /** 简介 */
  summary?: string;

  /** 视频路径 */
  path?: string;
}

export interface TrainDto {
  /** 封面图 */
  titleImagePath?: string;

  /** 标题 */
  title?: string;

  /**  */
  trainMethod?: TrainMethod;

  /** 培训标签 */
  trainTag?: string;

  /** 培训详情 */
  content?: string;

  /** 培训类型 */
  trainTypeId?: number;

  /**  */
  trainType?: DataDictionaryDto;

  /** 培训开始时间 */
  trainStartTime?: Date;

  /** 培训结束时间 */
  trainEndTime?: Date;

  /** 奖励积分 */
  point?: number;

  /** 金额 */
  price?: number;

  /** 主办方 */
  host?: string;

  /** 讲师 */
  lecturerId?: number;

  /**  */
  lecturer?: LecturerDto;

  /** 讲师（线下） */
  lecturerUserId?: number;

  /**  */
  lecturerUser?: UserMiniDto;

  /** 培训位置坐标 */
  placeCoordinate?: string;

  /** 省份Id */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /** 城市Id */
  cityId?: number;

  /**  */
  city?: CityDto;

  /** 区域Id */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 详细地址 */
  address?: string;

  /** 浏览量 */
  readCount?: number;

  /** 已报名数量 */
  signUpCount?: number;

  /** 点赞数量 */
  thumbsUpCount?: number;

  /** 报名截止时间 */
  applyEndTime?: Date;

  /** 最小志愿者星级 */
  minLevel?: number;

  /**  */
  minLevelText?: string;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 最大报名人数，0为不限 */
  aimAmount?: number;

  /** 是否需要人工审核 */
  needApprove?: boolean;

  /**  */
  status?: TrainStatus;

  /** 发布时间 */
  publishedTime?: Date;

  /**  */
  trainChapters?: TrainChapterDto[];

  /**  */
  id?: number;
}

export interface StarRatingDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /** 封面图 */
  titleImagePath?: string;

  /** 标题 */
  title?: string;

  /** 详情 */
  content?: string;

  /** 评定类型Id */
  typeId?: number;

  /**  */
  type?: DataDictionaryDto;

  /** 报名截止时间 */
  applyEndTime?: string;

  /** 笔试截止时间 */
  writtenEndTime?: string;

  /** 成长报告提交截止时间 */
  reportSubmitEndTime?: string;

  /** 面试时间 */
  interviewTime?: string;

  /** 是否开启公示 */
  isPublicity?: boolean;

  /** 主办方 */
  host?: string;

  /** 负责人Id */
  managerId?: number;

  /**  */
  manager?: UserMiniDto;

  /** 浏览量 */
  readCount?: number;

  /** 已报名成功数量 */
  signUpCount?: number;

  /** 点赞数量 */
  thumbsUpCount?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 最小服务时长(分钟) */
  minServiceMinutes?: number;

  /** 最小服务时长(小时) */
  minServiceHours?: number;

  /** 最小培训次数，0为不限 */
  trainCount?: number;

  /** 最小服务次数，0为不限 */
  serviceCount?: number;

  /** 最小活动次数，0为不限 */
  activityCount?: number;

  /**  */
  status?: StarRatingStatus;

  /** 已通过志愿者人数 */
  passCount?: number;

  /** 培训课程ID */
  trainIds?: string;

  /**  */
  trainList?: TrainDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StarRatingDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StarRatingDto[];
}

export interface StarRatingPagedResultRequestDto {
  /** 标题 */
  title?: string;

  /** 评定类型Id */
  typeId?: number;

  /**  */
  status?: StarRatingStatus;

  /** 是否开启公示 */
  isPublicity?: boolean;

  /** 创建时间 */
  creationTime?: Date;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StarRatingApplyCreateOrUpdateDto {
  /** 星级评定Id */
  starRatingId?: number;

  /**  */
  id?: number;
}

export interface StarRatingApplyDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 星级评定Id */
  starRatingId?: number;

  /**  */
  starRating?: StarRatingDto;

  /** 报名用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserMiniDto;

  /** 志愿者 */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 用户扩展Id */
  userExtendId?: number;

  /**  */
  userExtend?: UserExtendMiniDto;

  /** 报名时间 */
  applyTime?: Date;

  /** 取消时间 */
  cancelTime?: Date;

  /**  */
  status?: StarRatingApplyStatus;

  /** 志愿者当前星级 */
  currentLevel?: number;

  /**  */
  currentLevelName?: string;

  /** 机构名称 */
  orgName?: string;

  /** 是否可以重新考试 */
  canReExamination?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StarRatingApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StarRatingApplyDto[];
}

export interface StarRatingApplyPagedResultRequestDto {
  /** 星级评定Id */
  starRatingId?: number;

  /** 姓名 */
  surname?: string;

  /** 手机号 */
  phoneNumber?: string;

  /**  */
  status?: StarRatingApplyStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StarRatingManagementCreateOrUpdateDto {
  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /** 星级评定Id */
  starRatingId?: number;

  /**  */
  id?: number;
}

export interface StarRatingManagementDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /** 星级评定Id */
  starRatingId?: number;

  /**  */
  starRating?: StarRatingDto;

  /** 已通过志愿者人数 */
  passCount?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StarRatingManagementDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StarRatingManagementDto[];
}

export interface StarRatingRecordCreateOrUpdateDto {
  /** 星级评定报名Id */
  starRatingApplyId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface StarRatingRecordDto {
  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 星级评定Id */
  starRatingId?: number;

  /**  */
  starRating?: StarRatingDto;

  /** 星级评定报名Id */
  starRatingApplyId?: number;

  /**  */
  starRatingApply?: StarRatingApplyDto;

  /** 报名用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserMiniDto;

  /** 志愿者 */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 自我成长报告评语 */
  reportComment?: string;

  /** 面试评语 */
  interviewComment?: string;

  /**  */
  status?: StarRatingRecordStatus;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /** 机构名称 */
  orgName?: string;

  /**  */
  userExtend?: UserExtendMiniDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StarRatingRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StarRatingRecordDto[];
}

export interface StarRatingRecordPagedResultRequestDto {
  /** 星级评定Id */
  starRatingId?: number;

  /** 志愿者编号 */
  volunteerOrgCode?: string;

  /** 姓名 */
  surname?: string;

  /** 星级评定标题 */
  starRatingTitle?: string;

  /** 发布时间 */
  creationTime?: Date;

  /** 评定类型Id */
  typeId?: number;

  /** 是否只包含 已开启的公示 */
  isIncludePublicity?: boolean;

  /**  */
  status?: StarRatingRecordStatus;

  /**  */
  searchStatus?: StarRatingRecordSearchStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StarRatingRecordSubmitDto {
  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface StarRatingRecordAuditDto {
  /** 自我成长报告评语 */
  reportComment?: string;

  /** 面试评语 */
  interviewComment?: string;

  /**  */
  hostId?: string;

  /**  */
  desc?: string;

  /**  */
  extensionData?: object;

  /**  */
  status?: AuditUserLogStatus;

  /**  */
  saveAttachments?: number[];
}

export interface StarRatingRecordDtoListResultDto {
  /**  */
  items?: StarRatingRecordDto[];
}

export interface StudentCreateOrUpdateDto {
  /** 学生编号 */
  studentNo?: string;

  /** 标的状态 */
  aidBidStatusStr?: string;

  /** 所属学校 */
  schoolName?: string;

  /** 姓名 */
  name?: string;

  /** 对接人 */
  counterpart?: string;

  /** 出生日期 */
  birthday?: Date;

  /**  */
  sex?: Sex;

  /** 民族(数据字典id) */
  nationId?: number;

  /** 照片 */
  photo?: string;

  /** 全身照 */
  bodyPhoto?: string;

  /** 详情 */
  description?: string;

  /**  */
  areaSelect?: AreaSelectDto;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  address?: string;

  /** 所属学校 */
  schoolId?: number;

  /**  */
  grade?: StudentGrade;

  /**  */
  studentStatus?: StudentStatus;

  /** 监护人姓名 */
  guardianName?: string;

  /** 监护人电话 */
  guardianPhone?: string;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface StudentMiniDto {
  /** 学生编号 */
  studentNo?: string;

  /** 姓名 */
  name?: string;

  /** 出生日期 */
  birthday?: Date;

  /**  */
  school?: SchoolDto;

  /**  */
  photo?: string;

  /**  */
  sex?: Sex;

  /**  */
  nation?: DataDictionaryShortDto;

  /**  */
  grade?: StudentGrade;

  /**  */
  id?: number;
}

export interface StudentAidBidShortDto {
  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  status?: StudentAidBidStatus;

  /** 标的金额 */
  amount?: number;

  /**  */
  bidType?: StudentBidType;

  /** 学生Id */
  studentId?: number;

  /**  */
  student?: StudentMiniDto;

  /**  */
  grade?: StudentGrade;

  /**  */
  progress?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StudentDto {
  /** 学生编号 */
  studentNo?: string;

  /** 姓名 */
  name?: string;

  /**  */
  nation?: DataDictionaryShortDto;

  /** 民族（数据字典ID） */
  nationId?: number;

  /** 出生日期 */
  birthday?: Date;

  /**  */
  sex?: Sex;

  /** 照片 */
  photo?: string;

  /** 全身照 */
  bodyPhoto?: string;

  /** 详情 */
  description?: string;

  /** 对接人 */
  counterpart?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  area?: AreaDto;

  /**  */
  areaId?: number;

  /**  */
  address?: string;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 监护人姓名 */
  guardianName?: string;

  /** 监护人电话 */
  guardianPhone?: string;

  /** 所属学校 */
  schoolId?: number;

  /**  */
  school?: SchoolMiniDto;

  /**  */
  grade?: StudentGrade;

  /**  */
  studentStatus?: StudentStatus;

  /** 助学标的Id */
  bidId?: number;

  /**  */
  bid?: StudentAidBidShortDto;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface BatchUpdateGradeInput {
  /**  */
  studentId?: number[];

  /**  */
  grade?: StudentGrade;
}

export interface BatchGradeUpInput {
  /**  */
  studentId?: number[];
}

export interface BatchUpdateStudentStatus {
  /**  */
  studentId?: number[];

  /**  */
  status?: StudentStatus;
}

export interface StudentPagedResultRequestDto {
  /**  */
  schoolName?: string;

  /**  */
  schoolId?: number;

  /**  */
  studentName?: string;

  /**  */
  studentNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  counterpart?: string;

  /**  */
  studentGrade?: StudentGrade;

  /**  */
  studentStatus?: StudentStatus;

  /**  */
  sex?: Sex;

  /**  */
  bidStatus?: StudentAidBidStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StudentCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: StudentCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface StudentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StudentDto[];
}

export interface StudentExtensionDto {
  /** 学生编号 */
  studentNo?: string;

  /** 姓名 */
  name?: string;

  /** 出生日期 */
  birthday?: Date;

  /**  */
  school?: SchoolDto;

  /**  */
  photo?: string;

  /**  */
  sex?: Sex;

  /**  */
  nation?: DataDictionaryShortDto;

  /**  */
  grade?: StudentGrade;

  /** 详情 */
  description?: string;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface StudentDonorShortDto {
  /**  */
  userId?: number;

  /**  */
  type?: StudentDonorType;

  /** 捐助方名称 */
  name?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机 */
  contactPhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 联系人地址 */
  address?: string;

  /**  */
  province?: ProvinceDto;

  /**  */
  area?: AreaDto;

  /**  */
  city?: CityDto;

  /**  */
  id?: number;
}

export interface StudentAidBidDonorObjectMemberDetailDto {
  /** 捐助方id */
  donorId?: number;

  /**  */
  donor?: StudentDonorShortDto;

  /** 认捐金额 */
  amount?: number;

  /** 是否已完成捐助(已付款) */
  isSuccess?: boolean;

  /** 订单id */
  orderId?: number;

  /** 状态描述 */
  statusSummary?: string;

  /**  */
  id?: number;
}

export interface StudentAidBidDonorObjectDto {
  /**  */
  details?: StudentAidBidDonorObjectMemberDetailDto[];

  /**  */
  id?: number;
}

export interface BidDto {
  /**  */
  student?: StudentExtensionDto;

  /**  */
  bidType?: StudentBidType;

  /** 最低认捐金额(公开募捐时，需要填写) */
  lowestDonateAmount?: number;

  /**  */
  status?: StudentAidBidStatus;

  /**  */
  grade?: StudentGrade;

  /** 标的的开始日期 */
  startDate?: Date;

  /** 标的的结束日期 */
  endDate?: Date;

  /** 目标金额 */
  amount?: number;

  /** 备注 */
  remark?: string;

  /** 发布时间 */
  publishTime?: Date;

  /** 结对时间 */
  pairingTime?: Date;

  /** 发放时间 */
  grantTime?: Date;

  /** 认捐(第一次认捐)时间 */
  subscribeTime?: Date;

  /**  */
  donorObject?: StudentAidBidDonorObjectDto;

  /** 当前标的的已资助金额 */
  fundedAmount?: number;

  /** 进度 */
  progress?: number;

  /** 发放备注 */
  grantRemark?: string;

  /**  */
  singleDonor?: StudentAidBidDonorObjectMemberDetailDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface BidDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BidDto[];
}

export interface DonorWithAmountInput {
  /**  */
  donorId?: number;

  /**  */
  amount?: number;
}

export interface BidCreateOrUpdateInput {
  /** 学生id */
  studentId?: number;

  /**  */
  bidType?: StudentBidType;

  /** 最低认捐金额(公开募捐时，需要填写) */
  lowestDonateAmount?: number;

  /** 金额 */
  amount?: number;

  /**  */
  remark?: string;

  /**  */
  startYear?: number;

  /**  */
  startMonth?: number;

  /**  */
  endYear?: number;

  /**  */
  endMonth?: number;

  /** 是否发布标的 */
  isPublish?: boolean;

  /** 是否创建待付款订单(只有在IsPublish为true时才可用) */
  createPayOrder?: boolean;

  /** 捐助方列表 */
  donorWithAmountList?: DonorWithAmountInput[];

  /**  */
  id?: number;
}

export interface BatchCreateBidInput {
  /** 学生id */
  studentIds?: number[];

  /** 金额 */
  amount?: number;

  /**  */
  remark?: string;

  /**  */
  startYear?: number;

  /**  */
  startMonth?: number;

  /**  */
  endYear?: number;

  /**  */
  endMonth?: number;

  /** 是否发布标的 */
  isPublish?: boolean;

  /** 是否创建线下订单 */
  isCreatePayOrder?: boolean;
}

export interface BatchGrantInput {
  /** 发放时间 */
  grantTime?: Date;

  /** 发放金额 */
  grantedAmount?: number;

  /** 备注 */
  grantRemark?: string;

  /**  */
  ids?: number[];
}

export interface Int64BatchOperationInput {
  /**  */
  ids?: number[];
}

export interface AddDonorToBidInput {
  /** 标的id */
  bidId?: number;

  /** 捐助方id */
  donorId?: number;

  /** 捐助金额 */
  amount?: number;

  /** 创建订单 */
  createPayOrder?: boolean;
}

export interface UserSubscribeInput {
  /**  */
  bidId?: number;

  /**  */
  amount?: number;

  /**  */
  donorId?: number;
}

export interface StudentBidOrderShortDto {
  /** 系统内部订单号 */
  billNo?: string;

  /**  */
  bidId?: number;

  /**  */
  studentId?: number;

  /**  */
  memberId?: number;

  /**  */
  studentDonorId?: number;

  /**  */
  successPayTime?: Date;

  /**  */
  payType?: OrderPayType;

  /** 捐助金额 */
  amount?: number;

  /** 交易流水号 */
  transactionId?: string;

  /**  */
  orderStatus?: OrderStatus;

  /**  */
  id?: number;
}

export interface BidPagedResultRequestDto {
  /**  */
  studentName?: string;

  /**  */
  studentNo?: string;

  /**  */
  schoolName?: string;

  /**  */
  schoolId?: number;

  /** 标的开始日期(完全匹配) */
  bidStartDate?: Date;

  /** 学生所在省 */
  provinceId?: number;

  /** 学生所在市 */
  cityId?: number;

  /** 学生所在区\/县 */
  areaId?: number;

  /**  */
  bidStatus?: StudentAidBidStatus;

  /** 要获取的多个标的状态 */
  bidStatusFilters?: StudentAidBidStatus[];

  /** 是否包含当前标的的已资助金额 */
  isIncludeFundedAmount?: boolean;

  /** 是否包含进度 */
  isIncludeProgress?: boolean;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StudentDonateRecordDto {
  /** 系统内订单号 */
  billNo?: string;

  /**  */
  student?: StudentMiniDto;

  /**  */
  studentDonor?: StudentDonorShortDto;

  /**  */
  bid?: StudentAidBidShortDto;

  /** 捐助金额 */
  amount?: number;

  /** 已发放金额 */
  grantedTotalAmount?: number;

  /**  */
  payType?: OrderPayType;

  /** 交易流水号 */
  transactionId?: string;

  /** 付款完成时间 */
  successPayTime?: Date;

  /**  */
  orderStatus?: OrderStatus;

  /** 订单备注 */
  remark?: string;

  /**  */
  id?: number;
}

export interface StudentDonateRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StudentDonateRecordDto[];
}

export interface TotalDataDto {
  /**  */
  totalCount?: number;

  /**  */
  totalMoney?: number;
}

export interface StudentAidGrantRecordDto {
  /**  */
  bid?: StudentAidBidShortDto;

  /** 发放金额 */
  grantedAmount?: number;

  /** 发放时间 */
  grantTime?: Date;

  /** 发放用户Id */
  grantUserId?: number;

  /**  */
  grantUser?: UserMiniDto;

  /** 发放备注 */
  grantRemark?: string;

  /**  */
  id?: number;
}

export interface StudentAidGrantRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StudentAidGrantRecordDto[];
}

export interface StudentDonateRecordPagedResultRequestDto {
  /** 捐助方姓名 */
  donorName?: string;

  /** 捐助人联系人 */
  donorContactName?: string;

  /**  */
  donorContactPhone?: string;

  /**  */
  donorType?: StudentDonorType;

  /**  */
  studentName?: string;

  /**  */
  studentNo?: string;

  /**  */
  paySuccessStartTime?: Date;

  /**  */
  paySuccessEndTime?: Date;

  /**  */
  transactionId?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StudentBidOrderDto {
  /**  */
  student?: StudentMiniDto;

  /**  */
  studentDonor?: StudentDonorShortDto;

  /** 订单号 */
  billNo?: string;

  /**  */
  successPayTime?: Date;

  /**  */
  payType?: OrderPayType;

  /** 订单金额 */
  amount?: number;

  /** 交易流水号 */
  transactionId?: string;

  /**  */
  orderStatus?: OrderStatus;

  /** 取消时间 */
  cancelTime?: Date;

  /** 取消的用户 */
  cancelUserId?: number;

  /** 取消原因 */
  cancelReason?: string;

  /** 备注 */
  remark?: string;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface StudentBidOrderDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StudentBidOrderDto[];
}

export interface BatchCancelInput {
  /**  */
  reason?: string;

  /**  */
  ids?: number[];
}

export interface ManualConfirmOrderPaySuccessInput {
  /** 交易流水号 */
  transactionId?: string;

  /** 订单id */
  orderId?: number;

  /** 备注 */
  remark?: string;
}

export interface CancelOrderInput {
  /**  */
  orderId?: number;

  /**  */
  cancelReason?: string;
}

export interface StudentBidOrderPagedResultRequestDto {
  /**  */
  orderStatus?: OrderStatus;

  /** 捐助方名称 */
  donorName?: string;

  /**  */
  studentName?: string;

  /**  */
  studentNo?: string;

  /**  */
  schoolId?: number;

  /**  */
  amount?: number;

  /**  */
  successStartTime?: Date;

  /**  */
  successEndTime?: Date;

  /** 所属标的id */
  bidId?: number;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface SubmitOfflinePayInput {
  /**  */
  billNo?: string;

  /**  */
  remark?: string;

  /** 银行交易流水号 */
  transactionId?: string;
}

export interface PayOrderInput {
  /** 订单号 */
  billNo?: string;

  /**  */
  orderSourceType?: OrderSourceType;
}

export interface StudentDonorCreateOrUpdateDto {
  /**  */
  type?: StudentDonorType;

  /** 捐助方名称 */
  name?: string;

  /** 联系人 */
  contactName?: string;

  /** 联系人手机号 */
  contactPhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 联系人QQ */
  contactQQ?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /** 地址 */
  address?: string;

  /**  */
  remark?: string;

  /** 介绍人 */
  introducer?: string;

  /** 称呼（学生对捐助人的称呼） */
  named?: string;

  /**  */
  id?: number;
}

export interface StudentDonorUserShortDto {
  /**  */
  isActive?: boolean;

  /** 性别 */
  sex?: string;

  /**  */
  id?: number;
}

export interface StudentDonorDto {
  /**  */
  userId?: number;

  /**  */
  user?: StudentDonorUserShortDto;

  /** 是否启用 */
  isActive?: boolean;

  /**  */
  type?: StudentDonorType;

  /** 捐助方名称 */
  name?: string;

  /** 联系人 */
  contactName?: string;

  /** 联系人手机号 */
  contactPhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 联系人QQ */
  contactQQ?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  area?: AreaDto;

  /**  */
  city?: CityDto;

  /** 地址 */
  address?: string;

  /** 备注 */
  remark?: string;

  /** 累计捐助次数 */
  donorCount?: number;

  /** 累计认捐学生数量 */
  studentCount?: number;

  /** 介绍人 */
  introducer?: string;

  /** 称呼（学生对捐助人的称呼） */
  named?: string;

  /** 累计捐助金额 */
  donorAmount?: number;

  /** 关联注册账号Id */
  registerAccountId?: number;

  /**  */
  registerAccount?: UserMiniDto;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StudentDonorSimpleDto {
  /**  */
  type?: StudentDonorType;

  /** 捐助方名称 */
  name?: string;

  /** 联系人 */
  contactName?: string;

  /** 联系人手机号 */
  contactPhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 联系人QQ */
  contactQQ?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  area?: AreaDto;

  /**  */
  city?: CityDto;

  /** 地址 */
  address?: string;

  /** 备注 */
  remark?: string;

  /** 介绍人 */
  introducer?: string;

  /** 称呼（学生对捐助人的称呼） */
  named?: string;

  /**  */
  id?: number;
}

export interface StudentDonorSimpleDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StudentDonorSimpleDto[];
}

export interface StudentDonorSelfUpdateDto {
  /**  */
  type?: StudentDonorType;

  /** 捐助方名称 */
  name?: string;

  /** 联系人 */
  contactName?: string;

  /** 联系人手机号 */
  contactPhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 联系人QQ */
  contactQQ?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /** 地址 */
  address?: string;

  /** 介绍人 */
  introducer?: string;

  /** 称呼（学生对捐助人的称呼） */
  named?: string;
}

export interface StudentDonorSelfDto {
  /** 是否已注册成为捐助方 */
  hasRegister?: boolean;

  /**  */
  isActive?: boolean;

  /**  */
  type?: StudentDonorType;

  /** 捐助方名称 */
  name?: string;

  /** 联系人 */
  contactName?: string;

  /** 联系人手机号 */
  contactPhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 联系人QQ */
  contactQQ?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  area?: AreaDto;

  /**  */
  city?: CityDto;

  /** 地址 */
  address?: string;

  /** 备注 */
  remark?: string;

  /** 累计捐助次数 */
  donorCount?: number;

  /** 累计认捐学生数量 */
  studentCount?: number;

  /** 介绍人 */
  introducer?: string;

  /** 称呼（学生对捐助人的称呼） */
  named?: string;

  /** 累计捐助金额 */
  donorAmount?: number;
}

export interface BidDonorMemberDto {
  /**  */
  donorId?: number;

  /**  */
  donorName?: string;

  /** 捐助金额 */
  amount?: number;

  /** 是否已捐助完成 */
  isSuccess?: boolean;

  /**  */
  type?: StudentDonorType;

  /** 联系人 */
  contactName?: string;

  /** 联系人手机号 */
  contactPhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 联系地址 */
  address?: string;

  /** 省Id */
  provinceId?: number;

  /** 省 */
  provinceName?: string;

  /** 市Id */
  cityId?: number;

  /** 市 */
  cityName?: string;

  /** 区Id */
  areaId?: number;

  /** 区 */
  areaName?: string;
}

export interface StudentShortDto {
  /**  */
  name?: string;

  /**  */
  studentNo?: string;

  /**  */
  photo?: string;

  /**  */
  nation?: DataDictionaryShortDto;

  /**  */
  sex?: Sex;

  /**  */
  school?: SchoolMiniDto;

  /**  */
  grade?: StudentGrade;

  /** 对接人 */
  counterpart?: string;

  /**  */
  id?: number;
}

export interface PairingRecordDetailHistoryDto {
  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /** 目标金额 */
  amount?: number;

  /** 参与的捐助方 */
  donorMembers?: BidDonorMemberDto[];

  /**  */
  student?: StudentShortDto;
}

export interface StudentDonorPagedResultRequestDto {
  /**  */
  isActive?: boolean;

  /**  */
  name?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /**  */
  type?: StudentDonorType;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StudentDonorDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StudentDonorDto[];
}

export interface StudentDonorCountDto {
  /** 助学（人次） */
  donorCount?: number;

  /** 报名成功的活动数量 */
  activityApplyCount?: number;

  /** 用户收收藏文章的数量 */
  userCollectCount?: number;
}

export interface StudentDonorRegisterAccountDto {
  /** 注册账号Id */
  registerAccountId?: number;

  /**  */
  id?: number;
}

export interface StudentDonorCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: StudentDonorCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface StudentFeedbackRecordCreateOrUpdateInput {
  /** 反馈标题 */
  title?: string;

  /**  */
  studentId?: number;

  /**  */
  grade?: StudentGrade;

  /** 学年 */
  year?: number;

  /** 学期 */
  term?: number;

  /**  */
  content?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface StudentFeedbackRecordDto {
  /** 反馈标题 */
  title?: string;

  /**  */
  studentId?: number;

  /**  */
  student?: StudentMiniDto;

  /** 所属学校 */
  schoolId?: number;

  /**  */
  school?: SchoolMiniDto;

  /**  */
  grade?: StudentGrade;

  /** 学年 */
  year?: number;

  /** 学期 */
  term?: number;

  /**  */
  content?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StudentFeedbackRecordBatchCreateInput {
  /**  */
  studentIds?: number[];

  /** 反馈标题 */
  title?: string;

  /**  */
  grade?: StudentGrade;

  /** 学年 */
  year?: number;

  /** 学期 */
  term?: number;

  /** 反馈内容 */
  content?: string;

  /**  */
  savedAttachments?: number[];
}

export interface StudentFeedbackRecordPagedResultRequestDto {
  /**  */
  title?: string;

  /**  */
  studentId?: number;

  /**  */
  studentName?: string;

  /**  */
  studentNo?: string;

  /**  */
  schoolId?: number;

  /**  */
  creatorUserId?: number;

  /**  */
  year?: number;

  /**  */
  term?: number;

  /**  */
  creationStartTime?: Date;

  /**  */
  creationEndTime?: Date;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface StudentFeedbackRecordCreateOrUpdateInputGetForEditOutput {
  /**  */
  data?: StudentFeedbackRecordCreateOrUpdateInput;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface StudentFeedbackRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: StudentFeedbackRecordDto[];
}

export interface CreatePairingRecordInput {
  /**  */
  studentId?: number;

  /**  */
  amount?: number;

  /**  */
  remark?: string;

  /**  */
  startYear?: number;

  /**  */
  startMonth?: number;

  /**  */
  endYear?: number;

  /**  */
  endMonth?: number;

  /** 发放时间，如果指定，会更新标的的发放记录 */
  grantTime?: Date;

  /** 助学结对时间 */
  pairingTime?: Date;

  /** 备注 */
  grantRemark?: string;

  /**  */
  bidType?: StudentBidType;

  /** 捐助方列表 */
  donorWithAmountList?: DonorWithAmountInput[];
}

export interface ChangeStudentInput {
  /** 结对记录id */
  recordId?: number;

  /**  */
  studentId?: number;

  /**  */
  reason?: string;
}

export interface PairingRecordStudentChangeHistoryDto {
  /** 变更原因 */
  reason?: string;

  /**  */
  student?: StudentMiniDto;

  /**  */
  grade?: StudentGrade;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface PairingRecordDto {
  /**  */
  bid?: StudentAidBidShortDto;

  /** 参与的捐助方 */
  donorMembers?: BidDonorMemberDto[];

  /**  */
  student?: StudentShortDto;

  /** 是否为手工创建 */
  isManualCreate?: boolean;

  /** 备注 */
  remark?: string;

  /** 目标金额 */
  amount?: number;

  /** 已发放金额 */
  grantedTotalAmount?: number;

  /** 剩余余额 */
  availableMoney?: number;

  /** 变更学生次数 */
  changedCount?: number;

  /** 最后一次变更原因 */
  lastChangeReason?: string;

  /** 最后一次变更时间 */
  lastChangeTime?: Date;

  /** 创建时间(完成结对时间) */
  creationTime?: Date;

  /** 最后一次变更时间 */
  lastModificationTime?: Date;

  /**  */
  id?: number;
}

export interface PairingRecordDetailDto {
  /**  */
  historyList?: PairingRecordDetailHistoryDto[];

  /**  */
  bid?: PairingRecordDto;
}

export interface PairingStateDto {
  /**  */
  id?: number;

  /**  */
  donor?: StudentDonorShortDto;

  /**  */
  student?: StudentShortDto;

  /**  */
  order?: StudentBidOrderShortDto;

  /** 捐助方要捐助的金额 */
  donateAmount?: number;

  /** 是否已完成结对 */
  isSuccess?: boolean;

  /**  */
  bid?: StudentAidBidShortDto;

  /** 记录创建时间 */
  creationTime?: Date;

  /** 完成付款时间(成功结对时间) */
  successPayTime?: Date;

  /** 状态描述 */
  statusSummary?: string;
}

export interface PairingStateDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PairingStateDto[];
}

export interface PairingRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PairingRecordDto[];
}

export interface PairingRecordPagedResultRequestDto {
  /**  */
  studentId?: number;

  /**  */
  studentName?: string;

  /**  */
  studentNo?: string;

  /**  */
  donorName?: string;

  /**  */
  schoolName?: string;

  /**  */
  schoolId?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  counterpart?: string;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface PairingStatePagedResultRequestDto {
  /**  */
  donorName?: string;

  /**  */
  studentName?: string;

  /**  */
  studentNo?: string;

  /** 是否已成功结对 */
  isSuccess?: boolean;

  /**  */
  schoolName?: string;

  /**  */
  schoolId?: number;

  /**  */
  bidStatus?: StudentAidBidStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface SwiperCreateOrUpdateDto {
  /**  */
  redirectUrl?: string;

  /**  */
  displayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /** 名称 */
  displayName?: string;

  /**  */
  remark?: string;

  /**  */
  cmsAnchorId?: number;

  /**  */
  id?: number;
}

export interface SwiperDto {
  /**  */
  redirectUrl?: string;

  /**  */
  displayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /** 名称 */
  displayName?: string;

  /**  */
  cmsAnchorId?: number;

  /**  */
  remark?: string;

  /**  */
  anchor?: CmsAnchorDto;

  /**  */
  id?: number;
}

export interface SwiperDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SwiperDto[];
}

export interface TenantDomainCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 域名 */
  domain?: string;

  /**  */
  type?: TenantDomainType;

  /**  */
  id?: number;
}

export interface CreateTenantDto {
  /** 机构标识 */
  tenancyName?: string;

  /** 机构全名 */
  name?: string;

  /** 是否启用 */
  isActive?: boolean;

  /** 域名 */
  domains?: TenantDomainCreateOrUpdateDto[];

  /** 法人 */
  legalPerson?: string;

  /** 注册地址 */
  registeredAddress?: string;

  /** 成立日期 */
  incorporationDate?: Date;

  /** 服务范围 */
  serviceScope?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 登录账号 */
  adminEmailAddress?: string;

  /** 登录密码 */
  adminPassword?: string;

  /**  */
  connectionString?: string;

  /** 方形logo */
  logoSquare?: string;

  /**  */
  logo?: string;
}

export interface TenantDto {
  /** 机构标识 */
  tenancyName?: string;

  /** 机构名 */
  name?: string;

  /** 是否启用 */
  isActive?: boolean;

  /** 版本Id */
  editionId?: number;

  /** 版本名称 */
  editionName?: string;

  /** 法人 */
  legalPerson?: string;

  /** 注册地址 */
  registeredAddress?: string;

  /** 成立日期 */
  incorporationDate?: Date;

  /** 服务范围 */
  serviceScope?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /**  */
  logoSquare?: string;

  /**  */
  logo?: string;

  /**  */
  id?: number;
}

export interface TenantDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TenantDto[];
}

export interface TenantEditionExpireDto {
  /**  */
  editionId?: number;

  /**  */
  endTime?: Date;
}

export interface TenantGrantEditionInput {
  /**  */
  editionId?: number;

  /**  */
  planEndTime?: Date;

  /**  */
  effectDays?: number;

  /**  */
  id?: number;
}

export interface GrantFeaturesToTenantInput {
  /**  */
  tenantId?: number;

  /**  */
  features?: GrantFeatureDto[];
}

export interface UpdateTenantDto {
  /** 机构标识 */
  tenancyName?: string;

  /** 机构名称 */
  name?: string;

  /** 法人 */
  legalPerson?: string;

  /** 注册地址 */
  registeredAddress?: string;

  /** 成立日期 */
  incorporationDate?: Date;

  /** 服务范围 */
  serviceScope?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 方形logo */
  logoSquare?: string;

  /**  */
  logo?: string;

  /**  */
  id?: number;
}

export interface TenantAccessRecordCreateDto {
  /**  */
  tenantId?: number;

  /** 类型 */
  type?: string;

  /** 访问日期 */
  date?: Date;
}

export interface TenantAccountRecordDto {
  /** 金额 */
  moneyAmount?: number;

  /**  */
  recordType?: TenantAccountRecordType;

  /** 内容 */
  content?: string;

  /** 是否属于基金托管 */
  isFundOwner?: boolean;

  /** 是否线上支付 */
  isOnLinePayment?: boolean;

  /** 数据字典维护 */
  paymentType?: string;

  /** 对应收入记录ID */
  incomeRecordId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface TenantAccountRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TenantAccountRecordDto[];
}

export interface TenantAccountDto {
  /** 可用余额 */
  availableMoney?: number;

  /** 可用基金余额 */
  availableFundMoney?: number;

  /** 累计拨付金额 */
  appropriatedMoney?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface TenantApplyCreateOrUpdateDto {
  /**  */
  savedAttachments?: number[];

  /** 机构全名 */
  name?: string;

  /** 法人 */
  legalPerson?: string;

  /** 注册地址 */
  registeredAddress?: string;

  /** 成立日期 */
  incorporationDate?: Date;

  /** 服务范围 */
  serviceScope?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 域名 */
  domains?: string[];

  /** 登录账号 */
  adminAccount?: string;

  /** 登录密码 */
  adminPassword?: string;

  /**  */
  id?: number;
}

export interface TenantApplyDto {
  /** 机构全名 */
  name?: string;

  /** 法人 */
  legalPerson?: string;

  /** 注册地址 */
  registeredAddress?: string;

  /** 成立日期 */
  incorporationDate?: Date;

  /** 服务范围 */
  serviceScope?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /**  */
  status?: TenantApplyAuditStatus;

  /** 拒绝理由 */
  rejectReason?: string;

  /** 域名 */
  domains?: object;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface TenantApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TenantApplyDto[];
}

export interface TenantApplyRejectPostDto {
  /** 拒绝理由 */
  rejectReason?: string;

  /**  */
  id?: number;
}

export interface TenantBankAccountCreateOrUpdateDto {
  /** 银行账号 */
  bankAccountCode?: string;

  /** 银行名称 */
  bankName?: string;

  /** 户名 */
  bankAccountName?: string;

  /**  */
  id?: number;
}

export interface TenantBankAccountDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TenantBankAccountDto[];
}

export interface TenantDomainDto {
  /** 租户Id */
  tenantId?: number;

  /** 租户名称 */
  tenantName?: string;

  /** 域名 */
  domain?: string;

  /**  */
  type?: TenantDomainType;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface TenantDomainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TenantDomainDto[];
}

export interface TestDetail {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  value?: string;

  /**  */
  id?: number;
}

export interface TestMainCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  title?: string;

  /**  */
  testDetails?: TestDetail[];

  /**  */
  id?: number;
}

export interface TestDetailDto {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  value?: string;

  /**  */
  id?: number;
}

export interface TestMainDto {
  /**  */
  name?: string;

  /**  */
  title?: string;

  /**  */
  testDetails?: TestDetailDto[];

  /**  */
  id?: number;
}

export interface ExecuteSqlInput {
  /**  */
  sql?: string;
}

export interface TestMainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TestMainDto[];
}

export interface AuthenticateModel {
  /**  */
  userNameOrEmailAddress?: string;

  /**  */
  password?: string;

  /**  */
  rememberClient?: boolean;
}

export interface AuthenticateResultModel {
  /**  */
  accessToken?: string;

  /**  */
  encryptedAccessToken?: string;

  /**  */
  expireInSeconds?: number;

  /**  */
  userId?: number;

  /**  */
  isNormalLogin?: boolean;

  /**  */
  mobilePhone?: string;
}

export interface ThirdPlatformAuthenticateModel {
  /**  */
  code?: string;
}

export interface ThirdPlatformBindingModel {
  /**  */
  code?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  verificationCode?: string;

  /**  */
  password?: string;
}

export interface TownCreateOrUpdateDto {
  /**  */
  areaId?: number;

  /**  */
  townName?: string;

  /**  */
  id?: number;
}

export interface TownDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TownDto[];
}

export interface TagDto {
  /**  */
  tag?: string;

  /**  */
  isChosen?: boolean;
}

export interface TrainCreateOrUpdateDto {
  /**  */
  id?: number;

  /** 封面图 */
  titleImagePath?: string;

  /** 标题 */
  title?: string;

  /**  */
  trainMethod?: TrainMethod;

  /** 培训标签 */
  trainTag?: string;

  /** 培训详情 */
  content?: string;

  /** 培训类型 */
  trainTypeId?: number;

  /**  */
  trainType?: DataDictionaryDto;

  /** 培训开始时间 */
  trainStartTime?: Date;

  /** 培训结束时间 */
  trainEndTime?: Date;

  /** 奖励积分 */
  point?: number;

  /** 金额 */
  price?: number;

  /** 主办方 */
  host?: string;

  /** 讲师 */
  lecturerId?: number;

  /**  */
  lecturer?: LecturerDto;

  /** 讲师（线下） */
  lecturerUserId?: number;

  /** 培训位置坐标 */
  placeCoordinate?: string;

  /** 省份Id */
  provinceId?: number;

  /** 城市Id */
  cityId?: number;

  /** 区域Id */
  areaId?: number;

  /** 详细地址 */
  address?: string;

  /** 浏览量 */
  readCount?: number;

  /** 已报名数量 */
  signUpCount?: number;

  /** 点赞数量 */
  thumbsUpCount?: number;

  /** 报名截止时间 */
  applyEndTime?: Date;

  /** 最小志愿者星级 */
  minLevel?: number;

  /** 最小志愿者积分 */
  minPoint?: number;

  /** 最大报名人数，0为不限 */
  aimAmount?: number;

  /** 是否需要人工审核 */
  needApprove?: boolean;

  /**  */
  applyTimeType?: number;

  /**  */
  status?: TrainStatus;

  /**  */
  trainChapterList?: TrainChapterDto[];

  /**  */
  tagList?: TagDto[];
}

export interface TrainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainDto[];
}

export interface TrainApplyCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 培训ID */
  trainId?: number;

  /** 报名用户Id */
  userId?: number;

  /** 报名时间 */
  applyTime?: Date;

  /**  */
  id?: number;
}

export interface TrainApplyDto {
  /**  */
  id?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 培训ID */
  trainId?: number;

  /**  */
  train?: TrainDto;

  /** 报名用户Id */
  userId?: number;

  /**  */
  user?: UserMiniDto;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 报名时间 */
  applyTime?: Date;

  /** 审核通过时间 */
  approvedTime?: Date;

  /** 取消时间 */
  cancelTime?: Date;

  /** 退回时间 */
  sendbackTime?: Date;

  /** 取消原因 */
  applyCancelReason?: string;

  /** 退回原因 */
  applyReturnReason?: string;

  /**  */
  status?: ActivityApplyStatus;

  /** 金额 */
  totalPrice?: number;

  /** 实际付款金额 */
  totalPaid?: number;

  /** 签到次数 */
  checkInTimes?: number;

  /** 创建时间 */
  creationTime?: Date;

  /**  */
  userExtend?: UserExtendMiniDto;

  /** 最小志愿者星级 */
  minLevel?: number;

  /**  */
  minLevelText?: string;

  /** 可以进行考试 */
  canGoExam?: boolean;

  /** 反馈退回原因 */
  recordReturnReason?: string;

  /** 审核退回附件 */
  returnAttachments?: AttachmentDto[];
}

export interface TrainApplyStatusDto {
  /**  */
  applyStatus?: ActivityApplyStatus;
}

export interface TrainApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainApplyDto[];
}

export interface TrainApplyCheckInPostDto {
  /** 签到时间 */
  checkInTime?: Date;

  /**  */
  id?: number;
}

export interface TrainCheckInRecordUdateDto {
  /** 感悟 */
  content?: string;

  /**  */
  id?: number;
}

export interface TrainCheckInRecordDto {
  /** 培训ID */
  trainId?: number;

  /**  */
  train?: TrainDto;

  /** 培训报名ID */
  trainApplyId?: number;

  /**  */
  trainApply?: TrainApplyDto;

  /** 报名用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserMiniDto;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 签到时间 */
  checkInTime?: Date;

  /**  */
  userExtend?: UserExtendMiniDto;

  /** 感悟 */
  content?: string;

  /** 感悟填写时间 */
  writtenTime?: Date;

  /**  */
  id?: number;
}

export interface TrainCheckInRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainCheckInRecordDto[];
}

export interface TrainApplyPagedResultRequestDto {
  /** 姓名 */
  surName?: string;

  /**  */
  phone?: string;

  /**  */
  trainId?: number;

  /**  */
  trainApplyId?: number;

  /**  */
  status?: ActivityApplyStatus;

  /**  */
  queryStatus?: QueryApplyStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface TrainApplyDtoListResultDto {
  /**  */
  items?: TrainApplyDto[];
}

export interface TrainManagementCreateOrUpdateDto {
  /** 培训主题 */
  trainId?: number;

  /** 参加人数 */
  volunteerCount?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface TrainManagementDto {
  /**  */
  organizationUnit?: OrganizationUnitDto;

  /** 所属培训 */
  trainId?: number;

  /**  */
  train?: TrainDto;

  /** 志愿者人数 */
  volunteerCount?: number;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface TrainManagementDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainManagementDto[];
}

export interface TrainRecordDto {
  /**  */
  id?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 培训ID */
  trainId?: number;

  /**  */
  train?: TrainDto;

  /** 培训报名ID */
  trainApplyId?: number;

  /** 报名用户Id */
  applyUserId?: number;

  /**  */
  applyUser?: UserMiniDto;

  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 培训感悟 */
  feelings?: string;

  /**  */
  returnReason?: string;

  /** 创建时间 */
  creationTime?: Date;

  /** 培训次数 */
  trainCount?: number;

  /** 最小志愿者星级 */
  minLevel?: number;

  /**  */
  minLevelText?: string;
}

export interface TrainRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainRecordDto[];
}

export interface TrainRecordCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 培训ID */
  trainId?: number;

  /** 培训报名ID */
  trainApplyId?: number;

  /** 报名用户Id */
  applyUserId?: number;

  /** 志愿者Id */
  volunteerId?: number;

  /** 培训感悟 */
  feelings?: string;

  /**  */
  returnReason?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface TrainRecordPagedResultRequestDto {
  /** 姓名 */
  surName?: string;

  /** 培训主题 */
  trainTitle?: string;

  /** 培训类型 */
  trainTypeId?: number;

  /** 开始时间 */
  startDate?: Date;

  /** 志愿者 */
  volunteerId?: number;

  /** 结束时间 */
  endDate?: Date;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface TrainRecordDtoListResultDto {
  /**  */
  items?: TrainRecordDto[];
}

export interface CreateUserDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /** 所属部门 */
  departmentId?: number;

  /**  */
  isActive?: boolean;

  /**  */
  roleNames?: string[];

  /**  */
  password?: string;

  /**  */
  organizationUnitId?: number;

  /**  */
  phoneNumber?: string;
}

export interface UserDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserDto[];
}

export interface RoleDtoListResultDto {
  /**  */
  items?: RoleDto[];
}

export interface GrantRoleInput {
  /**  */
  userId?: number;

  /**  */
  roles?: string[];
}

export interface ChangeUserLanguageDto {
  /**  */
  languageName?: string;
}

export interface UserOrganizationUnitDto {
  /**  */
  user?: UserDto;

  /**  */
  organizationUnit?: OrganizationUnitDto;

  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isActive?: boolean;

  /**  */
  fullName?: string;

  /**  */
  lastLoginTime?: Date;

  /**  */
  creationTime?: Date;

  /**  */
  departmentId?: number;

  /**  */
  departmentName?: string;

  /**  */
  roleNames?: string[];

  /**  */
  roleNamesText?: string[];

  /**  */
  organizationUnitId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  userType?: UserType;

  /**  */
  fundId?: number;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /** 公众号openId */
  openId?: string;

  /** 当前积分 */
  point?: number;

  /**  */
  id?: number;
}

export interface UserOrganizationUnitDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserOrganizationUnitDto[];
}

export interface UserProfileUpdateDto {
  /** 昵称 */
  name?: string;

  /** 性别 */
  sex?: string;

  /** 住址 */
  address?: string;

  /** 自我介绍 */
  description?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  birthday?: Date;

  /**  */
  headImgUrl?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  id?: number;
}

export interface ChangePasswordDto {
  /**  */
  currentPassword?: string;

  /**  */
  newPassword?: string;
}

export interface ResetPasswordDto {
  /**  */
  adminPassword?: string;

  /**  */
  userId?: number;

  /**  */
  newPassword?: string;
}

export interface ResetPassword4PortalDto {
  /** 手机号码 */
  phone?: string;

  /** 新密码 */
  newPassword?: string;

  /** 短信验证码 */
  code?: string;
}

export interface ResetPassword4PortalWithEmailDto {
  /** 邮箱 */
  emailAddress?: string;

  /** 新密码 */
  newPassword?: string;

  /** 验证码 */
  code?: string;
}

export interface UserPointRecordDto {
  /**  */
  tenantId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /** 用户Id */
  userId?: number;

  /**  */
  user?: UserMiniDto;

  /** 积分 */
  point?: number;

  /**  */
  pointType?: PointType;

  /** 具体类型Id */
  detailTypeId?: number;

  /**  */
  detailType?: DataDictionaryDto;

  /** 积分描述 */
  content?: string;

  /**  */
  id?: number;
}

export interface UserPointRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserPointRecordDto[];
}

export interface PagedUserResultRequestDto {
  /**  */
  keyword?: string;

  /**  */
  isActive?: boolean;

  /**  */
  organizationUnitId?: number;

  /**  */
  departmentId?: number;

  /**  */
  departments?: string;

  /** 用户查询模式: foundation | organizationUnit */
  mode?: string;

  /** 角色Id */
  roleId?: number;

  /** 昵称 */
  name?: string;

  /** 姓名 */
  surname?: string;

  /** 手机号 */
  phoneNumber?: string;

  /** 是否显示注册用户 */
  isOnlyMembership?: boolean;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface UserCertificate {
  /** 证书Id */
  id?: number;

  /** 证书名称 */
  title?: string;

  /** 证书描述 */
  description?: string;

  /** 图标 */
  imagePath?: string;

  /**  */
  type?: CertificateType;

  /** 是否已颁发 */
  isIssued?: boolean;
}

export interface UserCenterDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /** 成员图片\/用户头像 */
  headImgUrl?: string;

  /** 用户积分 */
  point?: number;

  /** 性别 */
  sex?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 活动时长(小时) */
  totalHours?: number;

  /** 志愿者等级 */
  level?: number;

  /** 服务次数 */
  serviceCount?: number;

  /** 培训次数 */
  trainCount?: number;

  /** 活动次数 */
  activityCount?: number;

  /** 是否志愿者 */
  isVolunteer?: boolean;

  /** 机构名称 */
  orgName?: string;

  /** 活动次数 */
  userCertificates?: UserCertificate[];
}

export interface BbsGroupMiniDto {
  /** 群组名称 */
  name?: string;

  /**  */
  status?: GroupStatus;

  /** 封面图 */
  titleImagePath?: string;

  /**  */
  id?: number;
}

export interface Post {
  /** 主贴Id */
  postId?: number;

  /** 主贴标题 */
  postTitle?: string;

  /** 评论Id */
  commentId?: number;

  /** 主贴内容 */
  content?: string;

  /** 评论内容 */
  commentContent?: string;

  /** 浏览数量 */
  browseCount?: number;

  /** 点赞数量 */
  thumbsUpCount?: number;

  /** 评论数量 */
  commentCount?: number;

  /** 群组名称 */
  groupName?: string;

  /**  */
  creationTime?: Date;
}

export interface UserTaskCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  savedAttachments?: number[];

  /** 紧急程度Id */
  importantLevelId?: number;

  /** TagId */
  tagId?: number;

  /** 被指派人Id */
  permissionUserId?: number;

  /** 对应的活动Id */
  activityId?: number;

  /** 任务标题 */
  title?: string;

  /** 任务内容 */
  content?: string;

  /** 任务开始时间 */
  startTime?: Date;

  /** 任务结束时间 */
  endTime?: Date;

  /**  */
  id?: number;
}

export interface UserTaskDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /** 紧急程度Id */
  importantLevelId?: number;

  /**  */
  importantLevel?: DataDictionaryDto;

  /** TagId */
  tagId?: number;

  /**  */
  tag?: DataDictionaryDto;

  /** 被指派人Id */
  permissionUserId?: number;

  /**  */
  permissionUser?: UserMiniDto;

  /** 对应的活动Id */
  activityId?: number;

  /**  */
  activity?: ActivityDto;

  /** 任务标题 */
  title?: string;

  /** 任务内容 */
  content?: string;

  /**  */
  type?: TaskType;

  /**  */
  status?: TaskStatus;

  /** 审核不通过理由 */
  sendbackReason?: string;

  /** 任务开始时间 */
  startTime?: Date;

  /** 任务结束时间 */
  endTime?: Date;

  /** 提交时间 */
  submitTime?: Date;

  /** 审核通过时间 */
  approvedTime?: Date;

  /** 审核不通过时间 */
  sendbackTime?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface UserTaskDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserTaskDto[];
}

export interface UserTaskDtoListResultDto {
  /**  */
  items?: UserTaskDto[];
}

export interface VolunteerDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: VolunteerDto[];
}

export interface VolunteerCreateOrUpdateDto {
  /** 姓名 */
  surName?: string;

  /** 手机号码 */
  mobile?: string;

  /** 身份证号码 */
  idNo?: string;

  /** 性别 */
  sex?: string;

  /** 生日 */
  birthday?: Date;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  userId?: number;

  /** 推荐人 */
  recommenderUserId?: number;

  /**  */
  id?: number;
}

export interface VolunteerPagedResultRequestDto {
  /** 志愿者姓名 */
  volunteerName?: string;

  /** 志愿者手机号 */
  volunteerPhone?: string;

  /**  */
  volunteerId?: number;

  /** 志愿者编号 */
  volunteerCode?: string;

  /**  */
  includeUserExtend?: boolean;

  /**  */
  status?: VolunteerStatus;

  /**  */
  sorting?: string;

  /**  */
  skipCount?: number;

  /**  */
  maxResultCount?: number;
}

export interface BatchAuditInput {
  /**  */
  list?: UserAuditInput[];
}

export interface VolunteerQueryDto {
  /**  */
  phone?: string;

  /**  */
  surname?: string;

  /**  */
  idNo?: string;
}

export interface VolunteerQueryResultDto {
  /**  */
  volunteerOrgCode?: string;

  /**  */
  level?: number;

  /**  */
  surname?: string;

  /**  */
  userName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  idNo?: string;

  /**  */
  sex?: string;
}

export interface VolunteerDtoListResultDto {
  /**  */
  items?: VolunteerDto[];
}

export interface VolunteerDashbordCountDto {
  /** 服务数量 */
  serviceCount?: number;

  /** 培训数量 */
  trainCount?: number;

  /** 活动数量 */
  activityCount?: number;

  /** 志愿者数量 */
  volunteerCount?: number;

  /** 会员单位数量 */
  orgCount?: number;
}

export interface VolunteerTotalHoursRankingDto {
  /** 志愿者姓名 */
  surname?: string;

  /** 服务时长 */
  totalHours?: number;

  /** 志愿者星级 */
  level?: number;
}

export interface VolunteerPointRankingDto {
  /** 志愿者姓名 */
  surname?: string;

  /** 积分 */
  point?: number;

  /** 志愿者星级 */
  level?: number;
}

export interface VolunteerServiceHistoryCreateOrUpdateDto {
  /** 志愿者Id */
  volunteerId?: number;

  /** 对应的活动Id */
  activityId?: number;

  /** 服务开始时间 */
  serviceStartTime?: Date;

  /** 服务结束时间 */
  serviceEndTime?: Date;

  /** 服务时长（小时） */
  serviceHour?: number;

  /** 服务内容 */
  serviceContent?: string;

  /**  */
  serviceType?: ServiceType;

  /** 完成情况 */
  completeStatus?: string;

  /** 评价 */
  appraise?: string;

  /** 评价人姓名 */
  appraiserName?: string;

  /**  */
  id?: number;
}

export interface VolunteerServiceHistoryDto {
  /** 志愿者Id */
  volunteerId?: number;

  /**  */
  volunteer?: VolunteerDto;

  /** 对应的活动Id */
  activityId?: number;

  /**  */
  activity?: ActivityDto;

  /** 服务开始时间 */
  serviceStartTime?: Date;

  /** 服务结束时间 */
  serviceEndTime?: Date;

  /** 服务时常（分钟） */
  serviceMinute?: number;

  /** 服务时长（小时） */
  serviceHour?: number;

  /** 服务内容 */
  serviceContent?: string;

  /**  */
  serviceType?: ServiceType;

  /** 完成情况 */
  completeStatus?: string;

  /** 评价 */
  appraise?: string;

  /** 评价人姓名 */
  appraiserName?: string;

  /** 0：未核销，1：已核销 */
  checkStatus?: boolean;

  /**  */
  id?: number;
}

export interface UserVolunteerInfo {
  /** 服务时长 */
  serviceMinutes?: number;

  /** 志愿者等级 */
  volunteerLevel?: number;

  /** 服务次数 */
  serviceCount?: number;

  /** 培训次数 */
  trainCount?: number;

  /** 活动次数 */
  activityCount?: number;

  /** 是否为志愿者 */
  isVolunteer?: boolean;
}

export interface VolunteerServiceHistoryCreateOrUpdateDtoGetForEditOutput {
  /**  */
  data?: VolunteerServiceHistoryCreateOrUpdateDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface VolunteerServiceHistoryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: VolunteerServiceHistoryDto[];
}

export enum TenantAvailabilityState {
  'Available' = 'Available',
  'InActive' = 'InActive',
  'NotFound' = 'NotFound'
}

export enum VerificationCode {
  'RegisterVercificationCodeSMS' = 'RegisterVercificationCodeSMS',
  'RetrivePasswordVercificationCodeSMS' = 'RetrivePasswordVercificationCodeSMS',
  'ChangePhoneVercificationCodeSMS' = 'ChangePhoneVercificationCodeSMS',
  'RegisterVercificationCodeEmail' = 'RegisterVercificationCodeEmail',
  'RetrivePasswordVercificationCodeEmail' = 'RetrivePasswordVercificationCodeEmail'
}

export enum ActivityStatus {
  'Deleted' = 'Deleted',
  'Saved' = 'Saved',
  'SendBacked' = 'SendBacked',
  'Submited' = 'Submited',
  'Approved' = 'Approved',
  'Published' = 'Published',
  'Finished' = 'Finished'
}

export enum ActivityQueryStatus {
  'Ongoing' = 'Ongoing',
  'NotStarted' = 'NotStarted',
  'Ended' = 'Ended'
}

export enum ActivityTypeEnum {
  'GeneralActivity' = 'GeneralActivity',
  'VolunteerActivity' = 'VolunteerActivity'
}

export enum AttachmentHostType {
  'Temp' = 'Temp',
  'CmsContentTitleImage' = 'CmsContentTitleImage',
  'CmsContentAttachment' = 'CmsContentAttachment',
  'EditorImage' = 'EditorImage',
  'CmsCategory' = 'CmsCategory',
  'Swiper' = 'Swiper',
  'FriendlyLinkLogo' = 'FriendlyLinkLogo',
  'OrganizationUnit' = 'OrganizationUnit',
  'Message' = 'Message',
  'Audit' = 'Audit',
  'External_Project_Monthly_Summarize_Attachment' = 'External_Project_Monthly_Summarize_Attachment',
  'External_Project_Monthly_Summarize_Photo' = 'External_Project_Monthly_Summarize_Photo',
  'External_Project_Intention' = 'External_Project_Intention',
  'External_Project' = 'External_Project',
  'External_Project_Process_Monitoring' = 'External_Project_Process_Monitoring',
  'External_Project_Progress_Report' = 'External_Project_Progress_Report',
  'External_Project_Summary_Report' = 'External_Project_Summary_Report',
  'External_Project_Close_Report' = 'External_Project_Close_Report',
  'External_Project_Monthly_Summarize_Detail_Attachment' = 'External_Project_Monthly_Summarize_Detail_Attachment',
  'Internal_Project_Monthly_Summarize_Attachment' = 'Internal_Project_Monthly_Summarize_Attachment',
  'Internal_Project' = 'Internal_Project',
  'Internal_Project_Summary_Report' = 'Internal_Project_Summary_Report',
  'Internal_Personal_Year_End_Summary' = 'Internal_Personal_Year_End_Summary',
  'Internal_ServiceRecord' = 'Internal_ServiceRecord',
  'Beneficiary' = 'Beneficiary',
  'ProjectSeries' = 'ProjectSeries',
  'FundApply' = 'FundApply',
  'Fund' = 'Fund',
  'FundModifyApply' = 'FundModifyApply',
  'FundProjectApply' = 'FundProjectApply',
  'FundProject' = 'FundProject',
  'FundProjectModifyApply' = 'FundProjectModifyApply',
  'FundProjectProgress' = 'FundProjectProgress',
  'DonatedAmountConfig' = 'DonatedAmountConfig',
  'Invoice' = 'Invoice',
  'FundAppropriateApply' = 'FundAppropriateApply',
  'FundAppropriateRecord' = 'FundAppropriateRecord',
  'FundUser' = 'FundUser',
  'InvoiceInformation' = 'InvoiceInformation',
  'UserTask' = 'UserTask',
  'Activity' = 'Activity',
  'Job' = 'Job',
  'ApplicationPayment' = 'ApplicationPayment',
  'FundDonationOrder' = 'FundDonationOrder',
  'Volunteer' = 'Volunteer',
  'ElectronicInvoice' = 'ElectronicInvoice',
  'Student' = 'Student',
  'StudentFeedback' = 'StudentFeedback',
  'TenantApply' = 'TenantApply',
  'TrainManage' = 'TrainManage',
  'TrainRecord' = 'TrainRecord',
  'Train' = 'Train',
  'Help' = 'Help',
  'HelpApplies' = 'HelpApplies',
  'ActivityManage' = 'ActivityManage',
  'ActivityRecord' = 'ActivityRecord',
  'BbsPost' = 'BbsPost',
  'StarRating' = 'StarRating',
  'StarRatingRecord' = 'StarRatingRecord',
  'ServiceRecord' = 'ServiceRecord',
  'ServiceManage' = 'ServiceManage',
  'BbsGroupTitleImage' = 'BbsGroupTitleImage',
  'SlashScreen' = 'SlashScreen',
  'ProjectReport' = 'ProjectReport',
  'SignupFile' = 'SignupFile'
}

export enum UserType {
  'Membership' = 'Membership',
  'Tenant' = 'Tenant',
  'Fund' = 'Fund',
  'School' = 'School',
  'Platform' = 'Platform'
}

export enum ActivityQrType {
  'Detail' = 'Detail',
  'CheckIn' = 'CheckIn',
  'CodeUrl' = 'CodeUrl'
}

export enum ActivityApplyStatus {
  'Auditing' = 'Auditing',
  'Return' = 'Return',
  'AuditReject' = 'AuditReject',
  'WaitPay' = 'WaitPay',
  'Approved' = 'Approved',
  'Finished' = 'Finished',
  'Cancel' = 'Cancel'
}

export enum AuditUserLogStatus {
  'Reject' = 'Reject',
  'Pass' = 'Pass',
  'Back' = 'Back',
  'Continue' = 'Continue'
}

export enum AuditFlowRecordStatus {
  'Ongoing' = 'Ongoing',
  'Pass' = 'Pass',
  'Reject' = 'Reject',
  'Withdraw' = 'Withdraw'
}

export enum AuditMethod {
  'AnyOnePassed' = 'AnyOnePassed',
  'AllApproved' = 'AllApproved',
  'AllPassed' = 'AllPassed',
  'AllParticipateAudit' = 'AllParticipateAudit'
}

export enum AuditFLowStatus {
  'Closed' = 'Closed',
  'Open' = 'Open'
}

export enum AuditFlowType {
  'AuditOne' = 'AuditOne',
  'AuditAll' = 'AuditAll'
}

export enum VolunteerStatus {
  'Pending' = 'Pending',
  'Approved' = 'Approved',
  'Rejected' = 'Rejected',
  'Baned' = 'Baned'
}

export enum FundType {
  'Professional' = 'Professional'
}

export enum ValidStatus {
  'Deleted' = 'Deleted',
  'InValid' = 'InValid',
  'Valid' = 'Valid'
}

export enum ApplyStatus {
  'Cancel' = 'Cancel',
  'Check' = 'Check',
  'Return' = 'Return',
  'Approved' = 'Approved'
}

export enum ClientType {
  'System' = 'System',
  'PC' = 'PC',
  'WeixinMini' = 'WeixinMini',
  'Weixin' = 'Weixin',
  'Backend' = 'Backend'
}

export enum OrderSourceType {
  'Pc' = 'Pc',
  'H5' = 'H5',
  'WechatMiniApp' = 'WechatMiniApp'
}

export enum QueryApplyStatus {
  'Auditing' = 'Auditing',
  'Return' = 'Return',
  'AuditReject' = 'AuditReject',
  'NeedFeedback' = 'NeedFeedback',
  'Approved' = 'Approved',
  'Finished' = 'Finished',
  'Cancel' = 'Cancel'
}

export enum ImportResultType {
  'Inserted' = 'Inserted',
  'Updated' = 'Updated',
  'NotFound' = 'NotFound',
  'ValidationError' = 'ValidationError',
  'Error' = 'Error',
  'DataConvertFailed' = 'DataConvertFailed',
  'Exist' = 'Exist'
}

export enum OrderType {
  'Activity' = 'Activity',
  'Course' = 'Course'
}

export enum OrderStatus {
  'NotPay' = 'NotPay',
  'Expired' = 'Expired',
  'Finished' = 'Finished',
  'Refunded' = 'Refunded',
  'Cancel' = 'Cancel'
}

export enum ApprovalCategory {
  'Current_Expenses' = 'Current_Expenses',
  'Expenditure_Charitable_Projects' = 'Expenditure_Charitable_Projects',
  'Physical_Investment' = 'Physical_Investment',
  'Investment_Financing' = 'Investment_Financing',
  'Internal_Business_Reconciliation' = 'Internal_Business_Reconciliation',
  'Other' = 'Other'
}

export enum CurrencyType {
  'RMB' = 'RMB'
}

export enum FundProjectType {
  'OnlineFundraising' = 'OnlineFundraising',
  'CollectionOfAwardWinning' = 'CollectionOfAwardWinning',
  'ExcellentAssistanceCases' = 'ExcellentAssistanceCases',
  'CharityFundRaising' = 'CharityFundRaising'
}

export enum AuditFlowScope {
  'All' = 'All',
  'PartnerApply' = 'PartnerApply',
  'ExternalProjectIntention' = 'ExternalProjectIntention',
  'DueDiligenceRecord' = 'DueDiligenceRecord',
  'ExternalProject' = 'ExternalProject',
  'ExternalMonthlyPlan' = 'ExternalMonthlyPlan',
  'ExternalMonthlySummarize' = 'ExternalMonthlySummarize',
  'ExternalProjectAdjustment' = 'ExternalProjectAdjustment',
  'ExternalProjectProgressReport' = 'ExternalProjectProgressReport',
  'ExternalProjectProcessMonitoring' = 'ExternalProjectProcessMonitoring',
  'ExternalProjectSummaryReport' = 'ExternalProjectSummaryReport',
  'ExternalProjectCloseReport' = 'ExternalProjectCloseReport',
  'InternalProject' = 'InternalProject',
  'InternalMonthlyPlan' = 'InternalMonthlyPlan',
  'InternalMonthlySummarize' = 'InternalMonthlySummarize',
  'InternalProjectSummaryReport' = 'InternalProjectSummaryReport',
  'InternalPersonalYearEndSummary' = 'InternalPersonalYearEndSummary',
  'Beneficiary' = 'Beneficiary',
  'ServiceRecord' = 'ServiceRecord',
  'ProjectBebeficiaryMapping' = 'ProjectBebeficiaryMapping',
  'CapitalIncomeRecordFromDonation' = 'CapitalIncomeRecordFromDonation',
  'CapitalIncomeRecordFromOther' = 'CapitalIncomeRecordFromOther',
  'PartnerCapitalApply' = 'PartnerCapitalApply',
  'CapitalExpenseRecord' = 'CapitalExpenseRecord',
  'FundApply' = 'FundApply',
  'FundModifyApply' = 'FundModifyApply',
  'FundProjectApply' = 'FundProjectApply',
  'FundProjectModifyApply' = 'FundProjectModifyApply',
  'FundNonCashDonation' = 'FundNonCashDonation',
  'FundAppropriateApply' = 'FundAppropriateApply',
  'Application_Payment_Current_Expenses_Le_5' = 'Application_Payment_Current_Expenses_Le_5',
  'Application_Payment_Current_Expenses_Gt_5' = 'Application_Payment_Current_Expenses_Gt_5',
  'Application_Payment_Expenditure_Charitable_Projects_Le_20' = 'Application_Payment_Expenditure_Charitable_Projects_Le_20',
  'Application_Payment_Expenditure_Charitable_Projects_Gt_20' = 'Application_Payment_Expenditure_Charitable_Projects_Gt_20',
  'Application_Payment_Physical_Investment_Le_100' = 'Application_Payment_Physical_Investment_Le_100',
  'Application_Payment_Physical_Investment_Gt_100' = 'Application_Payment_Physical_Investment_Gt_100',
  'Application_Payment_Investment_Financing_Le_500' = 'Application_Payment_Investment_Financing_Le_500',
  'Application_Payment_Investment_Financing_Gt_500' = 'Application_Payment_Investment_Financing_Gt_500',
  'Application_Payment_Internal_Business_Reconciliation_Le_20' = 'Application_Payment_Internal_Business_Reconciliation_Le_20',
  'Application_Payment_Internal_Business_Reconciliation_Gt_20' = 'Application_Payment_Internal_Business_Reconciliation_Gt_20',
  'Application_Payment_Other' = 'Application_Payment_Other',
  'UserTask' = 'UserTask',
  'Activity' = 'Activity',
  'ActivityApply' = 'ActivityApply',
  'Volunteer' = 'Volunteer',
  'FinanceApplicationPayment' = 'FinanceApplicationPayment',
  'TrainApply' = 'TrainApply',
  'ActivityRecord' = 'ActivityRecord',
  'ServiceApply' = 'ServiceApply',
  'StarRatingRecord' = 'StarRatingRecord',
  'CmsContentApply' = 'CmsContentApply',
  'HelpApply' = 'HelpApply',
  'TrainRecord' = 'TrainRecord'
}

export enum ApplicationPaymentValidationStatus {
  'Success' = 'Success',
  'Fail' = 'Fail'
}

export enum AuditFlowConditionValueType {
  'Enum' = 'Enum',
  'Number' = 'Number',
  'DataSource' = 'DataSource',
  'String' = 'String'
}

export enum GroupStatus {
  'Enable' = 'Enable',
  'Disable' = 'Disable'
}

export enum ServiceRecordType {
  'Beneficiary' = 'Beneficiary',
  'School' = 'School'
}

export enum WorkflowOfProjectByExternal {
  'ProjectApproval' = 'ProjectApproval',
  'ProgressReport' = 'ProgressReport',
  'SummaryReport' = 'SummaryReport',
  'Closed' = 'Closed',
  'Termination' = 'Termination'
}

export enum ProjectServiceRecordCompleteStatus {
  'NotComplete' = 'NotComplete',
  'PartialComplete' = 'PartialComplete',
  'AllComplete' = 'AllComplete'
}

export enum PayType {
  'Cash' = 'Cash',
  'Transfer' = 'Transfer'
}

export enum ChangeState {
  'UnActive' = 'UnActive',
  'Active' = 'Active'
}

export enum ProjectBudgetCategory {
  'BusinessActivityCost' = 'BusinessActivityCost'
}

export enum ProjectBudgetType {
  'ActivityCost' = 'ActivityCost',
  'AdvertiseCost' = 'AdvertiseCost',
  'AdministrativeCost' = 'AdministrativeCost',
  'PersonnelSalary' = 'PersonnelSalary',
  'Other' = 'Other'
}

export enum ProjectBudgetCapitalSource {
  'Apply' = 'Apply',
  'Self' = 'Self',
  'Other' = 'Other'
}

export enum CapitalProperty {
  'NonFinite' = 'NonFinite',
  'Finite' = 'Finite'
}

export enum IncomeCategory {
  'Interest' = 'Interest',
  'Investment' = 'Investment',
  'Other' = 'Other'
}

export enum CmsCategoryType {
  'Content' = 'Content',
  'Article' = 'Article'
}

export enum CmsContentStatus {
  'Draft' = 'Draft',
  'Auditing' = 'Auditing',
  'Reject' = 'Reject',
  'Published' = 'Published',
  'Deleted' = 'Deleted'
}

export enum CollectWaterStatus {
  'Waiting' = 'Waiting',
  'Confirmed' = 'Confirmed'
}

export enum CustomFormType {
  'Student' = 'Student',
  'OrganizationUnit' = 'OrganizationUnit',
  'InternalProject' = 'InternalProject',
  'Beneficiary' = 'Beneficiary',
  'BeneficiaryServiceRecord' = 'BeneficiaryServiceRecord',
  'SchoolServiceRecord' = 'SchoolServiceRecord',
  'Volunteer' = 'Volunteer',
  'ApplicationPayment' = 'ApplicationPayment',
  'FundProject' = 'FundProject',
  'FundProjectApply' = 'FundProjectApply',
  'FundProjectModifyApply' = 'FundProjectModifyApply',
  'TrainManage' = 'TrainManage',
  'ActivityManage' = 'ActivityManage',
  'StarRatingManagement' = 'StarRatingManagement',
  'ServiceManagement' = 'ServiceManagement',
  'ServiceObjectFeedback' = 'ServiceObjectFeedback'
}

export enum CustomFormDataSourceType {
  'Enum' = 'Enum',
  'DataDictionary' = 'DataDictionary',
  'Api' = 'Api',
  'CustomValues' = 'CustomValues'
}

export enum DonationType {
  'Monthly' = 'Monthly',
  'Commemorative' = 'Commemorative',
  'Birthday' = 'Birthday',
  'Festival' = 'Festival'
}

export enum DonorType {
  'Personal' = 'Personal',
  'Group' = 'Group',
  'Enterprise' = 'Enterprise'
}

export enum DonorItemType {
  'NonDirectional' = 'NonDirectional',
  'NamingFund' = 'NamingFund',
  'ProjectDonor' = 'ProjectDonor'
}

export enum ElectronicInvoiceType {
  'Personal' = 'Personal',
  'EnOrOtherOrg' = 'EnOrOtherOrg'
}

export enum DonationCycle {
  'Weekly' = 'Weekly',
  'Monthly' = 'Monthly',
  'Year' = 'Year'
}

export enum DataDictionarySyncMode {
  'MinimumEffect' = 'MinimumEffect',
  'Force' = 'Force'
}

export enum DataPermissionLimitHostType {
  'CmsCategory' = 'CmsCategory'
}

export enum DataPermissionLimitTargetType {
  'User' = 'User',
  'Role' = 'Role'
}

export enum DonatedAmountConfigSourceType {
  'Fund' = 'Fund',
  'FundProject' = 'FundProject'
}

export enum ElectronicInvoiceStatus {
  'ToBeConfirmed' = 'ToBeConfirmed',
  'BillingInProgress' = 'BillingInProgress',
  'Invoiced' = 'Invoiced',
  'Invalid' = 'Invalid',
  'Failed' = 'Failed'
}

export enum QuestionType {
  'Radio' = 'Radio',
  'CheckBox' = 'CheckBox',
  'YesOrNo' = 'YesOrNo',
  'QuestionAndAnswer' = 'QuestionAndAnswer'
}

export enum ExamSourceType {
  'Train' = 'Train',
  'StarRating' = 'StarRating'
}

export enum ExamPaperStatus {
  'Ongoing' = 'Ongoing',
  'Finished' = 'Finished',
  'ImportExcel' = 'ImportExcel'
}

export enum ProjectMonthlyPlanCompleteStatus {
  'None' = 'None',
  'Adjust' = 'Adjust',
  'Complete' = 'Complete'
}

export enum ProjectPhaseStatus {
  'NoTask' = 'NoTask',
  'None' = 'None',
  'Ongoing' = 'Ongoing',
  'DelayComplete' = 'DelayComplete',
  'Complete' = 'Complete'
}

export enum FeatureScopes {
  'Edition' = 'Edition',
  'Tenant' = 'Tenant',
  'All' = 'All'
}

export enum FeedbackSourceType {
  'Reception' = 'Reception',
  'Backstage' = 'Backstage'
}

export enum FormType {
  'VolunteerApply' = 'VolunteerApply',
  'OrganizationUnitApply' = 'OrganizationUnitApply'
}

export enum DonateType {
  'NormalDonate' = 'NormalDonate',
  'MonthlyDonate' = 'MonthlyDonate',
  'ProjectNormalDonate' = 'ProjectNormalDonate',
  'ProjectMonthlyDonate' = 'ProjectMonthlyDonate'
}

export enum MessageType {
  'ProjectProgress' = 'ProjectProgress',
  'Organization' = 'Organization',
  'System' = 'System',
  'Fund' = 'Fund',
  'FundProjectProgress' = 'FundProjectProgress',
  'Donor' = 'Donor'
}

export enum FundAccountRecordOperationType {
  'Income' = 'Income',
  'Appropriate' = 'Appropriate',
  'ManageFee' = 'ManageFee',
  'ManageFeeReturn' = 'ManageFeeReturn',
  'ManageFeeAdd' = 'ManageFeeAdd'
}

export enum FundAppropriateType {
  'FundProject' = 'FundProject',
  'Fund' = 'Fund',
  'CaseAppropriation' = 'CaseAppropriation',
  'InternalAppropriation' = 'InternalAppropriation',
  'QuotaAppropriationInTheAnnualPlan' = 'QuotaAppropriationInTheAnnualPlan',
  'SCHProject' = 'SCHProject',
  'SCHPersonal' = 'SCHPersonal',
  'SCHIllness' = 'SCHIllness',
  'FinancingCost' = 'FinancingCost',
  'PerformCost' = 'PerformCost',
  'ProjectCost' = 'ProjectCost',
  'Goods' = 'Goods',
  'TencentDay' = 'TencentDay',
  'PoorDay' = 'PoorDay',
  'Directional' = 'Directional',
  'SupportDepartment' = 'SupportDepartment',
  'CounterpartsSupport' = 'CounterpartsSupport',
  'Other' = 'Other'
}

export enum IncomeType {
  'Project' = 'Project',
  'Fund' = 'Fund',
  'Org' = 'Org'
}

export enum ManagerFeeChangeType {
  'ManageFeeAdd' = 'ManageFeeAdd',
  'ManageFeeReturn' = 'ManageFeeReturn'
}

export enum DonatorType {
  'Personal' = 'Personal',
  'Company' = 'Company',
  'Team' = 'Team'
}

export enum FundProjectStatus {
  'InValid' = 'InValid',
  'Valid' = 'Valid',
  'End' = 'End',
  'Deleted' = 'Deleted'
}

export enum AuditStatus {
  'Auditing' = 'Auditing',
  'Reject' = 'Reject',
  'Pass' = 'Pass'
}

export enum CertificateType {
  'OneStarVolunteer' = 'OneStarVolunteer',
  'TwoStarVolunteer' = 'TwoStarVolunteer',
  'ThreeStarVolunteer' = 'ThreeStarVolunteer',
  'FourStarVolunteer' = 'FourStarVolunteer',
  'FiveStarVolunteer' = 'FiveStarVolunteer'
}

export enum WorkflowOfProjectByInternal {
  'ProjectApproval' = 'ProjectApproval',
  'Closed' = 'Closed',
  'Termination' = 'Termination'
}

export enum NotifyTemplateType {
  'ProjectAssessmentPlan' = 'ProjectAssessmentPlan',
  'OrganizationInvestigation' = 'OrganizationInvestigation'
}

export enum OrganizationStatus {
  'Pending' = 'Pending',
  'Approved' = 'Approved',
  'Rejected' = 'Rejected'
}

export enum PermissionDefinitionScope {
  'Foundation' = 'Foundation',
  'Partner' = 'Partner'
}

export enum DataPermissionScopeType {
  'All' = 'All',
  'Mine' = 'Mine',
  'MyDepartment' = 'MyDepartment',
  'MyDepartmentAndSubs' = 'MyDepartmentAndSubs',
  'Specified' = 'Specified'
}

export enum ServiceStatus {
  'Saved' = 'Saved',
  'Published' = 'Published'
}

export enum ServiceQrType {
  'Detail' = 'Detail',
  'CheckIn' = 'CheckIn'
}

export enum StarRatingStatus {
  'Saved' = 'Saved',
  'Published' = 'Published'
}

export enum TrainMethod {
  'Offline' = 'Offline',
  'Online' = 'Online'
}

export enum TrainStatus {
  'Saved' = 'Saved',
  'SendBacked' = 'SendBacked',
  'Submited' = 'Submited',
  'Approved' = 'Approved',
  'Published' = 'Published',
  'Finished' = 'Finished',
  'Deleted' = 'Deleted'
}

export enum StarRatingApplyStatus {
  'PendingWritten' = 'PendingWritten',
  'WrittenFailed' = 'WrittenFailed',
  'WrittenSuccess' = 'WrittenSuccess',
  'WaitAudit' = 'WaitAudit',
  'AuditReject' = 'AuditReject',
  'AuditPass' = 'AuditPass',
  'Cancel' = 'Cancel'
}

export enum StarRatingRecordStatus {
  'ExamPassed' = 'ExamPassed',
  'ExamFail' = 'ExamFail',
  'Auditing' = 'Auditing',
  'AuditPassed' = 'AuditPassed',
  'AuditFail' = 'AuditFail'
}

export enum StarRatingRecordSearchStatus {
  'ExamPassed' = 'ExamPassed',
  'ExamFail' = 'ExamFail',
  'Auditing' = 'Auditing',
  'AuditPassed' = 'AuditPassed',
  'AuditFail' = 'AuditFail'
}

export enum Sex {
  'Man' = 'Man',
  'Woman' = 'Woman'
}

export enum StudentGrade {
  'PrimarySchool1' = 'PrimarySchool1',
  'PrimarySchool2' = 'PrimarySchool2',
  'PrimarySchool3' = 'PrimarySchool3',
  'PrimarySchool4' = 'PrimarySchool4',
  'PrimarySchool5' = 'PrimarySchool5',
  'PrimarySchool6' = 'PrimarySchool6',
  'JuniorHighSchool1' = 'JuniorHighSchool1',
  'JuniorHighSchool2' = 'JuniorHighSchool2',
  'JuniorHighSchool3' = 'JuniorHighSchool3',
  'SeniorHighSchool1' = 'SeniorHighSchool1',
  'SeniorHighSchool2' = 'SeniorHighSchool2',
  'SeniorHighSchool3' = 'SeniorHighSchool3',
  'University1' = 'University1',
  'University2' = 'University2',
  'University3' = 'University3',
  'University4' = 'University4'
}

export enum StudentStatus {
  'InSchool' = 'InSchool',
  'DropOut' = 'DropOut',
  'Finish' = 'Finish',
  'TransferToAnotherSchool' = 'TransferToAnotherSchool'
}

export enum StudentAidBidStatus {
  'UnPublish' = 'UnPublish',
  'Published' = 'Published',
  'Subscript' = 'Subscript',
  'HasParing' = 'HasParing',
  'HasGranted' = 'HasGranted',
  'None' = 'None'
}

export enum StudentBidType {
  'ManyForOne' = 'ManyForOne',
  'OneToOne' = 'OneToOne'
}

export enum StudentDonorType {
  'Personal' = 'Personal',
  'Enterprise' = 'Enterprise'
}

export enum OrderPayType {
  '微信' = '微信',
  '微信扫码' = '微信扫码',
  '支付宝' = '支付宝',
  '银联' = '银联',
  '用户余额' = '用户余额',
  '线下' = '线下'
}

export enum TenantDomainType {
  'Admin' = 'Admin',
  'Portal' = 'Portal',
  'MobilePortal' = 'MobilePortal'
}

export enum TenantAccountRecordType {
  'Income' = 'Income',
  'Outcome' = 'Outcome',
  'ManageFee' = 'ManageFee',
  'ManageFeeReturn' = 'ManageFeeReturn',
  'ManageFeeAdd' = 'ManageFeeAdd'
}

export enum TenantApplyAuditStatus {
  'InReview' = 'InReview',
  'Reject' = 'Reject',
  'Pass' = 'Pass'
}

export enum TrainQrType {
  'Detail' = 'Detail',
  'CheckIn' = 'CheckIn'
}

export enum PointType {
  'ServiceRecord' = 'ServiceRecord',
  'TrainRecord' = 'TrainRecord',
  'ActivityRecord' = 'ActivityRecord',
  'HelpApply' = 'HelpApply',
  'BBS' = 'BBS',
  'Volunteer' = 'Volunteer'
}

export enum TaskType {
  'Volunteer' = 'Volunteer',
  'Office' = 'Office'
}

export enum TaskStatus {
  'Waiting' = 'Waiting',
  'SendBack' = 'SendBack',
  'Executing' = 'Executing',
  'Submit' = 'Submit',
  'Complete' = 'Complete',
  'Incomplete' = 'Incomplete'
}

export enum ServiceType {
  'PunchCard' = 'PunchCard',
  'TaskExecute' = 'TaskExecute',
  'ImportExcel' = 'ImportExcel'
}
