import { OrganizationUnitDto } from "@/api/appService";

export default {
  filters: {
    // 课程报名详情
    applyStatus(value: string) {
      if (value) {
        switch (value) {
          case "NotPay":
            return "未支付";
          case "Approved":
            return "待参与";
          case "CheckOut":
            return "完成";
          case "Canceled":
            return "已取消";
          case "Refunded":
            return "已退款";
          case "Reject":
            return "已退回";
          case "Refunding":
            return "退款中";
          case "Auditing":
            return "待审核";
          case "Payed":
            return "已支付";
          default:
            return "未知";
        }
      }
      return "未知";
    },

    // 活动类型
    activityType(value: number) {
      if (value || value === 0) {
        switch (value) {
          case 0:
            return "课程";
          case 1:
            return "普通活动";
          case 2:
            return "志愿者活动";
          case 11:
            return "课程";
          default:
            break;
        }
      }
      return "未知";
    },

    // 活动状态
    activityStatus(value: number) {
      if (value || value === 0) {
        switch (value) {
          case 0:
            return "草稿";
          case 1:
            return "待审核";
          case 2:
            return "审核退回";
          case 6:
            return "审核通过";
          case 7:
            return "已发布";
          default:
            break;
        }
      }
      return value;
    },

    // 支付类型
    payType(value: number) {
      if (value || value === 0) {
        switch (value) {
          case 0:
            return "微信";
          case 1:
            return "微信扫码";
          case 2:
            return "支付宝";
          case 3:
            return "银联";
          case 4:
            return "用户余额";
          default:
            break;
        }
      }
      return "未知";
    },

    // 订单类型
    orderType(value: number) {
      if (value || value === 0) {
        switch (value) {
          case 0:
            return "课程报名";
          default:
            break;
        }
      }
      return "未知";
    },

    // 机构类型
    ou_type(value: number) {
      if (value || value === 0) {
        switch (value) {
          case 1:
            return "教育培训类院校";
          case 2:
            return "企业";
          case 3:
            return "社会组织";
          default:
            return "未知";
        }
      }
      return "未知";
    },
    volunteerHistoryServiceType(value?: string) {
      if (!value) {
        return "";
      } else {
        switch (value) {
          case "PunchCard":
            return "活动打卡";
          case "TaskExecute":
            return "任务执行";
          case "ImportExcel":
            return "Excel导入";
          default:
            return value;
        }
      }
    },
  },
};
