import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const ouRouter: RouteConfig[] = [
  {
    path: "ou",
    component: AppContent,
    name: "admin",
    redirect: "",
    meta: {
      title: "权限管理",
    },
    children: [
      {
        path: "roles",
        name: "roles",
        component: () =>
          import(
            /* webpackChunkName: "admin-role" */ "@/views/admin/role/list.vue"
          ),
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "roles/create",
        name: "role-create",
        meta: {
          title: "新建角色",
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-role" */ "@/views/admin/role/editSinglePage.vue"
          ),
      },
      {
        path: "roles/edit/:id",
        name: "role-edit",
        meta: {
          title: "编辑角色",
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-role" */ "@/views/admin/role/editSinglePage.vue"
          ),
      },
      {
        path: "roles/grantPermission/:id",
        name: "role-grant-permissions",
        meta: {
          title: "分配权限",
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-role" */ "@/views/admin/role/grantPermissions.vue"
          ),
      },

      {
        path: "departments",
        name: "departments",
        component: () =>
          import(
            /* webpackChunkName: "admin-ou" */ "@/views/ou/department/index.vue"
          ),
        meta: {
          title: "部门管理",
        },
      },
      {
        path: "users",
        name: "usersList",
        component: () =>
          import(
            /* webpackChunkName: "admin-user" */ "@/views/ou/user/list.vue"
          ),
        meta: {
          title: "用户管理",
        },
      },
      {
        // 合作方的用户管理
        path: "ou-users",
        name: "ou-users",
        component: () =>
          import(
            /* webpackChunkName: "admin-user" */ "@/views/ou/user/ouUsers.vue"
          ),
        meta: {
          title: "用户管理",
        },
      },
      {
        path: "users/create",
        name: "usersCreate",
        component: () =>
          import(
            /* webpackChunkName: "admin-user" */ "@/views/ou/user/edit.vue"
          ),
        meta: {
          title: "新建用户",
        },
      },
      {
        path: "users/edit/:id",
        name: "usersEdit",
        component: () =>
          import(
            /* webpackChunkName: "admin-user" */ "@/views/ou/user/edit.vue"
          ),
        meta: {
          title: "修改用户",
        },
      },
      {
        path: "users/detail/:id",
        name: "usersDetail",
        component: () =>
          import(
            /* webpackChunkName: "admin-user" */ "@/views/ou/user/detail.vue"
          ),
        meta: {
          title: "用户详情",
        },
      },
      {
        path: "roleUsers/:id",
        name: "RoleUsers",
        component: () =>
          import(
            /* webpackChunkName: "admin-user" */ "@/views/ou/user/roleUsers.vue"
          ),
        meta: {
          title: "角色用户列表",
        },
      },
    ],
  },
];

export default ouRouter;
