import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const servicesRouter: RouteConfig[] = [
  {
    path: "servicesManage",
    component: AppContent,
    name: "servicesManage",
    redirect: "/servicesManage",
    meta: {
      title: "服务中心",
    },
    children: [
      {
        path: "servicesObject",
        name: "servicesObject",
        component: () => import("@/views/services/serviceObject/list.vue"),
        meta: {
          title: "服务对象管理",
        },
      },
      {
        path: "servicesObject/detail/:id",
        name: "serviceObjectDetail",
        component: () => import("@/views/services/serviceObject/detail.vue"),
        meta: {
          title: "服务对象详情",
        },
      },
      {
        path: "servicesObject/create",
        name: "serviceObjectCreate",
        component: () => import("@/views/services/serviceObject/edit.vue"),
        meta: {
          title: "新增服务对象",
        },
      },
      {
        path: "servicesObject/edit/:id",
        name: "serviceObjectEdit",
        component: () => import("@/views/services/serviceObject/edit.vue"),
        meta: {
          title: "修改服务对象",
        },
      },
      {
        path: "serviceOrganization",
        name: "serviceOrganization",
        component: () =>
          import("@/views/services/serviceOrganization/list.vue"),
        meta: {
          title: "服务机构管理",
        },
      },
      {
        path: "serviceOrganization/detail/:id",
        name: "serviceOrganizationDetail",
        component: () =>
          import("@/views/services/serviceOrganization/detail.vue"),
        meta: {
          title: "服务机构详情",
        },
      },
      {
        path: "serviceOrganization/create",
        name: "serviceOrganizationCreate",
        component: () =>
          import("@/views/services/serviceOrganization/edit.vue"),
        meta: {
          title: "新增服务机构",
        },
      },
      {
        path: "serviceOrganization/edit/:id",
        name: "serviceOrganizationEdit",
        component: () =>
          import("@/views/services/serviceOrganization/edit.vue"),
        meta: {
          title: "修改服务机构",
        },
      },
      {
        path: "serviceMaintain",
        name: "serviceMaintain",
        component: () =>
          import("@/views/services/serviceMaintain/list.vue"),
        meta: {
          title: "服务维护",
        },
      },
      {
        path: "serviceMaintain-create",
        name: "serviceMaintain-create",
        component: () =>
          import("@/views/services/serviceMaintain/edit.vue"),
        meta: {
          title: "发布新服务",
        },
      },
      {
        path: "serviceMaintain-edit/:id",
        name: "serviceMaintain-edit",
        component: () =>
          import("@/views/services/serviceMaintain/edit.vue"),
        meta: {
          title: "编辑服务",
        },
      },
      {
        path: "serviceMaintain-detail/:id",
        name: "serviceMaintain-detail",
        component: () =>
          import("@/views/services/serviceMaintain/detail.vue"),
        meta: {
          title: "服务详情",
        },
      },
      {
        path: "serviceManagement",
        name: "serviceManagement",
        component: () =>
          import("@/views/services/serviceManagement/list.vue"),
        meta: {
          title: "服务管理总结表",
        },
      },
      {
        path: "serviceManagement-create",
        name: "serviceManagement-create",
        component: () =>
          import("@/views/services/serviceManagement/edit.vue"),
        meta: {
          title: "新建服务管理总结表",
        },
      },
      {
        path: "serviceManagement-detail/:id",
        name: "serviceManagement-detail",
        component: () =>
          import("@/views/services/serviceManagement/detail.vue"),
        meta: {
          title: "服务管理总结表详情",
        },
      },
      {
        path: "serviceManagement-edit/:id",
        name: "serviceManagement-edit",
        component: () =>
          import("@/views/services/serviceManagement/edit.vue"),
        meta: {
          title: "编辑服务管理总结表",
        },
      },
      {
        path: "helpApplies",
        name: "helpApplies",
        component: () =>
          import("@/views/services/helpApplies/list.vue"),
        meta: {
          title: "服务需求管理",
        },
      },
      {
        path: "helpApplies/detail/:id",
        name: "helpAppliesDetail",
        component: () =>
          import("@/views/services/helpApplies/detail.vue"),
        meta: {
          title: "服务需求详情",
        },
      },
      {
        path: "serviceRecord",
        name: "serviceRecord",
        component: () =>
          import("@/views/services/serviceRecord/list.vue"),
        meta: {
          title: "服务记录管理",
        },
      },
      {
        path: "serviceRecord-detail/:id",
        name: "serviceRecord-detail",
        component: () =>
          import("@/views/services/serviceRecord/detail.vue"),
        meta: {
          title: "服务记录详情",
        },
      },
      {
        path: "serviceApply",
        name: "serviceApply",
        component: () =>
          import("@/views/services/serviceApply/list.vue"),
        meta: {
          title: "服务报名管理",
        },
      },
      {
        path: "serviceApply-SignUpDetail/:id",
        name: "serviceApply-SignUpDetail",
        component: () =>
          import("@/views/services/serviceApply/signUpDetail.vue"),
        meta: {
          title: "服务报名签到明细",
        },
      },
      {
        path: "serviceApply-Detail/:id",
        name: "serviceApply-Detail",
        component: () =>
          import("@/views/services/serviceApply/detail.vue"),
        meta: {
          title: "服务报名签到明细",
        },
      },
      {
        path: "servicesObjectFeedback/list/:serviceId",
        name: "serviceObjectFeedbackList",
        component: () => import("@/views/services/serviceMaintain/components/serviceObjectFeedbackList.vue"),
        meta: {
          title: "服务对象反馈列表",
        },
      },
      {
        path: "servicesObjectFeedback/detail",
        name: "serviceObjectFeedbackDetail",
        component: () => import("@/views/services/serviceMaintain/components/serviceObjectFeedbackDetail.vue"),
        meta: {
          title: "服务对象反馈详情",
        },
      },
    ],
  },
];

export default servicesRouter;
