import { ActionContext } from "vuex";
import api from "@/api";
import { TenantDto } from "@/api/appService";
import { AppState } from "@/store/module/app";

export interface TenantData {
  displayName: string;
  name: string;
  logo: string;
  squareLogo: string;
  tenantId: number;
}

let storage = JSON.parse(
  window.localStorage.getItem("tenant") ||
    window.sessionStorage.getItem("tenant") ||
    "{}"
) as TenantData;

function serialize(params: any) {
  storage = { ...storage, ...params };
  window.sessionStorage.setItem("tenant", JSON.stringify(storage));
}

export default {
  namespaced: true,
  state: () =>
    ({
      displayName: storage.displayName || "",
      name: storage.name || "",
      logo: storage.logo || "",
      squareLogo: storage.squareLogo || "",
      tenantId: storage.tenantId,
    } as TenantData),

  mutations: {
    SUBMIT(state: TenantData, payload: TenantData) {
      state.logo = payload.logo;
      state.name = payload.name;
      state.displayName = payload.displayName;
      state.squareLogo = payload.squareLogo;
      state.tenantId = payload.tenantId;
      serialize(state);
    },
    SET_TENANT(state: TenantData, payload: TenantDto) {
      state.logo = payload.logo!;
      state.squareLogo = payload.logoSquare!;
      state.name = payload.tenancyName!;
      state.displayName = payload.name!;
      state.tenantId = payload.id!;

      serialize(state);
    },
  },

  actions: {
    fetch(context: ActionContext<TenantData, any>): Promise<TenantData> {
      return new Promise((resolve) => {
        if (context.state.name) {
          resolve(context.state);
        } else {
          api.tenant.getForCurrent().then((res) => {
            const data = {
              squareLogo: res.logoSquare,
              logo: res.logo,
              name: res.name,
              displayName: res.tenancyName,
            } as TenantData;

            context.commit("SUBMIT", data);
            resolve(data);
          });
        }
      });
    },
  },

  getters: {
    displayName(state: TenantData): string {
      return state.displayName;
    },
    name(state: TenantData): string {
      return state.name;
    },
    logo(state: TenantData) {
      return state.logo;
    },
    squareLogo(state: TenantData) {
      return state.squareLogo;
    },
    id(state: TenantData): number {
      return state.tenantId;
    },
  },
};
