import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const jobRouter: RouteConfig[] = [
  {
    path: "jobMaintain",
    component: AppContent,
    name: "officeCenter",
    redirect: "",
    meta: {
      title: "办公中心",
    },
    children: [
      {
        path: "jobMaintain",
        name: "jobMaintain",
        component: () => import("@/views/jobMaintain/list.vue"),
        meta: {
          title: "招聘管理",
        },
        children: [
          {
            path: "detail/:id",
            name: "jobMaintainDetail",
            component: () => import("@/views/jobMaintain/detail.vue"),
            meta: {
              title: "招聘信息详情",
            },
          },
        ],
      },
      {
        path: "jobMaintain/create",
        name: "jobMaintainCreate",
        component: () => import("@/views/jobMaintain/edit.vue"),
        meta: {
          title: "新增招聘信息",
        },
      },
      {
        path: "jobMaintain/edit/:id",
        name: "jobMaintainEdit",
        component: () => import("@/views/jobMaintain/edit.vue"),
        meta: {
          title: "修改招聘信息",
        },
      },
    ],
  },
];

export default jobRouter;
