























import { Component, Prop, Vue } from "vue-property-decorator";
import SidebarItem from "./SidebarItem.vue";
import variables from "@/styles/_variables.scss";
import Logo from "./logo.vue";

@Component({
  name: "SideBar",
  components: {
    SidebarItem,
    Logo,
  },
})
export default class extends Vue {
  get sidebar() {
    return this.$store.getters["app/sidebar"];
  }

  get menus() {
    return this.$store.getters["permission/menus"];
  }

  get variables() {
    return variables;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }
}
