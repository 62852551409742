



































































































import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import api from "@/api";
import { AlbumDto, UserDto } from "../../api/appService";
import PagedTableViewSimple from "@/components/PagedTableViewSimple/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  components: { AbSelect, PagedTableViewSimple },
})
export default class Album extends Vue {
  @Prop({ required: false, default: false })
  showButton?: boolean;

  public show = true;
  active = false;
  netPath = "";
  defineImg = require("@/assets/images/none-cover.png");
  tabName = "first";
  pagesize = 20;
  page = 1;
  totalCount = 0;
  queryForm = {
    albumId: 0,
    skipCount: 0,
    maxResultCount: 10,
  };
  list = [
    {
      selected: false,
    },
  ];
  selectedPhotos: string[] = [];
  albumList: AlbumDto[] | undefined = [];
  uploadData = {
    albumId: undefined,
  };
  isShowUpdata = false;
  dialogVisible = false;
  dialogImageUrl = "";
  uploadHeaders = {
    contentType: "multipart/form-data;",
    Authorization: "",
  };
  form = {
    visible: false,
    submitting: false,
    data: {
      name: "",
      summary: "",
      id: 0,
    },
    rules: {
      name: [
        {
          required: true,
          message: "相册名称",
          trigger: "change",
        },
        {
          max: 100,
          message: "最多100个字符",
          trigger: "blur",
        },
      ],
    },
  };

  created() {
    this.fetchAlbumList();
    // this.fetchPhotos(1)
    this.uploadHeaders.Authorization = `Bearer ${
      (this as any).$store.getters["user/token"]
    }`;
  }

  changeTab(tab: any, event: any) {
    this.tabName = tab.name;
  }

  //  获取所有相册列表
  fetchAlbumList() {
    api.album
      .getAllAlbums({
        skipCount: 0,
        maxResultCount: 65535,
      })
      .then((response) => {
        var total = response.totalCount as number;
        if (total > 0) {
          this.albumList = response.items;
          this.queryForm.albumId = this.uploadData.albumId = this.albumList![0]
            .id as any;
          this.fetchPhotos(1);
        }
      });
  }
  // 获取照片
  fetchPhotos(page: any) {
    //根据实际请求中的页码更新分页器每页数据条数
    var sessionPagesize = this.$getActualPageSize();
    if (sessionPagesize > 0) {
      this.pagesize = sessionPagesize;
    }
    if (!page) page = this.page;
    this.queryForm.skipCount = (page - 1) * this.pagesize;
    this.queryForm.maxResultCount = this.pagesize;
    api.album.getAllPhotos(this.queryForm as any).then((response) => {
      this.list = response.items as any;
      if (this.list && this.list.length > 0) {
        this.list.forEach((item: any) => {
          Vue.set(item, "selected", false);
        });
      }
      this.totalCount = response.totalCount as any;
      //根据实际请求中的页码更新分页器页码
      var actualPage = this.$getActualPageIndex();
      if (actualPage > 0) {
        this.page = actualPage;
      }
    });
  }

  current_change(e: number) {
    //手动切换页码时清空上次请求缓存信息，确保以实际选择的页码进行请求
    this.$clearHistorySearchParams();
    this.page = e;
    this.fetchPhotos(this.page);
  }

  handleSizeChange(e: number) {
    //手动切换页码时清空上次请求缓存信息，确保以实际选择的页码进行请求
    this.$clearHistorySearchParams();
    this.pagesize = e;
    this.fetchPhotos(1);
  }

  // 创建相册
  handleCreateNewRole() {
    this.form.data.id = 0;
    this.form.data.name = "";
    this.form.data.summary = "";
    this.form.visible = true;
  }
  // 保存新建相册
  handleRoleSave() {
    (this.$refs.roleForm as any).validate((valid: any) => {
      if (valid) {
        this.form.submitting = true;
        var fn;
        if (this.form.data.id) {
          fn = api.album.update;
        } else {
          fn = api.album.create;
        }
        fn({ body: this.form.data }).then((res) => {
          this.form.submitting = false;
          this.form.visible = false;
          this.$message.success("保存成功");
          this.fetchPhotos(undefined);
          this.fetchAlbumList();
        });
      }
    });
  }
  // 删除
  handleDelete(item: AlbumDto, index: number) {
    const id = item.id;
    this.$confirm("您确认要删除此相册吗", "删除提示").then(() => {
      api.album
        .delete({
          id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.fetchPhotos(undefined);
        })
        .catch(() => {
          console.log("delete catch");
        });
    });
  }
  // 编辑
  handleEdit(item: AlbumDto, index: number) {
    const id = item.id;
    api.album
      .get({
        id,
      })
      .then((res) => {
        this.form.data = res as any;
        console.log(this.form.data);
        this.form.visible = true;
      });
  }
  // 上传照片
  handleUpData() {
    this.isShowUpdata = true;
  }
  //  上传
  handleAvatarSuccess(res: any, file: any) {
    if (res && res.result && res.result.photoPath) {
      this.selectedPhotos.push(res.result.photoPath);
    }

    /*this.activityimagePath = res.result.url as any
    this.form.data.titleImageId = res.result.attachmentId*/
  }
  beforeAvatarUpload(file: any) {
    console.log("file", file);
  }
  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
    const id = file.response.result.id;
    api.album
      .deletePhoto({
        id,
      })
      .then(() => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
      })
      .catch(() => {
        console.log("delete catch");
      });
  }
  handlePictureCardPreview(file: any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }

  // 从本地相册选择图片
  choosePhoto(item: any, index: number) {
    this.list[index].selected = !this.list[index].selected;
    const selectIndex = this.selectedPhotos.indexOf(item.photoPath);
    if (selectIndex != -1) {
      this.selectedPhotos.splice(selectIndex, 1);
    } else {
      this.selectedPhotos.push(item.photoPath);
    }
  }

  // 取消本地相册选择的图片
  unChoosePhoto(index: number) {
    this.selectedPhotos.splice(index, 1);
  }

  // 添加网络图片
  addPhoto() {
    this.selectedPhotos.push(this.netPath);
  }

  // 确定选择图片
  handleSelectedPhotos() {
    this.$emit("handleSelectedPhotos", this.selectedPhotos);
  }
}
