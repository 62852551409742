import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const bbsRouter: RouteConfig[] = [
  {
    path: "bbsManage",
    component: AppContent,
    name: "bbsManage",
    redirect: "/bbsManage",
    meta: {
      title: "论坛管理",
    },
    children: [
      {
        path: "bbsPost",
        name: "bbsPost",
        component: () => import("@/views/bbs/bbsPost/list.vue"),
        meta: {
          title: "主贴管理",
        },
      },
      {
        path: "bbsPost/detail/:id",
        name: "bbsPostDetail",
        component: () => import("@/views/bbs/bbsPost/detail.vue"),
        meta: {
          title: "主贴详情",
        },
      },
      {
        path: "bbsGroup",
        name: "bbsGroup",
        component: () => import("@/views/bbs/bbsGroup/list.vue"),
        meta: {
          title: "群组管理",
        },
      },
      {
        path: "bbsGroup/create",
        name: "bbsGroupCreate",
        component: () => import("@/views/bbs/bbsGroup/edit.vue"),
        meta: {
          title: "新增群组管理",
        },
      },
      {
        path: "bbsGroup/edit/:id",
        name: "bbsGroupEdit",
        component: () => import("@/views/bbs/bbsGroup/edit.vue"),
        meta: {
          title: "修改群组管理",
        },
      },
      {
        path: "bbsPostComment",
        name: "bbsPostComment",
        component: () => import("@/views/bbs/bbsPostComment/list.vue"),
        meta: {
          title: "评论管理",
        },
      },
      {
        path: "bbsPostComment/detail/:id",
        name: "bbsPostCommentDetail",
        component: () => import("@/views/bbs/bbsPostComment/detail.vue"),
        meta: {
          title: "评论详情",
        },
      },
      {
        path: "bbsGroup",
        name: "bbsGroup",
        component: () => import("@/views/bbs/bbsGroup/list.vue"),
        meta: {
          title: "群组管理",
        },
      },
      {
        path: "bbsGroup/create",
        name: "bbsGroupCreate",
        component: () => import("@/views/bbs/bbsGroup/edit.vue"),
        meta: {
          title: "新增群组管理",
        },
      },
      {
        path: "bbsGroup/edit/:id",
        name: "bbsGroupEdit",
        component: () => import("@/views/bbs/bbsGroup/edit.vue"),
        meta: {
          title: "修改群组管理",
        },
      },
      {
        path: "bbsGroupUser/:id",
        name: "bbsGroupUser",
        component: () => import("@/views/bbs/bbsGroupUser/list.vue"),
        meta: {
          title: "群组成员管理",
        },
      },
    ],
  },
];

export default bbsRouter;
