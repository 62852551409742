import request from "@/utils/request";

import {
  serviceOptions,
  AccountService,
  UserService,
  OrganizationUnitService,
  // AuditLogService,
  CmsContentService,
  CmsCategoryService,
  CmsAnchorService,
  SwiperService,
  CmsFriendLinkService,
  SessionService,
  RoleService,
  AuditFlowService,
  AuditNodePreDefineService,
  DepartmentService,
  AttachmentService,
  PermissionDefinitionService,
  NotifyTemplateService,
  DataDictionaryService,
  PartnerInviteService,
  EnumService,
  AlbumService,
  CapitalIncomeRecordFromOtherService,
  CapitalIncomeRecordFromDonationService,
  CapitalExpenseRecordAppserviceService,
  InternalMonthlyPlanService,
  InternalMonthlySummarizeService,
  InternalPersonalYearEndSummaryService,
  ExternalProjectIntentionService,
  ExternalMonthlyPlanService,
  ExternalMonthlySummarizeService,
  ProjectInviteService,
  ProjectService,
  ProjectSeriesService,
  ExternalDueDiligenceRecordService,
  ExternalProjectProcessMonitoringService,
  ExternalProjectProgressReportService,
  ExternalProjectSummaryReportService,
  ExternalProjectService,
  ExternalProjectCloseReportService,
  NotifyRecordService,
  ProvinceService,
  CityService,
  AreaService,
  TownService,
  CustomFormService,
  InternalProjectSummaryReportsService,
  InternalProjectService,
  PartnerCapitalApplyService,
  BeneficiaryService,
  ProjectBeneficiaryMappingService,
  BeneficiaryServiceRecordServiceService,
  BankReconciliationService,
  InternalProjectPhaseService,
  ExternalProjectPhaseService,
  InternalProjectReportService,
  ExternalProjectReportService,
  ExternalProjectChangeApplyService,
  FundService,
  FundApplyService,
  MessageService,
  FundModifyApplyService,
  FundUserService,
  AuditFlowProxyService,
  FundProjectService,
  FundProjectApplyService,
  FundProjectYearSummaryService,
  FundProjectModifyApplyService,
  FundProjectProgressService,
  FundDonationOrderService,
  FundNonCashDonationService,
  FundAppropriateApplyService,
  FundProjectDonationRecordService,
  FundAccountService,
  FundAppropriateRecordService,
  FundIncomeRecordService,
  FundManageFeeRecordService,
  FundDonationMonthlyConfigService,
  ActivityService,
  ActivityApplyService,
  ActivitySignInRecordService,
  VolunteerService,
  VolunteerServiceHistoryService,
  UserTaskService,
  JobService,
  FeedbackService,
  FeedbackReplyService,
  ElectronicInvoiceService,
  ApplicationPaymentService,
  DonatedAmountConfigService,
  DashbordService,
  TenantBankAccountService,
  MerchantService,
  MerchantBankAccountService,
  CycleDonateConfigService,
  ScopeDataSourceService,
  TokenAuthService,
  BankTypeService,
  BankNameService,
  AuditFlowDefinitionService,
  TenantService,
  FundProjectAccountService,
  ExamQuestionService,
  LecturerService,
  TrainManagementService,
  TrainService,
  TrainApplyService,
  TrainRecordService,
  ServiceObjectService,
  ServiceOrganizationService,
  BbsPostService,
  BbsGroupService,
  BbsPostCommentService,
  BbsGroupUserService,
  ActivityRecordService,
  ActivityManagementService,
  StarRatingService,
  StarRatingApplyService,
  StarRatingManagementService,
  StarRatingRecordService,
  HelpAppliesService,
  ServiceMaintainService,
  ServiceManagementService,
  ServiceRecordService,
  ServiceApplyService,
  VolunteerDashbordService,
  ProductConsultationsService,
  SchoolService,
  ContactsService,
  StudentService,
  StudentPairingRecordService,
  StudentDonorService,
  StudentAidReportService,
  StudentBidOrderService,
  StudentAidBidService,
  StudentFeedbackRecordService,
  SignupInfoService,
  SlashScreenService,
  ServiceObjectFeedbackService, DonateDetailService, ExpenditureDetailService, MusicMachineDonateDetailService,
} from "./appService";

import {Attachment} from "./attachment";

serviceOptions.axios = request;

//const basrUrl = process.env.VUE_APP_BASE_API;

export default {
  tokenAuth: TokenAuthService,
  auditFlow: AuditFlowProxyService,
  auditNodePreDefine: AuditNodePreDefineService,
  account: AccountService,
  album: AlbumService,
  cmsContent: CmsContentService,
  cmsCategory: CmsCategoryService,
  cmsAnchor: CmsAnchorService,
  cmsFriendLink: CmsFriendLinkService,
  swiper: SwiperService,
  slashScreen:SlashScreenService,
  donateDetail:DonateDetailService,
  musicMachineDonateDetail:MusicMachineDonateDetailService,
  expenditureDetail:ExpenditureDetailService,
  department: DepartmentService,
  organizationUnit: OrganizationUnitService,
  role: RoleService,
  session: SessionService,
  user: UserService,
  attachment: Attachment,
  attachmentService: AttachmentService,
  permissionDefinition: PermissionDefinitionService,

  partnerInvite: PartnerInviteService,
  notifyTemplate: NotifyTemplateService,
  dataDictionary: DataDictionaryService,
  capitalIncomeRecordFromOther: CapitalIncomeRecordFromOtherService,
  capitalIncomeRecordFromDonation: CapitalIncomeRecordFromDonationService,
  capitalExpenseRecord: CapitalExpenseRecordAppserviceService,
  internalMonthlyPlan: InternalMonthlyPlanService,
  internalMonthlySummarize: InternalMonthlySummarizeService,
  internalPersonalYearEndSummary: InternalPersonalYearEndSummaryService,
  enumService: EnumService,
  externalProjectIntention: ExternalProjectIntentionService,
  externalMonthlyPlanService: ExternalMonthlyPlanService,
  externalMonthlySummarize: ExternalMonthlySummarizeService,
  projectInvite: ProjectInviteService,
  projectSeries: ProjectSeriesService,
  project: ProjectService,
  externalDueDiligenceRecord: ExternalDueDiligenceRecordService,
  externalProjectProcessMonitoringService:
  ExternalProjectProcessMonitoringService,
  externalProjectProcessReport: ExternalProjectProgressReportService,
  externalProjectSummaryReport: ExternalProjectSummaryReportService,
  notifyRecord: NotifyRecordService,
  //
  province: ProvinceService,
  city: CityService,
  area: AreaService,
  town: TownService,
  externalProject: ExternalProjectService,
  externalProjectCloseReport: ExternalProjectCloseReportService,

  customFormService: CustomFormService,
  internalProjectSummaryReports: InternalProjectSummaryReportsService,
  internalProject: InternalProjectService,
  partnerCapitalApply: PartnerCapitalApplyService,
  beneficiary: BeneficiaryService,
  projectBeneficiaryMapping: ProjectBeneficiaryMappingService,
  beneficiaryServiceRecord: BeneficiaryServiceRecordServiceService,
  bankReconciliation: BankReconciliationService,
  guid: "00000000-0000-0000-0000-000000000000",

  internalProjectPhase: InternalProjectPhaseService,
  externalProjectPhase: ExternalProjectPhaseService,
  internalProjectReport: InternalProjectReportService,
  externalProjectReport: ExternalProjectReportService,
  externalProjectChangeApply: ExternalProjectChangeApplyService,

  fund: FundService,
  fundApply: FundApplyService,
  message: MessageService,
  fundModifyApply: FundModifyApplyService,
  fundUser: FundUserService,
  fundAccount: FundAccountService,
  fundAppropriateRecord: FundAppropriateRecordService,
  fundIncomeRecord: FundIncomeRecordService,

  fundProject: FundProjectService,
  fundProjectApply: FundProjectApplyService,
  fundProjectModifyApply: FundProjectModifyApplyService,
  fundProjectYearSummaryService: FundProjectYearSummaryService,
  fundProjectProgress: FundProjectProgressService,
  fundDonationOrder: FundDonationOrderService,
  fundProjectDonationRecord: FundProjectDonationRecordService,
  fundNonCashDonation: FundNonCashDonationService,
  fundAppropriateApply: FundAppropriateApplyService,
  fundManageFeeRecord: FundManageFeeRecordService,
  fundDonationMonthly: FundDonationMonthlyConfigService,
  cycleDonateConfig: CycleDonateConfigService,

  //activity
  activity: ActivityService,
  activityApply: ActivityApplyService,
  activitySignInRecord: ActivitySignInRecordService,
  activityRecord: ActivityRecordService,
  activityManagement: ActivityManagementService,

  //volunteer
  volunteer: VolunteerService,
  volunteerServiceHistory: VolunteerServiceHistoryService,
  volunteerDashboard: VolunteerDashbordService,

  userTask: UserTaskService,
  job: JobService,
  feedback: FeedbackService,
  feedbackReply: FeedbackReplyService,
  electronicInvoice: ElectronicInvoiceService,
  applicationPayment: ApplicationPaymentService,
  donatedAmountConfig: DonatedAmountConfigService,

  dashboard: DashbordService,
  tenantBankAccount: TenantBankAccountService,
  merchant: MerchantService,
  merchantBankAccount: MerchantBankAccountService,
  tenant: TenantService,
  dataPermissionScopeProvider: ScopeDataSourceService,
  bankType: BankTypeService,
  bankName: BankNameService,
  auditFlowDefinition: AuditFlowDefinitionService,
  auditFlowService: AuditFlowService,
  fundProjectAccount: FundProjectAccountService,
  examQuestion: ExamQuestionService,
  lecturer: LecturerService,
  trainManagement: TrainManagementService,
  train: TrainService,
  trainApply: TrainApplyService,
  trainRecord: TrainRecordService,
  serviceObject: ServiceObjectService,
  serviceOrganization: ServiceOrganizationService,
  helpApplies: HelpAppliesService,
  bbsPost: BbsPostService,
  bbsGroup: BbsGroupService,
  bbsPostComment: BbsPostCommentService,
  bbsGroupUser: BbsGroupUserService,

  starRating: StarRatingService,
  starRatingApply: StarRatingApplyService,
  starRatingManagement: StarRatingManagementService,
  starRatingRecord: StarRatingRecordService,
  serviceMaintainService: ServiceMaintainService,
  serviceManagement: ServiceManagementService,
  serviceRecord: ServiceRecordService,
  serviceApply: ServiceApplyService,
  productConsultations: ProductConsultationsService,

  //StudentAid
  school: SchoolService,
  student: StudentService,
  pairingRecord: StudentPairingRecordService,
  donor: StudentDonorService,
  studentAidReport: StudentAidReportService,
  studentBidOrder: StudentBidOrderService,
  studentAidBid: StudentAidBidService,
  studentFeedbackRecord: StudentFeedbackRecordService,
  signupInfo: SignupInfoService,
  serviceObjectFeedback:ServiceObjectFeedbackService,
};
