import Vue from "vue";
import Router, { Route, RouteConfig } from "vue-router";
import Layout from "@/layout/index.vue";
import Login from "@/views/account/login.vue";
import AlbumIframe from "@/components/Album/index.vue";
import Http404 from "@/views/404.vue";
import store from "@/store/index";

import commonRouter from "@/router/modules/common";
import activityRouter from "@/router/modules/activity";
import adminRouter from "@/router/modules/admin";
import cmsRouter from "@/router/modules/cms";
import auditRouter from "@/router/modules/audit";
import donateRouter from "@/router/modules/donate";
import feedbackRouter from "@/router/modules/feedback";
import financeRouter from "@/router/modules/finance";
import financeNewRouter from "@/router/modules/financeNew";
import formRouter from "@/router/modules/form";
import fundRouter from "@/router/modules/fund";
import fundProjectRouter from "@/router/modules/fundProject";
import jobRouter from "@/router/modules/job";
import notifyRouter from "@/router/modules/notify";
import officeRouter from "@/router/modules/office";
import organizationRouter from "@/router/modules/organization";
import organizationApplyRouter from "@/router/modules/organizationApply";
import ouRouter from "@/router/modules/ou";
import partnerInviteRouter from "@/router/modules/partnerInvite";
import projectRouter from "@/router/modules/project";
import syncDataRouter from "@/router/modules/syncData";
import taskRouter from "@/router/modules/task";
import volunteerRouter from "@/router/modules/volunteer";
import examRouter from "@/router/modules/exam";
import lecturerRouter from "@/router/modules/lecturer";
import trainManagementRouter from "@/router/modules/trainManagement";
import services from "@/router/modules/services";
import bbs from "@/router/modules/bbs";
import starLevelEvaluateRouter from "@/router/modules/starLevelEvaluate";
import agileBoostRouter from "@/router/modules/productConsultation";
import studentAidRouter from "@/router/modules/studentAid";
import signUp from "@/router/modules/signUp";

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/404",
    component: Http404,
    meta: { hidden: true, auth: false },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "登录",
      hidden: true,
      auth: false,
    },
  },
  {
    path: "/albumIframe",
    name: "albumIframe",
    component: AlbumIframe,
    meta: {
      title: "相册管理",
      hidden: true,
      auth: false,
    },
  },
  {
    path: "/generate/viewList",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/generateView/viewList.vue"),
    meta: {
      title: "列表页面生成器",
    },
  },
  {
    path: "/",
    component: Layout,
    redirect: "/welcome",
    meta: {
      title: "Welcome",
    },
    children: [
      ...commonRouter,
      ...activityRouter,
      ...adminRouter,
      ...cmsRouter,
      ...auditRouter,
      ...donateRouter,
      ...feedbackRouter,
      ...financeRouter,
      ...financeNewRouter,
      ...formRouter,
      ...fundRouter,
      ...fundProjectRouter,
      ...jobRouter,
      ...notifyRouter,
      ...officeRouter,
      ...organizationRouter,
      ...organizationApplyRouter,
      ...ouRouter,
      ...partnerInviteRouter,
      ...projectRouter,
      ...syncDataRouter,
      ...taskRouter,
      ...volunteerRouter,
      ...examRouter,
      ...lecturerRouter,
      ...trainManagementRouter,
      ...services,
      ...bbs,
      ...starLevelEvaluateRouter,
      ...agileBoostRouter,
      ...studentAidRouter,
      ...signUp
    ],
  },
];

// 动态加载菜单
export const asyncRouter = [
  {
    path: "*",
    redirect: "/404",
    meta: { hidden: true },
  },
];

const router = new Router({
  mode: "history", // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,

  routes: [...constantRoutes, ...asyncRouter],
});

router.beforeEach((to: Route, from: Route, next: any) => {
  const $bus = (Vue.prototype.$bus)
  if($bus){
    console.log('router to next, $bus.off data-updated,&-created,&-deleted,&-action-completed')
    $bus.$off('data-updated')
    $bus.$off('data-created')
    $bus.$off('data-deleted')
    $bus.$off('api-action-completed')
  }


  const token = store.getters["user/token"];
  if (token) {
    next();
  } else {
    if ((to.meta as any).auth !== false) {
      next({ name: "login", params: { returnUrl: to.fullPath } });
    } else {
      next();
    }
  }
});

export default router;
