import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const agileBoostRouter: RouteConfig[] = [
  {
    path: "agileBoost",
    component: AppContent,
    name: "agileBoost",
    redirect: "/agileBoost",
    meta: {
      title: "产品咨询",
    },
    children: [
      {
        path: "productConsultations",
        name: "productConsultations",
        component: () => import("@/views/productConsultations/index.vue"),
        meta: {
          title: "产品咨询管理",
        },
        children: [
          {
            path: "detail/:id",
            name: "productConsultationDetail",
            component: () => import("@/views/productConsultations/detail.vue"),
            meta: {
              title: "产品咨询详情",
            },
          },
        ],
      },
    ],
  },
];

export default agileBoostRouter;
