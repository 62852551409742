import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const officeRouter: RouteConfig[] = [
  {
    path: "office",
    component: AppContent,
    name: "office",
    redirect: "",
    meta: {
      title: "办公中心",
    },
    children: [
      {
        path: "applicationPayment",
        name: "applicationPayment",
        component: () =>
          import("@/views/officeCenter/applicationPayment/list.vue"),
        meta: {
          title: "用款申请",
        },
      },
      {
        path: "applicationPayment/detail/:id",
        name: "officeApplicationPaymentDetail",
        component: () =>
          import("@/views/officeCenter/applicationPayment/detail.vue"),
        meta: {
          title: "查看用款申请",
        },
      },
      {
        path: "applicationPayment/create",
        name: "applicationPaymentCreate",
        component: () =>
          import("@/views/officeCenter/applicationPayment/edit.vue"),
        meta: {
          title: "新建用款申请",
        },
      },
      {
        path: "applicationPayment/edit/:id",
        name: "applicationPaymentEdit",
        component: () =>
          import("@/views/officeCenter/applicationPayment/edit.vue"),
        meta: {
          title: "编辑用款申请",
        },
      },
      {
        path: "merchant",
        name: "merchant",
        component: () => import("@/views/officeCenter/merchant/list.vue"),
        meta: {
          title: "收款账户管理",
        },
      },
      {
        path: "merchantBankAccount",
        name: "merchantBankAccount",
        component: () =>
          import("@/views/officeCenter/merchantBankAccount/list.vue"),
        meta: {
          title: "账户管理",
        },
      },
    ],
  },
];

export default officeRouter;
