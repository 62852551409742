















import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { AppMain, Navbar, Sidebar } from "./components";
import ResizeMixin from "./mixin/resize";
import { DeviceType } from "@/store/module/app";

@Component({
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    Sidebar,
  },
})
export default class extends mixins(ResizeMixin) {
  get classObj(): any {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile,
    };
  }

  mounted() {
    this.autoRefreshToken();
  }

  autoRefreshToken() {
    const checkAndRefresh = () => {
      console.log("checkAndRefresh");
      const token = this.$store.getters["user/token"];
      if (!token) {
        return;
      }

      const expireTime = this.$store.getters["user/tokenExpireTime"] as Date;
      const now = new Date();
      // timeSpan: 2 minutes
      const timeSpan = 2 * 60 * 1000;
      if (expireTime.getTime() - now.getTime() < timeSpan) {
        this.$store.dispatch("user/refreshToken");
      }
    };

    setInterval(() => {
      checkAndRefresh();
    }, 5 * 1000);
    checkAndRefresh();
  }

  private handleClickOutside() {
    (this as any).$store.commit("app/CLOSE_SIDEBAR", false);
  }
}
