import { RouteConfig } from "vue-router";
import { ActionContext } from "vuex";
import {
  PermissionDefinitionDto,
  PermissionDefinitionScope,
} from "@/api/appService";
import { createNgTree, INgNode } from "@/utils/tree";

export interface IPermissionItem {
  displayOrder: number;
  name: string;
  displayName: string;
  children?: IPermissionItem[];
  icon?: string;
  url?: string;
  id: number;
}

export interface PermissionState {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
  menus: IPermissionItem[];
}

export interface GenerateMenuInput {
  permissions: string[];
  roles: string[];
  type: string;
  permissionDefinitions: PermissionDefinitionDto[];
}

function filterPermission(
  permissions: string[],
  permissionDefinitions: INgNode<PermissionDefinitionDto>[],
  scope: PermissionDefinitionScope
) {
  const res: IPermissionItem[] = [];
  permissionDefinitions
    .filter(
      (s: INgNode<PermissionDefinitionDto>) =>
        !s.data.isHiddenForMenu &&
        (s!.data.multiTenancySides ?? "").indexOf("Tenant") >= 0 &&
        ( (s.data.permissionDefinitionScopes === null || s.data.permissionDefinitionScopes!.length === 0)
          ||  (s.data.permissionDefinitionScopes ?? []).some((s) => s === scope))

    )
    .forEach((definition) => {
      let permissionItem: IPermissionItem | undefined;
      if (permissions.some((name) => name === definition.data.name)) {
        permissionItem = {
          id: definition.id!,
          name: definition.data.name!,
          displayName: definition.data.displayName!,
          displayOrder: 0,
          icon: definition.data.icon,
          children: [],
          url: definition.data.url,
        };
        const children = definition.children;
        if (children?.length) {
          permissionItem.children = filterPermission(
            permissions,
            children,
            scope
          );
        }
      }

      if (permissionItem) {
        res.push(permissionItem);
      }
    });

  return res;
}

let storage = JSON.parse(
  window.localStorage.getItem("permission") ||
    window.sessionStorage.getItem("permission") ||
    "{}"
) as PermissionState;

function serialize(params: any) {
  storage = { ...storage, ...params };
  window.sessionStorage.setItem("permission", JSON.stringify(storage));
}

export default {
  namespaced: true,
  state: () =>
    ({
      routes: storage.routes || [],
      dynamicRoutes: storage.dynamicRoutes || [],
      menus: storage.menus || [],
    } as PermissionState),
  mutations: {
    SET_ROUTES(state: PermissionState, routes: RouteConfig[]) {
      state.routes = routes;
      serialize(state);
    },
    SET_MENUS(state: PermissionState, menus: IPermissionItem[]) {
      state.menus = menus;
      serialize(state);
    },
  },
  actions: {
    generateMenu(
      context: ActionContext<PermissionState, any>,
      input: GenerateMenuInput
    ): Promise<void> {
      return new Promise<void>((resolve) => {
        if (!input.permissions || !input.permissions.length) {
          context.commit("SET_MENUS", []);
          resolve();
          return;
        }

        input.permissionDefinitions = input.permissionDefinitions.sort(
          (a, b) => a.displayOrder! - b.displayOrder!
        );
        const permissionTree = createNgTree<PermissionDefinitionDto>(
          input.permissionDefinitions,
          "parentId",
          "id",
          null,
          "children",
          "",
          false,
          "id"
        );

        let permissionScope: PermissionDefinitionScope;

        switch (input.type) {
          case "Partner":
            permissionScope = PermissionDefinitionScope.Partner;
            break;
          case "Foundation":
            permissionScope = PermissionDefinitionScope.Foundation;
            break;
          default:
            permissionScope = PermissionDefinitionScope.Foundation;
            break;
        }

        const accessedMenus = filterPermission(
          input.permissions!,
          permissionTree,
          permissionScope
        );
        context.commit("SET_MENUS", accessedMenus);

        resolve();
      });
    },
  },
  getters: {
    menus(state: PermissionState): IPermissionItem[] {
      return state.menus;
    },
  },
};
