import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const formRouter: RouteConfig[] = [
  {
    path: "form",
    component: AppContent,
    name: "customForm",
    redirect: "",
    meta: {
      title: "表单设计",
    },
    children: [
      {
        path: "index",
        name: "customFormIndex",
        component: () =>
          import(/* webpackChunkName: "form" */ "@/views/customForm/index.vue"),
        meta: {
          title: "表单设计",
        },
      },
      {
        path: "design",
        name: "customFormDesign",
        component: () =>
          import(/* webpackChunkName: "form" */ "@/views/customForm/index.vue"),
        meta: {
          title: "表单设计",
        },
      },
      {
        path: "usage/:id",
        name: "customFormUsage",
        component: () =>
          import(
            /* webpackChunkName: "form" */ "@/views/project/externalProject/customDemo/index.vue"
          ),
        meta: {
          title: "使用示例",
        },
      },
      {
        path: "dataDetail",
        name: "customFormDataDetail",
        component: () =>
          import(
            /* webpackChunkName: "form" */ "@/views/customForm/standardDetail.vue"
          ),
        meta: {
          title: "明细",
        },
      },
    ],
  },
];

export default formRouter;
