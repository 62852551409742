import axios from "axios";
import { Message } from "element-ui";

import store from "@/store";

// auth.userManager.events.addAccessTokenExpiring(function () {
//   console.log("token expiring...");
//   Message.info({ message: "token expiring...", duration: 2000 });
//   setTimeout(() => {
//     auth.userManager.signinRedirect();
//   }, 1000);
// });
//
// auth.userManager.events.addAccessTokenExpired(function () {
//   console.log("Token Expired...");
//   Message.info({ message: "登录信息已过期,正在跳转登录", duration: 2000 });
//   setTimeout(() => {
//     auth.userManager.signinRedirect();
//   }, 1000);
// });

const tenantId_Key =
  "http://www.aspnetboilerplate.com/identity/claims/tenantId";

const refreshing = false;

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 25000,
});

// Request interceptors
service.interceptors.request.use(
  (config) => {
    const ouId = store.getters["user/ouId"];
    const token = store.getters["user/token"];
    if (ouId) {
      config.headers["Abp-OrganizationUnitId"] = ouId;
    }
    if(process.env.VUE_APP_TENANT_ID){
      config.headers["Abp-TenantId"] = `${process.env.VUE_APP_TENANT_ID}`;
    }
    console.log(`${process.env.NODE_ENV},process.env.VUE_APP_TENANT_ID:${process.env.VUE_APP_TENANT_ID}`)
    if (token) {
      config.headers["Authorization"] = `Bearer ${store.getters["user/token"]}`;
    }

    config.headers["Content-Type"] = "application/json";
    config.headers["Endpoint"] = "Management";
    config.headers[".AspNetCore.Culture"] = "c=zh-Hans|uic=zh-Hans";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    if (isAbpResponse(response)) {
      return doAbpResponse(response).then(
        (abpRes) => {
          return abpRes;
        },
        (error) => {
          Message({
            message: error,
            type: "error",
            duration: 5 * 1000,
          });
          return error;
        }
      );
    } else {
      return response;
    }
  },
  (err) => {
    // var axiosConfig = err.response.config;
    // if (err.response.status === 401) {
    //   console.log("axios error status is 401");
    //   // if already refreshing don't refresh
    //   if (!refreshing) {
    //     refreshing = true;
    //     console.log("to refresh the token");
    //     return auth.signinSilent().then(user => {
    //       console.log(user);
    //       if (user) {
    //         axios.defaults.headers.common["Authorization"] = `Bearer ${user.access_token}`;
    //         axiosConfig.headers["Authorization"] = `Bearer ${user.access_token}`;
    //       }
    //       refreshing = false;
    //       // retry the http request
    //       return axios(axiosConfig);
    //     }, (error: any) => {
    //       console.log(error);
    //       refreshing = false;
    //     });
    //   }
    // }
    console.log("%chttp response error", "color:red;");
    console.log(err);
    if (isAbpResponse(err.response)) {
      // if (error.response.data.unAuthorizedRequest === true) {
      //   store.dispatch("user/logout").then(() => {
      //     return Promise.reject(error.response.data.error)
      //   });
      // }
      // if (err.response.data.unAuthorizedRequest) {
      //   auth.logout();
      //   return;
      // }
      if (
        err.response.data.error.validationErrors &&
        err.response.data.error.validationErrors.length > 0
      ) {
        const message = [];
        message.push(`<h4>请求参数未能通过验证</h4>`);
        message.push('<ul style="padding-left:20px">');
        err.response.data.error.validationErrors.forEach((errItem: any) =>
          message.push(`<li style="line-height: 16px;">${errItem.message}</li>`)
        );
        message.push("</ul>");
        Message({
          message: message.join("\n"),
          type: "error",
          dangerouslyUseHTMLString: true,
          duration: 5 * 1000,
        });
      } else {
        Message({
          message:
            err.response.data.error.details || err.response.data.error.message,
          type: "error",
          duration: 5 * 1000,
        });
      }

      return Promise.reject(err.response.data.error);
    } else {
      Message({
        message: err.message,
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(err);
    }
  }
);

function isAbpResponse(response: any) {
  return response && response.data && response.data.__abp;
}

function doAbpResponse(response: any) {
  return new Promise((resolve, reject) => {
    const $eventBus = (window as any).$eventBus;
    if (response.data.success === true) {
      const _response = response;
      _response.data = response.data.result;
      resolve(_response);
      if (/(Update|Delete|Create)$/.test(response.config.url)) {
        const result =
          /(Update|Delete|Create)$/.exec(response.config.url) || [];
        const action = result[0].toLowerCase();
        if ($eventBus) {
          const eventName = `data-${action}d`;
          $eventBus.$emit(eventName, _response.data);
          console.log(`window.$eventBus $emit:${eventName}`);
        }
      } else if (/(GetAll|Get)/.test(response.config.url) === false) {
        if ($eventBus) {
          const eventName = `api-action-completed`;
          $eventBus.$emit(eventName, _response.data);
          console.log(`window.$eventBus $emit:${eventName}`);
        }
      }
    } else {
      //todo:处理ABP错误
      reject(response.data.error.message);
    }
  });
}

export default service;
