




















import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Model,
} from "vue-property-decorator";
import api from "@/api";

@Component({ name: "AbSelect" })
export default class AbSelect extends Vue {
  @Model("change", { required: true, default: "" }) readonly value!:
    | number
    | string;

  @Prop({ required: false, default: false }) filterable!: boolean;

  // 页面上显示的值
  @Prop({ required: true }) itemValue!: string;

  // 传给后台的值再数据中的属性
  @Prop({ required: true }) itemKey!: string;

  @Prop({ required: false }) name!: string | number;

  @Prop({ required: false }) placeholder!: string;

  // 数据数组
  @Prop({ required: true }) list!: any[];

  @Prop({ required: false }) itemImage!: string | undefined;

  @Prop({ required: false }) itemTime!: string | undefined;

  @Prop({ required: false, default: false }) disabled!: boolean;

  @Prop({ required: false }) color!: string | undefined;
  @Prop({ required: false, default: true }) clearable!: boolean;
  @Prop({ required: false, default: false }) loading!: boolean;

  private val: number | string = "";

  created() {
    if (!this.loading) {
      this.val = this.value;
    }
  }
  @Watch("value", { deep: true })
  getValue(newVal: string | number) {
    this.val = newVal;
  }

  @Watch("val")
  onValueChange(newVal: string | number) {
    this.$emit("change", newVal);
  }
}
