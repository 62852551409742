import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const commonRouter: RouteConfig[] = [
  {
    path: "welcome",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/welcome.vue"),
    meta: {
      title: "Welcome",
    },
  },
  {
    path: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"),
    meta: {
      title: "Dashboard",
    },
  },
  {
    path: "dashboard/volunteerDashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/volunteerDashboard.vue"),
    meta: {
      title: "Dashboard",
    },
  },

  {
    path: "audit",
    component: AppContent,
    redirect: "",
    meta: {
      title: "我的待审核列表",
    },
    children: [
      {
        path: "myAudit",
        name: "myAudit",
        component: () =>
          import(/* webpackChunkName: "audit" */ "@/views/myAudit/index.vue"),
        meta: {
          title: "我的待审核列表",
        },
      },
    ],
  },
  {
    path: "/import/result",
    name: "importResult",
    component: () => import("@/components/UploadTemplate/result.vue"),
    meta: {
      title: "模板导入结果",
    },
  },
];

export default commonRouter;
