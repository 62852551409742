import router from "./router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { Route } from "vue-router";
import { getOu } from "./utils/cookies";

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to: Route, _: Route, next: any) => {
  // Start progress bar
  // NProgress.start()
  console.log("before_route");
  const ou = getOu();
  next();
  NProgress.done();
});

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done();

  // set page title
  if ((to.meta as any).title) {
    document.title = (to.meta as any).title;
  }
});
