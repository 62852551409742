import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const adminRouter: RouteConfig[] = [
  {
    path: "admin",
    component: AppContent,
    name: "admin",
    redirect: "",
    meta: {
      title: "系统设置",
    },
    children: [
      {
        path: "dataDictionaries",
        name: "dataDictionaries",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@/views/admin/dataDictionaries/list.vue"
          ),
        meta: {
          title: "数据字典",
        },
      },
      {
        path: "customFormSettings",
        name: "customFormSettings",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@/views/customForm/settingList.vue"
            ),
        meta: {
          title: "自定义表单设置",
        },
      },
    ],
  },
];

export default adminRouter;
