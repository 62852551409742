import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const studentAidRouter: RouteConfig[] = [
  {
    path: "studentAid",
    component: AppContent,
    name: "studentAid",
    redirect: "",
    meta: {
      title: "助学"
    },
    children: [
      {
        path: "school",
        name: "school",
        component: () =>
          import( /* webpackChunkName: "studentAid" */ "@/views/studentAid/school/list.vue" ),
        meta: {
          title: "学校管理"
        }
      },
      {
        path: "school/contacts/:schoolId",
        name: "schoolContact",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/school/contact/list.vue"),
        meta: {
          title: "学校联系人管理"
        }
      },
      {
        path: "school/contacts/:schoolId/add/:schoolId",
        name: "contactAdd",
        component: () => import("@/views/studentAid/school/contact/add.vue"),
        meta: {
          title: "新增学校联系人"
        }
      },
      {
        path: "school/contacts/:schoolId/edit/:contactId",
        name: "contactEdit",
        component: () => import("@/views/studentAid/school/contact/add.vue"),
        meta: {
          title: "编辑学校联系人"
        }
      },
      {
        path: "school/contacts/:schoolId/detail/:contactId",
        name: "contactDetail",
        component: () =>
          import("@/views/studentAid/school/contact/detail.vue"),
        meta: {
          title: "学校联系人详情"
        }
      },
      {
        path: "school/contacts/:schoolId/feedback/:userExtendId",
        name: "studentFeedbackRecord",
        component: () =>
          import("@/views/studentAid/school/contact/feedbackRecord/list.vue"),
        meta: {
          title: "学生反馈记录"
        }
      },
      {
        path: "school/contacts/feedback/:schoolId/:studentId",
        name: "studentFeedbackList",
        component: () =>
          import("@/views/studentAid/school/contact/feedbackRecord/list.vue"),
        meta: {
          title: "学生反馈记录"
        }
      },
      {
        path: "school/contacts/:schoolId/feedback/add/:schoolId",
        name: "studentFeedbackRecordAdd",
        component: () => import("@/views/studentAid/school/contact/feedbackRecord/add.vue"),
        meta: {
          title: "新增反馈记录"
        }
      },
      {
        path: "school/contacts/:schoolId/feedback/edit/:id",
        name: "studentFeedbackRecordEdit",
        component: () => import("@/views/studentAid/school/contact/feedbackRecord/add.vue"),
        meta: {
          title: "编辑反馈记录"
        }
      },
      {
        path: "school/feedback/detail/:id",
        name: "studentFeedbackRecordDetail",
        component: () => import("@/views/studentAid/school/contact/feedbackRecord/detail.vue"),
        meta: {
          title: "反馈记录详情"
        }
      },

      {
        path: "school/detail/:id",
        name: "schoolDetail",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/school/detail.vue"),
        meta: {
          title: "学校详情"
        }
      },
      {
        path: "school/add",
        name: "addSchool",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/school/add.vue"),
        meta: {
          title: "新增学校"
        }
      },
      {
        path: "school/add/:id",
        name: "editSchool",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/school/add.vue")
        ,
        meta: {
          title: "编辑学校信息"
        }
      },
      {
        path: "students",
        name: "studentList",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/students/list.vue"),
        meta: {
          title: "学生管理"
        }
      },
      {
        path: "students/create",
        name: "studentCreate",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/students/edit.vue"),
        meta: {
          title: "新增学生"
        }
      },
      {
        path: "students/edit/:id",
        name: "studentEdit",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/students/edit.vue"),
        meta: {
          title: "编辑学生"
        }
      },
      {
        path: "students/detail/:id",
        name: "studentDetail",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/students/detail.vue"),
        meta: {
          title: "学生详情"
        }
      },
      {
        path: "pairingRecords",
        name: "pairingRecords",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/pairingRecords/list.vue"),
        meta: {
          title: "助学结对管理"
        }
      },
      {
        path: "pairingRecordsDetail/:id",
        name: "pairingRecordsDetail",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/pairingRecords/detail.vue"),
        meta: {
          title: "助学结对详情"
        }
      },
      {
        path: "report",
        name: "report",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/report/list.vue"),
        meta: {
          title: "捐助记录报表"
        }
      },
      {
        path: "orders",
        name: "orders",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/orders/list.vue"),
        meta: {
          title: "待付款订单管理"
        }
      },
      {
        path: "orderDetail/:id",
        name: "bidOrderDetail",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/orders/detail.vue"),
        meta: {
          title: "待付款订单详情"
        }
      },
      {
        path: "bids",
        name: "bids",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/bid/list.vue"),
        meta: {
          title: "助学标的管理"
        }
      },

      {
        path: "bidDetail/:id",
        name: "bidDetail",
        component: () =>
          import(/* webpackChunkName: "studentAid" */ "@/views/studentAid/bid/detail.vue"),
        meta: {
          title: "助学标的详情"
        }
      },

      {
        path:"donors",
        name:"donors",
        component:() => {
            return import("@/views/studentAid/donor/list.vue")
        },
        meta:{
          title: "捐助方管理"
        }
      },
      {
        path:"donors/add",
        name:"donorsAdd",
        component:() => {
          return import("@/views/studentAid/donor/add.vue")
        },
        meta:{
          title: "新增捐助方"
        }
      },
      {
        path:"donors/edit/:donorId",
        name:"donorsEdit",
        component:() => {
          return import("@/views/studentAid/donor/add.vue")
        },
        meta:{
          title: "编辑捐助方"
        }
      },
      {
        path:"donors/detail/:donorId",
        name:"donorsDetail",
        component:() => {
          return import("@/views/studentAid/donor/detail.vue")
        },
        meta:{
          title: "捐助方详情"
        }
      },
    ]
  }
];

export default studentAidRouter;
