import { ActionContext } from "vuex";
import api from "@/api/index";
import {
  AuthenticateResultModel,
  GetCurrentLoginInformationsOutput,
  OrganizationUnitDto,
  PermissionDefinitionDto,
  UserLoginInfoDto,
} from "@/api/appService";
import { setOu } from "@/utils/cookies";
import { GenerateMenuInput } from "@/store/module/permission";

export interface TenantData {
  displayName: string;
  name: string;
  logo: string;
  squareLogo: string;
}

export interface UserState {
  token: string;
  tokenExpireTime: number;
  name: string;
  avatar: string;
  introduction: string;
  tenantId?: number;
  roles: string[];
  permissions: string[];
  ou?: OrganizationUnitDto;
  user?: UserLoginInfoDto;
}

interface SetTokenInput {
  token: string;
  expireInSeconds: number;
}

let storage = JSON.parse(
  window.sessionStorage.getItem("user") || "{}"
) as UserState;

function serialize(params: any) {
  storage = { ...storage, ...params };
  window.sessionStorage.setItem("user", JSON.stringify(storage));
}

function getPermissionDefinitions() {
  return api.permissionDefinition.getAllCanUsePermissions();
}

export default {
  namespaced: true,
  state: () =>
    ({
      token: storage.token,
      tokenExpireTime: storage.tokenExpireTime,
      name: storage.name,
      avatar: storage.avatar,
      introduction: storage.avatar,
      tenantId: storage.tenantId,
      roles: storage.roles,
      permissions: storage.permissions,
      ou: storage.ou || {
        displayName: "公益啦 - SaaS平台",
        logoImageUrl: "/img/logo-square-2.png",
      },
      user: (storage.user as UserLoginInfoDto) || undefined,
    } as UserState),
  mutations: {
    SET_TOKEN(state: UserState, payload: SetTokenInput) {
      state.token = payload.token;
      state.tokenExpireTime =
        new Date().getTime() + payload.expireInSeconds * 1000;
      serialize(state);
    },
    SET_ROLES(state: UserState, roles: string[] | string) {
      if (Array.isArray(roles)) state.roles = [...roles];
      else state.roles = [roles];

      serialize(state);
    },

    SET_PERMISSIONS(state: UserState, permissions: string[]) {
      state.permissions = permissions;
      serialize(state);
    },
    SET_USER(state: UserState, user: UserLoginInfoDto) {
      state.user = { ...state.user, ...user };
      state.avatar = user.headImage ?? "";
      state.name = user.name ?? "";

      serialize(state);
    },
    SET_TENANT_ID(state: UserState, tenantId: number) {
      state.tenantId = tenantId;
      serialize(state);
    },
    SET_OU(state: UserState, ou: OrganizationUnitDto | undefined) {
      state.ou = ou;
      serialize(state);
      setOu(ou?.id?.toString() ?? "");
    },
    LOGOUT(state: UserState) {
      state.name = "";
      state.ou = undefined;
      state.roles = [];
      state.permissions = [];
      state.avatar = "";
      state.token = "";
      state.tenantId = undefined;
      state.user = undefined;
      state.tokenExpireTime = 0;

      serialize(state);
    },
  },
  actions: {
    login(
      context: ActionContext<UserState, any>,
      loginResult: AuthenticateResultModel
    ): Promise<void> {
      context.commit("SET_TOKEN", {
        token: loginResult.accessToken,
        expireInSeconds: loginResult.expireInSeconds,
      } as SetTokenInput);
      return new Promise((resolve) => {
        context.dispatch("initializeUser").then(() => {
          resolve();
        });
      });
    },
    initializeUser(context: ActionContext<UserState, any>): Promise<void> {
      return new Promise<void>((resolve) => {
        const fetchPermissionDefinition = getPermissionDefinitions();
        const fetchUser = api.session.getCurrentLoginInformations();
        Promise.all([fetchPermissionDefinition, fetchUser]).then(
          (allResult) => {
            const canUsePermissions = allResult[0] as PermissionDefinitionDto[];
            const userDetail =
              allResult[1] as GetCurrentLoginInformationsOutput;

            context.commit("SET_USER", userDetail.user);
            context.commit("app/SET_TENANT", userDetail.tenant, { root: true });

            context.commit("SET_PERMISSIONS", userDetail.permissions ?? []);

            context.commit("SET_OU", userDetail.organizationUnit);
            const organizationUnitType = userDetail.organizationUnit
              ? String(userDetail.organizationUnit.organizationType)
              : "Foundation";

            context
              .dispatch(
                "permission/generateMenu",
                {
                  permissions: userDetail.permissions ?? [],
                  roles: [],
                  type: organizationUnitType,
                  permissionDefinitions: canUsePermissions,
                } as GenerateMenuInput,
                { root: true }
              )
              .then(() => {
                resolve();
              });
          }
        );
      });
    },
    getUserInfo(context: ActionContext<UserState, any>): Promise<void> {
      return context.dispatch("initializeUser");
    },
    refreshToken(context: ActionContext<UserState, any>): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        api.tokenAuth
          .refreshToken()
          .then((result: AuthenticateResultModel) => {
            context.commit("SET_TOKEN", {
              token: result.accessToken,
              expireInSeconds: result.expireInSeconds,
            } as SetTokenInput);
            resolve();
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },
  },

  getters: {
    ouId(state: UserState): number | undefined {
      return state.ou?.id;
    },
    ou(state: UserState): OrganizationUnitDto | undefined {
      return state.ou;
    },
    token(state: UserState): string {
      return state.token ?? "";
    },
    tokenExpireTime(state: UserState): Date {
      if (state.tokenExpireTime) {
        return new Date(state.tokenExpireTime);
      } else {
        return new Date(1970, 0, 1);
      }
    },
    roles(state: UserState): string[] {
      return state.roles ?? [];
    },
    isAdmin(state: UserState): boolean {
      return state.roles.includes("Admin") || state.roles.includes("admin");
    },
    avatar(state: UserState): string {
      return state.avatar;
    },
    name(state: UserState): string {
      return state.name;
    },
    fullName(state: UserState): string {
      return state.token && state.user
        ? `${state.user.surname} ${state.user.name}`
        : "NotLogin";
    },
    permissions(state: UserState): string[] {
      return state.permissions;
    },
    // tenantId(state: UserState){
    //   return
    // }
  },
};
