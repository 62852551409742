import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const organizationRouter: RouteConfig[] = [
  {
    path: "organization",
    component: AppContent,
    name: "organization",
    meta: {
      title: "志愿团体",
    },
    children: [
      {
        path: "list",
        name: "organizationList",
        component: () => import("@/views/organization/list.vue"),
        meta: {
          title: "志愿团体列表",
        },
      },
      {
        path: "create",
        name: "organizationCreate",
        component: () => import("@/views/organization/edit.vue"),
        meta: {
          title: "创建志愿团体",
        },
      },
      {
        path: "edit/:id",
        name: "organizationEdit",
        component: () => import("@/views/organization/edit.vue"),
        meta: {
          title: "修改志愿团体",
        },
      },
      {
        path: "detail",
        name: "orgDetail",
        component: () => import("@/views/organization/detail.vue"),
        meta: {
          title: "志愿团体详情",
        },
      },
      {
        path: "detail/:id",
        name: "organizationDetail",
        component: () => import("@/views/organizationApply/detail.vue"),
        meta: {
          title: "志愿团体详情",
        },
      },
      {
        path: "import/result/:reportName",
        name: "uploadTemplateDetail",
        component: () => import("@/components/UploadTemplate/result.vue"),
        meta: {
          title: "模板导入结果",
        },
      },
    ],
  },
];

export default organizationRouter;
