import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const lecturerRouter: RouteConfig[] = [
  {
    path: "lecturerMaintain",
    component: AppContent,
    name: "lecturerMaintain",
    redirect: "",
    meta: {
      title: "讲师管理",
    },
    children: [
      {
        path: "lecturerList",
        name: "lecturerList",
        component: () => import("@/views/train/lecturer/list.vue"),
        meta: {
          title: "讲师列表",
        },
      },
      {
        path: "lecturer/create",
        name: "lecturerCreate",
        component: () => import("@/views/train/lecturer/edit.vue"),
        meta: {
          title: "新增讲师",
        },
      },
      {
        path: "lecturer/edit/:id",
        name: "lecturerEdit",
        component: () => import("@/views/train/lecturer/edit.vue"),
        meta: {
          title: "编辑讲师",
        },
      },
      {
        path: "lecturer/detail/:id",
        name: "lecturerDetail",
        component: () => import("@/views/train/lecturer/detail.vue"),
        meta: {
          title: "讲师详情",
        },
      },
    ],
  },
];

export default lecturerRouter;
