import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const feedbackRouter: RouteConfig[] = [
  {
    path: "feedback",
    component: AppContent,
    name: "feedback",
    redirect: "",
    meta: {
      title: "办公中心",
    },
    children: [
      {
        path: "feedback",
        name: "feedback",
        component: () => import("@/views/feedback/list.vue"),
        meta: {
          title: "官网反馈管理",
        },
      },
      {
        path: "feedbackReply/:feedbackId",
        name: "feedbackReply",
        component: () => import("@/views/feedback/replyList.vue"),
        meta: {
          title: "反馈信息回复",
        },
      },
    ],
  },
];

export default feedbackRouter;
