import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const fundRouter: RouteConfig[] = [
  {
    path: "fund",
    component: AppContent,
    name: "fund",
    redirect: "",
    meta: {
      title: "专项基金管理",
    },
    children: [
      {
        path: "fundMaintain",
        name: "fundMaintain",
        component: () => import("@/views/fund/fundMaintain/list.vue"),
        meta: {
          title: "基金设立",
        },
      },
      {
        path: "fundApply",
        name: "fundApply",
        component: () => import("@/views/fund/fundApply/list.vue"),
        meta: {
          title: "基金审核",
        },
        children: [
          {
            path: "detail/:id",
            name: "fundApplyDetail",
            component: () =>
              import("@/views/fund/fundMaintain/applyDetail.vue"),
            meta: {
              title: "基金申请详情",
            },
          },
        ],
      },
      {
        path: "fundMaintain/create",
        name: "fundMaintainCreate",
        component: () => import("@/views/fund/fundMaintain/create.vue"),
        meta: {
          title: "新增基金",
        },
      },
      {
        path: "fundMaintain/edit/:id",
        name: "fundMaintainEdit",
        component: () => import("@/views/fund/fundMaintain/edit.vue"),
        meta: {
          title: "修改基金",
        },
      },
      {
        path: "fundMaintain/moneyFormEdit/:id",
        name: "fundMoneyFormEdit",
        component: () => import("@/views/fund/fundMaintain/moneyFormEdit.vue"),
        meta: {
          title: "捐赠金额自定义",
        },
      },
      {
        path: "fundMaintain/editApply/:id",
        name: "fundMaintainApplyEdit",
        component: () => import("@/views/fund/fundMaintain/editApply.vue"),
        meta: {
          title: "修改基金申请",
        },
      },
      {
        path: "fundMaintain/detail/:id",
        name: "fundMaintainDetail",
        component: () => import("@/views/fund/fundMaintain/detail.vue"),
        meta: {
          title: "基金详情",
        },
      },
      {
        path: "fundMaintain/applyDetail/:id",
        name: "fundMaintainApplyDetail",
        component: () => import("@/views/fund/fundMaintain/applyDetail.vue"),
        meta: {
          title: "基金申请详情",
        },
      },
      {
        path: "messageCenter",
        name: "messageCenter",
        component: () => import("@/views/fund/messageCenter/list.vue"),
        meta: {
          title: "基金消息",
        },
        children: [
          {
            path: "detail/:id",
            name: "messageCenterDetail",
            component: () => import("@/views/fund/messageCenter/detail.vue"),
            meta: {
              title: "基金消息详情",
            },
          },
        ],
      },
      {
        path: "messageCenter/messageMaintain",
        name: "messageMaintain",
        component: () =>
          import("@/views/fund/messageCenter/messageMaintain.vue"),
        meta: {
          title: "运维中心",
        },
      },
      {
        path: "messageCenter/create",
        name: "messageCenterCreate",
        component: () => import("@/views/fund/messageCenter/edit.vue"),
        meta: {
          title: "新增基金消息",
        },
      },
      {
        path: "messageCenter/edit/:id",
        name: "messageCenterEdit",
        component: () => import("@/views/fund/messageCenter/edit.vue"),
        meta: {
          title: "修改基金消息",
        },
      },

      {
        path: "fundUser",
        name: "fundUser",
        component: () => import("@/views/fund/fundUser/list.vue"),
        meta: {
          title: "基金成员管理",
        },
        children: [
          {
            path: "detail/:id",
            name: "fundUserDetail",
            component: () => import("@/views/fund/fundUser/detail.vue"),
            meta: {
              title: "基金成员详情",
            },
          },
        ],
      },
      {
        path: "fundUser/create",
        name: "fundUserCreate",
        component: () => import("@/views/fund/fundUser/edit.vue"),
        meta: {
          title: "新增基金成员",
        },
      },
      {
        path: "fundUser/edit/:id",
        name: "fundUserEdit",
        component: () => import("@/views/fund/fundUser/edit.vue"),
        meta: {
          title: "修改基金成员",
        },
      },

      {
        path: "fundModifyApply",
        name: "fundModifyApply",
        component: () => import("@/views/fund/fundModifyApply/list.vue"),
        meta: {
          title: "基金修改审核",
        },
      },
      {
        path: "fundModifyApply/detail/:id",
        name: "fundModifyApplyDetail",
        component: () => import("@/views/fund/fundModifyApply/detail.vue"),
        meta: {
          title: "基金修改详情",
        },
      },
      // {
      //   path: 'fundManageFeeRecord/detail/:id',
      //   name: 'fundManageFeeRecordDetail2',
      //   component: () => import('@/views/fund/fundManageFeeRecord/detail.vue'),
      //   meta: {
      //     title: '基金管理费详情'
      //   }
      // },
      {
        path: "fundManageFeeRecord/edit/:id",
        name: "fundManageFeeRecordEdit",
        component: () => import("@/views/fund/fundManageFeeRecord/edit.vue"),
        meta: {
          title: "编辑基金管理费",
        },
      },
    ],
  },
];

export default fundRouter;
