import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const fundProjectRouter: RouteConfig[] = [
  {
    path: "fundProject",
    component: AppContent,
    redirect: "",
    meta: {
      title: "项目管理",
    },
    children: [
      {
        path: "fundProjectAccount",
        name: "fundProjectAccount",
        component: () =>
          import("@/views/fundProject/fundProjectAccount/list.vue"),
        meta: {
          title: "项目台账管理",
        },
      },
      {
        path: "fundProjectList/:fundId",
        name: "fundProjectList",
        component: () => import("@/views/fundProject/fundProject/list.vue"),
        meta: {
          title: "基金会项目发布",
        },
      },
      {
        path: "fundProject",
        name: "fundProject",
        component: () => import("@/views/fundProject/fundProject/list.vue"),
        meta: {
          title: "基金会项目发布",
        },
      },
      {
        path: "fundProject/create",
        name: "fundProjectCreate",
        component: () => import("@/views/fundProject/fundProject/create.vue"),
        meta: {
          title: "新增基金会项目",
        },
      },
      {
        path: "fundProject/edit/:id",
        name: "fundProjectEdit",
        component: () => import("@/views/fundProject/fundProject/edit.vue"),
        meta: {
          title: "修改基金会项目",
        },
      },
      {
        path: "fundProject/moneyFormEdit/:id",
        name: "fundProjectMoneyFormEdit",
        component: () =>
          import("@/views/fundProject/fundProject/moneyFormEdit.vue"),
        meta: {
          title: "捐赠金额自定义",
        },
      },
      {
        path: "fundProject/editApply/:id",
        name: "fundProjectApplyEdit",
        component: () =>
          import("@/views/fundProject/fundProject/editApply.vue"),
        meta: {
          title: "基金会项目修改申请",
        },
      },
      {
        path: "fundProject/detail/:id",
        name: "fundProjectDetail",
        component: () => import("@/views/fundProject/fundProject/detail.vue"),
        meta: {
          title: "基金会项目详情",
        },
      },
      {
        path: "fundProject/createDonateOrder/:id",
        name: "createDonateOrder",
        component: () =>
          import("@/views/fundProject/fundProject/createDonateOrder.vue"),
        meta: {
          title: "新增捐助",
        },
      },
      {
        path: "fundProject/createEndReport/:id",
        name: "createEndReport",
        component: () =>
          import("@/views/fundProject/fundProject/createEndReport.vue"),
        meta: {
          title: "结项报告",
        },
      },
      {
        path: "fundProjectApply",
        name: "fundProjectApply",
        component: () =>
          import("@/views/fundProject/fundProjectApply/list.vue"),
        meta: {
          title: "基金会项目审核",
        },
      },
      {
        path: "fundProjectApply/detail/:id",
        name: "fundProjectApplyDetail",
        component: () =>
          import("@/views/fundProject/fundProjectApply/detail.vue"),
        meta: {
          title: "基金会项目审核详情",
        },
      },
      {
        path: "fundProjectYearSummary/:fundProjectId",
        name: "fundProjectYearSummary",
        component: () =>
          import("@/views/fundProject/fundProjectYearSummary/list.vue"),
        meta: {
          title: "年度总结管理",
        },
        children: [
          {
            path: "detail/:id",
            name: "fundProjectYearSummaryDetail",
            component: () =>
              import("@/views/fundProject/fundProjectYearSummary/detail.vue"),
            meta: {
              title: "年度总结详情",
            },
          },
        ],
      },
      {
        path: "fundProjectYearSummary/edit/:projectId",
        name: "fundProjectYearSummaryCreate",
        component: () =>
          import("@/views/fundProject/fundProjectYearSummary/edit.vue"),
        meta: {
          title: "新增结项报告",
        },
      },
      {
        path: "fundProjectYearSummary/edit/:id/:projectId",
        name: "fundProjectYearSummaryEdit",
        component: () =>
          import("@/views/fundProject/fundProjectYearSummary/edit.vue"),
        meta: {
          title: "修改结项报告",
        },
      },

      {
        path: "fundProjectModifyApply",
        name: "fundProjectModifyApply",
        component: () =>
          import("@/views/fundProject/fundProjectModifyApply/list.vue"),
        meta: {
          title: "基金项目修改审核",
        },
      },
      {
        path: "fundProjectModifyApply/detail/:id",
        name: "fundProjectModifyApplyDetail",
        component: () =>
          import("@/views/fundProject/fundProjectModifyApply/detail.vue"),
        meta: {
          title: "基金项目修改审核详情",
        },
      },
      {
        path: "fundProjectProgress",
        name: "fundProjectProgress",
        component: () =>
          import("@/views/fundProject/fundProjectProgress/fundProjectList.vue"),
        meta: {
          title: "基金会项目进展管理",
        },
      },
      {
        path: "fundProjectProgress/list/:projectId",
        name: "fundProjectProgressList",
        component: () =>
          import("@/views/fundProject/fundProjectProgress/list.vue"),
        meta: {
          title: "基金会项目进展",
        },
        children: [
          {
            path: "fundProjectProgress/detail/:id",
            name: "fundProjectProgressDetail",
            component: () =>
              import("@/views/fundProject/fundProjectProgress/detail.vue"),
            meta: {
              title: "项目进展详情",
            },
          },
        ],
      },
      {
        path: "fundProjectProgress/create/:projectId",
        name: "fundProjectProgressCreate",
        component: () =>
          import("@/views/fundProject/fundProjectProgress/edit.vue"),
        meta: {
          title: "新增项目进展",
        },
      },
      {
        path: "fundProjectProgress/edit/:id/:projectId",
        name: "fundProjectProgressEdit",
        component: () =>
          import("@/views/fundProject/fundProjectProgress/edit.vue"),
        meta: {
          title: "修改项目进展",
        },
      },

      {
        path: "fundAppropriateApply",
        name: "fundAppropriateApply",
        component: () =>
          import("@/views/fundProject/fundAppropriateApply/list.vue"),
        meta: {
          title: "一般业务走款申请",
        },
      },
      {
        path: "fundAppropriateApply/create",
        name: "fundAppropriateApplyCreate",
        component: () =>
          import("@/views/fundProject/fundAppropriateApply/edit.vue"),
        meta: {
          title: "新增一般业务走款申请",
        },
      },
      {
        path: "fundAppropriateApply/detail/:id",
        name: "fundAppropriateApplyDetail",
        component: () =>
          import("@/views/fundProject/fundAppropriateApply/detail.vue"),
        meta: {
          title: "一般业务走款申请详情",
        },
      },
      {
        path: "fundProjectReport/:fundProjectId",
        name: "fundProjectReport",
        component: () =>
          import("@/views/fundProject/fundProjectReport/list.vue"),
        meta: {
          title: "项目报告",
        },
      },
    ],
  },
];

export default fundProjectRouter;
