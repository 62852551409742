import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const taskRouter: RouteConfig[] = [
  {
    path: "taskCenter",
    component: AppContent,
    name: "taskCenter",
    redirect: "/taskCenter",
    meta: {
      title: "办公中心",
    },
    children: [
      {
        path: "taskManager",
        name: "taskManager",
        component: () => import("@/views/taskCenter/taskManager/taskList.vue"),
        meta: {
          title: "任务管理",
        },
      },
      {
        path: "detail/:id",
        name: "taskManagerDetail",
        component: () => import("@/views/taskCenter/taskManager/detail.vue"),
        meta: {
          title: "任务详情",
        },
      },
      {
        path: "taskManager/create",
        name: "taskManagerCreate",
        component: () => import("@/views/taskCenter/taskManager/edit.vue"),
        meta: {
          title: "新增任务",
        },
      },
      {
        path: "taskManager/edit/:id",
        name: "taskManagerEdit",
        component: () => import("@/views/taskCenter/taskManager/edit.vue"),
        meta: {
          title: "修改任务",
        },
      },
      {
        path: "myTask",
        name: "myTask",
        component: () => import("@/views/taskCenter/myTask/myTask.vue"),
        meta: {
          title: "待办任务",
        },
      },
    ],
  },
];

export default taskRouter;
