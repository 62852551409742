import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import plugins and bind to Vue
import Pin from "vue-pin";
import ElementUI from "element-ui";
import SvgIcon from "vue-svgicon";

Vue.use(Pin);
Vue.use(ElementUI);
Vue.use(SvgIcon, {
  tagName: "svg-icon",
  defaultWidth: "1em",
  defaultHeight: "1em" /**/,
});

// import core
import "@/icons/components";
import "@/permission";
import permissions from "@/core/permission";

// import filters

import filter from "@/utils/filter";
import enumFilter from "@/mixins/filters/enums";
import {
  clearHistorySearchParams,
  getActualPageSize,
  getActualPageIndex,
} from "./core/core";
import { DirectiveBinding } from "vue/types/options";

Vue.filter("formatDate", filter.formatDate);
Vue.filter("formatYearMonthDayDate", filter.formatYearMonthDayDate);
Vue.filter("formatYearMonthDayDateForProjectEndTime", filter.formatYearMonthDayDateForProjectEndTime);
Vue.filter("formatYearMonthData", filter.formatYearMonthData);
Vue.filter("currency", filter.currency);
Vue.filter("formatPartnerOrg", filter.formatPartnerOrg);
Vue.filter("formatYearMonth", filter.formatYearMonth);
Vue.filter("formatCurrencyType", filter.formatCurrencyType);
Vue.filter("formatCapitalProperty", filter.formatCapitalProperty);
Vue.filter("formatIncomeType", filter.formatIncomeType);
Vue.filter("formatIncomeCategory", filter.formatIncomeCategory);
Vue.filter("formatCurrency", filter.formatCurrency);
Vue.filter("getSingleColumnSum", filter.getSingleColumnSum);
Vue.filter("formatMoney", filter.formatMoney);
Vue.filter("formatYesOrNo", filter.formatYesOrNo);
Vue.filter("formatEnumValue", filter.formatEnumValue);
Vue.filter(
  "volunteerHistoryServiceType",
  enumFilter.filters.volunteerHistoryServiceType
);
Vue.filter("formatDataDictionary", filter.formatByDataDictionary);
Vue.filter("formatIsSynced", filter.formatIsSynced);
Vue.filter("formatIsEnabled", filter.formatIsEnabled);
Vue.filter("formatGrade", filter.formatGrade);
Vue.filter("formatType", filter.formatType);


// vue config

const hasPermission = (permissionName: string) => {
  const permissions = store.state.user.permissions as string[];
  return (
    permissions &&
    permissions instanceof Array &&
    permissions.length &&
    permissions.some((name) => name === permissionName)
  );
};

const eventBus = new Vue();
(window as any).$eventBus = eventBus;

Vue.config.productionTip = false;
Vue.prototype.$permissions = permissions;
Vue.prototype.hasPermission = hasPermission;
Vue.prototype.$uploadUrl =
  process.env.VUE_APP_BASE_API + "/api/services/app/Client/Upload?id=2";
Vue.prototype.baseURL = process.env.VUE_APP_BASE_API;
Vue.prototype.$bus = eventBus;
Vue.prototype.$clearHistorySearchParams = clearHistorySearchParams;
Vue.prototype.$getActualPageIndex = getActualPageIndex;
Vue.prototype.$getActualPageSize = getActualPageSize;

// directive

Vue.directive("permission", {
  bind: (el: HTMLElement, binding: DirectiveBinding) => {
    const permissionName = binding.value;
    if (!hasPermission(permissionName)) {
      el.style.display = "none";
    }
  },
  update(el: HTMLElement, binding: DirectiveBinding) {
    console.log(" directive:permission update");
    const permissionName = binding.value;
    if (!hasPermission(permissionName)) {
      el.style.display = "none";
    } else {
      el.style.display = "";
    }
  },
});

Vue.directive("feature", {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    console.log(binding.value)
  },
  update(el: HTMLElement, binding: DirectiveBinding) {
    console.log(binding.value)
  },
});

// start

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
