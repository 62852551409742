import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const starLevelEvaluateRouter: RouteConfig[] = [
  {
    path: "starLevelEvaluate",
    component: AppContent,
    name: "starLevelEvaluate",
    redirect: "",
    meta: {
      title: "" +
        "志愿者星级评定",
    },
    children: [
      {
        path: "recordAndPublicity",
        name: "recordAndPublicity",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/recordAndPublicity/list.vue"
            ),
        meta: {
          title: "星级评定记录&公示管理",
        },
      },
      {
        path: "recordAndPublicity-record/:id",
        name: "recordAndPublicity-record",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/recordAndPublicity/record/list.vue"
            ),
        meta: {
          title: "星级评定记录",
        },
      },
      {
        path: "recordAndPublicity-record-detail/:id",
        name: "recordAndPublicity-record-detail",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/recordAndPublicity/record/detail.vue"
            ),
        meta: {
          title: "评定记录详情",
        },
      },
      {
        path: "summary-table",
        name: "summary-table",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/summaryTable/list.vue"
            ),
        meta: {
          title: "星级评定总结表",
        },
      },
      {
        path: "summary-table-create",
        name: "summary-table-create",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/summaryTable/edit.vue"
            ),
        meta: {
          title: "新建星级评定总结表",
        },
      },
      // {
      //   path: "publicity",
      //   name: "publicity",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/publicity/list.vue"
      //       ),
      //   meta: {
      //     title: "评估公示管理",
      //   },
      // },
      {
        path: "maintain",
        name: "maintain",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/list.vue"
            ),
        meta: {
          title: "星级评定信息维护",
        },
      },
      {
        path: "maintain-detail/:id",
        name: "maintain-detail",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/detail.vue"
            ),
        meta: {
          title: "星级评定信息详情",
        },
      },
      {
        path: "star-level-evaluate-publish",
        name: "star-level-evaluate-publish",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/edit.vue"
            ),
        meta: {
          title: "发布星级评定",
        },
      },
      {
        path: "star-level-evaluate-edit/:id",
        name: "star-level-evaluate-edit",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/edit.vue"
            ),
        meta: {
          title: "编辑星级评定",
        },
      },
      {
        path: "registration/:id",
        name: "registration",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/registration/list.vue"
            ),
        meta: {
          title: "星级评定报名管理",
        },
      },
      // {
      //   path: "registration-detail/:id",
      //   name: "registration-detail",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/registration/detail.vue"
      //       ),
      //   meta: {
      //     title: "星级评定报名详情",
      //   },
      // },
      {
        path: "examination/:id",
        name: "examination",
        component: () =>
          import(
            /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/examination/list.vue"
            ),
        meta: {
          title: "考题管理",
        },
      },
      // {
      //   path: "name-list",
      //   name: "name-list",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "starLevelEvaluate" */ "@/views/starLevelEvaluate/maintain/nameList/list.vue"
      //       ),
      //   meta: {
      //     title: "星级名单",
      //   },
      // },
    ],
  },
];

export default starLevelEvaluateRouter;
