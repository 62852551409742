


























import { Component, Prop, Watch } from "vue-property-decorator";

interface ISoringPagedResultDto {
  sorting: string;
  maxResultCount: number;
  skipCount: number;
}

import { Vue } from "vue-property-decorator";

@Component({ name: "PagedTableViewSimple" })
export default class PagedTableViewSimple extends Vue {
  @Prop()
  queryForm: any;

  @Prop({ required: true })
  searchFunction!: any;

  @Prop({})
  pageSizes!: Array<number>;

  @Prop({ required: false, default: 10 })
  pageSize!: number;

  get pageSizeChoose() {
    if (this.pageSizes && this.pageSizes.length) {
      return this.pageSizes;
    } else {
      return [10, 20, 50, 100];
    }
  }

  @Watch("pageSize")
  onPageSizeChange(newValue: number) {
    this.table.pageSize = newValue;
    this.table.page = 1;
    this.fetchData();
  }

  // private fields
  tableItems: any = [];
  table: any = {
    listLoading: true,
    page: 1,
    totalCount: 0,
    pageSize: 0,
    sorting: "",
  };

  created() {
    this.table.pageSize = this.pageSize;
    this.fetchData();
  }

  refreshData() {
    console.log("pagedTableView:refreshData");
    this.table.page = 1;
    this.fetchData();
  }

  fetchData() {
    const pageParameter: ISoringPagedResultDto = {
      skipCount: (this.table.page - 1) * this.table.pageSize,
      maxResultCount: this.table.pageSize,
      sorting: this.table.sorting,
    };
    this.table.listLoading = true;

    const fetchResult = this.searchFunction(
      Object.assign({}, this.queryForm, pageParameter)
    );
    fetchResult.then((res: any) => {
      this.table.listLoading = false;
      this.tableItems = res.items!;
      this.table.totalCount = res.totalCount!;
    });

    return fetchResult;
  }

  // events handles
  handleSearch() {
    this.table.page = 1;
    this.fetchData();
  }

  // 更新当前页
  async current_change(e: number) {
    this.table.page = e;
    await this.fetchData();
  }

  // Table排序
  async sort(e: any) {
    console.log("sort : ", e);
    if (e.prop && e.order) {
      this.table.sorting = `${e.prop} ${e.order}`;
    }
    this.fetchData();
  }

  // 修改一页显示的条目
  handleSizeChange(e: number) {
    this.table.pageSize = e;
    this.fetchData();
  }
}
